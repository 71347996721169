import styled from "styled-components"
import { MiddleContainer } from "components/Containers"

import PatternImage from "assets/images/topology-pattern.svg"

export default function PageHead({ children, className }) {
  return (
    <Holder className={className}>
      <MiddleContainer>{children}</MiddleContainer>
    </Holder>
  )
}

const Holder = styled.div`
  position: relative;
  padding: 5em 0 3em 0;
  background: linear-gradient(
    89.37deg,
    rgba(18, 42, 71, 0.972136) -0.74%,
    #225b7b 85.93%
  );
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${PatternImage});
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right bottom;
  }
`
