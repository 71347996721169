import { useMemo } from "react"
import styled from "styled-components"
const colorAoi = (item) => {
  switch (item.aoi_type) {
    case 1:
      return "rgb(160, 185, 208)"
    case 2:
      return "rgb(247, 81, 81)"
    case 3:
      return "rgb(59, 188, 151)"
    default:
      return "rgb(0, 119, 255)"
  }
}
const maxWidth = 210
const safeWidthForLabels = 90
export default function AOIHoverOverlay(props) {
  const calculateWidthOfTextElement = (item) => {
    const hiddenElement = document.createElement("span")
    hiddenElement.style.visibility = "hidden"
    hiddenElement.style.whiteSpace = "nowrap"
    hiddenElement.style.fontSize = "12px"
    hiddenElement.style.padding = "0px 6px"
    hiddenElement.textContent = item
    document.body.appendChild(hiddenElement)
    const width = hiddenElement.offsetWidth
    document.body.removeChild(hiddenElement)
    return width
  }
  const labelsLength = useMemo(() => {
    if (props?.data?.alias_names?.length > 0) {
      const names = props.data.alias_names
      const widthTakenByAllElements = names.reduce((acc, item) => {
        acc += calculateWidthOfTextElement(item)
        return acc
      }, 0)
      if (widthTakenByAllElements > maxWidth - safeWidthForLabels) {
        const displayable = []
        let totalWidthTaken = 0
        names.map((name) => {
          const currentWidth = calculateWidthOfTextElement(name)
          if (totalWidthTaken + safeWidthForLabels < maxWidth) {
            displayable.push(name)
            totalWidthTaken += currentWidth
          }
          return name
        })
        return displayable.length
      }
      return names.length
    }
    return 2
  }, [props.data])
  const getLabels = useMemo(() => {
    return props &&
      props.data &&
      props.data.alias_names &&
      props.data.alias_names.length > labelsLength
      ? props.data.alias_names.slice(0, labelsLength)
      : props.data.alias_names
  }, [props, labelsLength])
  let labels =
    getLabels &&
    getLabels.map((item, index) => {
      return (
        <PopUpLabel key={index}>
          <EllipsisSpan title={item}>{item}</EllipsisSpan>
        </PopUpLabel>
      )
    })

  return (
    <Holder onMouseLeave={() => props.hideMe()}>
      <PopUpDialogContent>
        <PopUpTitleContainer>
          <PopUpCountryTitle onClick={props.click}>
            <NameSpan title={props.data.name}>{props.data.name}</NameSpan>
            <MinWidthDiv>
              <svg
                width="15"
                height="21"
                viewBox="0 0 15 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.3472 4.49139C13.6424 2.93187 12.422 1.63483 10.9219 0.849341C9.86634 0.296535 8.68706 0.00678117 7.5 0C6.31301 0.00678117 5.13366 0.296535 4.07812 0.849341C2.57802 1.63483 1.35762 2.93187 0.652847 4.49139C0.0919552 5.73319 -0.0791525 7.0883 0.0320953 8.42955C0.0952885 9.19018 0.249174 9.94642 0.474586 10.675C0.793191 11.7058 1.24256 12.6888 1.74873 13.6375C2.65288 15.3321 3.75383 16.906 4.97088 18.384C5.17074 18.6273 5.37365 18.868 5.579 19.1065C5.8458 19.4165 6.11718 19.7222 6.39363 20.0232C6.65849 20.3117 6.92022 20.6063 7.21952 20.8587L7.22619 20.8642C7.28028 20.9092 7.33875 20.951 7.4041 20.9771C7.43368 20.989 7.46708 20.9999 7.4991 21H7.50097C7.53299 20.9999 7.56639 20.989 7.59597 20.9771C7.66125 20.951 7.71979 20.9092 7.77388 20.8642C7.77611 20.8624 7.77833 20.8606 7.78048 20.8587C8.07985 20.6063 8.34151 20.3117 8.60644 20.0232C8.88289 19.7222 9.15427 19.4165 9.421 19.1065C9.62635 18.868 9.82926 18.6273 10.0292 18.384C11.2462 16.906 12.3472 15.3321 13.2513 13.6375C13.7575 12.6888 14.2068 11.7058 14.5255 10.675C14.7508 9.94642 14.9048 9.19018 14.9679 8.42955C15.0792 7.0883 14.908 5.73319 14.3472 4.49139ZM7.5 13.328C4.5552 13.328 2.16795 10.8996 2.16795 7.90416C2.16795 4.90872 4.5552 2.48049 7.5 2.48049C10.4448 2.48049 12.8321 4.90872 12.8321 7.90416C12.8321 10.8996 10.4448 13.328 7.5 13.328Z"
                  fill={colorAoi(props.data)}
                />
              </svg>
            </MinWidthDiv>
          </PopUpCountryTitle>

          {props.data.alias_names.length > 0 && (
            <PopUpLabelContainer>
              {labels}
              {props.data.alias_names.length > labelsLength && (
                <MoreLabel>
                  {props.data.alias_names.length - labelsLength} +
                </MoreLabel>
              )}
            </PopUpLabelContainer>
          )}
        </PopUpTitleContainer>
      </PopUpDialogContent>
    </Holder>
  )
}

const PopUpTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: start;
  border-radius: 50%;
  width: 100%;
  background: #122a47;
`
const Holder = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: #122a47;
  border-radius: 8px;
  max-width: 300px;
  z-index: 400000;
  min-width: 300px;
  overflow: hidden;
`

const PopUpDialogContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #122a47;
  border-radius: 8px;
  > i {
    font-size: 12rem;
  }
`
const PopUpCountryTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center
  background: #122a47;
  border-radius: 8px;
  width: 100%;
  color: #fff;
  white-space: nowrap;
  padding: 10px 10px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  > div {
    margin-left: 48px
  }
`

const PopUpLabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-items: start;
  padding: 0px 10px;
  margin-bottom: 10px;
  border-radius: 8px;
`
const PopUpLabel = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  padding: 0px 8px;
  margin-right: 4px;
  border-radius: 2px;
  background: #9ba5b1;
  color: #122a47;
`

const MoreLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 20%;
  color: white;
  font-weight: bold;
  padding: 1px 8px;
  margin-right: 4px;
  border-radius: 2px;
  background: #9ba5b1;
  color: #122a47;
`

const EllipsisSpan = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`

const NameSpan = styled.p`
  word-wrap: break-word;
  min-width: 200px;
  max-width: 200px;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  white-space: normal;
`
const MinWidthDiv = styled.div`
  min-width: 15px;
`
