import InfoIcon from "components/Icons/InfoIcon"
import { Button } from "primereact/button"
import FilterIcon from "assets/images/icon-report.svg"
import { useStore } from "state/store"
import styled from "styled-components"

function FilterButton() {
  const setChartFilterDialogVisible = useStore(
    (state) => state.setChartFilterDialogVisible,
  )

  return (
    <StyledButton
      type="button"
      label="Chart Settings"
      icon={<InfoIcon src={FilterIcon} />}
      className=""
      badgeClassName="p-badge-danger"
      onClick={() => {
        setChartFilterDialogVisible(true)
      }}
    />
  )
}

const StyledButton = styled(Button)`
  background: rgba(4, 61, 93, 0.05);
  padding: 1em;
  color: #1d1d1b;
  span {
    font-weight: 400;
  }
`

export default FilterButton
