import styled from "styled-components"

export default function AmountBadge({ children, className }) {
  return <Holder className={className}>{children}</Holder>
}

const Holder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.2em;
  background-color: #e4e4e4;
  color: #000000;
  font-weight: 700;
  font-size: 0.875em;
  padding: 0.6em 1em;
`
