import { IMAGE_KEYS } from "dataset/image"
import { VESSEL_KEYS } from "dataset/vessel"

/**
 * Filter images by searchValue, inputValue, startDate, endDate, filterStartDate, filterEndDate, selectedLocations, selectedSensors, selectedSources
 * @param {Array} images
 * @param {Array} searchValue
 * @param {String} inputValue
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {Date} filterStartDate
 * @param {Date} filterEndDate
 * @param {Array} selectedLocations
 * @param {Array} selectedSensors
 * @param {Array} selectedSources
 * @returns
 */
export function filterImages(
  images = [],
  searchValue = [],
  inputValue = "",
  startDate,
  endDate,
  filterStartDate,
  filterEndDate,
  selectedLocations,
  selectedSensors,
  selectedSources,
) {
  // Filter by searchValue
  let searchedList = []
  if (searchValue.length > 0) {
    for (let i = 0; i < searchValue.length; i++) {
      let item = searchValue[i]
      if (typeof item === "string") {
        item = item.replace(/"/g, "")
        const _images = images.filter((image) =>
          image[IMAGE_KEYS.title].toLowerCase().includes(item.toLowerCase()),
        )
        for (let k = 0; k < _images.length; k++) {
          if (!searchedList.includes(_images[k])) {
            searchedList.push(_images[k])
          }
        }
      } else {
        for (let j = 0; j < item[VESSEL_KEYS.images].length; j++) {
          const id = item[VESSEL_KEYS.images][j]
          const image = images.find((image) => image[IMAGE_KEYS.id] === id)
          if (image) {
            if (!searchedList.includes(image)) {
              searchedList.push(image)
            }
          }
        }
      }
    }
  } else {
    searchedList = [...images]
  }

  // Filter by inputValue
  if (inputValue !== "") {
    const imagesByKey = images.filter((image) => {
      if (
        image[IMAGE_KEYS.title].toLowerCase().includes(inputValue.toLowerCase())
      ) {
        return true
      }
      return false
    })

    if (searchValue.length > 0) {
      for (let i = 0; i < imagesByKey.length; i++) {
        if (!searchedList.includes(imagesByKey[i])) {
          searchedList.push(imagesByKey[i])
        }
      }
    } else {
      searchedList = imagesByKey
    }
  }

  // Filter by Date
  const noDateFilter =
    startDate.getTime() === filterStartDate.getTime() &&
    endDate.getTime() === filterEndDate.getTime()

  if (!noDateFilter) {
    searchedList = searchedList.filter(
      (image) =>
        image[IMAGE_KEYS.date_taken] &&
        image.date >= filterStartDate &&
        image.date <= filterEndDate,
    )
  }

  // Filter location
  if (selectedLocations.length > 0) {
    searchedList = searchedList.filter((image) =>
      selectedLocations?.includes(image[IMAGE_KEYS.region_aoi]),
    )
  }

  // Filter sensor
  if (selectedSensors.length > 0) {
    searchedList = searchedList.filter((image) =>
      selectedSensors?.includes(image[IMAGE_KEYS.sensor]),
    )
  }

  // Filter source
  if (selectedSources.length > 0) {
    searchedList = searchedList.filter((image) =>
      selectedSources?.includes(image[IMAGE_KEYS.source]),
    )
  }

  return searchedList
}

/**
 * Get timeline data from images
 * @param {Array} images
 * @returns Array of timeline data
 */
export const getTimelineData = (images) => {
  const result = []
  const years = []

  //add date
  images.map(
    (d) =>
      (d.date = d[IMAGE_KEYS.date_taken]
        ? new Date(d[IMAGE_KEYS.date_taken])
        : null),
  )

  for (let i = 0; i < images.length; i++) {
    if (images[i].date) {
      const year = images[i].date.getFullYear()
      const month = images[i].date.getMonth() + 1

      const findIndex = result.findIndex(
        (r) => r.year === year && r.month === month,
      )

      if (findIndex === -1) {
        result.push({
          date: month === 1 ? year.toString() : "",
          year: year,
          month: month,
          count: 1,
        })
      } else {
        result[findIndex].count += 1
      }

      if (years.findIndex((y) => y === year) === -1) {
        years.push(year)
      }
    }
  }

  //Add empty month
  for (let i = 0; i < years.length; i++) {
    for (let j = 1; j <= 12; j++) {
      if (
        result.findIndex((r) => r.year === years[i] && r.month === j) === -1
      ) {
        result.push({
          date: j === 1 ? years[i].toString() : "",
          year: years[i],
          month: j,
          count: 0,
        })
      }
    }
  }

  //Sort results according to year and month
  result.sort(function (a, b) {
    return new Date(a.year, a.month, 1) - new Date(b.year, b.month, 1)
  })

  return result
}
