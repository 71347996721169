import { memo, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Dropdown } from "primereact/dropdown"

import { VESSEL_IDENTITY_TYPE, VESSEL_KEYS } from "dataset/vessel"
import { H12, H8 } from "components/Labels"

import ArrowDownIcon from "assets/images/icon-arrow-down.svg"
import InfoIcon from "assets/images/icon-info.svg"
import { getValueForHumans } from "utils"

function IdentityDropdown({ options }) {
  const navigate = useNavigate()

  const valueTemplate = (option, props) => {
    return (
      <DropdownLabel>
        <IdentityInfo>
          <img src={InfoIcon} alt="" />
          <span>{`${options.length} Identities`}</span>
        </IdentityInfo>
        <DropdownItem>
          {option ? (
            <>
              <ItemName>{option[VESSEL_KEYS.target_name]}</ItemName>
              <ItemType
                color={
                  VESSEL_IDENTITY_TYPE.find(
                    (d) => d.value === option[VESSEL_KEYS.identity],
                  )?.color
                }
              >
                {
                  VESSEL_IDENTITY_TYPE.find(
                    (d) => d.value === option[VESSEL_KEYS.identity],
                  )?.label
                }
              </ItemType>
            </>
          ) : (
            <ItemName>
              {options.length === 0
                ? "No linked identities"
                : "Select an identity"}
            </ItemName>
          )}
        </DropdownItem>
      </DropdownLabel>
    )
  }

  const itemTemplate = (option) => {
    return (
      <OptionItem>
        <div className="flex justify-content-between">
          <ItemName>{option[VESSEL_KEYS.target_name]}</ItemName>
          <ItemType
            color={
              VESSEL_IDENTITY_TYPE.find(
                (d) => d.value === option[VESSEL_KEYS.identity],
              )?.color
            }
          >
            {
              VESSEL_IDENTITY_TYPE.find(
                (d) => d.value === option[VESSEL_KEYS.identity],
              )?.label
            }
          </ItemType>
        </div>
        <div className="flex align-items-center gap-2">
          <div className="flex align-items-center">
            <H8>IMO: </H8>
            <H12>{getValueForHumans(option[VESSEL_KEYS.target_imo])}</H12>
          </div>
          <div className="flex align-items-center">
            <H8>MMSI: </H8>
            <H12>{getValueForHumans(option[VESSEL_KEYS.target_mmsi])}</H12>
          </div>
        </div>
      </OptionItem>
    )
  }

  const [value, setValue] = useState(null)

  return (
    <Holder
      value={value}
      options={options}
      onChange={(e) => {
        navigate(`/vessel-profile?id=${e.value?.id}`)
        setValue(e.value)
      }}
      valueTemplate={valueTemplate}
      itemTemplate={itemTemplate}
      scrollHeight="500px"
      optionLabel={VESSEL_KEYS.target_name}
      placeholder="Select a identity"
      dropdownIcon="pi-arrow-down"
    />
  )
}

export default memo(IdentityDropdown)

const Holder = styled(Dropdown)`
  .p-dropdown-label {
    padding: 0;
  }
  .pi-arrow-down {
    width: 0.7em;
    height: 0.7em;
    background-image: url(${ArrowDownIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &:before {
      display: none;
    }
  }
`

const DropdownItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const ItemName = styled.div`
  font-size: 1em;
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
`

const ItemType = styled.div`
  font-size: 1em;
  font-weight: 100;
  color: ${(props) => props.color};
  margin-left: 1em;
  display: flex;
  justify-content: flex-end;
`

const DropdownLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IdentityInfo = styled.div`
  padding: 0.8em;
  margin-right: 0.8em;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > img {
    width: 1.1em;
    height: 1.1em;
  }
  > span {
    min-width: 6em;
    text-align: right;
    font-weight: 700;
  }
`

const OptionItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.2em;
`
