import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Lottie from "react-lottie"
import styled from "styled-components"

import { device } from "theme/device"
import { useStore } from "state/store"
import { targetCenterData } from "dataset/targetCenterData"
import PageTransition from "components/PageTransition"
import { MiddleContainer } from "components/Containers"
import BannerImg from "assets/images/Banner/new-home-banner.png"
import ArrowLeft from "assets/images/Banner/arrow-left.svg"

export default function Home() {
  const navigate = useNavigate()

  const currentUser = useStore((state) => state.currentUser)

  const [stopAnimate, setStopAnimate] = useState({ index: -1, isStopped: true })

  const handleStartAnimation = (indexValue) => {
    setStopAnimate({ index: indexValue, isStopped: false })
  }

  const handleStopAnimation = () => {
    setStopAnimate({ index: -1, isStopped: true })
  }

  const handleClickCard = (link) => {
    navigate(link)
  }

  return (
    <PageTransition>
      <Holder>
        <MiddleContainer>
          <CardContainer>
            {targetCenterData?.map((data, index) => (
              <Card
                key={index}
                onClick={() => {
                  handleClickCard(data.link)
                }}
              >
                <CardContent
                  onMouseEnter={(e) => {
                    handleStartAnimation(index)
                  }}
                  onMouseLeave={() => {
                    handleStopAnimation()
                  }}
                >
                  <ImageContainer>
                    <img
                      src={data.icon}
                      alt={data.icon}
                      className="static-icon"
                    />
                    <div className="animated-icon">
                      <Lottie
                        options={data.options}
                        height="100%"
                        width="100%"
                        isClickToPauseDisabled
                        isStopped={
                          stopAnimate.index === index
                            ? stopAnimate.isStopped
                            : true
                        }
                      />
                    </div>
                  </ImageContainer>
                  <CardTitle>
                    <h3 className="card-title-text">{data.title}</h3>
                    <div className="arrow-img">
                      <img src={ArrowLeft} alt={ArrowLeft} />
                    </div>
                  </CardTitle>
                  <CardCaption className="caption-text">
                    {data.content}
                  </CardCaption>
                </CardContent>
              </Card>
            ))}
          </CardContainer>
        </MiddleContainer>
        <CopyRight>
          Image © {new Date().getFullYear()} Planet Labs PBC. All Rights
          Reserved. Reprinted by permission.
        </CopyRight>
      </Holder>
    </PageTransition>
  )
}

const Holder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: black;
  min-height: 85vh;
  padding: 7em 0 2em 0;
  background-image: url(${BannerImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180.07deg,
      #122a47 4.12%,
      #132e4b 17.04%,
      rgba(26, 66, 97, 0.67) 86.46%,
      rgba(34, 91, 123, 0) 99.93%
    );
  }
  @media ${device.tablet} {
    padding: 8em 0 3em 0;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Card = styled.div`
  width: 50%;
  cursor: pointer;
  @media ${device.tablet} {
    width: 100%;
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 1em;
  padding: 1em;
  border-radius: 0.5em;
  background-color: #ffffff00;
  .arrow-img {
    width: 2.2em;
    height: 2.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary};
    visibility: hidden;
  }
  .static-icon {
    display: block;
  }
  .animated-icon {
    display: none;
  }
  &:hover {
    background-color: #ffffffff;
    .card-title-text {
      color: ${(props) => props.theme.colors.primary_5};
    }
    .arrow-img {
      visibility: visible;
    }
    .caption-text {
      color: ${(props) => props.theme.colors.primary_color};
    }
    .static-icon {
      display: none;
    }
    .animated-icon {
      display: block;
    }
  }
  @media ${device.tablet} {
    .arrow-img {
      display: none;
    }
  }
`
const ImageContainer = styled.div`
  width: 8.5em;
  height: 8.5em;
  margin: auto;
`

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  color: #ffffff;
  padding: 2em 2em 1em 2em;
  > h3 {
    font-size: 1.8em;
    line-height: 1;
    font-weight: 700;
  }
  @media ${device.tablet} {
  }
`

const CardCaption = styled.div`
  line-height: 1.5;
  font-weight: 400;
  font-size: 0.9em;
  color: #dddddd;
  padding: 0 2em 0 2em;
`

const CopyRight = styled.div`
  position: absolute;
  bottom: 1em;
  right: 1em;
  font-size: 0.8em;
  color: #aaa;
`
