import { memo, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { toast } from "react-toastify"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { OverlayPanel } from "primereact/overlaypanel"
import { InputText } from "primereact/inputtext"
import { usePostHog } from 'posthog-js/react'
import MapGL, { MapProvider, Popup } from "react-map-gl"
import mapboxgl from "mapbox-gl"
import DeckGL from "@deck.gl/react"
import { IconLayer } from "@deck.gl/layers"
import { EditableGeoJsonLayer } from "@nebula.gl/layers"

import CircleButton from "components/Buttons/CircleButton"
import { useStore } from "state/store"
import ClusterLayer from "./ClusterLayer"
import { insidePointInPolygon } from "utils"
import { IMAGE_KEYS } from "dataset/image"
import {
  DRAW_MODES,
  ICON_MAPPING,
  START_VIEW_STATE,
  SWITCH_ZOOM,
  ZOOM_STEP,
} from "./constants"
import SearchIcon from "assets/images/icon-search.svg"
import DrawSquareIcon from "assets/images/icon-draw-square.svg"
import ResetIcon from "assets/images/icon-reset.svg"
import ZoomOutIcon from "assets/images/icon-minus.svg"
import ZoomInIcon from "assets/images/icon-plus.svg"
import CloseIcon from "assets/images/icon-times.svg"
import SatelliteIcon from "assets/images/icon-satellite.png"
import StreetIcon from "assets/images/icon-street.png"

// eslint-disable-next-line import/no-webpack-loader-syntax
const worker = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker")
mapboxgl.workerClass = worker.default

function ImageryLibraryMap() {
  const posthog = usePostHog()

  const setCurrentImage = useStore((state) => state.setCurrentImage)
  const setProfileGalleryDialogVisible = useStore(
    (state) => state.setProfileGalleryDialogVisible,
  )
  const isExpandedTimeline = useStore((state) => state.isExpandedTimeline)
  const isDrawMode = useStore((state) => state.isDrawMode)
  const setIsDrawMode = useStore((state) => state.setIsDrawMode)
  const timelinedImages = useStore((state) => state.timelinedImages)
  const setRectedImages = useStore((state) => state.setRectedImages)

  const [isCluster, setIsCluster] = useState(true)
  const [viewState, setViewState] = useState(START_VIEW_STATE)
  const [isSatellite, setIsSatellite] = useState(false)
  const [features, setFeatures] = useState({
    type: "FeatureCollection",
    features: [],
  })
  const [mode, setMode] = useState(DRAW_MODES[0])
  const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState([])
  const [selectedFeatureCoordinates, setSelectedFeatureCoordinates] =
    useState(null)
  const [modeConfig, setModeConfig] = useState({})
  const [isStartDraw, setIsStartDraw] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)

  const locationSearchPanel = useRef()
  const [locationValue, setLocationValue] = useState("")
  const [imagesForMap, setImagesForMap] = useState([])

  const changeClusterByZoom = (zoom) => {
    if (zoom < SWITCH_ZOOM) {
      setIsCluster(true)
    } else {
      setIsCluster(false)
    }
  }

  const handleChangeViewState = ({
    viewState,
    interactionState,
    oldViewState,
  }) => {
    if (interactionState.isZooming) {
      changeClusterByZoom(viewState.zoom)
    }

    setViewState(viewState)
  }

  const handleChangeMode = (e) => {
    setMode(e.value)
    setModeConfig(e.value.config)
  }

  const handleRemoveFeature = (e) => {
    e.stopPropagation()

    const newFeatures = {
      type: "FeatureCollection",
      features: [],
    }
    for (let i = 0; i < features.features.length; i++) {
      if (i !== selectedFeatureIndexes[0]) {
        newFeatures.features.push(features.features[i])
      }
    }
    setFeatures(newFeatures)
    setSelectedFeatureIndexes([])
    setSelectedFeatureCoordinates(null)
  }

  const handleResetMap = (e) => {
    e.stopPropagation()
    setViewState(START_VIEW_STATE)
    changeClusterByZoom(START_VIEW_STATE.zoom)
  }

  const handleZoomIn = (e) => {
    e.stopPropagation()
    const zoom = viewState.zoom + ZOOM_STEP
    if (zoom <= START_VIEW_STATE.maxZoom) {
      setViewState({ ...viewState, zoom: zoom })
      changeClusterByZoom(zoom)
    }
  }

  const handleZoomOut = (e) => {
    e.stopPropagation()
    const zoom = viewState.zoom - ZOOM_STEP
    if (zoom >= START_VIEW_STATE.minZoom) {
      setViewState({ ...viewState, zoom: zoom })
      changeClusterByZoom(zoom)
    }
  }

  const handleChangeMapStyle = (e) => {
    e.stopPropagation()
    setIsSatellite(!isSatellite)
  }

  const handleOpenDrawOptions = (e) => {
    e.stopPropagation()
    setIsDrawMode(true)
  }

  const handleCloseDrawOptions = (e) => {
    e.stopPropagation()
    setIsDrawMode(false)
  }

  const handleClickSearchLocation = (e) => {
    e.stopPropagation()
    locationSearchPanel.current.toggle(e)
  }

  const handleClearFeature = (e) => {
    e.stopPropagation()
    setFeatures({
      type: "FeatureCollection",
      features: [],
    })
  }

  const handleClickGoLocation = () => {
    if (locationValue !== "") {
      let isValid = false
      let val = locationValue.split(",")
      if (val.length === 2) {
        val[0] = val[0].trim()
        val[1] = val[1].trim()
        if (!isNaN(val[0]) && !isNaN(val[1])) {
          isValid = true
        }
      }
      if (isValid) {
        let isRanges = false
        if (
          Number(val[0]) >= -90 &&
          Number(val[0]) < 90 &&
          Number(val[1]) >= -180 &&
          Number(val[1]) < 180
        ) {
          isRanges = true
        }
        if (isRanges) {
          setViewState({
            ...viewState,
            latitude: Number(val[0]),
            longitude: Number(val[1]),
          })
          posthog.capture("Coordinate pair searched")
        } else {
          toast.error("Enter a valid coordinate pair", {
            autoClose: 5000,
          })
        }
      } else {
        toast.error(
          "Invalid location, Please enter a valid location. Format is latitude, longitude",
          {
            autoClose: 5000,
          },
        )
      }
    } else {
      toast.info("Please enter a location. Format is latitude, longitude")
    }
  }

  const handleChangeSearchLocation = (e) => {
    setLocationValue(e.target.value)
  }

  useEffect(() => {
    //Get polygons
    const polygons = []
    for (let i = 0; i < features.features.length; i++) {
      const feature = features.features[i]

      const geometry = feature.geometry
      const polygon = geometry.coordinates[0]
      polygons.push(polygon)
    }

    if (polygons.length === 0) {
      setRectedImages(timelinedImages)
    } else {
      //Detect image data by polygons
      const images = []
      for (let i = 0; i < timelinedImages.length; i++) {
        let coord = timelinedImages[i][IMAGE_KEYS.coord]
        if (coord) {
          coord = coord
            .substring(coord.indexOf("(") + 1, coord.indexOf(")"))
            .split(" ")
          const point = [Number(coord[0]), Number(coord[1])]

          for (let j = 0; j < polygons.length; j++) {
            const polygon = polygons[j]
            const isInside = insidePointInPolygon(point, polygon)
            if (isInside) {
              images.push(timelinedImages[i])
              break
            }
          }
        }
      }
      setRectedImages(images)
    }
  }, [features, timelinedImages])

  useEffect(() => {
    const value = timelinedImages.filter(
      (image) => image[IMAGE_KEYS.coord] !== null,
    )
    setImagesForMap(value)
  }, [timelinedImages])

  useEffect(() => {
    // Track drawing
    if (features.features.length === 0) {
      if (isStartDraw) {
        setIsStartDraw(false)
      }
    } else if (features.features.length > 0) {
      if (!isStartDraw) {
        setIsStartDraw(true)
        posthog.capture("Map polygon(s) drawn")
      }
    }
  }, [features])

  useEffect(() => {
    setShowOverlay(false)
  }, [mode])

  return (
    <Holder>
      <MapGL
        mapStyle={
          isSatellite
            ? "mapbox://styles/mapbox/satellite-v8"
            : process.env.REACT_APP_MAPBOX_POI_MAP_STYLE
        }
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        {...viewState}
      >
        <DeckGL
          layers={[
            isCluster
              ? new ClusterLayer({
                  id: "cluster-layer",
                  data: imagesForMap,
                  extendRadius: 40,
                  getPosition: (d) => {
                    if (!d.coord) {
                      return [null, null]
                    }
                    const coord = d.coord
                      .substring(d.coord.indexOf("(") + 1, d.coord.indexOf(")"))
                      .split(" ")
                    return [Number(coord[0]), Number(coord[1])]
                  },
                })
              : new IconLayer({
                  id: "icon-layer",
                  data: imagesForMap,
                  pickable: true,
                  iconAtlas: "/images/mapbox/pin.svg",
                  iconMapping: ICON_MAPPING,
                  sizeScale: 40,
                  getIcon: (d) => "marker",
                  getPosition: (d) => {
                    if (!d.coord) {
                      return [null, null]
                    }
                    const coord = d.coord
                      .substring(d.coord.indexOf("(") + 1, d.coord.indexOf(")"))
                      .split(" ")

                    return [Number(coord[0]), Number(coord[1])]
                  },
                  getSize: (d) => 1,
                  onClick: (d, event) => {
                    if (isDrawMode) {
                      return
                    }
                    if (d.object) {
                      setCurrentImage(d.object)
                      setProfileGalleryDialogVisible(true)
                      posthog.capture("Image viewed")
                    }
                    event.stopPropagation()
                  },
                }),
            isDrawMode &&
              new EditableGeoJsonLayer({
                data: features,
                pickable: true,
                autoHighlight: false,
                mode: mode.mode,
                modeConfig: modeConfig,
                selectedFeatureIndexes,
                _subLayerProps: {
                  guides: {
                    getFillColor: (guide) => {
                      if (guide.properties.guideType === "tentative") {
                        return [255, 0, 0, 40]
                      } else {
                        return [255, 0, 0]
                      }
                    },
                    getLineColor: (feature) => {
                      return [255, 255, 0, 40]
                    },
                    getLineWidth: (feature) => {
                      return 0.5
                    },
                  },
                  geojson: {
                    getFillColor: (feature) => {
                      if (
                        selectedFeatureIndexes.some(
                          (i) => features.features[i] === feature,
                        )
                      ) {
                        return [255, 0, 0, 120]
                      } else {
                        return [255, 0, 0, 40]
                      }
                    },
                    getLineColor: (feature) => {
                      return [255, 255, 0, 180]
                    },
                    getLineWidth: (feature) => {
                      return 0.5
                    },
                  },
                },
                getEditHandlePointColor: ({ type }) =>
                  type === "existing" ? [244, 63, 83, 255] : [24, 159, 235, 0],
                getEditHandlePointRadius: () => 300,
                getEditHandleIconSize: () => 24,
                getEditHandlePointOutlineColor: () => [255, 255, 0, 255],
                onClick: (info, event) => {
                  setSelectedFeatureIndexes([info.index])

                  const feature = features.features[info.index]
                  if (feature) {
                    const geometry = feature.geometry
                    const polygon = geometry.coordinates[0]

                    //Get right-top coordinate from info.object.geometry.coordinates
                    const rightTopCoordinate = polygon.reduce(
                      (prev, current) => {
                        return [
                          Math.max(prev[0], current[0]),
                          Math.max(prev[1], current[1]),
                        ]
                      },
                      [-Infinity, -Infinity],
                    )
                    setSelectedFeatureCoordinates(rightTopCoordinate)
                  }

                  setShowOverlay(
                    isDrawMode &&
                      features.features.length > 0 &&
                      mode.code === "modify",
                  )
                },
                onEdit: (event) => {
                  const { editType, updatedData } = event
                  setFeatures(updatedData)
                },
              }),
          ]}
          ContextProvider={MapProvider}
          viewState={viewState}
          controller={{ dragRotate: false }}
          getCursor={() => (isDrawMode ? "crosshair" : "pointer")}
          onViewStateChange={handleChangeViewState}
        ></DeckGL>
        {showOverlay && selectedFeatureCoordinates && (
          <StyledPopup
            longitude={selectedFeatureCoordinates[0]}
            latitude={selectedFeatureCoordinates[1]}
            offset={[20, -10]}
            closeButton={false}
            anchor="center"
            closeOnMove={true}
          >
            <Button
              icon="pi pi-times"
              rounded
              severity="warning"
              aria-label="Cancel"
              onClick={handleRemoveFeature}
            />
          </StyledPopup>
        )}
      </MapGL>

      <MapControls bottom={isExpandedTimeline ? "16em" : "1em"}>
        <CircleButton icon={ResetIcon} onClick={handleResetMap} />
        <CircleButton icon={ZoomInIcon} onClick={handleZoomIn} />
        <CircleButton icon={ZoomOutIcon} onClick={handleZoomOut} />
        <MapStyleChanger onClick={handleChangeMapStyle}>
          <img src={isSatellite ? StreetIcon : SatelliteIcon} alt="" />
        </MapStyleChanger>
      </MapControls>
      <FindControls bottom={isExpandedTimeline ? "16em" : "1em"}>
        <CircleButton
          background="linear-gradient(89.37deg, rgba(18, 42, 71, 0.972136) -0.74%, #225B7B 85.93%)"
          icon={DrawSquareIcon}
          onClick={handleOpenDrawOptions}
        />
        <CircleButton
          background="linear-gradient(89.37deg, rgba(18, 42, 71, 0.972136) -0.74%, #225B7B 85.93%)"
          icon={SearchIcon}
          onClick={handleClickSearchLocation}
        />
      </FindControls>
      {isDrawMode && (
        <DrawControls bottom={isExpandedTimeline ? "16.5em" : "1.5em"}>
          <Dropdown
            value={mode}
            options={DRAW_MODES}
            onChange={handleChangeMode}
            optionLabel="name"
            className="p-dropdown-sm"
          />
          <Button
            label="Clear"
            className="p-button-sm"
            onClick={handleClearFeature}
          />
          <CircleButton
            size="2em"
            icon={CloseIcon}
            onClick={handleCloseDrawOptions}
          />
        </DrawControls>
      )}
      <OverlayPanel ref={locationSearchPanel}>
        <div className="flex gap-1">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              placeholder="latitude, longitude"
              value={locationValue}
              onChange={handleChangeSearchLocation}
            />
          </span>
          <Button
            label="Go"
            className="p-button-raised"
            onClick={handleClickGoLocation}
          />
        </div>
      </OverlayPanel>
    </Holder>
  )
}

export default memo(ImageryLibraryMap)

const Holder = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #5d757e;
`

const FindControls = styled.div`
  position: absolute;
  bottom: ${(props) => props.bottom};
  right: 6em;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 0.6em;
  transition: all 0.8s ease-out;
`

const MapControls = styled.div`
  position: absolute;
  bottom: ${(props) => props.bottom};
  right: 1em;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.6em;
  transition: all 0.8s ease-out;
  user-select: none;
`

const MapStyleChanger = styled.div`
  border-radius: 10px;
  width: 4em;
  height: 4em;
  border: 2px solid #eee;
  margin-top: 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const DrawControls = styled.div`
  position: absolute;
  bottom: ${(props) => props.bottom};
  right: 13.5em;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 0.5em;
  transition: all 0.8s ease-out;
`

const StyledPopup = styled(Popup)`
  .mapboxgl-popup-tip {
    border-color: transparent !important;
  }
  .mapboxgl-popup-content {
    background: rgba(0, 0, 0, 0) !important;
  }
`
