import { api } from "api"

/**
 * Fetch all images
 * @returns
 */
export const allImagesRequest = async () => {
  try {
    const { data } = await api.get(`/images`)
    data.forEach(image => {
      if (image.region_aoi === 'East Sea') {
        image.region_aoi = 'East Sea / Sea of Japan'
      }
    })
    return data
  } catch (error) {
    return null
  }
}

export const getImageDownloadRequest = async (id) => {
  try {
    const { data } = await api.get(`/images/${id}/download`, {
      responseType: "blob",
    })
    return data
  } catch (error) {
    return null
  }
}
