import { MiddleContainer } from "components/Containers"
import styled from "styled-components"

import BannerImg from "assets/images/Banner/home-banner-1.jpeg"
import Banner1Img from "assets/images/Banner/home-heading-pattern.svg"

import { HighLight } from "components/Labels"

export default function Banner() {
  return (
    <BannerHolder>
      <MiddleContainer>
        <BannerTextHolder>
          <BannerTitle>
            The global hub for countering North Korea illicit shipping
          </BannerTitle>
          <BannerTypoGraphy>
            Powered by <HighLight>publicly available information</HighLight>,
            ARION provides the international community with actionable data and
            tools to monitor the DPRK maritime sanctions regime
          </BannerTypoGraphy>
        </BannerTextHolder>
      </MiddleContainer>
      <div className="banner-image-container">
        <img src={BannerImg} alt={BannerImg} className="banner-image" />
      </div>
    </BannerHolder>
  )
}

const BannerHolder = styled.section`
  position: relative;
  top: 0;
  width: 100%;
  @media (min-width: 1022px) {
    min-height: 804px;
  }
  padding: 0 0 0 0;
  margin-top: 0;
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    background: linear-gradient(
      175.57deg,
      rgba(18, 42, 71, 0.972136) 13.19%,
      #225b7b 48.9%,
      rgba(34, 91, 123, 0) 83.22%
    );
    background-repeat: no-repeat;
    background-position: cover;
    z-index: 1;
  }
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    background-image: url(${Banner1Img});
    background-repeat: no-repeat, no-repeat;
    background-size: contain;
    background-position: right bottom;
    z-index: 2;
  }
  .banner-image-container {
    position: relative;
  }
  .banner-image {
    width: 100%;
    height: 100%;
    margin-top: 394px;
    display: block;
  }
  .highlight {
    color: ${(props) => props.theme.colors.high_light};
  }
`

const BannerTextHolder = styled.div`
  position: absolute;
  z-index: 1;
  top: 276px;
  max-width: 842px;
  z-index: 3;
`
const BannerTitle = styled.h1`
  font-size: ${(props) => props.theme.fontSize.banner_h1};
  color: ${(props) => props.theme.colors.color_white};
  line-height: 64px;
  font-weight: 700;
  margin: 0 0 15px 0;

  @media (max-width: 492px) {
    font-size: 2.9em;
    line-height: 44px;
  }
`
const BannerTypoGraphy = styled.p`
  font-size: ${(props) => props.theme.fontSize.banner_content};
  color: ${(props) => props.theme.colors.color_white};
  line-height: 33px;
  font-weight: 400;

  @media (max-width: 492px) {
    font-size: 1.3em;
    line-height: 26px;
  }
`
