import styled from "styled-components"
import { useRef, useState } from "react"
const UploadFile = (props) => {
  const fileRef = useRef()

  const [selectedFile, setSelectedFile] = useState()
  const [isFilePicked, setIsFilePicked] = useState(false)

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0].name)
    setIsFilePicked(true)
    props.setFile(event.target.files[0])
  }

  const clickOnSelect = () => {
    fileRef.current.click()
  }
  return (
    <MainContainer>
      <Container onClick={() => clickOnSelect()}>{props.data.title}</Container>
      {isFilePicked && <NameContainer>{selectedFile}</NameContainer>}
      <input
        onChange={changeHandler}
        type="file"
        ref={fileRef}
        style={{ display: "none" }}
      />
    </MainContainer>
  )
}
const MainContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
`
const NameContainer = styled.div`
  width: 35%;
  text-overflow: ellipsis;
  overflow: hidden;
`
const Container = styled.div`
  cursor: pointer;
  background-color: #e1e4e8;
  color: #122945;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 50px;
  border-radius: 5px;
  :hover {
    background-color: #b1b5bb;
  }
`
export default UploadFile
