import { useState } from "react"
import { DataTable } from "primereact/datatable"
import styled from "styled-components"

import PaginatorTemplate from "components/PaginatorTemplate"

export default function PaginateDataTable({ children, ...restProps }) {
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(10)

  const onCustomPage = (event) => {
    setFirst(event.first)
    setRows(event.rows)
  }

  const paginatorTemplate = PaginatorTemplate()

  return (
    <DataTableWrapper
      {...restProps}
      paginator
      paginatorTemplate={paginatorTemplate}
      first={first}
      rows={rows}
      onPage={onCustomPage}
      responsiveLayout="scroll"
    >
      {children}
    </DataTableWrapper>
  )
}

const DataTableWrapper = styled(DataTable)`
  .p-column-filter-constraints {
    .p-column-filter-constraint {
      padding: 0;
    }
  }
`
