const theme = {
  colors: {
    primary: "#F75151",
    primary_1: "#F27676",
    primary_2: "#EE9A9A",
    primary_3: "#EABFBF",
    secondary: "#414042",
    secondary_1: "#6A696A",
    secondary_2: "#929293",
    secondary_3: "#BCBCBC",
    third: "#144A68",
    third_1: "#31617C",
    third_2: "#4F788F",
    third_3: "#6D90A3",
    third_4: "#8AA6B7",
    third_5: "#AAC0CC",
    third_6: "#D7E3EA",
    third_7: "#F3F9FD",
    color_white: "#fff",
    color_grey1: "#7B8694",
    primary_4: "#122945",
    high_light: "#F35F5F",
    primary_color: "#131521",
    value_color: "#1D1D1B",
    primary_5: "#122A47",
  },

  fontSize: {
    banner_h1: "3.6em",
    banner_content: "1.7em",
    page_title: "3.4em",
    typography_1: "1.2em",
    typography_16: "1.1em",
    banner_title: "3.25em",
  },
}

export default theme
