import { memo, useMemo, useState, useCallback } from "react"
import { useQuery } from "@tanstack/react-query"
import styled from "styled-components"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { RadioButton } from "primereact/radiobutton"

import { postRrpRequest } from "api/rrp"
import { getUsersRequest } from "api/users"
import { RRP_PRODUCT_TYPE } from "dataset/rrp"
import { RRP_ANALYSIS_TYPE } from "dataset/rrp"
import { useStore } from "state/store"
import PermissionDialogHeader from "./RRPUploadDialogHeader"
import RRPUploadPermissionDataTable from "./RRPUploadPermissionDataTable"
import UploadImage from "assets/images/upload.svg"

function RRPUploadDialog() {
  const rrpUploadDialogVisible = useStore(
    (state) => state.rrpUploadDialogVisible,
  )
  const setRrpUploadDialogVisible = useStore(
    (state) => state.setRrpUploadDialogVisible,
  )
  const setNeedGetRrp = useStore((state) => state.setNeedGetRrp)

  const [search, setSearch] = useState("")

  const [file, setFile] = useState(null)
  const [productType, setProductType] = useState(RRP_PRODUCT_TYPE[0].label)
  const [analysisType, setAnalysisType] = useState(RRP_ANALYSIS_TYPE[0].label)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [isUploading, setIsUploading] = useState(false)

  const [isDrag, setIsDrag] = useState(false)

  const handleHide = () => {
    setRrpUploadDialogVisible(false)
  }

  const handleSearch = (e) => {
    setSearch(e.target.value ? e.target.value : "")
  }

  const { data, isLoading } = useQuery(["getUsersRequest"], () =>
    getUsersRequest(),
  )

  const onDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDrag(true)
  }

  const onDragLeave = (e) => {
    setIsDrag(false)
  }

  const onDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDrag(false)

    const _file = e.dataTransfer.files[0]
    setFile(_file)
  }

  const handleUpload = useCallback(async () => {
    if (!file) {
      return
    }

    if (isUploading) {
      return
    }

    setIsUploading(true)

    try {
      const selectedProductType = RRP_PRODUCT_TYPE.find(
        (item) => item.label === productType,
      ).value

      const selectedAnalysisType = RRP_ANALYSIS_TYPE.find(
        (item) => item.label === analysisType,
      ).value

      await postRrpRequest(file, selectedProductType, selectedAnalysisType, selectedUsers)
      handleHide()
      setNeedGetRrp(true)
    } finally {
      setIsUploading(false)
      setFile(null)
    }
  }, [file, isUploading, productType, analysisType, selectedUsers])

  return (
    <Holder
      visible={rrpUploadDialogVisible}
      onHide={handleHide}
      header={<PermissionDialogHeader />}
    >
      <Content>
        <p className="mb-1">Attachments</p>
        <FileUpload
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          isdrag={isDrag}
        >
          {file === null ? (
            <img src={UploadImage} alt="upload" className="w-1/2 mx-auto" />
          ) : (
            <SelectedFile className="">{file.name}</SelectedFile>
          )}
          <div>
            <input
              className="custom-file-input"
              type="file"
              accept="image/*, .doc, .docx, application/pdf"
              onChange={(e) => {
                const _file = e.target.files[0]
                setFile(_file)
              }}
            />
            <span>OR Drag and Drop File Here</span>
          </div>
        </FileUpload>
        <p className="mt-4 mb-2">Analysis Type</p>
        <div className="flex flex-wrap gap-3 mb-3">
          {RRP_ANALYSIS_TYPE.map((item, index) => (
            <div key={item.key} className="flex align-items-center">
              <RadioButton
                inputId={item.label}
                name={item.label}
                value={item.label}
                onChange={(e) => setAnalysisType(e.value)}
                checked={analysisType === item.label}
              />
              <label htmlFor={item.label} className="ml-2">
                {item.label}
              </label>
            </div>
          ))}
        </div>
        <p className="mt-4 mb-2">Product Type</p>
        <div className="flex flex-wrap gap-3 mb-3">
          {RRP_PRODUCT_TYPE.map((item, index) => (
            <div key={item.key} className="flex align-items-center">
              <RadioButton
                inputId={item.label}
                name={item.label}
                value={item.label}
                onChange={(e) => setProductType(e.value)}
                checked={productType === item.label}
              />
              <label htmlFor={item.label} className="ml-2">
                {item.label}
              </label>
            </div>
          ))}
        </div>
        {productType === "Bespoke" && (
          <div className="mt-2">
            <span className="p-input-icon-left w-full mb-3">
              <i className="pi pi-search" />
              <InputText
                placeholder="Search Users"
                className="w-full"
                value={search}
                onChange={handleSearch}
              />
            </span>
            <RRPUploadPermissionDataTable
              value={data}
              isLoading={isLoading}
              search={search}
              rowAmount={10}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />
          </div>
        )}
        <div className="w-full flex justify-content-end mt-4">
          <Button
            label="Upload"
            className="p-button-primary"
            icon="pi pi-upload"
            loading={isUploading}
            disabled={file === null}
            onClick={handleUpload}
          />
        </div>
      </Content>
    </Holder>
  )
}

export default memo(RRPUploadDialog)

const Holder = styled(Dialog)`
  min-width: 300px;
  width: 60vw;
  max-width: 700px;
  .p-dialog-content {
    padding: 1em;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

const Content = styled.div`
  padding: 0em 0.5em;
`

const FileUpload = styled.div`
  border: 1px dashed #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;

  background-color: ${(props) =>
    props.isdrag ? "rgba(0,0,255,0.1)" : "transparent"};

  .custom-file-input {
    color: rgba(0, 0, 0, 0);
    width: 4.2em;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: "Browse";
    display: inline-block;
    background: transparent;
    border: none;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    color: #000;
    text-decoration: underline;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`

const SelectedFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  padding: 1em;
  height: 55px;
  background: #14304e;
  color: #fff;
`
