import PageTransition from "components/PageTransition"
import styled from "styled-components"
import PageHead from "./components/PageHead"
import FilterButton from "./components/FilterButton"
import FilterDialog from "./components/FilterDialog"
import BarChart from "./components/BarChart"
import { H31, H30 } from "components/Labels"
import { MiddleContainer } from "components/Containers"
import Shipments from "./components/Sections/Shipments"
export default function OilShipmentTracker() {
  return (
    <PageTransition>
      <Holder>
        <PageHead>
          <H31>DPRK Oil Shipment Tracker</H31>
          <H30>
            Investigate DPRK oil procurement trends and shipments since
            September 2017. Adjust the column filters and chart settings to
            customize your analysis.
          </H30>
        </PageHead>
        <MiddleContainerBordered className="mb-6">
          <SearchContainer className="flex justify-content-center sm:justify-content-end flex-wrap py-3 pr-3">
            <FilterButton />
          </SearchContainer>
          <SectionContainer>
            <BarChart />
            <Shipments />
          </SectionContainer>
        </MiddleContainerBordered>
        <FilterDialog />
      </Holder>
    </PageTransition>
  )
}

const Holder = styled.div`
  min-height: 120vh;
`

const SearchContainer = styled.div`
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
`
const SectionContainer = styled.div`
  padding: 1em;
`

const MiddleContainerBordered = styled(MiddleContainer)`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;
`
