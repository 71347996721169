import { useRef, useEffect, useState, useCallback, memo } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { usePostHog } from 'posthog-js/react'

import { useStore } from "state/store"
import { DatePicker } from "components/Form"
import { H21 } from "components/Labels"
import InfoBadge from "components/Badges/InfoBadge"
import TimelineEdgeRanger from "./TimelineEdgeRanger"
import TimelineButton from "./TimelineButton"
import TimelineHoverRanger from "./TimelineHoverRanger"

function Timelinebar() {
  const posthog = usePostHog()
  const isExpandedSidebar = useStore((state) => state.isExpandedSidebar)
  const isExpandedTimeline = useStore((state) => state.isExpandedTimeline)
  const setIsExpandedTimeline = useStore((state) => state.setIsExpandedTimeline)
  const timelineStartDate = useStore((state) => state.timelineStartDate)
  const setTimelineStartDate = useStore((state) => state.setTimelineStartDate)
  const timelineEndDate = useStore((state) => state.timelineEndDate)
  const setTimelineEndDate = useStore((state) => state.setTimelineEndDate)
  const filteredImages = useStore((state) => state.filteredImages)
  const setTimelinedImages = useStore((state) => state.setTimelinedImages)
  const timelineData = useStore((state) => state.timelineData)
  const filterStartDate = useStore((state) => state.filterStartDate)
  const filterEndDate = useStore((state) => state.filterEndDate)
  const startDate = useStore((state) => state.startDate)
  const endDate = useStore((state) => state.endDate)

  const [sliderValue, setSliderValue] = useState([0, timelineData.length - 1])
  const [sliderHoverValue, setSliderHoverValue] = useState([
    0,
    timelineData.length - 1,
  ])
  const [activeFilter, setActiveFilter] = useState(false)
  const isTracking = useRef(false)

  const getSliderValueFromDate = (startDate, endDate, data) => {
    const start = (() => {
      let result = 0
      for (let i = 0; i < data.length; i++) {
        if (
          startDate.getFullYear() === data[i].year &&
          startDate.getMonth() + 1 === data[i].month
        ) {
          result = i
          break
        }
      }
      return result
    })()

    const end = (() => {
      let result = data.length - 1
      for (let i = 0; i < data.length; i++) {
        if (
          endDate.getFullYear() === data[i].year &&
          endDate.getMonth() + 1 === data[i].month
        ) {
          result = i
          break
        }
      }
      return result
    })()

    return [start, end]
  }

  const getDateRangeFromSliderValue = (start, end, data) => {
    const startDate = (() => {
      const year = data[start].year
      const month = data[start].month - 1
      const date = 1

      return new Date(year, month, date)
    })()

    const endDate = (() => {
      const year = data[end].year
      const month = data[end].month - 1
      const date = new Date(year, month + 1, 0).getDate()

      return new Date(year, month, date)
    })()

    return [startDate, endDate]
  }

  const onChangeSlider = (e) => {
    // Set slider value
    if (e.value[0] > e.value[1]) {
      return
    }

    setSliderValue(e.value)

    setSliderHoverValue(e.value)

    // Set date
    const dateRange = getDateRangeFromSliderValue(
      e.value[0],
      e.value[1],
      timelineData,
    )
    setTimelineStartDate(dateRange[0])
    setTimelineEndDate(dateRange[1])

    // Tracking event
    if (activeFilter) {
      if (!isTracking.current) {
        isTracking.current = true
        posthog.capture("Image timeline filtered")
      }
    }
  }

  const getBarShape = (x, y, width, height, radius) => {
    const [tl, tr, bl, br] = radius
    const d = `M${x},${y + tl}
      a${tl},${tl} 0 0 1 ${tl},${-tl}
      h${width - tl - tr}
      a${tr},${tr} 0 0 1 ${tr},${tr}
      v${height - tr - br}
      a${br},${br} 0 0 1 ${-br},${br}
      h${bl + (br - width)}
      a${bl},${bl} 0 0 1 ${-bl},${-bl}
      z`
    return ({ fill, fillOpacity, stroke }) => (
      <path d={d} fill={fill} fillOpacity={fillOpacity} stroke={stroke} />
    )
  }

  const handleActive = useCallback(() => {
    const newActiveFilter = !activeFilter
    setActiveFilter(!activeFilter)

    if (!newActiveFilter) {
      setTimelineStartDate(filterStartDate)
      setTimelineEndDate(filterEndDate)
    }
  }, [
    activeFilter,
    filterStartDate,
    filterEndDate,
    setTimelineStartDate,
    setTimelineEndDate,
  ])

  const handleExpand = () => {
    setIsExpandedTimeline(!isExpandedTimeline)
  }

  useEffect(() => {
    // Set slider values
    const newSliderValue = getSliderValueFromDate(
      timelineStartDate,
      timelineEndDate,
      timelineData,
    )
    setSliderValue(newSliderValue)

    // Filter images
    const noDateFilter =
      startDate.getTime() === timelineStartDate.getTime() &&
      endDate.getTime() === timelineEndDate.getTime()

    if (activeFilter && !noDateFilter) {
      const result = filteredImages.filter(
        (image) =>
          image.date >= timelineStartDate && image.date <= timelineEndDate,
      )
      setTimelinedImages(result)
    } else {
      setTimelinedImages(filteredImages)
    }
  }, [
    timelineStartDate,
    timelineEndDate,
    timelineData,
    filteredImages,
    activeFilter,
    startDate,
    endDate,
    setTimelinedImages,
  ])

  useEffect(() => {
    setIsExpandedTimeline(!isExpandedSidebar)
  }, [isExpandedSidebar, setIsExpandedTimeline])

  useEffect(() => {
    if (!activeFilter) {
      isTracking.current = false
    }
  }, [activeFilter])

  return (
    <Holder
      variants={{
        expand: { y: 0 },
        collapse: { y: "100%" },
      }}
      animate={isExpandedTimeline ? "expand" : "collapse"}
      transition={{
        duration: 0.5,
      }}
    >
      <Content>
        <ExpandButton
          label={isExpandedTimeline ? "" : "Reveal Timeline"}
          iconPos="right"
          icon={`pi ${
            isExpandedTimeline ? "pi-chevron-down" : "pi-chevron-up"
          }`}
          onClick={handleExpand}
        />
        <TopPart>
          <div className="flex gap-1 align-items-center">
            <H21>Timeline</H21>
            <InfoBadge label="Timeline" />
          </div>
          {activeFilter ? (
            <DateRangeHolder>
              <DatePicker
                customInput={<CustomInput />}
                selected={timelineStartDate}
                placeholderText="From date"
                onChange={(date) => setTimelineStartDate(date)}
                selectsStart
                startDate={timelineStartDate}
                endDate={timelineEndDate}
              />
              <span>-</span>
              <DatePicker
                customInput={<CustomInput />}
                selected={timelineEndDate}
                placeholderText="To date"
                onChange={(date) => setTimelineEndDate(date)}
                selectsEnd
                startDate={timelineStartDate}
                endDate={timelineEndDate}
                minDate={timelineStartDate}
              />
              <CloseButton
                icon="pi pi-times"
                className="p-button-text"
                onClick={handleActive}
              />
            </DateRangeHolder>
          ) : (
            <TimelineButton onClick={handleActive} />
          )}
        </TopPart>
        <BottomPart>
          <ResponsiveContainer>
            <BarChart data={timelineData}>
              <XAxis
                dataKey="date"
                axisLine={true}
                tickLine={true}
                tick={<CustomizedAxisTick />}
                interval={0}
              />
              <YAxis />
              <Bar
                dataKey="count"
                fill="rgba(18, 42, 71, 0.5)"
                shape={({ x, y, width, height, value, background }) => {
                  const w = 6
                  x = x + (width - w) / 2
                  const radius = value > 0 ? 3 : 0
                  const Shape = getBarShape(x, y, w, height, [
                    radius,
                    radius,
                    radius,
                    radius,
                  ])
                  return (
                    <g>
                      {/* <rect
                      x={background.x}
                      y={background.y}
                      width={background.width}
                      height={background.height}
                      fill={"yellow"}
                    /> */}
                      <Shape fillOpacity={1} fill="rgba(18, 42, 71, 0.5)" />
                    </g>
                  )
                }}
              />
            </BarChart>
          </ResponsiveContainer>
          {activeFilter && (
            <>
              <TimelineHoverRanger
                value={sliderHoverValue}
                max={timelineData.length - 1}
              />
              <TimelineEdgeRanger
                value={sliderValue}
                max={timelineData.length - 1}
                onChange={onChangeSlider}
                setHoverValue={setSliderHoverValue}
              />
            </>
          )}
        </BottomPart>
      </Content>
    </Holder>
  )
}

export default memo(Timelinebar)

const Holder = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 15em;
`

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #cfd6d9;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`

const TopPart = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const BottomPart = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
`

const CustomInput = styled(InputText)`
  min-width: 5em;
  width: 6em !important;
  padding: 0.1em 0.2em !important;
  background: #ffffff00 !important;
  border: none !important;
  outline: none !important;
  margin-left: 1em;
`

const CloseButton = styled(Button)`
  color: #688b9a !important;
  > span {
    font-size: 0.8em;
  }
`

const DateRangeHolder = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
`

const ExpandButton = styled(Button)`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -99%);
  background-color: #ced5d8 !important;
  color: black !important;
  padding: 0.5em 1.5em !important;
  width: auto !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`

function CustomizedAxisTick(props) {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={14}
        textAnchor="middle"
        fontSize={13}
        fontWeight="bold"
        fill="#666"
        // transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  )
}
