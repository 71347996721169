import { memo, useState } from "react"
import { Checkbox } from "primereact/checkbox"

function RRPUploadPermissionActionSwitch({ onChange }) {
  const [checked, setChecked] = useState(false)

  const handleSwitch = (event) => {
    event.stopPropagation()
    setChecked(event.checked)

    if (event.checked) {
      // add user
      onChange(true)
    } else {
      // remove user
      onChange(false)
    }
  }

  return (
    <div className="flex w-full align-items-center justify-content-center">
      <Checkbox onChange={handleSwitch} checked={checked} />
    </div>
  )
}

export default memo(RRPUploadPermissionActionSwitch)
