import { useState } from "react"
import styled from "styled-components"
import { Divider } from "primereact/divider"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"

import { H45 } from "components/Labels"
import { Button } from "primereact/button"

export default function SignupForm({ className }) {
  const hasError = false

  const industries = [
    {
      label: "NGO",
      value: "13614",
    },
    {
      label: "US Government - Policy",
      value: "13616",
    },
    {
      label: "US Government - Enforcement",
      value: "13618",
    },
    {
      label: "Foreign Government - Policy",
      value: "13620",
    },
    {
      label: "Foreign Government - Enforcement",
      value: "13622",
    },
    {
      label: "Inter-Governmental Organization (IGO)",
      value: "13624",
    },
    {
      label: "Private Sector - Technology",
      value: "13626",
    },
    {
      label: "Private Sector - Finance",
      value: "13628",
    },
    {
      label: "Private Sector - Insurance",
      value: "13630",
    },
    {
      label: "Private Sector - Other",
      value: "27648",
    },
    {
      label: "Media",
      value: "13634",
    },
    {
      label: "Charitable Foundation",
      value: "13636",
    },
    {
      label: "Think Tank Organization",
      value: "13638",
    },
    {
      label: "Academic Institution",
      value: "13640",
    },
    {
      label: "Logistics and Transportation",
      value: "27650",
    },
  ]

  const [industry, setIndustry] = useState(null)

  return (
    <Holder
      className={className}
      method="post"
      action="https://go.c4ads.org/l/833983/2023-09-13/5vv2bj"
    >
      <H45>Sign up to get updates</H45>
      <Divider className="p-0 m-0 my-2" />
      <div className="flex flex-column gap-2 mt-4">
        <InputTitle htmlFor="firstname">First Name</InputTitle>
        <StyledInputText
          id="firstname"
          aria-describedby="firstname-help"
          placeholder="Enter first name"
          name="833983_157023pi_833983_157023"
        />
        <InputHelp id="firstname-help">
          {hasError ? "Enter your first name." : ""}
        </InputHelp>
      </div>
      <div className="flex flex-column gap-2 mt-3">
        <InputTitle htmlFor="lastname">Last Name</InputTitle>
        <StyledInputText
          id="lastname"
          aria-describedby="lastname-help"
          placeholder="Enter last name"
          name="833983_157026pi_833983_157026"
        />
        <InputHelp id="lastname-help">
          {hasError ? "Enter your last name." : ""}
        </InputHelp>
      </div>
      <div className="flex flex-column gap-2 mt-3">
        <InputTitle htmlFor="organization">Organization</InputTitle>
        <StyledInputText
          id="organization"
          aria-describedby="organization-help"
          placeholder="Enter organization"
          name="833983_157032pi_833983_157032"
        />
        <InputHelp id="organization-help">
          {hasError ? "Enter your organization." : ""}
        </InputHelp>
      </div>
      <div className="flex flex-column gap-2 mt-3">
        <InputTitle>Industry</InputTitle>
        <StyledDropdown
          value={industry}
          onChange={(e) => setIndustry(e.value)}
          options={industries}
          optionLabel="label"
          optionValue="value"
          placeholder="Select a industry"
          className="w-full"
          name="833983_157083pi_833983_157083"
        />
        <InputHelp id="industry-help">
          {hasError ? "Select a industry." : ""}
        </InputHelp>
      </div>
      <div className="flex flex-column gap-2 mt-3">
        <InputTitle htmlFor="email">Email</InputTitle>
        <StyledInputText
          id="email"
          aria-describedby="email-help"
          placeholder="Enter email"
          name="833983_157029pi_833983_157029"
        />
        <InputHelp id="email-help">
          {hasError ? "Enter your email." : ""}
        </InputHelp>
      </div>
      <Button
        className="mt-4 w-full"
        label="Subscribe to Updates"
        type="submit"
      />
    </Holder>
  )
}

const Holder = styled.form`
  min-width: 15em;
  min-height: 15em;
  height: fit-content;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 41px 0px rgba(0, 0, 0, 0.15);
  padding: 1em;
`

const StyledInputText = styled(InputText)`
  border: 1px solid #ced3d9;
  background: #fff;
  ::placeholder {
    color: #c4c4c4;
  }
`

const InputHelp = styled.small`
  color: #536378;
`

const InputTitle = styled.label`
  font-size: 0.85em;
  font-weight: 400;
  color: #536378;
`

const StyledDropdown = styled(Dropdown)`
  border: 1px solid #ced3d9;
  background: #fff;
`
