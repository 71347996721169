import { memo } from "react"
import styled from "styled-components"

import { H36, H18 } from "components/Labels"
import { VESSEL_KEYS, VESSEL_IDENTITY_TYPE } from "dataset/vessel"
import { device } from "theme/device"
import { getValueForHumans } from "utils"
import { useStore } from "state/store"

function SearchItem({ data, width, isVesselDatabase }) {
  const isSavedVessels = useStore((state) => state.isSavedVessels)
  if (typeof data !== "object") {
    return (
      <SearchText width={width}>{`Search ${
        isVesselDatabase
          ? isSavedVessels
            ? "Saved Vessels"
            : "Vessel Database"
          : "Imagery Library"
      } for "${data}"`}</SearchText>
    )
  }

  return (
    <div>
      <div className="flex justify-content-between gap-1">
        <Title width={width}>
          {`${getValueForHumans(
            data[VESSEL_KEYS.target_name],
          )} (UID: ${getValueForHumans(data[VESSEL_KEYS.internal_id])})`}
        </Title>
        <H36
          style={{
            color: VESSEL_IDENTITY_TYPE.find(
              (d) => d.value === data[VESSEL_KEYS.identity],
            )?.color,
          }}
        >
          {getValueForHumans(data[VESSEL_KEYS.identity_verbose])}
        </H36>
      </div>
      <div className="mt-2 flex gap-2">
        <H36>{`IMO: ${getValueForHumans(data[VESSEL_KEYS.target_imo])}`}</H36>
        <H36>{`MMSI: ${getValueForHumans(data[VESSEL_KEYS.target_mmsi])}`}</H36>
        <H36>{`FLAG: ${getValueForHumans(data[VESSEL_KEYS.target_flag])}`}</H36>
        <H36>{`TYPE: ${getValueForHumans(
          data[VESSEL_KEYS.type_verbose],
        )}`}</H36>
      </div>
    </div>
  )
}

export default memo(SearchItem)

const Title = styled(H18)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(props) => props.width - 50}px;
  @media ${device.tablet} {
    white-space: pre-wrap;
    overflow: auto;
    text-overflow: initial;
  }
`

const SearchText = styled.h4`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(props) => props.width - 50}px;
`
