import { MiddleContainer } from "components/Containers"
import { PageTitle, Typography, TypographyHolder } from "components/Labels"
import styled from "styled-components"
import AreaChart from "./AreaChart"

import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"

export default function OilShipmentTracker() {
  const history = useNavigate()

  return (
    <Holder>
      <MiddleContainer>
        <FlexRow>
          <LeftContent>
            <PageTitle>DPRK Oil Shipment Tracker</PageTitle>
            <TypographyHolder>
              <Typography>
                Investigate DPRK oil procurement trends and shipments since
                January 2018
              </Typography>
            </TypographyHolder>
            <Button
              label="Explore"
              icon="icon-common icon-arrow-button"
              iconPos="right"
              onClick={() => history("/oil-shipment-tracker")}
            />
          </LeftContent>
          <ChartContainer>
            <AreaChart />
          </ChartContainer>
        </FlexRow>
      </MiddleContainer>
    </Holder>
  )
}

const Holder = styled.section`
  width: 100%;
  position: relative;
  margin-top: 50px;
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: -1;
    top: 0;
    opacity: 0.5;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #dde4ec 100%
    );
  }
`
const LeftContent = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 95%;
  }
  p {
    line-height: 33px;
    font-size: 18px;
    font-weight: 400;
  }
  .hight_light {
    color: ${(props) => props.theme.colors.primary};
  }
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const ChartContainer = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`
