import { memo, useEffect, useState } from "react"
import styled from "styled-components"
import { useMutation } from "@tanstack/react-query"

import { device } from "theme/device"
import { VESSEL_KEYS } from "dataset/vessel"
import { H17, H20, H21, H5, H8 } from "components/Labels"
import InfoBadge from "components/Badges/InfoBadge"
import {
  companiesRequest,
  managersRequest,
  operatorsRequest,
  getRegisteredownersRequest,
  technicalManagersRequest,
} from "api/vessels"
import snPLogo from "assets/images/spgloballogo.png"

function VesselNetwork({ identity }) {
  const [owners, setOwners] = useState([])
  const [shipManagers, setShipManagers] = useState([])
  const [operators, setOperators] = useState([])
  const [companies, setCompanies] = useState([])
  const [techManagers, setTechManagers] = useState([])

  const [data, setData] = useState([])

  const { mutate: ownerMutate, isLoading: ownerLoading } = useMutation(
    getRegisteredownersRequest,
    {
      onSuccess: (data) => {
        if (data) {
          setOwners(data)
        } else {
          setOwners([])
        }
      },
    },
  )

  const { mutate: shipManagerMutate, isLoading: shipManagerLoading } =
    useMutation(managersRequest, {
      onSuccess: (data) => {
        if (data) {
          setShipManagers(data)
        } else {
          setShipManagers([])
        }
      },
    })

  const { mutate: operatorMutate, isLoading: operatorLoading } = useMutation(
    operatorsRequest,
    {
      onSuccess: (data) => {
        if (data) {
          setOperators(data)
        } else {
          setOperators([])
        }
      },
    },
  )

  const { mutate: companyMutate, isLoading: companyLoading } = useMutation(
    companiesRequest,
    {
      onSuccess: (data) => {
        if (data) {
          setCompanies(data)
        } else {
          setCompanies([])
        }
      },
    },
  )

  const { mutate: techManagerMutate, isLoading: techManagerLoading } =
    useMutation(technicalManagersRequest, {
      onSuccess: (data) => {
        if (data) {
          setTechManagers(data)
        } else {
          setTechManagers([])
        }
      },
    })

  useEffect(() => {
    if (identity) {
      ownerMutate(identity[VESSEL_KEYS.id])
      shipManagerMutate(identity[VESSEL_KEYS.id])
      operatorMutate(identity[VESSEL_KEYS.id])
      companyMutate(identity[VESSEL_KEYS.id])
      techManagerMutate(identity[VESSEL_KEYS.id])
    }
  }, [identity])

  useEffect(() => {
    setData([
      {
        key: "Registered Owner",
        value: owners,
      },
      {
        key: "Ship Manager",
        value: shipManagers,
      },
      {
        key: "Operator",
        value: operators,
      },
      {
        key: "DOC Company",
        value: companies,
      },
      {
        key: "Technical Manager",
        value: techManagers,
      },
    ])
  }, [owners, shipManagers, operators, companies, techManagers])

  return (
    <Holder id="anchor_profile_vessel_network">
      <div className="flex gap-1 align-items-center">
        <div className="flex align-items-center">
          <H17>Vessel Network</H17>
          <InfoBadge label="Vessel Network" />
        </div>
        {identity?.analyst_identifiers === false && (
          <img
            className="ml-auto"
            src={snPLogo}
            alt="S&P Global Logo"
            width="150"
          />
        )}
      </div>
      <List>
        {data.map((d, index) => (
          <ListItem key={index}>
            <Key>{d.key}</Key>
            <div>
              {d.value.map((v, index) => (
                <Value key={`value-${index}`}>
                  <H5>{v.name}</H5>
                  <div className="flex gap-1 mt-2">
                    <H8>Place of Incorporation: </H8>
                    <Item>{v.reg_nationality || "N/A"}</Item>
                  </div>
                  <div className="flex gap-1 mt-1">
                    <H8>IMO: </H8>
                    <Item>{v.imo || "N/A"}</Item>
                  </div>
                  <div className="flex gap-1 mt-1">
                    <H8>Address: </H8>
                    <Item>{v.full_address || "N/A"}</Item>
                  </div>
                </Value>
              ))}
              {d.value.length === 0 && (
                <Value>
                  <Item>N/A</Item>
                </Value>
              )}
            </div>
          </ListItem>
        ))}
      </List>
    </Holder>
  )
}

export default memo(VesselNetwork)

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  scroll-margin-top: 20px;
`

const List = styled.ul`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.6em;
`

const ListItem = styled.li`
  display: flex;
`

const Key = styled(H20)`
  min-width: 17em;
  width: 17em;
  background-color: #f7f7f7;
  padding: 0.7em 1.5em;
  @media ${device.pad} {
    min-width: 14em;
    width: 14em;
  }
`

const Value = styled.div`
  flex: 1;
  padding: 0.7em 1.5em;
`

const Item = styled(H21)``
