import { api } from "api"
import { RRP_KEYS } from "dataset/rrp"
import { toast } from "react-toastify"

/**
 * Make request to fetch rrp
 * @returns {Object}
 */
export const getRrpRequest = async () => {
  try {
    const { data } = await api.get(`/rrp_products`)

    return data
  } catch (error) {
    console.log("error", error)
    throw new Error(error?.response?.data?.detail)
  }
}

export const getRrpDownloadRequest = async (id) => {
  try {
    const { data } = await api.get(`/rrp_products/${id}/download`, {
      responseType: "blob",
    })
    return data
  } catch (error) {
    return null
  }
}

/**
 * @param {String} productId
 * @param {Array} originUsers
 * @param {Array} newUsers
 * @returns
 */
export const postUsersRequest = async (productId, originUsers, newUsers) => {
  try {
    // Remove origin users
    let body = {
      users: originUsers,
    }
    await api.post(`/rrp_products/${productId}/remove_users/`, body)

    // Add new users
    body = {
      users: newUsers,
    }
    await api.post(`/rrp_products/${productId}/add_users/`, body)
    toast.success("Permission updated successfully")
    return true
  } catch (error) {
    toast.error("Something went wrong while updating permission")
    return null
  }
}

export const postRrpRequest = async (file, productType, analysisType, users) => {
  try {
    // POST request to upload file with form data
    const formData = new FormData()
    formData.append("s3_path", file)
    formData.append("product_type", productType)
    formData.append("analysis_type", analysisType)
    formData.append("upload_date", new Date().toISOString())

    for (let i = 0; i < users.length; i++) {
      formData.append("users", users[i])
    }

    //Change content-type to multipart/form-data
    api.interceptors.request.use((config) => {
      config.headers["Content-Type"] = "multipart/form-data"
      return config
    })

    const response = await api.post(`/rrp_products/`, formData)

    toast.success("File uploaded successfully")

    return response
  } catch (error) {
    console.log("postRrpRequest error", error)
    return null
  }
}

export const deleteRrpRequest = async (product) => {
  try {
    const { data } = await api.delete(`/rrp_products/${product[RRP_KEYS.id]}/`)

    toast.success(`${product[RRP_KEYS.file_name]} deleted successfully`)

    return data
  } catch (error) {
    console.log("deleteRrpRequest error", error)
    return null
  }
}

export const renameRrpRequest = async (id, name) => {
  try {
    const { data } = await api.patch(`/rrp_products/${id}/`, {
      file_name: name,
    })

    toast.success("File renamed successfully")

    return data
  } catch (error) {
    console.log("deleteRrpRequest error", error)
    return null
  }
}
