import { ProgressSpinner } from "primereact/progressspinner"

export default function ProgressLoader() {
  return (
    <div className="flex align-items-center">
      <ProgressSpinner
        style={{ width: "3em", height: "3em" }}
        strokeWidth="4"
        // fill="#1a1f2c"
        animationDuration=".9s"
      />
    </div>
  )
}
