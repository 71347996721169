import { memo, useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Divider } from "primereact/divider"
import { Button } from "primereact/button"
import ReactMarkdown from "react-markdown"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useMutation } from "@tanstack/react-query"
import { OverlayPanel } from "primereact/overlaypanel"

import { IMAGE_KEYS } from "dataset/image"
import { useStore } from "state/store"
import { formatDateString, getValueForHumans } from "utils"
import { saveVesselOrImage, deleteVesselOrImage } from "api/auth"
import {
  VESSEL_IDENTITY_TYPE,
  VESSEL_KEYS,
  VESSEL_REDFLAG,
  VESSEL_TYPE,
} from "dataset/vessel"
import { H12, H24, H25, H27, H8 } from "components/Labels"
import InfoAmountBadge from "components/Badges/InfoAmountBadge"

import NoVesselImage from "assets/images/no-vessel.svg"
import { usePostHog } from 'posthog-js/react'
import { Skeleton } from "primereact/skeleton"

function GridItem({ data }) {
  const navigate = useNavigate()

  const posthog = usePostHog()

  const currentUser = useStore((state) => state.currentUser)
  const totalImages = useStore((state) => state.totalImages)
  const savedVessels = useStore((state) => state.savedVessels)
  const toggleSavedVessels = useStore((state) => state.toggleSavedVessels)
  const [thumbnail, setThumbnail] = useState("")
  const [isImageLoading, setIsImageLoading] = useState(true)
  const currentImageStatus = useStore((state) => state.currentImageStatus)

  const isBooked = useMemo(() => {
    return savedVessels.some(
      (identity) => identity[VESSEL_KEYS.id] === data[VESSEL_KEYS.id],
    )
  }, [savedVessels, data])

  useEffect(() => {
    if (data) {
      if (data[VESSEL_KEYS.images]?.length > 0) {
        const imageId = data[VESSEL_KEYS.images][0]
        if (currentImageStatus === true) {
          if (totalImages?.length > 0) {
            const image = totalImages.find((i) => i[IMAGE_KEYS.id] === imageId)
            if (image) {
              setThumbnail(image[IMAGE_KEYS.s3_path])
            } else {
              setThumbnail("")
            }
          }
        }
      } else {
        setThumbnail("")
      }
    }
  }, [currentImageStatus])
  useEffect(() => {
    if (data) {
      if (data[VESSEL_KEYS.images]?.length > 0) {
        const imageId = data[VESSEL_KEYS.images][0]
        if (currentImageStatus === true) {
          if (totalImages?.length > 0) {
            const image = totalImages.find((i) => i[IMAGE_KEYS.id] === imageId)
            if (image) {
              setThumbnail(image[IMAGE_KEYS.s3_path])
            } else {
              setThumbnail("")
            }
          }
        }
      } else {
        setThumbnail("")
      }
    }
  }, [data])

  const {
    mutate: saveVesselOrImageMutate,
    isLoading: saveVesselOrImageLoading,
  } = useMutation(saveVesselOrImage, {
    onSuccess: ({ status }) => {
      if (status === 200) {
        posthog.capture("Vessel saved")
        toast.success("Vessel saved successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      } else {
        toast.error("Error while saving vessel.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      }
    },
  })

  const {
    mutate: deleteVesselOrImageMutate,
    isLoading: deleteVesselOrImageLoading,
  } = useMutation(deleteVesselOrImage, {
    onSuccess: ({ status }) => {
      if (status === 200) {
        posthog.capture("Vessel unsaved")
        toast.success("Vessel removed successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      } else {
        toast.error("Error while saving vessel.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      }
    },
  })

  const save = (e) => {
    const params = {
      userId: currentUser.id,
      objectId: data[VESSEL_KEYS.id],
    }
    if (isBooked) {
      deleteVesselOrImageMutate(params)
    } else {
      saveVesselOrImageMutate(params)
    }
    toggleSavedVessels(data)
    e.stopPropagation()
  }

  const identities = data[VESSEL_KEYS.linked_ids_count] || 0
  const identitiesOPRef = useRef(null)
  let identitiesOPContent = ""
  if (data[VESSEL_KEYS.red_flags]?.length > 2) {
    for (let i = 2; i < data[VESSEL_KEYS.red_flags].length; i++) {
      identitiesOPContent += `"${
        VESSEL_REDFLAG.find((v) => v.value === data[VESSEL_KEYS.red_flags][i])
          .label
      }", `
    }
    identitiesOPContent = identitiesOPContent.substring(
      0,
      identitiesOPContent.length - 2,
    )
  }
  return (
    <div className="col-12 md:col-6 lg:col-4">
      <Card
        onClick={() => {
          navigate(`/vessel-profile?id=${data[VESSEL_KEYS.id]}`)
        }}
      >
        {identities > 0 && (
          <AmountBadge>{`${identities} ${
            identities > 1 ? "identities" : "identity"
          }`}</AmountBadge>
        )}
        <Photo>
          {thumbnail === "" && currentImageStatus === true ? (
            <NoImage src={NoVesselImage} />
          ) : (
            <>
              {isImageLoading ? (
                <SkeletonContainer>
                  <Skeleton width="100%" height="100%" />
                </SkeletonContainer>
              ) : null}
              <LazyLoadImage
                src={thumbnail}
                beforeLoad={() => {
                  setIsImageLoading(true)
                }}
                afterLoad={() => {
                  setIsImageLoading(false)
                }}
                alt={data[VESSEL_KEYS.target_name]}
                width="105%"
                height="auto"
                effect="blur"
              />
            </>
          )}
        </Photo>
        <div className="p-3 relative">
          <BookButton
            icon={`pi ${isBooked ? "pi-bookmark-fill" : "pi-bookmark"}`}
            className="p-button-rounded p-button-info"
            onClick={save}
          />
          <div>
            <H24
              style={{
                color: VESSEL_IDENTITY_TYPE.find(
                  (d) => d.value === data[VESSEL_KEYS.identity],
                )?.color,
              }}
            >
              {
                VESSEL_IDENTITY_TYPE.find(
                  (d) => d.value === data[VESSEL_KEYS.identity],
                )?.label
              }
            </H24>
          </div>
          <div className="mt-2">
            <H25>{data[VESSEL_KEYS.target_name]}</H25>
          </div>
          <Tags className="mt-2 flex flex-wrap gap-2 ">
            {data[VESSEL_KEYS.red_flags].map((value, index) => {
              if (index < 2) {
                return (
                  <Tag key={index}>
                    {VESSEL_REDFLAG.find((d) => d.value === value)?.label}
                  </Tag>
                )
              }
              return null
            })}
            {data[VESSEL_KEYS.red_flags]?.length > 2 && (
              <Tag
                onMouseOver={(e) => {
                  identitiesOPRef.current.toggle(e)
                }}
                onMouseLeave={() => {
                  identitiesOPRef.current.hide()
                }}
              >{`+${data[VESSEL_KEYS.red_flags]?.length - 2}`}</Tag>
            )}
          </Tags>
          <StyledDivider />
          <div className="flex gap-6">
            <div style={{ minWidth: "5em" }}>
              <div>
                <H8>IMO</H8>
                <H12>{getValueForHumans(data[VESSEL_KEYS.target_imo])}</H12>
              </div>
              <div className="mt-2">
                <H8>MMSI</H8>
                <H12>{getValueForHumans(data[VESSEL_KEYS.target_mmsi])}</H12>
              </div>
            </div>
            <StyledDivider layout="vertical" />
            <div>
              <div>
                <H8>TYPE</H8>
                <H12>
                  {getValueForHumans(
                    VESSEL_TYPE.find((d) => d.value === data[VESSEL_KEYS.type])
                      ?.label,
                  )}
                </H12>
              </div>
              <div className="mt-2">
                <H8>FLAG</H8>
                <H12>{getValueForHumans(data[VESSEL_KEYS.target_flag])}</H12>
              </div>
            </div>
          </div>
          <StyledDivider />
          <Description>
            <H27>
              <ReactMarkdown>{data[VESSEL_KEYS.analyst_notes]}</ReactMarkdown>
            </H27>
          </Description>
          <div className="mt-2">
            <H8>
              {`Last Updated: ${formatDateString(
                data[VESSEL_KEYS.update_date],
              )}`}
            </H8>
          </div>
        </div>
      </Card>
      <OverlayPanel ref={identitiesOPRef}>
        <H12>{identitiesOPContent}</H12>
      </OverlayPanel>
    </div>
  )
}

export default memo(GridItem)

const Card = styled.div`
  position: relative;
  width: 100%;
  min-height: 25em;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid rgba(4, 61, 93, 0.26);
  border-radius: 0.5em;
  overflow: hidden;
  z-index: 0;
  transition: all 0.2s ease-out;
  cursor: pointer;
  &:hover {
    filter: drop-shadow(0px 0.35em 0.35em rgba(0, 0, 0, 0.25));
  }
`

const AmountBadge = styled(InfoAmountBadge)`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 1;
`

const Photo = styled.div`
  width: 100%;
  height: 12em;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #edecec;
  position: relative;
`

const Tag = styled.div`
  padding: 0.6em 0.8em;
  background-color: #e4e4e4;
  border-radius: 0.25em;
  min-width: max-content;
  height: min-content;
  font-size: 0.625em;
  font-weight: 700;
  color: #1d1d1b;
  line-height: 1;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`

const StyledDivider = styled(Divider)`
  margin: 0.8em 0 !important;
  padding: 0 !important;
`

const BookButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0.8em;
  background-color: #e4e4e4 !important;
  color: black !important;
  transform: translate(0, -50%);
  z-index: 1;
`

const Description = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Tags = styled.div`
  height: 2.2em;
  overflow: hidden;
`

const NoImage = styled.img``

const SkeletonContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
