import { memo } from "react"
import styled from "styled-components"
import SwitchSelector from "react-switch-selector"

import { useStore } from "state/store"

function FilterOption({ className }) {
  const isSavedImages = useStore((state) => state.isSavedImages)
  const setIsSavedImages = useStore((state) => state.setIsSavedImages)
  const filteredImagesSaved = useStore((state) => state.filteredImagesSaved)
  const rectedImages = useStore((state) => state.rectedImages)
  const sidebarWidth = useStore((state) => state.sidebarWidth)
  const isExpandedSidebar = useStore((state) => state.isExpandedSidebar)

  const onChange = (v) => {
    if (v === 0) {
      setIsSavedImages(false)
    } else {
      setIsSavedImages(true)
    }
  }

  const options = [
    {
      label: (
        <Label active={isSavedImages === false}>
          <i className="pi pi-bars"></i>
          <span>All Images &#183; {rectedImages.length}</span>
        </Label>
      ),
      value: 0,
      backgroundColor: "#e8eaec",
      selectedBackgroundColor: "#204a64",
    },
    {
      label: (
        <Label active={isSavedImages === true}>
          <i className="pi pi-bookmark"></i>
          <span>Saved Images &#183; {filteredImagesSaved.length}</span>
        </Label>
      ),
      value: 1,
      backgroundColor: "#e8eaec",
      selectedBackgroundColor: "#204a64",
    },
  ]

  return (
    <div
      className={className}
      style={{
        height: "3em",
        width: `${isExpandedSidebar ? 350 : sidebarWidth}px`,
        minWidth: "20em",
      }}
    >
      <SwitchSelector
        onChange={onChange}
        options={options}
        optionBorderRadius={32}
        wrapperBorderRadius={30}
        selectionIndicatorMargin={4}
        initialSelectedIndex={isSavedImages ? 1 : 0}
      />
    </div>
  )
}

export default memo(FilterOption)

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 0.875em;
  padding: 0.5em;
  * {
    font-size: 1em !important;
    color: ${(props) => (props.active ? "white" : "black")};
  }
`
