import {
  DrawPolygonMode,
  DrawRectangleMode,
  ModifyMode,
  TranslateMode,
} from "@nebula.gl/edit-modes"

export const START_VIEW_STATE = {
  latitude: 15.69042526673684,
  longitude: 122.39709129995093,
  zoom: 3,
  minZoom: 3,
  maxZoom: 14,
}

export const SWITCH_ZOOM = 10

export const ZOOM_STEP = 0.5

export const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 23, height: 31, anchorY: 31 },
}

export const DRAW_MODES = [
  {
    name: "Draw Rectangle",
    code: "rectangle",
    mode: DrawRectangleMode,
    config: {},
  },
  {
    name: "Draw Polygon",
    code: "polygon",
    mode: DrawPolygonMode,
    config: {},
  },
  // {
  //   name: "Translate",
  //   code: "translate",
  //   mode: TranslateMode,
  //   config: {
  //     translateInScreenSpace: true,
  //   },
  // },
  {
    name: "Modify & Delete",
    code: "modify",
    mode: ModifyMode,
    config: {},
  },
]
