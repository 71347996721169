import { CompositeLayer } from "@deck.gl/core"
import { CollisionFilterExtension } from "@deck.gl/extensions"
import { TextLayer, ScatterplotLayer } from "@deck.gl/layers"
import Supercluster from "supercluster"

function getClusterSize(size) {
  return size //Math.min(100, size) / 100 + 1
}

function getClusterText(text) {
  return text + ""
}

export default class ClusterLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged
  }

  updateState({ props, oldProps, changeFlags }) {
    const rebuildIndex =
      changeFlags.dataChanged || props.extendRadius !== oldProps.extendRadius

    if (rebuildIndex) {
      const index = new Supercluster({
        maxZoom: 16,
        radius: props.extendRadius * Math.sqrt(2),
      })
      index.load(
        props.data.map((d) => ({
          geometry: { coordinates: props.getPosition(d) },
          properties: d,
        })),
      )
      this.setState({ index })
    }

    const z = Math.floor(this.context.viewport.zoom)
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z,
      })
    }
  }

  getPickingInfo({ info, mode }) {
    const pickedObject = info.object && info.object.properties
    if (pickedObject) {
      if (pickedObject.cluster && mode !== "hover") {
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map((f) => f.properties)
      }
      info.object = pickedObject
    }
    return info
  }

  renderLayers() {
    const { data } = this.state

    const fontSize = 100
    const scale = 2 ** this.context.viewport.zoom
    const sizeMaxPixels = (scale / 3) * fontSize
    const sizeMinPixels = Math.min(scale / 1000, 0.5) * fontSize

    return [
      new ScatterplotLayer({
        id: "scatterplot-layer",
        data: data,
        pickable: false,
        opacity: 0.7,
        stroked: true,
        filled: true,
        radiusScale: 50000,
        radiusMinPixels: 1,
        radiusMaxPixels: 100,
        lineWidthMinPixels: 1,
        getPosition: (d) => d.geometry.coordinates,
        getRadius: (d) =>
          getClusterSize(d.properties.cluster ? d.properties.point_count : 1),
        getFillColor: (d) => [4, 61, 93, 140],
        getLineColor: (d) => [4, 61, 93, 255],
      }),
      new TextLayer({
        id: "text-layer",
        data: data,
        getText: (d) =>
          getClusterText(d.properties.cluster ? d.properties.point_count : 1),
        getPosition: (d) => d.geometry.coordinates,
        getColor: (d) => [255, 255, 255],
        getSize: (d) => Math.pow(d.properties.point_count || 1, 0.1) / 5,
        sizeScale: fontSize,
        sizeMaxPixels,
        sizeMinPixels,
        maxWidth: 64 * 12,
        getTextAnchor: "middle",
        getAlignmentBaseline: "center",
        // CollideExtension options
        collisionEnabled: true,
        getCollisionPriority: (d) => Math.log10(d.properties.point_count),
        collisionTestProps: {
          sizeScale: fontSize * 2,
          sizeMaxPixels: sizeMaxPixels * 2,
          sizeMinPixels: sizeMinPixels * 2,
        },
        extensions: [new CollisionFilterExtension()],
      }),
    ]
  }
}
