import styled from "styled-components"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Button } from "primereact/button"
import { usePostHog } from 'posthog-js/react'
import { device } from "theme/device"
import { H10 } from "components/Labels"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
export default function CountryImagePreview(props) {
  const posthog = usePostHog()

  const images = props.images
  const [selectedImage, setSelectedImage] = useState(images[0])

  const handleKeyPress = (event) => {
    switch (event.key) {
      case "Escape":
        props.closePreview(false)
        break
      case "ArrowRight":
        nextImage()
        break
      case "ArrowLeft":
        previousImage()
        break
      default:
        break
    }
  }

  useEffect(() => {
    posthog.capture("Country image viewed")
    document.addEventListener("keydown", handleKeyPress)
    return () => {
      document.removeEventListener("keydown", handleKeyPress)
    }
  }, [handleKeyPress])

  const nextImage = () => {
    const currentIndex = selectedImageIndex()
    const nextIndex = currentIndex + 1
    if (nextIndex < images.length) {
      setSelectedImage(images[nextIndex])
    }
  }

  const selectedImageIndex = () => {
    return images.map((item) => item.id).indexOf(selectedImage.id)
  }

  const previousImage = () => {
    const currentIndex = selectedImageIndex()
    const previousIndex = currentIndex - 1
    if (previousIndex > -1) {
      setSelectedImage(images[previousIndex])
    }
  }

  const miniImages = () => {
    return images.map((img) => (
      <MiniImage
        style={{
          border: selectedImage.id === img.id ? "2px solid #122A47" : "none",
        }}
        key={img.id}
        onClick={() => setSelectedImage(img)}
      >
        <img src={img.s3_path} alt="" />
      </MiniImage>
    ))
  }

  return (
    <AnimatePresence>
      <Holder
        transition={{ duration: 0.5 }}
        initial={{ y: 50, opacity: 0 }}
        exit={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      >
        <Content>
          <CloseButton
            icon="pi pi-times"
            className="p-button-rounded p-button-text"
            aria-label="Close"
            onClick={() => props.closePreview(false)}
          />
          <PreviousButton
            icon="pi pi-angle-left"
            className="p-button-rounded p-button-outlined"
            aria-label="Previous"
            onClick={() => previousImage()}
          />
          <NextButton
            icon="pi pi-angle-right"
            className="p-button-rounded p-button-outlined"
            aria-label="Next"
            onClick={() => nextImage()}
          />
          <Viewer>
            <LazyLoadImage
              width="auto"
              height="100%"
              src={selectedImage.s3_path}
              alt=""
              effect="blur"
            />
          </Viewer>
          <MiniImagePreview>{miniImages()}</MiniImagePreview>
          <Details>
            <div className="flex gap-2 w-full justify-content-between">
              <div>
                <H10>{props.data.name}</H10>
              </div>
            </div>

            <ReactMarkdown className="markdown_style_custom">
              {props.data.notes}
            </ReactMarkdown>
          </Details>
        </Content>
      </Holder>
    </AnimatePresence>
  )
}
const Holder = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #000000af;
`
const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 1900px;
  height: 100%;
  padding: 5em 5em 0 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  //gap: 1em;
  @media ${device.pad} {
    padding: 0.5em 0.5em 0 0.5em;
  }
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 1;
  color: white !important;
`
const PreviousButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 1em;
  z-index: 1;
  color: white !important;
  transform: translateY(-50%);
`
const NextButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 1em;
  z-index: 1;
  color: white !important;
  transform: translateY(-50%);
`
const Viewer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  overflow: hidden;
  background-color: #111111;
`
const Details = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  padding: 1em;
`
// const SourceButton = styled.a`
//   cursor: pointer;
//   border-radius: 10px;
//   padding: 5px 10px;
//   appearance: none;
//   background-color: #0a6cff50;
//   color: #0a6cff;
//   display: flex;
//   align-items: center;
//   row-gap: 20px;
//   && ::hover {
//     background-color: #0a6cff8d;
//   }
// `

const MiniImagePreview = styled.div`
  width: 100%;
  /* border-top: solid 1px #4442427b; */
  display: flex;
  align-items: center;
  row-gap: 10px;
  justify-content: center;

  overflow-y: hidden;
`

const MiniImage = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 5px;
  > img {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
`
