import { VESSEL_KEYS } from "dataset/vessel"
import SearchItem from "./SearchItem"
import { AutoComplete } from "primereact/autocomplete"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { usePostHog } from 'posthog-js/react'
import "./index.css"

export default function SearchInput({
  onChange,
  results,
  setSearchedVessels,
  searchValue,
  setSearchValue,
}) {
  const posthog = usePostHog()
  const searchbarRef = useRef()
  const [suggestions, setSuggestions] = useState([])
  const [toggleSearch, setToggleSearch] = useState(false)
  useEffect(() => {
    const sortedResults = [...results].sort((a, b) =>
      a.target_name.localeCompare(b.target_name),
    )
    setSuggestions(sortedResults)
  }, [results])

  const filterResults = (results, searchKey, searchType) => {
    const filteredResults = results.filter((item) => {
      return (
        item[VESSEL_KEYS.target_name]
          ?.toString()
          .toLowerCase()
          .includes(searchKey.toLowerCase()) ||
        item[VESSEL_KEYS.target_imo]
          ?.toString()
          .toLowerCase()
          .includes(searchKey.toLowerCase()) ||
        item[VESSEL_KEYS.target_mmsi]
          ?.toString()
          .toLowerCase()
          .includes(searchKey.toLowerCase()) ||
        item[VESSEL_KEYS.internal_id]
          ?.toString()
          .toLowerCase()
          .includes(searchKey.toLowerCase()) ||
        (searchType === "searched" &&
          item[VESSEL_KEYS.analyst_notes]
            ?.toString()
            .toLowerCase()
            .includes(searchKey.toLowerCase()))
      )
    })

    filteredResults.sort((a, b) => {
      const aContainsTarget = a[VESSEL_KEYS.target_name]
        ?.toString()
        .toLowerCase()
        .includes(searchKey.toLowerCase())
      const bContainsTarget = b[VESSEL_KEYS.target_name]
        ?.toString()
        .toLowerCase()
        .includes(searchKey.toLowerCase())

      if (aContainsTarget && !bContainsTarget) {
        return -1
      } else if (!aContainsTarget && bContainsTarget) {
        return 1
      } else {
        const aContainsNotes =
          searchType === "searched" &&
          a[VESSEL_KEYS.analyst_notes]
            ?.toString()
            .toLowerCase()
            .includes(searchKey.toLowerCase())

        const bContainsNotes =
          searchType === "searched" &&
          b[VESSEL_KEYS.analyst_notes]
            ?.toString()
            .toLowerCase()
            .includes(searchKey.toLowerCase())

        if (aContainsNotes && !bContainsNotes) {
          return -1
        } else if (!aContainsNotes && bContainsNotes) {
          return 1
        } else {
          return 0
        }
      }
    })
    return filteredResults
  }
  const completeMethod = (event) => {
    onChange({ target: { value: event.query.trim() } })
    setTimeout(() => {
      let _suggestions = []

      if (!event.query.trim().length) {
        _suggestions = [...results]
      } else {
        _suggestions = filterResults(results, event.query, "searching")
      }
      const sortedData = _suggestions
        .slice()
        .sort((a, b) => a.target_name.localeCompare(b.target_name))
      sortedData.unshift(event.query.trim())
      let updatedArray = sortedData.filter(
        (obj) => !searchValue.some((item) => item.id === obj.id),
      )
      setSuggestions(updatedArray)
    }, 250)
  }
  const itemTemplate = (d) => {
    return (
      <SearchItem data={d} width={searchbarRef?.current?.clientWidth || 400} />
    )
  }

  const selectedItemTemplate = (d) => {
    if (typeof d === "string") {
      return `${d}`
    }
    return d[VESSEL_KEYS.target_name]
  }

  const doSearch = (value = null) => {
    let searched = value !== null ? value : searchValue
    if (searched?.length) {
      let tempKeywordResults = []
      let tempVesselsResults = searched
        .map((_searchItem) => {
          if (typeof _searchItem !== "string") {
            return _searchItem
          }
          let searchItem = _searchItem.split(`"`).join("")
          const keywordSearchResults = filterResults(
            results,
            searchItem,
            "searched",
          )
          tempKeywordResults.push(...keywordSearchResults)
          // Sort the array alphabetically
          tempKeywordResults.sort()
          return null
        })
        .filter((item) => item)
      tempKeywordResults = [
        ...new Set([
          ...new Set([...tempKeywordResults]),
          ...new Set([...tempVesselsResults]),
        ]),
      ]
      setSearchedVessels(tempKeywordResults)
      posthog.capture("Vessel searched")
    } else {
      setSearchedVessels(results)
    }
  }

  useEffect(() => {
    doSearch()
    setToggleSearch(true)
  }, [searchValue])

  return (
    <Holder className="p-input-icon-left search-panel-holder search-panel w-full py-2 relative">
      <Searchbar className="p-float-label p-input-icon-left" ref={searchbarRef}>
        <i className="pi pi-search" />
        <StyledAutoComplete
          panelClassName="panel-position"
          panelStyle={{ top: 0, left: 0 }}
          value={toggleSearch ? searchValue : ""}
          suggestions={suggestions}
          completeMethod={completeMethod}
          selectedItemTemplate={selectedItemTemplate}
          field={VESSEL_KEYS.target_name}
          autoFocus
          virtualScrollerOptions={
            suggestions?.length > 1 ? { itemSize: 70 } : undefined
          }
          appendTo={searchbarRef.current}
          onChange={(e) => {
            setToggleSearch(true)
            if (e?.value?.length) {
              const objects = []
              const strings = []
              e.value.forEach((element) => {
                if (typeof element === "object") {
                  if (objects.indexOf(element) === -1) objects.push(element)
                } else {
                  let _element = element.split(`"`).join("")
                  const existingElements = strings.filter(
                    (string) => string.split(`"`).join("") === _element,
                  )
                  if (!existingElements?.length) strings.push(`"${_element}"`)
                }
              })
              const out = objects.concat(strings)
              setSearchValue(out)
            } else {
              setSearchValue(e.value)
            }
          }}
          itemTemplate={itemTemplate}
          size={50}
          forceSelection={false}
          multiple
          placeholder="Search by vessel or identity"
        />
      </Searchbar>
      <Button
        onClick={(e) => {
          doSearch()
        }}
      >
        <i className="pi pi-search right" />
      </Button>
    </Holder>
  )
}

const Button = styled.button`
  padding: 0.8em;
  background: #f75151;
  line-height: 14px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  > .right {
    color: white;
    font-size: 24px;
  }
`

const Holder = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  background-color: #f2f2f2;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.07);
  padding-right: 1em;
  width: 100% !important;
  z-index: 50;
  position: absolute;
  .p-inputtext {
    border: none;
    line-height: 3;
    padding-left: 4em;
    border-radius: 0;
  }
`

const Searchbar = styled.span`
  display: flex;
  flex: 1;
  min-width: 10em;
  position: relative;
`

const StyledAutoComplete = styled(AutoComplete)`
  .p-autocomplete-multiple-container {
    width: 100%;
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
    padding-left: 3em !important;
  }

  .p-autocomplete-token {
    position: relative;
    max-width: 100%;
  }

  .p-autocomplete-token-label {
    white-space: normal;
    overflow-wrap: break-word;
    max-width: 100%;
  }
`
