import { useStore } from "state/store"
import styled from "styled-components"

export default function ConfirmUserDetailsModal({
  creationType,
  userData,
  role,
  checkbox,
  setConfirmUserDetailsModalToggle,
  handleSubmit,
}) {
  const setAddUserDialogVisible = useStore(
    (state) => state.setAddUserDialogVisible,
  )

  return (
    <Holder>
      <Heading>Confirm User Details</Heading>
      <FlexHolder>
        <SingleItem>
          <Label>First Name:</Label>
          <Text>{userData?.firstName ? userData?.firstName : ""}</Text>
        </SingleItem>
        <SingleItem>
          <Label>Last Name:</Label>
          <Text>{userData?.lastName ? userData?.lastName : ""}</Text>
        </SingleItem>
        <SingleItem>
          <Label>Email Address:</Label>
          <Text>{userData?.email ? userData?.email : ""}</Text>
        </SingleItem>
        <SingleItem>
          <Label>Organization:</Label>
          <Text>{userData?.organization ? userData?.organization : ""}</Text>
        </SingleItem>
        <SingleItem>
          <Label>Title:</Label>
          <Text>{userData?.title ? userData?.title : ""}</Text>
        </SingleItem>
        <SingleItem>
          <Label>Role:</Label>
          <Text>{role ? role : ""}</Text>
        </SingleItem>
        <SingleItem>
          <Label>
            {checkbox ? "Can make Insights Bespoke Requests" : "Cannot make Insights Bespoke Requests"}{" "}
          </Label>
        </SingleItem>
      </FlexHolder>
      <ButtonHolder>
        <Button
          onClick={() => {
            setConfirmUserDetailsModalToggle(false)
            setAddUserDialogVisible(true)
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            setConfirmUserDetailsModalToggle(false)
            handleSubmit()
          }}
        >
          Submit
        </Button>
      </ButtonHolder>
      <ClosedIcon
        onClick={() => {
          setConfirmUserDetailsModalToggle(false)
        }}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="p-icon p-dialog-header-close-icon"
          aria-hidden="true"
        >
          <path
            d="M8.01186 7.00933L12.27 2.75116C12.341 2.68501 12.398 2.60524 12.4375 2.51661C12.4769 2.42798 12.4982 2.3323 12.4999 2.23529C12.5016 2.13827 12.4838 2.0419 12.4474 1.95194C12.4111 1.86197 12.357 1.78024 12.2884 1.71163C12.2198 1.64302 12.138 1.58893 12.0481 1.55259C11.9581 1.51625 11.8617 1.4984 11.7647 1.50011C11.6677 1.50182 11.572 1.52306 11.4834 1.56255C11.3948 1.60204 11.315 1.65898 11.2488 1.72997L6.99067 5.98814L2.7325 1.72997C2.59553 1.60234 2.41437 1.53286 2.22718 1.53616C2.03999 1.53946 1.8614 1.61529 1.72901 1.74767C1.59663 1.88006 1.5208 2.05865 1.5175 2.24584C1.5142 2.43303 1.58368 2.61419 1.71131 2.75116L5.96948 7.00933L1.71131 11.2675C1.576 11.403 1.5 11.5866 1.5 11.7781C1.5 11.9696 1.576 12.1532 1.71131 12.2887C1.84679 12.424 2.03043 12.5 2.2219 12.5C2.41338 12.5 2.59702 12.424 2.7325 12.2887L6.99067 8.03052L11.2488 12.2887C11.3843 12.424 11.568 12.5 11.7594 12.5C11.9509 12.5 12.1346 12.424 12.27 12.2887C12.4053 12.1532 12.4813 11.9696 12.4813 11.7781C12.4813 11.5866 12.4053 11.403 12.27 11.2675L8.01186 7.00933Z"
            fill="currentColor"
          ></path>
        </svg>
      </ClosedIcon>
    </Holder>
  )
}

const Holder = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
`

const FlexHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
`

const SingleItem = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
`

const ClosedIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: -10px;
  right: 30px;
  font-size: 19px;
`

const Heading = styled.div`
  border-bottom: 0.1px solid gray;
  padding-bottom: 10px;
`
const Label = styled.h1`
  font-size: 1rem;
`
const Text = styled.p`
  font-size: 1rem;
`

const ButtonHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`

const Button = styled.button`
  font-size: 1rem;
  background: rgb(252, 38, 38);
  color: white;
  padding-right: 22px;
  border-radius: 10px;
  padding-left: 22px;
  font-weight: 700;
  border: none;
  padding-top: 14px;
  padding-bottom: 14px;
  cursor: pointer;
`
