import { useState, useEffect } from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { useMutation } from "@tanstack/react-query"
import { toast } from "react-toastify"
import { Splitter, SplitterPanel } from "primereact/splitter"
import { Button } from "primereact/button"

import { getQueryString } from "utils"
import { useStore } from "state/store"
import { IMAGE_KEYS } from "dataset/image"
import { allImagesRequest } from "api/images"
import { getVesselsRequest } from "api/vessels"
import { getSavedVesselOrImage } from "api/auth"
import ImageProfileDialog from "components/ImageProfileDialog"
import Loader from "components/Loader"
import Sidebar from "./components/Sidebar"
import ImageryLibraryMap from "./components/ImageryLibraryMap"
import Timelinebar from "./components/Timelinebar"
import Filterbar from "./components/Filterbar"

export default function ImageryLibrary() {
  const location = useLocation()

  const rectedImages = useStore((state) => state.rectedImages)
  const setFilteredImages = useStore((state) => state.setFilteredImages)
  const setFilteredImagesSaved = useStore(
    (state) => state.setFilteredImagesSaved,
  )
  const setImageCollection = useStore((state) => state.setImageCollection)
  const setTotalVessels = useStore((state) => state.setTotalVessels)
  const totalVessels = useStore((state) => state.totalVessels)
  const totalImages = useStore((state) => state.totalImages)
  const setTotalImages = useStore((state) => state.setTotalImages)
  const setSavedImages = useStore((state) => state.setSavedImages)
  const isExpandedSidebar = useStore((state) => state.isExpandedSidebar)
  const setIsExpandedSidebar = useStore((state) => state.setIsExpandedSidebar)
  const currentUser = useStore((state) => state.currentUser)
  const setCurrentImage = useStore((state) => state.setCurrentImage)
  const setProfileGalleryDialogVisible = useStore(
    (state) => state.setProfileGalleryDialogVisible,
  )

  const { data: iData, isLoading: iIsLoading } = useQuery(
    ["allImagesRequest"],
    () => allImagesRequest(),
  )

  const { data: vData, isLoading: vIsLoading } = useQuery(
    ["getVesselsRequest"],
    () => getVesselsRequest(),
  )

  const {
    mutate: getSavedVesselOrImageMutate,
    isLoading: getSavedVesselOrImageLoading,
  } = useMutation(getSavedVesselOrImage, {
    onSuccess: ({ status, data }) => {
      if (status === 200) {
        const savedIds = data?.saved_images || []
        const saved = iData.filter(({ id }) => {
          return savedIds.includes(id)
        })
        setSavedImages(saved)
        setFilteredImagesSaved(saved)
      } else {
        setSavedImages([])
      }
    },
  })

  const [sizes, setSizes] = useState([25, 75])

  useEffect(() => {
    if (totalImages.length === 0 && iData) {
      setTotalImages(iData)
      setFilteredImages(iData)
    }
  }, [iData, totalImages, setTotalImages, setFilteredImages])

  useEffect(() => {
    if (totalVessels.length === 0 && vData) {
      setTotalVessels(vData)
    }
  }, [vData, totalVessels, setTotalVessels])

  useEffect(() => {
    setImageCollection(rectedImages)
  }, [rectedImages, setImageCollection])

  useEffect(() => {
    if (currentUser && iData) {
      getSavedVesselOrImageMutate({
        userId: currentUser.id,
        objectType: "images",
      })
    }
  }, [currentUser, iData, getSavedVesselOrImageMutate])

  useEffect(() => {
    const id = getQueryString(location.search, "id")
    if (id) {
      const image = totalImages.find((i) => i[IMAGE_KEYS.id] === id)
      if (image) {
        setCurrentImage(image)
        setProfileGalleryDialogVisible(true)
      } else {
        toast.error("There is no image selected", { autoClose: 5000 })
      }
    } else {
      setProfileGalleryDialogVisible(false)
    }
  }, [location, totalImages, setCurrentImage, setProfileGalleryDialogVisible])

  const isLoading = iIsLoading || vIsLoading || getSavedVesselOrImageLoading

  return (
    <Holder>
      <Loader visible={isLoading} size={50} />
      <Container>
        <Filterbar />
        <Content>
          <Splitter
            className="w-full h-full"
            layout="horizontal"
            gutterSize={0}
            sizes={sizes}
            onResizeEnd={(e) => {
              setSizes(e.sizes)
            }}
          >
            <SplitterPanel
              minSize={20}
              style={{
                position: "relative",
              }}
              size={sizes[0]}
            >
              <Sidebar />
              <ExpandButton
                icon={`pi ${
                  isExpandedSidebar ? "pi-chevron-left" : "pi-chevron-right"
                }`}
                onClick={() => {
                  if (isExpandedSidebar) {
                    setIsExpandedSidebar(false)
                    setSizes([25, 75])
                  } else {
                    setIsExpandedSidebar(true)
                    setSizes([75, 25])
                  }
                }}
              />
            </SplitterPanel>
            <SplitterPanel
              minSize={25}
              style={{
                position: "relative",
              }}
              size={sizes[1]}
            >
              <MapHolder>
                <ImageryLibraryMap />
                <Timelinebar />
                <Title>Imagery Library</Title>
              </MapHolder>
            </SplitterPanel>
          </Splitter>
        </Content>
        <ImageProfileDialog />
      </Container>
    </Holder>
  )
}

const Holder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: black;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 5em;
  flex-direction: column;
`

const Content = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
`

const MapHolder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const ExpandButton = styled(Button)`
  position: absolute;
  top: 5em;
  right: 0;
  z-index: 2;
  transform: translate(100%, -50%);
  background-color: white !important;
  color: black !important;
  padding: 1em 0.5em !important;
  width: auto !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
`

const Title = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(25px);
  padding: 0.7em 1.2em;
  border-radius: 0px 0px 0px 8px;
  font-weight: 600;
`
