import Slider from "rc-slider"
import styled from "styled-components"
import "rc-slider/assets/index.css"

const marks = {
  0: {
    style: {
      color: "gray",
    },
    label: <strong>0%</strong>,
  },
  30: {
    style: {
      color: "gray",
    },
    label: <strong>30%</strong>,
  },
  60: {
    style: {
      color: "gray",
    },
    label: <strong>60%</strong>,
  },
  90: {
    style: {
      color: "gray",
    },
    label: <strong>90%</strong>,
  },
  // 100: {
  //   style: {
  //     color: "gray",
  //   },
  //   label: <strong>100%</strong>,
  // },
}

export default function GACSlider(props) {
  return <SliderStyled marks={marks} {...props} />
}

const currentValue = 'attr(aria-valuenow) "%"'
const SliderStyled = styled(Slider)`
  height: 2px;
  &.rc-slider-disabled {
    height: 2px;
    opacity: 0.8;
  }
  .rc-slider-handle {
    background-color: #f75151;
    border: solid 2px #f75151;
    margin-top: -7px;
    opacity: 1;
    &:hover {
      border-color: #f75151;
    }
    &:after {
      content: ${(props) => (props.value > 0 ? currentValue : "")};
      position: absolute;
      padding: 0.5em;
      top: -25px;
      left: -7px;
      font-weight: 600;
      font-size: 12px;
      color: #f75151;
    }
  }
  .rc-slider-track {
    height: 2px;
    background-color: #f75151;
  }
  .rc-slider-rail {
    height: 2px;
  }
  .rc-slider-dot {
    bottom: -1px;
  }
  .rc-slider-dot-active {
    border-color: #f75151;
  }
  .rc-slider-handle-dragging {
    box-shadow: 0 0 5px #f75151 !important;
    border-color: #f75151 !important;
  }
`
