import styled from "styled-components"
/**
 * Interest database
 */
export const H1 = styled.div`
  font-size: 3.25em;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.2;
  letter-spacing: 0.01em;
`
export const H2 = styled.div`
  font-size: 1.5em;
  font-weight: 100;
  color: #ffffff;
  line-height: 1.2;
  letter-spacing: 0.01em;
  opacity: 0.5;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 45em;
`
/**
 * Profile
 */
export const H3 = styled.div`
  font-size: 2em;
  font-weight: 700;
  color: #000000;
  line-height: 1.2;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`
export const H4 = styled.div`
  font-size: 0.875em;
  font-weight: 400;
  color: #000000;
  line-height: 1.2;
  letter-spacing: 0.01em;
  opacity: 0.7;
`
export const H5 = styled.div`
  font-size: 0.875em;
  font-weight: 700;
  color: #000000;
  line-height: 1.2;
  letter-spacing: 0.01em;
`
export const H6 = styled.div`
  font-size: 0.8125em;
  font-weight: 700;
  color: #1d1d1b;
  line-height: 1.2;
  letter-spacing: 0.01em;
  background-color: #e4e4e4;
  border-radius: 4px;
  align-items: center;
  padding: 0.5em;
`

export const H7 = styled.div`
  font-size: 1.125em;
  font-weight: 700;
  color: #000000;
  line-height: 1.2;
  letter-spacing: 0.01em;
`

export const H8 = styled.div`
  font-size: 0.85em;
  font-weight: 400;
  color: #7d7d7d;
  line-height: 1.2;
  letter-spacing: 0.01em;
`

export const H9 = styled.div`
  font-size: 0.875em;
  font-weight: 700;
  color: #393939;
  line-height: 1.2;
  letter-spacing: 0.01em;
`

export const H10 = styled.div`
  font-size: 1.25em;
  font-weight: 700;
  color: #000000;
  line-height: 1.25;
  letter-spacing: 0.01em;
`

export const H11 = styled.span`
  font-size: 0.75em;
  font-weight: 700;
  color: #b5b5b5;
  line-height: 1.25;
  letter-spacing: 0.01em;
`

export const H12 = styled.div`
  font-size: 0.9em; //12px
  font-weight: 700;
  color: #000000;
  line-height: 1.25;
  letter-spacing: 0.03em;
  &.text-uppercase {
    text-transform: uppercase;
  }
`

export const H13 = styled.span`
  font-size: 0.625em;
  font-weight: 700;
  color: #b5b5b5;
  line-height: 1.25;
  letter-spacing: 0.01em;
  &:before {
    content: "•";
  }
`

export const H14 = styled.span`
  font-size: 0.6875em;
  font-weight: 400;
  color: #7d7d7d;
  line-height: 1.25;
  letter-spacing: 0.01em;
`

export const H15 = styled.div`
  font-size: 0.9em;
  font-weight: 700;
  color: #000000;
  line-height: 1.2;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`

export const H17 = styled.div`
  font-size: 1.5em;
  font-weight: 700;
  color: #000000;
  line-height: 1.25;
  letter-spacing: 0.01em;
`

export const H18 = styled.div`
  font-size: 1em;
  font-weight: 700;
  color: #000000;
  line-height: 1.25;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`

export const H19 = styled.div`
  font-size: 0.875em;
  font-weight: 400;
  color: #000000;
  line-height: 1.7;
  letter-spacing: 0.01em;
  opacity: 0.7;
`

export const H20 = styled.div`
  font-size: 0.875em;
  font-weight: 400;
  color: #000000;
  line-height: 1.2;
  letter-spacing: 0.01em;
`

export const H21 = styled.div`
  font-size: 0.75em;
  font-weight: 400;
  color: #000000;
  line-height: 1.2;
  letter-spacing: 0.01em;
`
export const H22 = styled.div`
  font-size: 1em;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.25;
  letter-spacing: 0.01em;
`
export const H23 = styled.div`
  font-size: 1.25em;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.25;
  letter-spacing: 0.01em;
`

export const H24 = styled.span`
  font-size: 1em; //12px
  font-weight: 700;
  color: #f75151;
  line-height: 1.25;
  letter-spacing: 0.01em;
`

export const H25 = styled.span`
  font-size: 1.375em;
  font-weight: 700;
  color: #000000;
  line-height: 1.25;
  letter-spacing: 0.01em;
`

export const H26 = styled.span`
  font-size: 0.625em;
  font-weight: 700;
  color: #1d1d1b;
  line-height: 1.2;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`

export const H27 = styled.span`
  font-size: 0.875em;
  font-weight: 400;
  color: #1d1d1b;
  line-height: 1.68;
  letter-spacing: 0.01em;
`

export const H28 = styled.span`
  font-size: 0.875em;
  font-weight: 400;
  color: #000000;
  line-height: 1.2;
  letter-spacing: 0.01em;
`

export const H29 = styled.span`
  font-size: 0.6875em;
  font-weight: 500;
  color: #000000;
  line-height: 1;
  letter-spacing: 0.01em;
`

/**
 * Oil shipment tracker
 */
export const H30 = styled.p`
  font-weight: 400;
  font-size: 1.1em;
  line-height: 2em;
  color: #131521;
`
export const H31 = styled.h1`
  font-weight: 700;
  font-size: 2.5em;
  line-height: 2.5em;
  color: #122945;
  margin: 0;
`
export const H32 = styled.div`
  font-size: 0.8125em;
  font-weight: 700;
  color: #7d7d7d;
  line-height: 1.2;
  letter-spacing: 0.01em;
`
export const H33 = styled.div`
  font-size: 0.8125em;
  font-weight: 700;
  color: #393939;
  line-height: 1.2;
  letter-spacing: 0.01em;
`
export const H34 = styled.h2`
  font-size: 1em;
  font-weight: 700;
  line-height: 1.4em;
`

export const H35 = styled.span`
  font-size: 0.9em;
  font-weight: 100;
  line-height: 1.7;
`

export const H36 = styled.span`
  font-size: 0.6875em;
  font-weight: 400;
  color: #7d7d7d;
  line-height: 1;
  letter-spacing: 0.01em;
`

export const H37 = styled.span`
  font-size: 1.2em;
  font-weight: 400;
  color: #acacac;
  line-height: 1.5;
  letter-spacing: 0.025em;
`

export const H38 = styled.div`
  font-size: 2em;
  font-weight: 700;
  color: #000000;
  line-height: 1.2;
  letter-spacing: 0.01em;
`

export const H39 = styled.div`
  font-size: 1.125em;
  font-weight: 400;
  color: #000000;
  line-height: 1.2;
  letter-spacing: 0.01em;
`

export const H40 = styled.div`
  font-size: 1em;
  font-weight: 700;
  color: #043d5d;
  line-height: 1.2;
`

export const H41 = styled.p`
  font-size: 1em;
  font-weight: 700;
  color: #043d5d;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 260px;
  white-space: nowrap;
`

export const H42 = styled.p`
  font-size: 1em;
  font-weight: 100;
  color: #808080;
  line-height: 1.2;
`

export const H43 = styled.div`
  font-size: 2em;
  font-weight: 700;
  color: #fff;
  line-height: 1.2;
`

export const H44 = styled.div`
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 1.5em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
`

export const H45 = styled.div`
  color: #122945;
  font-size: 1.125em;
  font-style: normal;
  font-weight: 700;
  line-height: 2;
`

export const H46 = styled.div`
  color: #122945;
  font-size: 2em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
`

/** Home **/
export const PageTitle = styled.h1`
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  margin: 0 0 30px 0;
  color: ${(props) => props.theme.colors.primary_4};
  &:after {
    content: "";
    width: 60px;
    height: 5px;
    display: block;
    background-color: ${(props) => props.theme.colors.primary};
    margin-top: 30px;
  }
`
export const Typography = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 33px;
  color: ${(props) => props.theme.colors.primary_color};
`
export const HighLight = styled.span`
  color: #f75151;
  font-weight: 700;
`
export const CounterBox = styled.div`
  background-color: rgba(65, 64, 66, 0.14);
  width: 119px;
  height: 77px;
  @media (max-width: 500px) {
    width: 100%;
    height: auto;
  }
  text-align: center;
  border-radius: 8px;
  padding: 8px;
  margin-right: 30px;
  .counterValue {
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.value_color};
  }
  .counterTitle {
    font-weight: 400;
    font-size: ${(props) => props.theme.fontSize.typography_16};
    line-height: 27px;
    margin: 0;
    color: ${(props) => props.theme.colors.value_color};
    opacity: 0.5;
  }
`
export const CounterBoxHolder = styled.div`
  margin-top: 76px;
  display: flex;
  @media (max-width: 500px) {
    flex-direction: column;
    row-gap: 1em;
  }
`
export const TypographyHolder = styled.div`
  margin-bottom: 37px;
`

/** Image Library **/
export const WarningMsg = styled.span`
  font-weight: 400;
  font-size: 0.825em;
  line-height: 1.5;
  color: #f75151;
`
