import { useEffect, useRef } from "react"
import { Divider } from "primereact/divider"
import styled from "styled-components"

import { H45 } from "components/Labels"

export default function EmbeddedSignupForm({ className }) {
  const iframeRef = useRef(null)

  useEffect(() => {
    const iframe = iframeRef.current
    if (iframe) {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
      if (iframeDoc) {
        const body = iframeDoc.querySelector("body")
        if (body) {
          const form = body.querySelector("form")
          if (form) {
            const firstP = body.querySelector("p")
            if (firstP) {
              firstP.style.display = "none"
            }
          }
        }
      }
    }
  }, [])

  return (
    <Holder className={className}>
      <H45>Sign up to get updates</H45>
      <Divider className="p-0 m-0 my-2" />
      <iframe
        ref={iframeRef}
        title="Embedded Form"
        src="https://go.c4ads.org/l/833983/2023-09-13/5vv2bj"
      ></iframe>
    </Holder>
  )
}

const Holder = styled.div`
  min-width: 15em;
  min-height: 15em;
  height: fit-content;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 41px 0px rgba(0, 0, 0, 0.15);
  padding: 1em;
  > iframe {
    width: 100%;
    height: 900px;
    border: none;
    > body {
      background-color: #fff !important;
    }
  }
`
