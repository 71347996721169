import { memo, useState } from "react"
import { ProgressSpinner } from "primereact/progressspinner"
import styled from "styled-components"
import { toast } from "react-toastify"
import { isEqual } from "lodash-es"

import { getRrpDownloadRequest } from "api/rrp"
import { useStore } from "state/store"
import { USER_KEYS } from "dataset/user"
import DownloadIcon from "assets/images/icon-download.svg"
import TrashIcon from "assets/images/icon-trash.svg"
import EditIcon from "assets/images/icon-pen.svg"

function RRPActionElement({ data }) {
  const setRrpRemoveProductDialogVisible = useStore(
    (state) => state.setRrpRemoveProductDialogVisible,
  )
  const setRrpEditProductDialogVisible = useStore(
    (state) => state.setRrpEditProductDialogVisible,
  )
  const setSelectedRrpProductData = useStore(
    (state) => state.setSelectedRrpProductData,
  )
  const currentUser = useStore((state) => state.currentUser)

  const [isDownloading, setIsDownloading] = useState(false)

  const isAdmin = isEqual(currentUser?.[USER_KEYS.role], 0)

  const handleDownload = (event) => {
    event.stopPropagation()
    setIsDownloading(true)
    getRrpDownloadRequest(data.id).then((response) => {
      const url = URL.createObjectURL(response)
      const a = document.createElement("a")
      a.href = url
      a.download = data.file_name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      setIsDownloading(false)
      toast.info("Downloaded successfully")
    })
  }

  const handleDelete = (event) => {
    event.stopPropagation()
    setSelectedRrpProductData(data)
    setRrpRemoveProductDialogVisible(true)
  }

  const handleEdit = (event) => {
    event.stopPropagation()
    setSelectedRrpProductData(data)
    setRrpEditProductDialogVisible(true)
  }

  return (
    <Holder>
      {isAdmin && <ImageButton src={TrashIcon} onClick={handleDelete} />}
      {isAdmin && <ImageButton src={EditIcon} onClick={handleEdit} />}
      {isDownloading ? (
        <ProgressSpinner
          style={{ width: "1.375em", height: "1.375em" }}
          strokeWidth="3"
        />
      ) : (
        <ImageButton src={DownloadIcon} onClick={handleDownload} />
      )}
    </Holder>
  )
}

export default memo(RRPActionElement)

const Holder = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
`

const ImageButton = styled.img`
  height: 1.2em;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  :hover {
    opacity: 0.5;
  }
`
