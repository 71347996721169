import { useEffect } from "react"

const useHideSelectOptionsOnScroll = () => {
  const containers = document.querySelectorAll(
    ".p-sidebar-content, .p-dialog-content",
  )
  useEffect(() => {
    const scrollEventHandler = () => {
      const dropdownOptions = document.querySelectorAll(
        ".p-dropdown-panel,.p-multiselect-panel",
      )
      // Hack: Casting activeElement as 'any' in order to use 'blur', which is otherwise not allowed
      // and requires jumping through myriad hoops
      const activeElement = document.activeElement
      activeElement?.blur()
      // Only one set of options will be open at a time
      dropdownOptions[0] && dropdownOptions[0].classList.add("hidden")
    }

    // NOTE: setTimeout is used because for some reason, Prime components can't be found with document.querySelector
    // until some time elapses, even though they're immediately visible
    setTimeout(() => {
      window.addEventListener("scroll", scrollEventHandler)
      containers.forEach((x) =>
        x.addEventListener("scroll", scrollEventHandler),
      )
    }, 500)
  }, [containers])
}

export default useHideSelectOptionsOnScroll
