import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { useQuery } from "@tanstack/react-query"
import { usePostHog } from 'posthog-js/react'

import { getCountries, getAOIs } from "api/interactivemap"

export default function AoiSearchAutoComplete(props) {
  const posthog = usePostHog()
  const [searchQ, setSearchQ] = useState("")
  const [currentIndex, setCurrentIndex] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [selectedCountry1, setSelectedCountry1] = useState(null)
  const [filteredCountries, setFilteredCountries] = useState([])
  const inputRef = useRef()

  useEffect(() => {
    setCurrentIndex(-1)
    if (iData && inputRef.current.value.length > 0) {
      setFilteredCountries(
        iData
          .filter((item) => {
            return item.name
              .toLowerCase()
              .includes(inputRef.current.value.toLowerCase())
          })
          .splice(0, 5),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQ, setFilteredCountries])
  const { data: iData } = useQuery(["getResults", [props.name]], () =>
    props.name === "country" ? getCountries() : getAOIs(),
  )

  const itemTemplate = (item, index) => {
    const color = function (type) {
      switch (type) {
        case "shipyard":
          return "#3BBC97"
        case "port":
          return "#F75151"
        case "hotspot":
          return "#000000"
        default:
          return "#FFFFFF"
      }
    }

    return (
      <li key={index}>
        <AoiItem
          className={
            index === currentIndex ? "selected-autotocomplete-item" : ""
          }
          onClick={() => {
            setFilteredCountries([])
            setSearchQ("")
            selectCountry(item)
            if (props.name === "aoi") {
              posthog.capture("AOI profile viewed through search bar")
            } else {
              posthog.capture("Country profile viewed through search bar")
            }
          }}
        >
          <div>{item.name}</div>
          {props.legend && (
            <svg
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.52232 4.17223L12.4475 2.76105V14.4472L1.97671 11.4246L3.52232 4.17223Z"
                fill={color(item?.aoi_type_verbose?.toLowerCase())}
                fillOpacity="0.2"
                stroke={color(item?.aoi_type_verbose?.toLowerCase())}
              />
              <circle
                cx="12.4469"
                cy="2.27889"
                r="1.55623"
                fill={color(item?.aoi_type_verbose?.toLowerCase())}
              />
              <circle
                cx="12.4469"
                cy="14.7242"
                r="1.55623"
                fill={color(item?.aoi_type_verbose?.toLowerCase())}
              />
              <circle
                cx="1.55623"
                cy="11.6148"
                r="1.55623"
                fill={color(item?.aoi_type_verbose?.toLowerCase())}
              />
              <circle
                cx="3.11873"
                cy="3.84139"
                r="1.55623"
                fill={color(item?.aoi_type_verbose?.toLowerCase())}
              />
            </svg>
          )}
        </AoiItem>
      </li>
    )
  }

  function selectCountry(country) {
    inputRef.current.value = country.name
    setSelectedCountry1(country)
    props.onChangeCountry(country)
  }

  const keyDown = function (e) {
    if (e.code === "ArrowDown") {
      if (currentIndex < filteredCountries.length - 1) {
        e.preventDefault()
        const newD = currentIndex + 1

        setCurrentIndex(newD)
      } else {
        setCurrentIndex(0)
      }

      return
    } else if (e.code === "ArrowUp") {
      e.preventDefault()
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1)
      }
      if (currentIndex === 0) {
        setCurrentIndex(filteredCountries.length - 1)
      }
      //search()
    } else if (e.code === "Enter") {
      e.preventDefault()
      const country = filteredCountries[currentIndex < 0 ? 0 : currentIndex]
      inputRef.current.value = country.name
      props.onChangeCountry(country)
      setFilteredCountries([])

      if (props.name === "aoi") {
        posthog.capture("AOI profile viewed through search bar")
      } else {
        posthog.capture("Country profile viewed through search bar")
      }

      return
    }
  }

  return (
    <>
      <CountryAutoComplete id="cid" onKeyDown={(e) => keyDown(e)}>
        <input
          ref={inputRef}
          value={searchQ}
          onInput={(e) => setSearchQ(e.target.value)}
          type="text"
          className="search_input"
          placeholder={
            props.name === "aoi"
              ? "Search areas of interest"
              : "Search countries"
          }
        />
        <IconPanel>
          <i className="pi pi-search search_icon"></i>
        </IconPanel>
      </CountryAutoComplete>

      {searchQ.length > 0 &&
        iData &&
        filteredCountries &&
        filteredCountries.length > 0 && (
          <ItemPanel id="results">
            <ul>
              {filteredCountries.map((item, index) =>
                itemTemplate(item, index),
              )}
            </ul>
          </ItemPanel>
        )}
    </>
  )
}

const CountryAutoComplete = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #949494 !important;
  border-radius: 4px !important;
  padding: 6px 14px !important;
`

const IconPanel = styled.div`
  display: flex;
  align-items: center;
  right: 0;
`

const ItemPanel = styled.div`
  z-index: 99;
  border: solid 1px rgba(102, 98, 98, 0.226);
  margin-top: 12px;
  background-color: white;
  padding: 1px;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 999999;
  > ul {
    list-style: none;
  }
`

const AoiItem = styled.div`
  min-width: 100%;
  display: flex;
  border-bottom: solid 1px rgba(102, 98, 98, 0.226);
  padding: 12px;
  justify-content: space-between;
  > i {
    color: #f75151;
  }
  :hover {
    background-color: rgba(102, 98, 98, 0.226);
  }
`
