import mapboxgl from "!mapbox-gl" // eslint-disable-line import/no-webpack-loader-syntax
import { FillStyleExtension } from "@deck.gl/extensions"
import { GeoJsonLayer } from "@deck.gl/layers"
import DeckGL from "@deck.gl/react"
import "mapbox-gl/dist/mapbox-gl.css"
import { useCallback, useRef, useState } from "react"
import { Map } from "react-map-gl"

const INITIAL_VIEW_STATE = {
  longitude: 131.41669,
  latitude: 32.7853,
  zoom: 3.8,
  pitch: 0,
  bearing: 0,
}

const VIEW_STATE = {
  longitude: 135.41669,
  latitude: 35.4853,
  zoom: 4,
}
const accessToken =
  "pk.eyJ1IjoiZGhhbmE2Nzk1IiwiYSI6ImNrcG1nMmw3bzA1d2gyb2tieXpyZTdyZGcifQ.sLxheO-RplAyuolYkBZNgQ"
mapboxgl.accessToken = accessToken

export default function MapContainer() {
  const deckRef = useRef(null)
  const deckRef1 = useRef(null)

  const mapRef = useRef(null)
  const [glContext, setGLContext] = useState()

  const onMapLoad = useCallback(() => {
    const map = mapRef.current.getMap()

    function getpulsingDot(color, size) {
      return {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
          const canvas = document.createElement("canvas")
          canvas.width = this.width
          canvas.height = this.height
          this.context = canvas.getContext("2d")
        },

        // Call once before every frame where the icon will be used.
        render: function () {
          const duration = 1000
          const t = (performance.now() % duration) / duration

          const radius = (size / 2) * 0.3
          const outerRadius = (size / 2) * 0.7 * t + radius
          const context = this.context

          // Draw the outer circle.
          context.clearRect(0, 0, this.width, this.height)
          context.beginPath()
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2,
          )
          const fillColor = color.replace("1)", `${1 - t})`)
          context.fillStyle = fillColor
          context.fill()

          // Draw the inner circle.
          context.beginPath()
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2)
          context.fillStyle = color
          context.strokeStyle = color
          context.lineWidth = 2 + 4 * (1 - t)
          context.fill()
          context.stroke()
          // Update this image's data with data from the canvas.
          this.data = context.getImageData(0, 0, this.width, this.height).data
          // Continuously repaint the map, resulting
          // in the smooth animation of the dot.
          map.triggerRepaint()
          // Return `true` to let the map know that the image was updated.
          return true
        },
      }
    }
    function addSource(name, coordinate) {
      map.addSource(name, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: coordinate, // icon position [lng, lat]
              },
            },
          ],
        },
      })
    }
    function addLayer(id, source, image) {
      map.addLayer({
        id: id,
        type: "symbol",
        source: source,
        layout: {
          "icon-image": image,
        },
      })
    }
    function addImageOfBlinkingDots(id, color, size) {
      map.addImage(id, getpulsingDot(color, size), { pixelRatio: 2 })
    }
    addImageOfBlinkingDots("pulsing-dot-blue", "rgba(88, 123, 245, 1)", 100) //blue
    addImageOfBlinkingDots("pulsing-dot-green", "rgba(71, 149, 119, 1)", 70) //green
    addImageOfBlinkingDots("pulsing-dot-dark-blue", "rgba(15, 37, 82, 1)", 150) //dark blue
    addImageOfBlinkingDots("pulsing-dot-red", "rgba(247, 81, 81, 1)", 100) //red

    addSource("dot-point-blue", [129, 35])
    addSource("dot-point-green", [130, 37])
    addSource("dot-point-dark-blue", [132, 40])
    addSource("dot-point-red", [130, 30])
    addSource("dot-point-another-red", [125, 36])

    addLayer(
      "layer-with-pulsing-dot-blue",
      "dot-point-blue",
      "pulsing-dot-blue",
    )
    addLayer(
      "layer-with-pulsing-dot-green",
      "dot-point-green",
      "pulsing-dot-green",
    )
    addLayer(
      "layer-with-pulsing-dot-dark-blue",
      "dot-point-dark-blue",
      "pulsing-dot-dark-blue",
    )
    addLayer("layer-with-pulsing-dot-red", "dot-point-red", "pulsing-dot-red")
    addLayer(
      "layer-with-pulsing-dot-another-red",
      "dot-point-another-red",
      "pulsing-dot-red",
    )
  })
  const layer = [
    new GeoJsonLayer({
      id: "background",
      data: "https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_scale_rank.geojson",
      stroked: true,
      filled: true,
      getFillColor: [35, 92, 124],
      fillPatternMask: true,
      fillPatternAtlas:
        "https://raw.githubusercontent.com/visgl/deck.gl/master/examples/layer-browser/data/pattern.png",
      fillPatternMapping:
        "https://raw.githubusercontent.com/visgl/deck.gl/master/examples/layer-browser/data/pattern.json",
      getFillPattern: (f, { index }) => "dots",
      getFillPatternScale: 500,
      getFillPatternOffset: [0, 0],
      extensions: [new FillStyleExtension({ pattern: true })],
    }),
  ]
  return (
    <div>
      <DeckGL
        ref={deckRef}
        initialViewState={INITIAL_VIEW_STATE}
        controller={false}
        layers={layer}
        glOptions={{
          stencil: true,
        }}
      ></DeckGL>
      <DeckGL
        ref={deckRef1}
        onWebGLInitialized={setGLContext}
        initialViewState={VIEW_STATE}
        controller={false}
        glOptions={{
          stencil: true,
        }}
      >
        {glContext && (
          /* This is important: Mapbox must be instantiated after the WebGLContext is available */
          <Map
            ref={mapRef}
            gl={glContext}
            controller={false}
            zoom={10}
            mapStyle="mapbox://styles/mapbox/empty-v8"
            mapboxApiAccessToken={accessToken}
            onLoad={onMapLoad}
          />
        )}
      </DeckGL>
    </div>
  )
}
