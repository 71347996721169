import { VESSEL_KEYS } from "./vessel"

export const SORT_DATA = [
  {
    key: VESSEL_KEYS.update_date,
    label: "Newest first",
    order: -1,
  },
  {
    key: VESSEL_KEYS.update_date,
    label: "Oldest first",
    order: 1,
  },
]
