import { Component } from "react"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import ErrorPage from "./ErrorPage"

export default class ErrorBoundary extends Component {
  state = {
    error: "",
    errorInfo: "",
    hasError: false,
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }
  componentDidCatch(error, errorInfo) {
    toast.error("Client Error", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    })
    console.log({ error, errorInfo })
    this.setState({ errorInfo })
  }
  render() {
    const { hasError, errorInfo } = this.state
    if (hasError) {
      return <ErrorPage />
    }
    return this.props.children
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}
