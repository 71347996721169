import { useEffect, useMemo, useRef } from "react"
import styled from "styled-components"
import { useLocation, useNavigate } from "react-router-dom"
import { OverlayPanel } from "primereact/overlaypanel"

import { device } from "theme/device"
import { useStore } from "state/store"
import ExpandIcon from "assets/images/icon-nav-arrow.svg"

export default function NavItemDesktop({ path, children, title }) {
  const location = useLocation()
  const navigate = useNavigate()

  const setCurrentUrl = useStore((state) => state.setCurrentUrl)
  const setNewUrl = useStore((state) => state.setNewUrl)

  const ref = useRef(null)

  const isActive = useMemo(() => {
    if (location.pathname === path) {
      return 1
    }
    if (
      location.pathname === "/vessel-profile" &&
      path === "/vessel-database"
    ) {
      return 1
    }
    return 0
  }, [location, path])

  useEffect(() => {
    if (
      location.pathname !== "/vessel-database" &&
      !location.pathname.includes("vessel-profile")
    ) {
      setCurrentUrl(location.pathname)
    } else {
      setCurrentUrl()
    }

    setNewUrl(location.pathname)
  }, [location.pathname])

  return (
    <NavItem
      active={isActive}
      onClick={(e) => {
        e.stopPropagation()

        if (path?.includes("https://")) {
          window.open(path, "_blank")
          return
        }

        navigate(path)
      }}
      onMouseEnter={(e) => {
        if (ref.current) {
          ref.current.show(e)
        }
      }}
      onMouseLeave={(e) => {
        if (ref.current) {
          ref.current.hide()
        }
      }}
    >
      <div>{title}</div>
      {children.length > 0 && (
        <>
          {/* <img src={ExpandIcon} alt="expand" /> */}
          <OverlayPanel
            ref={ref}
            showCloseIcon={false}
            dismissable
            className="p-nav-overlay"
          >
            <NavItemOverlay
              onMouseLeave={() => {
                if (ref.current) {
                  ref.current.hide()
                }
              }}
            >
              {children.map((child, index) => {
                return (
                  <SubNavItem
                    key={index}
                    active={location.pathname === child.path ? 1 : 0}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (child.path?.includes("https://")) {
                        window.open(child.path, "_blank")
                        return
                      }

                      navigate(child.path)

                      if (ref.current) {
                        ref.current.hide()
                      }
                    }}
                  >
                    {child.title}
                  </SubNavItem>
                )
              })}
            </NavItemOverlay>
          </OverlayPanel>
        </>
      )}
    </NavItem>
  )
}

const NavItem = styled.li`
  color: ${(props) => (props.active ? "#fff" : "#cccccc")};
  font-weight: 900;
  font-size: 1em;
  line-height: 1;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease;
  margin: 0 1.5em;
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 0.6em;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.7em;
    width: ${(props) => (props.active ? "2em" : "0")};
    height: 2px;
    background-color: ${(props) => props.theme.colors.primary};
    transition: width 0.5s ease;
  }
  &:hover::after {
    width: 3em;
  }
  @media ${device.laptop} {
    font-size: 1em;
  }
  > img {
    width: 0.8em;
  }
`

const NavItemOverlay = styled.div`
  margin-top: 1.5em;
  padding: 3em 1.5em;
  background-color: #ffffff;
  border-radius: 1.5em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 2em;
  min-width: 300px;
`

const SubNavItem = styled.div`
  position: relative;
  transition: all 0.5s ease;
  margin: 0 1.5em;
  list-style-type: none;
  display: flex;
  align-items: center;
  font-size: 1.2em;
  font-weight: 700;
  color: #1c4968;
  transition: all 0.2s ease-out;
  cursor: pointer;
  &:hover {
    color: #52befa;
  }
`
