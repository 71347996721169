import { Fragment } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"
import { ToastContainer } from "react-toastify"

import { PrivateRoutes, PublicRoutes } from "routes"
import ProtectedRoute from "routes/ProtectedRoute"
import LaunchNotesWidget from "components/LaunchNotesWidget"
import EulaChecker from "components/EulaChecker"
import ThemeProvider from "./theme/ThemeProvider"

const queryClient = new QueryClient()

export default function App() {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <EulaChecker />
          <LaunchNotesWidget />
          <ProtectedRoute>
            {PrivateRoutes.map((route, index) => (
              <Fragment key={index}>
                <Route path={route.path} element={route.component} />
                {route.children.map((child, i) => {
                  return (
                    <Route
                      key={`sub-path-${i}`}
                      path={child.path}
                      element={child.component}
                    />
                  )
                })}
              </Fragment>
            ))}
          </ProtectedRoute>
          <Routes>
            {PublicRoutes.map((route, index) => (
              <Fragment key={index}>
                <Route path={route.path} element={route.component} />
                {route.children.map((child, i) => {
                  return (
                    <Route
                      key={`sub-path-${i}`}
                      path={child.path}
                      element={child.component}
                    />
                  )
                })}
              </Fragment>
            ))}
          </Routes>
          <ToastContainer theme="colored" />
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  )
}
