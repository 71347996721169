import { memo } from "react"
import styled from "styled-components"
import { formatDateString } from "utils"

function UpdatedTime({ time = "06/12/2022 19:38 GMT" }) {
  return (
    <Holder>
      <Label1>Last Updated : </Label1>
      <Label2>{formatDateString(time)}</Label2>
    </Holder>
  )
}

export default memo(UpdatedTime)

const Holder = styled.div`
  display: flex;
  gap: 0.5em;
  min-width: max-content;
`

const Label1 = styled.span`
  font-size: 1em;
  font-weight: 400;
  color: black;
  opacity: 0.5;
`

const Label2 = styled.span`
  font-size: 1em;
  font-weight: 700;
  color: #122945;
`
