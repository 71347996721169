import React, { useEffect, useState, useRef, useMemo } from "react"
import styled from "styled-components"
import { useQuery } from "@tanstack/react-query"
import { PolygonLayer, IconLayer } from "@deck.gl/layers"
import { AnimatePresence } from "framer-motion"
import { usePostHog } from 'posthog-js/react'
import { debounce } from "lodash-es"

import { getCountries, getAOIs } from "api/interactivemap"
import { useStore } from "state/store"
import { centroid } from "utils"
import AOIHoverOverlay from "./components/AOI/AOIHoverOverlay"
import CountryHoverOverlay from "./components/CountryProfile/CountryHoverOverlay"
import CountryImagePreview from "./components/CountryProfile/CountryImagePreview"
import AoiImagePreview from "./components/AOI/AoiImagePreview"
import MapSidebarPanel from "./MapSidebarPanel"
import CountryPanel from "./MapSidebarPanel/CountryPanel"
import CustomMap from "./components/CustomMap"
import "./components/CustomMap/index.css"
import Loader from "components/Loader"

export default function InterActiveMap() {
  const posthog = usePostHog()
  const applyFilter = useStore((state) => state.applyFilter)
  const selectedFilters = useStore((state) => state.selectedFilters)

  const { data: gcountries, isLoading: countryLoading } = useQuery(
    ["getCountries", 0, 100],
    () => getCountries(),
    {
      retry: 100,
      retryDelay: 1000,
    },
  )
  const { data: gAois, isLoading: aoiLoading } = useQuery(
    ["getAOIs", 0, 100],
    () => getAOIs(0, 1000),
  )

  const changePosition = (coords) => {
    if (tabIndex === 0) {
      customMapRef.current.changePositionAOI(coords)
    } else {
      customMapRef.current.changePositionCountry(coords)
    }
  }

  const [tabIndex, setTabIndex] = useState(0)
  const [sidebarData, setSidebarData] = useState(false)
  const [hoverInfo, setHoverInfo] = useState(false)
  const [pos, setPos] = useState([0, 0])
  const [aoiFilter, setAoiFilter] = useState({
    port: true,
    shipyard: true,
    hotspot: true,
  })
  const [previewOpen, setpreviewOpen] = useState(false)
  const [SidebarOpen, setsidebarOpen] = useState(true)
  const [filterHoverCards, setFilterHoverCards] = useState([])

  const colorAoi = (item) => {
    switch (item.aoi_type) {
      case 1:
        return aoiFilter["port"] ? [160, 185, 208] : [0, 0, 0, 1]
      case 2:
        return aoiFilter["hotspot"] ? [247, 81, 81] : [0, 0, 0, 1]
      case 3:
        return aoiFilter["shipyard"] ? [59, 188, 151] : [0, 0, 0, 1]
      default:
        return [0, 119, 255]
    }
  }

  const setOpenSidebar = () => {
    if (hoverInfo) {
      setSidebarData(hoverInfo)
    }
    setsidebarOpen(true)
    setHoverInfo(false)
    setPos(false)
  }

  const openFilterFunc = () => {
    setsidebarOpen(false)
    setHoverInfo("")
  }

  const setDataCountryHover = (gdata) => {
    const data = gdata.data
    setPos(gdata.pos)
    setHoverInfo(data)
  }

  const customMapRef = useRef()

  const openCountrySidebarAndChangePosition = (data) => {
    changePosition(data)
    setTimeout(async () => {
      setSidebarData(data)
      setsidebarOpen(true)
      setHoverInfo(false)
      setPos(false)
    }, 800)
  }

  useEffect(() => {
    setpreviewOpen(false)
  }, [setpreviewOpen])

  const filteredCountries = useMemo(() => {
    if (tabIndex === 0 && selectedFilters.length && gcountries) {
      const tempId = selectedFilters.map((selected) => selected.id)
      return gcountries.filter((country) => tempId.includes(country.id))
    }
    if (tabIndex === 0 && !selectedFilters.length) {
      return []
    }
    return gcountries || []
  }, [selectedFilters, tabIndex, gcountries])

  const mapData = useMemo(() => {
    let results = {
      polygon: {
        total: [],
        childs: [],
        parents: [],
      },
      points: [],
    }

    if (!gAois) {
      return results
    }

    let d = []
    if (applyFilter) {
      const filteredAOIs = selectedFilters.filter((item) => item.value === true)
      const filters = (value) =>
        filteredAOIs.some((element) => value.id === element.id)
      d = gAois.filter(filters)
    } else {
      d = gAois
    }

    d.map((aoi) => {
      if (aoi.aoi_polygon !== null) {
        aoi.contour = aoi.aoi_polygon.coordinates
        results.polygon.total.push(aoi)

        if (aoi.aoi_class_verbose === "CHILD") {
          results.polygon.childs.push(aoi)
        } else {
          results.polygon.parents.push(aoi)
        }
      } else {
        results.points.push(aoi)
      }
      return null
    })

    return results
  }, [applyFilter, gAois, selectedFilters])

  const isLoading = countryLoading || aoiLoading

  if (isLoading) {
    return <Loader visible={isLoading} size={50} />
  }

  return (
    <Holder className="interactive-map-holder">
      <Title>DPRK Maritime Interactive Map</Title>

      {previewOpen && tabIndex === 0 && (
        <CountryImagePreview
          data={sidebarData}
          images={previewOpen}
          closePreview={(d) => setpreviewOpen(false)}
        />
      )}

      {previewOpen && tabIndex === 1 && (
        <AoiImagePreview
          data={sidebarData}
          images={previewOpen}
          closePreview={(d) => setpreviewOpen(false)}
        />
      )}

      <>
        <CustomMap
          ref={customMapRef}
          hoverInfo={hoverInfo}
          updateHover={() => {}}
          setCountryHover={(d) => {
            setDataCountryHover(d)
          }}
          filterHoverCards={filterHoverCards}
          latitude={pos !== undefined ? pos[0] : false}
          longitude={pos !== undefined ? pos[1] : false}
          countries={filteredCountries ?? []}
          setFilterHoverCards={setFilterHoverCards}
          aoifilters={aoiFilter}
          layers={
            tabIndex === 1
              ? []
              : gAois.length > 0
              ? [
                  new PolygonLayer({
                    id: "area-of-interest-layer_" + new Date().getTime(),
                    order: 2,
                    data: mapData.polygon.parents,
                    onClick: (e) => {
                      setHoverInfo(false)
                    },

                    onHover: debounce(async (info, e) => {
                      if (!e.srcEvent.originalEvent.relatedTarget) {
                        setHoverInfo(false)
                      }

                      if (info.object) {
                        const cn = info.object.id
                        const oldCN = hoverInfo?.object?.id
                        if (cn !== oldCN) {
                          const res = false // await getAoiThumbsById(cn)
                          info.object.images = []

                          info.object.thumbs = res ? res.results : []

                          setPos(centroid(info.object.contour[0]))
                          setHoverInfo(info.object)
                        }
                      }
                    }, 50),

                    opacity: 0.3,
                    pickable: true,
                    stroked: true,
                    filled: true,
                    wireframe: true,
                    lineWidthMinPixels: 1,
                    getPolygon: (d) => d.contour,
                    getFillColor: (d) => colorAoi(d),
                    getLineColor: (d) => colorAoi(d),
                    getLineWidth: 4,
                  }),
                  new PolygonLayer({
                    id: "area-of-interest-layer-childs_" + new Date().getTime(),
                    order: 2,
                    data: mapData.polygon.childs,
                    depthTest: false,
                    onClick: (e) => {
                      setHoverInfo(false)
                    },

                    onHover: debounce(async (info, e) => {
                      if (!e.srcEvent.originalEvent.relatedTarget) {
                        setHoverInfo(false)
                      }

                      if (info.object) {
                        const cn = info.object.id
                        const oldCN = hoverInfo?.object?.id
                        if (cn !== oldCN) {
                          const res = false // await getAoiThumbsById(cn)
                          info.object.images = []

                          info.object.thumbs = res ? res.results : []

                          setPos(centroid(info.object.contour[0]))
                          setHoverInfo(info.object)
                        }
                      }
                    }, 500),

                    opacity: 0.4,
                    pickable: true,
                    stroked: true,
                    filled: true,
                    wireframe: true,
                    lineWidthMinPixels: 1,
                    getPolygon: (d) => d.contour,
                    getFillColor: (d) => colorAoi(d),
                    getLineColor: (d) => colorAoi(d),
                    getLineWidth: 4,
                  }),
                  new IconLayer({
                    order: 1,
                    id: "icon-layer_" + new Date().getTime(),
                    data: mapData.points,
                    pickable: true,
                    onClick: (e) => {
                      setHoverInfo(false)
                    },
                    onHover: async (info, e) => {
                      if (info.object) {
                        const cn = info.object.id
                        const oldCN = hoverInfo?.object?.id
                        if (cn !== oldCN) {
                          const im = false // await getAoiThumbsById(cn)
                          info.object.images = []
                          info.object.thumbs = im ? im.results : []

                          setPos(info.object.aoi_point.coordinates)
                          setHoverInfo(info.object)
                        }
                      }
                    },
                    iconAtlas:
                      "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png",
                    iconMapping: {
                      marker: {
                        x: 0,
                        y: 0,
                        width: 128,
                        height: 128,
                        mask: true,
                      },
                    },
                    getIcon: (d) => "marker",

                    sizeScale: 5,
                    getPosition: (d) => d?.aoi_point?.coordinates,
                    getSize: (d) => 5,
                    getColor: (d) => colorAoi(d),
                  }),
                ]
              : []
          }
          currentTab={tabIndex}
          openSidebar={(d) => {
            setOpenSidebar()
            setHoverInfo(false)
            setFilterHoverCards([])
          }}
          overlay={
            hoverInfo &&
            (tabIndex === 1 ? (
              <CountryHoverOverlay
                data={hoverInfo}
                click={() => {
                  setOpenSidebar()
                  setHoverInfo(false)
                  posthog.capture("Country profile viewed through map")
                }}
                labels={hoverInfo.alias_names}
              />
            ) : (
              <AOIHoverOverlay
                hideMe={() => setHoverInfo(false)}
                click={() => {
                  setOpenSidebar()
                  setHoverInfo(false)
                  posthog.capture("AOI profile viewed through map")
                }}
                data={hoverInfo}
              />
            ))
          }
        />
        <AnimatePresence>
          {tabIndex === 1 && gcountries?.length && (
            <CountryPanel
              data={sidebarData}
              openPreview={(id) => setpreviewOpen(id)}
              setTabIndex={setTabIndex}
              tabIndex={tabIndex}
              setSidebarData={setSidebarData}
              SidebarOpen={SidebarOpen}
              setFilterHoverCards={setFilterHoverCards}
              changePosition={changePosition}
              openCountrySidebarAndChangePosition={
                openCountrySidebarAndChangePosition
              }
              info={sidebarData}
              changePositionFilter={(data) => {
                if (data.aoi_polygon !== null) {
                  var coords = ""
                  coords = data.aoi_polygon.coordinates[0][0]
                } else {
                  coords = data.aoi_point.coordinates
                }
                setPos(coords)
                changePosition(coords)
                data.coords = coords
                setFilterHoverCards([data])
              }}
              changeSetFilter={(d) => setAoiFilter(d)}
              countries={gcountries}
              aois={gAois}
              changePositionItem={(data) => {
                if (data.aoi_polygon !== null) {
                  var coords = ""
                  coords = data.aoi_polygon.coordinates[0][0]
                } else {
                  coords = data.aoi_point.coordinates
                }
                setPos(coords)
                changePosition(coords)
                data.coords = coords
                setFilterHoverCards([data])
              }}
              changeMapPosition={(data) => {
                setsidebarOpen(false)
                openCountrySidebarAndChangePosition(data)
              }}
              setsidebarOpen={setsidebarOpen}
              setHoverInfo={setHoverInfo}
              closeSidebar={() => {
                setsidebarOpen(false)
                setHoverInfo("")
              }}
              openFilter={() => openFilterFunc()}
            />
          )}
          {tabIndex === 0 && gAois?.length && (
            <MapSidebarPanel
              info={sidebarData}
              openPreview={(id) => setpreviewOpen(id)}
              SidebarOpen={SidebarOpen}
              setFilterHoverCards={setFilterHoverCards}
              setTabIndex={setTabIndex}
              tabIndex={tabIndex}
              setHoverInfo={setHoverInfo}
              setSidebarData={setSidebarData}
              changePosition={changePosition}
              setsidebarOpen={setsidebarOpen}
              openCountrySidebarAndChangePosition={
                openCountrySidebarAndChangePosition
              }
              changePositionFilter={(data) => {
                if (data.aoi_polygon !== null) {
                  var coords = ""
                  coords = data.aoi_polygon.coordinates[0][0]
                } else {
                  coords = data.aoi_point.coordinates
                }
                setPos(coords)
                changePosition(coords)
                data.coords = coords
                setFilterHoverCards([data])
              }}
              changeSetFilter={(d) => setAoiFilter(d)}
              countries={gcountries}
              aois={gAois}
              changePositionItem={(data) => {
                if (data.aoi_polygon !== null) {
                  var coords = ""
                  coords = data.aoi_polygon.coordinates[0][0]
                } else {
                  coords = data.aoi_point.coordinates
                }
                setPos(coords)
                changePosition(coords)
                data.coords = coords
                setFilterHoverCards([data])
              }}
            />
          )}
        </AnimatePresence>
      </>
    </Holder>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Title = styled.div`
  position: absolute;
  top: 4.7em;
  right: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(25px);
  padding: 0.7em 1.2em;
  border-radius: 0px 0px 0px 8px;
  font-weight: 600;
`
