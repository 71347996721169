import { memo } from "react"
import styled from "styled-components"

function ImageTag({ active = true, activeImage, inActiveImage }) {
  return (
    <Holder>
      <img src={active ? activeImage : inActiveImage} alt="" />
    </Holder>
  )
}

export default memo(ImageTag)

const Holder = styled.div`
  min-width: 2.5em;
  width: 2.5em;
  min-height: 2.5em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`
