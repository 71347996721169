import create from "zustand"
import { IMAGE_KEYS } from "dataset/image"
import { VESSEL_KEYS } from "dataset/vessel"
import { SORT_DATA } from "dataset/voi"

export const useStore = create((set, get) => ({
  //
  // Global
  //
  /* vessels */
  filterItemAmount: 0,
  setFilterItemAmount: (v) => set({ filterItemAmount: v }),
  totalVessels: [],
  setTotalVessels: (v) => set({ totalVessels: v }),
  filteredVessels: [],
  setFilteredVessels: (v) => set({ filteredVessels: v }),
  savedVessels: [],
  setSavedVessels: (v) => set({ savedVessels: v }),
  filteredVesselsSaved: [],
  userStub: null,
  setUserStub: (v) => set({ userStub: v }),
  acceptedEula: true,
  setAcceptedEula: (v) => set({ acceptedEula: v }),
  setFilteredVesselsSaved: (v) => set({ filteredVesselsSaved: v }),
  toggleSavedVessels: (v) =>
    set((state) => ({
      filteredVesselsSaved: state.filteredVesselsSaved.some(
        (e) => e[VESSEL_KEYS.id] === v[VESSEL_KEYS.id],
      )
        ? state.filteredVesselsSaved.filter(
            (c) => v[VESSEL_KEYS.id] !== c[VESSEL_KEYS.id],
          )
        : [...state.filteredVesselsSaved, v],
      savedVessels: state.savedVessels.some(
        (e) => e[VESSEL_KEYS.id] === v[VESSEL_KEYS.id],
      )
        ? state.savedVessels.filter(
            (c) => v[VESSEL_KEYS.id] !== c[VESSEL_KEYS.id],
          )
        : [...state.savedVessels, v],
    })),
  /* images */
  totalImages: [],
  setTotalImages: (v) => set({ totalImages: v }),
  currentImageStatus: false,
  setCurrentImageStatus: (v) => set({ currentImageStatus: v }),
  filteredImages: [],
  setFilteredImages: (v) => set({ filteredImages: v }),
  timelinedImages: [],
  setTimelinedImages: (v) => set({ timelinedImages: v }),
  rectedImages: [],
  setRectedImages: (v) => set({ rectedImages: v }),
  savedImages: [],
  setSavedImages: (v) => set({ savedImages: v }),
  toggleSavedImages: (v) =>
    set((state) => ({
      filteredImagesSaved: state.filteredImagesSaved.some(
        (e) => e[IMAGE_KEYS.id] === v[IMAGE_KEYS.id],
      )
        ? state.filteredImagesSaved.filter(
            (c) => v[IMAGE_KEYS.id] !== c[IMAGE_KEYS.id],
          )
        : [...state.filteredImagesSaved, v],
      savedImages: state.savedImages.some(
        (e) => e[IMAGE_KEYS.id] === v[IMAGE_KEYS.id],
      )
        ? state.savedImages.filter((c) => v[IMAGE_KEYS.id] !== c[IMAGE_KEYS.id])
        : [...state.savedImages, v],
    })),
  filteredImagesSaved: [],
  setFilteredImagesSaved: (v) => set({ filteredImagesSaved: v }),
  currentImage: null,
  setCurrentImage: (v) => set({ currentImage: v }),
  currentUrl: null,
  setCurrentUrl: (v) => set({ currentUrl: v }),
  newUrl: null,
  setNewUrl: (v) => set({ newUrl: v }),

  imageCollection: [],
  setImageCollection: (v) => set({ imageCollection: v }),
  //
  // DataTable
  //
  dataTablePagination: false,
  setDataTablePagination: (v) => set({ dataTablePagination: v }),
  //
  // Menu
  //
  menuVisible: false,
  setMenuVisible: (v) => set({ menuVisible: v }),
  //
  // Footer
  //
  footerVisible: true,
  setFooterVisible: (v) => set({ footerVisible: v }),
  //
  // AOI Filter
  //
  filterTotal: 2,
  setfilterTotal: (v) => set({ filterTotal: v }),
  //
  // VOI
  //
  isSavedVessels: false,
  setIsSavedVessels: (v) => set({ isSavedVessels: v }),
  voiLayout: "grid",
  setVOILayout: (v) => set({ voiLayout: v }),
  voiSortOption: SORT_DATA[1],
  setVOISortOption: (v) => set({ voiSortOption: v }),
  filterDialogVisible: false,
  setFilterDialogVisible: (v) => {
    document.body.style.overflow = v ? "hidden" : "auto"
    set({ filterDialogVisible: v })
  },
  activeVesselTab: "TOTAL",
  setActiveVesselTab: (v) => set({ activeVesselTab: v }),
  generalFeedbackDialogVisible: false,
  setGeneralFeedbackDialogVisible: (v) => {
    document.body.style.overflow = v ? "hidden" : "auto"
    set({ generalFeedbackDialogVisible: v })
  },
  //
  // Vessel Profile
  //
  currentSectionInView: "what_to_know",
  setCurrentSectionInView: (v) => set({ currentSectionInView: v }),
  profileReportDialogVisible: false,
  setProfileReportDialogVisible: (v) => {
    document.body.style.overflow = v ? "hidden" : "auto"
    set({ profileReportDialogVisible: v })
  },
  profileGalleryDialogVisible: false,
  setProfileGalleryDialogVisible: (v) => {
    document.body.style.overflow = v ? "hidden" : "auto"
    set({ profileGalleryDialogVisible: v })
  },
  profileMetaDialogVisible: false,
  setProfileMetaDialogVisible: (v) => set({ profileMetaDialogVisible: v }),
  //
  // Imagery Gallery
  //
  isSavedImages: false,
  setIsSavedImages: (v) => set({ isSavedImages: v }),
  filterOverlayEvent: null,
  setFilterOverlayEvent: (v) => set({ filterOverlayEvent: v }),
  isExpandedSidebar: false,
  setIsExpandedSidebar: (v) => set({ isExpandedSidebar: v }),
  isExpandedTimeline: true,
  setIsExpandedTimeline: (v) => set({ isExpandedTimeline: v }),
  isDrawMode: false,
  setIsDrawMode: (v) => set({ isDrawMode: v }),
  timelineData: [],
  setTimelineData: (v) => set({ timelineData: v }),
  startDate: new Date(1990, 0, 1),
  setStartDate: (v) => set({ startDate: v }),
  endDate: new Date(1990, 0, 1),
  setEndDate: (v) => set({ endDate: v }),
  filterStartDate: new Date(1990, 0, 1),
  setFilterStartDate: (v) => set({ filterStartDate: v }),
  filterEndDate: new Date(1990, 0, 1),
  setFilterEndDate: (v) => set({ filterEndDate: v }),
  timelineStartDate: new Date(1990, 0, 1),
  setTimelineStartDate: (v) => set({ timelineStartDate: v }),
  timelineEndDate: new Date(1990, 0, 1),
  setTimelineEndDate: (v) => set({ timelineEndDate: v }),
  selectedLocations: [],
  setSelectedLocations: (v) => set({ selectedLocations: v }),
  selectedSensors: [],
  setSelectedSensors: (v) => set({ selectedSensors: v }),
  selectedSources: [],
  setSelectedSources: (v) => set({ selectedSources: v }),
  sidebarWidth: 0,
  setSidebarWidth: (v) => set({ sidebarWidth: v }),
  filterBarHeight: 0,
  setFilterBarHeight: (v) => set({ filterBarHeight: v }),
  //
  // Oil Shipment Tracker
  //
  chartFilterDialogVisible: false,
  setChartFilterDialogVisible: (v) => set({ chartFilterDialogVisible: v }),
  chartSettings: {
    yAxis: "VOLUME",
    xAxis: "YEAR",
    volumeRange: 30,
  },
  setChartSettings: (v) => set({ chartSettings: v }),
  /* Shipment datatable filter params */
  shipmentsFilter: {},
  setShipmentsFilter: (v) => set({ shipmentsFilter: v }),
  autoCompleteOptions: {},
  setAutoCompleteOptions: (v) => set({ autoCompleteOptions: v }),
  //
  // interactive map
  //
  selectedFilters: [],
  setSelectedFilters: (v) => set({ selectedFilters: v }),
  applyFilter: false,
  setApplyFilter: (v) => set({ applyFilter: v }),
  //
  // User profile
  //
  currentUser: null,
  setCurrentUser: (v) => set({ currentUser: v }),
  currentScrollPosition: null,
  setCurrentScrollPosition: (v) => set({ currentScrollPosition: v }),
  addUserDialogVisible: false,
  setAddUserDialogVisible: (v) => set({ addUserDialogVisible: v }),
  //
  // RRP
  //
  rrpPermissionDialogVisible: false,
  setRrpPermissionDialogVisible: (v) => set({ rrpPermissionDialogVisible: v }),
  rrpUploadDialogVisible: false,
  setRrpUploadDialogVisible: (v) => set({ rrpUploadDialogVisible: v }),
  rrpDialogData: null,
  setRrpDialogData: (v) => set({ rrpDialogData: v }),
  needGetRrp: false,
  setNeedGetRrp: (v) => set({ needGetRrp: v }),
  rrpRemoveProductDialogVisible: false,
  setRrpRemoveProductDialogVisible: (v) =>
    set({ rrpRemoveProductDialogVisible: v }),
  rrpEditProductDialogVisible: false,
  setRrpEditProductDialogVisible: (v) =>
    set({ rrpEditProductDialogVisible: v }),
  selectedRrpProductData: null,
  setSelectedRrpProductData: (v) => set({ selectedRrpProductData: v }),

  // Reset
  resetStore: () => {
    set(() => ({
      filterItemAmount: 0,
      totalVessels: [],
      filteredVessels: [],
      savedVessels: [],
      filteredVesselsSaved: [],
      totalImages: [],
      filteredImages: [],
      timelinedImages: [],
      rectedImages: [],
      savedImages: [],
      filteredImagesSaved: [],
      currentImage: null,
      imageCollection: [],
      menuVisible: false,
      footerVisible: true,
      filterTotal: 2,
      isSavedVessels: false,
      voiLayout: "grid",
      voiSortOption: SORT_DATA[1],
      filterDialogVisible: false,
      activeVesselTab: "TOTAL",
      generalFeedbackDialogVisible: false,
      profileReportDialogVisible: false,
      profileGalleryDialogVisible: false,
      profileMetaDialogVisible: false,
      isSavedImages: false,
      filterOverlayEvent: null,
      isExpandedSidebar: false,
      isExpandedTimeline: true,
      isDrawMode: false,
      timelineData: [],
      startDate: new Date(1990, 0, 1),
      endDate: new Date(1990, 0, 1),
      filterStartDate: new Date(1990, 0, 1),
      filterEndDate: new Date(1990, 0, 1),
      timelineStartDate: new Date(1990, 0, 1),
      timelineEndDate: new Date(1990, 0, 1),
      selectedLocations: [],
      selectedSensors: [],
      selectedSources: [],
      chartFilterDialogVisible: false,
      chartSettings: {
        yAxis: "VOLUME",
        xAxis: "YEAR",
        volumeRange: 30,
      },
      shipmentsFilter: {},
      autoCompleteOptions: {},
      tabIndex: 0,
      selectedFilters: [],
      applyFilter: false,
      currentUser: null,
      rrpPermissionDialogVisible: false,
      rrpDialogData: null,
    }))
  },
}))
