import {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react"
import styled from "styled-components"

import { MapboxOverlay } from "@deck.gl/mapbox/typed"
import MapGL, { useControl, Popup, Layer, Source, Marker } from "react-map-gl"
import { AnimatePresence } from "framer-motion"

import { Countries, BoundingBox, countries_coords } from "dataset/countries"
import MapTool from "../MapToolsOverlayPanel"
import MapLegend from "../MapLegend/MapLegend"
import { debounce } from "lodash-es"
import { useStore } from "state/store"
import AOIHoverOverlay from "../AOI/AOIHoverOverlay"

const ZOOM_STEP = 0.3

function DeckGLOverlay(props) {
  const overlay = useControl(() => new MapboxOverlay(props))
  overlay.setProps(props)
  return null
}

const CustomMap = forwardRef((props, ref) => {
  const applyFilter = useStore((state) => state.applyFilter)

  const { filterHoverCards, setFilterHoverCards } = props
  useImperativeHandle(ref, () => ({
    changePositionAOI(data) {
      mapRef.current.flyTo({
        center: data,
        zoom: 9,
        speed: 2.8,
        curve: 1,
        easing(t) {
          return t
        },
      })
    },

    changePositionCountry(country) {
      const getCountry = Countries.filter((item) => {
        return (
          item["alpha-3"] ===
          (country.iso_name === "BDG" ? "BGD" : country.iso_name)
        )
      })[0]
      const getBoundingBox = BoundingBox[getCountry["alpha-2"]]
      mapRef.current.fitBounds(getBoundingBox[1])
    },
  }))

  const [hoverData, setHoverData] = useState("")
  const [hoverLocation, setHoverLocation] = useState({
    lat: false,
    lng: false,
  })

  const [countryThumbs] = useState("")

  const [aoiLayer, setAoiLayer] = useState([])

  useEffect(() => {
    if (props.hoverInfo) {
      if (props.hoverInfo.id !== hoverData.id) {
        setFilterHoverCards([])
        let d = props.hoverInfo
        setHoverData(d)

        props.updateHover(d)

        if (props.latitude && props.longitude) {
          setHoverLocation({
            lat: props.latitude,
            lng: props.longitude,
          })
        }
      }
    }
  }, [
    countryThumbs,
    hoverData.id,
    props,
    props.hoverInfo,
    props.latitude,
    props.longitude,
  ])

  useEffect(() => {
    if (mapRef.current) {
      if (props.currentTab === 0) {
        setAoiLayer(props.layers)
      }
    }
  }, [props.currentTab, props.layers, aoiLayer, applyFilter])

  useEffect(() => {
    if (mapRef.current) {
      setAoiLayer([])
    }
  }, [applyFilter])

  const [initialViewState] = useState({
    latitude: 39.0392,
    longitude: 110.7625,
    zoom: 2,
    bearing: 0,
    pitch: 0,
  })

  const [isSatellite, setIsSatellite] = useState(false)

  const mapRef = useRef()

  const zoomIn = () => {
    if (mapRef.current !== null) {
      mapRef.current.setZoom(mapRef.current.getZoom() + ZOOM_STEP)
    }
  }
  const zoomOut = () => {
    if (mapRef.current !== null) {
      mapRef.current.setZoom(mapRef.current.getZoom() - ZOOM_STEP)
    }
  }

  const countryLayer = {
    id: "country-boundaries",
    type: "fill",
    source: {
      type: "vector",
      url: "mapbox://mapbox.country-boundaries-v1",
    },
    "source-layer": "country_boundaries",
    paint: {
      "fill-color": "#F75151",
      "fill-opacity": 0.1,
      "fill-outline-color": "#F75151",
    },
    filter: [
      "all",
      ["in", "iso_3166_1_alpha_3"].concat(
        props.countries.map((item) => {
          if (item.iso_name === "BDG") {
            return "BGD"
          }
          return item.iso_name
        }),
      ),
    ],
  }

  const countryBoundMouseLeave = (e) => {
    if (!e.originalEvent.relatedTarget) {
      props.setCountryHover(false)
    }
  }

  const handleMouseMove = (e) => {
    debounce(function (e) {}, 300)
  }

  const handleMouseClick = (e) => {
    setFilterHoverCards([])
    setHoverData(false)
  }

  const countryBoundMouseMove = (e) => {
    const countryCode = e?.features[0]?.properties?.iso_3166_1_alpha_3
    const countryCodeOne = e?.features[0]?.properties.iso_3166_1
    const getCoord = countries_coords.filter((item) => {
      return item.short_name === countryCodeOne
    })[0]
    if (countryCode && getCoord) {
      const currentCountry = props.countries.filter((item) => {
        const lg = countryCode === "BGD" ? "BDG" : countryCode
        return item.iso_name === lg
      })[0]
      const hd = {
        data: currentCountry,
        pos: [getCoord.center_lng, getCoord.center_lat],
      }
      props.setCountryHover(hd)
    }
  }

  const hover = () => {
    if (mapRef.current !== null) {
      mapRef.current.setZoom(mapRef.current.getZoom())

      mapRef.current.on(
        "mouseleave",
        "country-boundaries",
        countryBoundMouseLeave,
      )
      mapRef.current.on("mousemove", handleMouseMove)

      mapRef.current.on("click", handleMouseClick)
      mapRef.current.on(
        "mousemove",
        "country-boundaries",
        countryBoundMouseMove,
      )
    }
  }

  const removeHover = () => {
    if (mapRef.current !== null) {
      mapRef.current.off(
        "mouseleave",
        "country-boundaries",
        countryBoundMouseLeave,
      )
      mapRef.current.off("mousemove", handleMouseMove)
      mapRef.current.off("click", handleMouseClick)
      mapRef.current.off(
        "mousemove",
        "country-boundaries",
        countryBoundMouseMove,
      )
    }
  }

  useEffect(() => {
    hover()

    return () => {
      removeHover()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current, props.countries])

  return (
    <>
      <MapHolder>
        <MapGL
          onClick={(e) => {
            props.updateHover(false)
          }}
          ref={mapRef}
          mapStyle={
            isSatellite
              ? "mapbox://styles/mapbox/satellite-v8"
              : "mapbox://styles/c4adsdata/ckqva1vdr0qdd17o6rhx5dsn0"
          }
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          initialViewState={initialViewState}
        >
          {props.currentTab === 1 ? (
            <Source
              id="country-boundaries"
              type="vector"
              url="mapbox://mapbox.country-boundaries-v1"
            >
              <Layer {...countryLayer} />
            </Source>
          ) : aoiLayer ? (
            <DeckGLOverlay layers={aoiLayer} />
          ) : null}

          {hoverData && props.hoverInfo && (
            <Popup
              longitude={hoverLocation.lat}
              latitude={hoverLocation.lng}
              offset={[0, -10]}
              closeButton={false}
              anchor="bottom"
              closeOnMove={true}
              onClose={(e) => props.setCountryHover(false)}
            >
              {props.overlay}
            </Popup>
          )}
          {filterHoverCards &&
            filterHoverCards.map((hc, i) => {
              return (
                <Marker
                  longitude={hc.coords[0]}
                  latitude={hc.coords[1]}
                  offset={[0, -10]}
                  closeButton={false}
                  anchor="bottom"
                  key={i}
                  closeOnMove={true}
                  onClose={(e) => props.setCountryHover(false)}
                  pitchAlignment={"viewport"}
                  style={{
                    zIndex: 999999999999,
                    background: "#122a47",
                    borderRadius: "8px",
                  }}
                >
                  <MapPopUp>
                    <AOIHoverOverlay
                      hideMe={() => {}}
                      click={() => props.openSidebar(hc)}
                      data={hc}
                    />
                  </MapPopUp>
                </Marker>
              )
            })}
        </MapGL>
      </MapHolder>
      <MapTool
        zoomOut={() => zoomOut()}
        zoomIn={() => zoomIn()}
        isSatellite={isSatellite}
        changeMode={() => setIsSatellite(!isSatellite)}
      >
        {props.currentTab === 0 && <MapLegend />}
      </MapTool>
      <AnimatePresence>{props.sidebarOpen && props.sideBar}</AnimatePresence>
    </>
  )
})

const MapHolder = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
`

const MapPopUp = styled.div`
  background-color: #122a47;
  border-radius: 8px;
  z-index: 9999999999;
  ::after {
    content: "";
    height: 20px;
    width: 20px;
    background-color: #122a47;
    position: absolute;
    bottom: -8px;
    left: 50%;
    z-index: -1;
    transform: translate(-50%) rotate(-0.12turn);
  }
`

export default CustomMap

//custom map
