/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, useRef } from "react"
import styled from "styled-components"
import { useQuery, useMutation } from "@tanstack/react-query"

import { useStore } from "state/store"
import { getVesselsRequest } from "api/vessels"
import { allImagesRequest } from "api/images"
import { getSavedVesselOrImage } from "api/auth"
import { MiddleContainer } from "components/Containers"
import { H1, H2 } from "components/Labels"
import PageTransition from "components/PageTransition"
import PageHead from "./components/PageHead"
import AggregateStatistics from "./components/AggregateStatistics"
import SearchInput from "./components/SearchInput"
import VOIDataView from "./components/VOIDataView"
import SavedSelectButton from "./components/SavedSelectButton"
import FilterButton from "./components/FilterButton"
import SortButton from "./components/SortButton"
import ViewModeButton from "./components/ViewModeButton"
import FilterDialog from "./components/FilterDialog"

export default function VesselDatabase() {
  const isSavedVessels = useStore((state) => state.isSavedVessels)
  const setSavedVessels = useStore((state) => state.setSavedVessels)
  const filteredVesselsSaved = useStore((state) => state.filteredVesselsSaved)
  const setFilteredVesselsSaved = useStore(
    (state) => state.setFilteredVesselsSaved,
  )
  const filteredVessels = useStore((state) => state.filteredVessels)
  const setTotalVessels = useStore((state) => state.setTotalVessels)
  const setTotalImages = useStore((state) => state.setTotalImages)
  const currentUser = useStore((state) => state.currentUser)
  const currentUrl = useStore((state) => state.currentUrl)
  const setCurrentImageStatus = useStore((state) => state.setCurrentImageStatus)
  const currentImageStatus = useStore((state) => state.currentImageStatus)

  const filterDialogRef = useRef(null)

  const [searchValue, setSearchValue] = useState("")
  const [selectedVessels, setSelectedVessels] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [searchedVessels, setSearchedVessels] = useState([])
  const [customVesselLoading, setCustomVesselLoading] = useState(true)

  const allVessels = useMemo(() => {
    return isSavedVessels ? filteredVesselsSaved : filteredVessels
  }, [isSavedVessels, filteredVessels, filteredVesselsSaved])

  const { data: iData, isLoading: imageLoading } = useQuery(
    ["allImagesRequest"],
    () => allImagesRequest(),
  )
  const { data: vData, isLoading: vesselLoading } = useQuery(
    ["getVesselsRequest"],
    () => getVesselsRequest(),
  )

  useEffect(() => {
    if (imageLoading === false) {
      setCurrentImageStatus(true)
      setTotalImages(iData)
      setCurrentImageStatus(true)
    }
  }, [currentImageStatus])

  const { mutate: getSavedVesselOrImageMutate } = useMutation(
    getSavedVesselOrImage,
    {
      onSuccess: ({ status, data }) => {
        if (status === 200) {
          const savedIds = data?.saved_vessels || []
          const saved = vData.filter(({ id }) => {
            return savedIds.includes(id)
          })
          setSavedVessels(saved)
          setFilteredVesselsSaved(saved)
        } else {
          setSavedVessels([])
        }
      },
    },
  )

  //filtered data
  useEffect(() => {
    let result = isSavedVessels ? filteredVesselsSaved : filteredVessels
    if (!suggestions?.length) {
      setSearchedVessels(result || [])
    }
  }, [isSavedVessels, filteredVesselsSaved, filteredVessels])

  useEffect(() => {
    let timeout
    if (!vesselLoading) {
      timeout = setTimeout(() => {
        setCustomVesselLoading(false)
      }, 1000)
    } else {
      setCustomVesselLoading(true)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [vesselLoading])

  useEffect(() => {
    if (currentUser && vData) {
      getSavedVesselOrImageMutate({
        userId: currentUser.id,
      })
    }
  }, [currentUser, vData])

  useEffect(() => {
    if (vData) {
      setTotalVessels(vData)
    }
  }, [vData])

  useEffect(() => {
    if (imageLoading === false) {
      setCurrentImageStatus(true)
      setTotalImages(iData)
    }
  }, [iData])

  useEffect(() => {
    if (currentUrl) {
      if (filterDialogRef.current) {
        // reset filter dialog
        filterDialogRef.current.handleReset()
      }
    }
  }, [currentUrl])

  return (
    <PageTransition>
      <Holder>
        <PageHead>
          <H1 className="mb-3">Vessel Database</H1>
          <H2 style={{ opacity: 1 }}>
            Explore DPRK-linked vessels and cover identities in one location
          </H2>
        </PageHead>
        <MiddleContainer>
          <SearchContainer>
            <AggregateStatistics />
            <SearchInput
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              results={allVessels}
              selectedVessels={selectedVessels}
              setSelectedVessels={setSelectedVessels}
              setSearchedVessels={setSearchedVessels}
              searchValue={suggestions}
              setSearchValue={setSuggestions}
            />
          </SearchContainer>
        </MiddleContainer>
        <MiddleContainer className="flex flex-wrap gap-4 justify-content-between mt-8">
          <SavedSelectButton setSuggestions={setSuggestions} />
          <div className="flex flex-wrap gap-2">
            <FilterButton />
            <SortButton />
            <ViewModeButton />
          </div>
        </MiddleContainer>
        <MiddleContainer className="mt-4">
          <VOIDataView
            value={searchedVessels}
            loading={customVesselLoading}
            isSavedVessels={isSavedVessels}
            isSearch={!!searchValue?.length}
            searchedValue={searchValue}
          />
        </MiddleContainer>
      </Holder>

      <FilterDialog ref={filterDialogRef} />
    </PageTransition>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  min-height: 120vh;
`

const SearchContainer = styled.div`
  position: absolute;
  bottom: -2.5em;
  left: 0;
  width: 100%;
`
