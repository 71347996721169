import { useState, useEffect } from "react"
import styled from "styled-components"
import { useMutation } from "@tanstack/react-query"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { usePostHog } from 'posthog-js/react'
import { Button } from "primereact/button"

import {
  VESSEL_STATUS,
  VESSEL_KEYS,
  VESSEL_TYPE,
  VESSEL_IDENTITY_TYPE,
  VESSEL_REDFLAG,
} from "dataset/vessel"
import { IMAGE_KEYS } from "dataset/image"
import { useStore } from "state/store"
import {
  getVesselImagesRequest,
  getLinkedIdentitiesRequest,
  getVesselShipmentsRequest,
  getVesselRequest,
} from "api/vessels"
import { saveVesselOrImage, deleteVesselOrImage } from "api/auth"
import { getQueryString, getValueForHumans } from "utils"
import { FlexSpaceContainer, MiddleContainer } from "components/Containers"
import { H3, H4, H5, H6 } from "components/Labels"
import PageTransition from "components/PageTransition"
import ImageProfileDialog from "components/ImageProfileDialog"
import Loader from "components/Loader"
import PageHeadContainer from "./components/PageHeadContainer"
import IdentityDropdown from "./components/IdentyDropdown"
import UpdatedTime from "./components/UpdatedTime"
import ReportDialog from "./components/ReportDialog"
import ProfileGallery from "./components/ProfileGallery"
import Sidebar from "./components/Sidebar"
import WhatToKnow from "./components/Sections/WhatToKnow"
import AnalystNotes from "./components/Sections/AnalystNotes"
import VesselIdentifiers from "./components/Sections/VesselIdentifiers"
import VesselNetwork from "./components/Sections/VesselNetwork"
import Shipments from "./components/Sections/Shipments"
import KnownCoverIdentity from "./components/Sections/KnownCoverIdentity"
import "./Vessel.css"

export default function VesselProfile() {
  const location = useLocation()
  const navigate = useNavigate()

  const posthog = usePostHog()

  const setProfileReportDialogVisible = useStore(
    (state) => state.setProfileReportDialogVisible,
  )
  const savedVessels = useStore((state) => state.savedVessels)
  const toggleSavedVessels = useStore((state) => state.toggleSavedVessels)
  const setImageCollection = useStore((state) => state.setImageCollection)
  const setCurrentImage = useStore((state) => state.setCurrentImage)
  const currentUser = useStore((state) => state.currentUser)
  const setProfileGalleryDialogVisible = useStore(
    (state) => state.setProfileGalleryDialogVisible,
  )
  const currentScrollPosition = useStore((state) => state.currentScrollPosition)

  const [currentVessel, setCurrentVessel] = useState(null)
  const [linkedIdentities, setLinkedIdentities] = useState([])
  const [linkedVessels, setLinkedVessels] = useState([])
  const [shipments, setShipments] = useState([])
  const [images, setImages] = useState([])
  const [isBooked, setIsBooked] = useState(false)
  const [scrollToggle, setScrollToggle] = useState(false)

  const { mutate: vesselMutate, isLoading: vesselLoading } = useMutation(
    getVesselRequest,
    {
      onSuccess: (data) => {
        if (data) {
          setCurrentVessel(data)
        } else {
          setCurrentVessel(null)
        }
      },
    },
  )

  const { mutate: identityMutate, isLoading: identityLoading } = useMutation(
    getLinkedIdentitiesRequest,
    {
      onSuccess: (data) => {
        if (data) {
          const linked_Identities = data["linked identity records"] || []
          const linked_vessels = data["linked identities"] || []
          setLinkedIdentities(linked_Identities)
          setLinkedVessels(linked_vessels)
        } else {
          setLinkedIdentities([])
        }
      },
    },
  )

  const { mutate: imageMutate } = useMutation(getVesselImagesRequest, {
    onSuccess: (data) => {
      if (data) {
        setImages(data)
        setImageCollection(data)
      } else {
        setImages([])
        setImageCollection([])
      }
    },
  })

  const { mutate: shipmentMutate, isLoading: shipmentLoading } = useMutation(
    getVesselShipmentsRequest,
    {
      onSuccess: (data) => {
        if (data) {
          setShipments(data)
        } else {
          setShipments([])
        }
      },
    },
  )

  const { mutate: saveVesselOrImageMutate } = useMutation(saveVesselOrImage, {
    onSuccess: ({ status }) => {
      if (status === 200) {
        posthog.capture("Vessel saved")
        toast.success("Vessel saved successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      } else {
        toast.error("Error while saving vessel.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      }
    },
  })

  const { mutate: deleteVesselOrImageMutate } = useMutation(
    deleteVesselOrImage,
    {
      onSuccess: ({ status }) => {
        if (status === 200) {
          posthog.capture("Vessel unsaved")
          toast.success("Vessel removed successfully.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        } else {
          toast.error("Error while saving vessel.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
      },
    },
  )

  useEffect(() => {
    const id = getQueryString(location.search, "id")
    if (id) {
      vesselMutate(id)
    } else {
      navigate(`/vessel-database`)
    }
  }, [location])

  useEffect(() => {
    const imageId = getQueryString(location.search, "imageid")
    if (imageId) {
      const image = images.find((image) => image[IMAGE_KEYS.id] === imageId)
      if (image) {
        posthog.capture("Image viewed")
        setCurrentImage(image)
        setProfileGalleryDialogVisible(true)
      }
    }
  }, [images])

  useEffect(() => {
    if (currentVessel) {
      identityMutate(currentVessel[VESSEL_KEYS.id])
      imageMutate(currentVessel[VESSEL_KEYS.id])
      shipmentMutate(currentVessel[VESSEL_KEYS.id])
      posthog.capture("Vessel profile viewed")
    }
  }, [currentVessel])

  useEffect(() => {
    const newIsBooked = savedVessels.some(
      (d) => d[VESSEL_KEYS.id] === currentVessel?.[VESSEL_KEYS.id],
    )
    setIsBooked(newIsBooked)
  }, [savedVessels, currentVessel])

  useEffect(() => {
    if (images?.length < 1) {
      if (currentScrollPosition > 500) {
        if (window.innerWidth > 1013) {
          setScrollToggle(true)
        } else {
          setScrollToggle(false)
        }
      } else {
        setScrollToggle(false)
      }
    } else {
      if (currentScrollPosition > 670) {
        if (window.innerWidth > 1013) {
          setScrollToggle(true)
        } else {
          setScrollToggle(false)
        }
      } else {
        setScrollToggle(false)
      }
    }
  }, [currentScrollPosition])

  const save = () => {
    const params = {
      userId: currentUser.id,
      objectId: currentVessel?.[VESSEL_KEYS.id],
    }
    if (isBooked) {
      deleteVesselOrImageMutate(params)
    } else {
      saveVesselOrImageMutate(params)
    }
    toggleSavedVessels(currentVessel)
  }

  if (vesselLoading || currentVessel === null) {
    return <Loader visible={vesselLoading} />
  }

  return (
    <PageTransition>
      <Holder>
        <PageHeadContainer>
          <MiddleContainer>
            <FlexSpaceContainer>
              <IdentityDropdown options={linkedVessels} />
              <UpdatedTime time={currentVessel[VESSEL_KEYS.update_date]} />
            </FlexSpaceContainer>
            <FlexSpaceContainer className="mt-5">
              <div className="flex flex-column gap-3">
                <div className="flex align-items-center gap-3 flex-wrap">
                  <H3>{currentVessel[VESSEL_KEYS.target_name]}</H3>
                  <IdentityTag
                    color={
                      VESSEL_STATUS.find(
                        (d) => d.value === currentVessel[VESSEL_KEYS.status],
                      )?.color
                    }
                  >
                    {
                      VESSEL_STATUS.find(
                        (d) => d.value === currentVessel[VESSEL_KEYS.status],
                      )?.label
                    }
                  </IdentityTag>
                  <IdentityTag
                    color={
                      VESSEL_IDENTITY_TYPE.find(
                        (d) => d.value === currentVessel[VESSEL_KEYS.identity],
                      )?.color
                    }
                  >
                    {
                      VESSEL_IDENTITY_TYPE.find(
                        (d) => d.value === currentVessel[VESSEL_KEYS.identity],
                      )?.label
                    }
                  </IdentityTag>
                </div>
                <PropsHolder className="flex flex-wrap gap-3">
                  <div className="flex gap-1">
                    <H4>IMO Number:</H4>
                    <H5>
                      {getValueForHumans(currentVessel[VESSEL_KEYS.target_imo])}
                    </H5>
                  </div>
                  <div className="flex gap-1">
                    <H4>MMSI Number:</H4>
                    <H5>
                      {getValueForHumans(
                        currentVessel[VESSEL_KEYS.target_mmsi],
                      )}
                    </H5>
                  </div>
                  <div className="flex gap-1">
                    <H4>UID:</H4>
                    <H5>
                      {getValueForHumans(
                        currentVessel[VESSEL_KEYS.internal_id],
                      )}
                    </H5>
                  </div>
                  <div className="flex gap-1">
                    <H4>TYPE:</H4>
                    <H5>
                      {getValueForHumans(
                        VESSEL_TYPE.find(
                          (d) => d.value === currentVessel[VESSEL_KEYS.type],
                        )?.label,
                      )}
                    </H5>
                  </div>
                  <div className="flex gap-1">
                    <H4>FLAG:</H4>
                    <H5>
                      {getValueForHumans(
                        currentVessel[VESSEL_KEYS.target_flag],
                      )}
                    </H5>
                  </div>
                </PropsHolder>
                <div className="flex flex-wrap gap-2">
                  {currentVessel[VESSEL_KEYS.red_flags].map((tag, index) => (
                    <H6 key={index}>
                      {VESSEL_REDFLAG.find((d) => d.value === tag)?.label}
                    </H6>
                  ))}
                </div>
              </div>
              <div className="flex gap-2">
                <Button
                  icon={`pi ${isBooked ? "pi-bookmark-fill" : "pi-bookmark"}`}
                  className="p-button-outlined p-button-secondary"
                  onClick={save}
                />
                <Button
                  label="Submit Feedback"
                  className="p-button-outlined p-button-secondary"
                  onClick={() => {
                    setProfileReportDialogVisible(true)
                  }}
                />
              </div>
            </FlexSpaceContainer>
          </MiddleContainer>
        </PageHeadContainer>
        <MiddleContainer>
          <ProfileGallery data={images || []} />
        </MiddleContainer>
        <MiddleContainer>
          <div className="flex mt-6 relative ">
            <LeftDiv>
              <FlexHolder scrollToggle={scrollToggle}>
                <Sidebar />
              </FlexHolder>
            </LeftDiv>
            <AllSections scrollToggle={scrollToggle}>
              <Sections id="profile-sections">
                <WhatToKnow identity={currentVessel} />
                <AnalystNotes identity={currentVessel} />
                <VesselIdentifiers identity={currentVessel} />
                <VesselNetwork identity={currentVessel} />
                <Shipments data={shipments || []} loading={shipmentLoading} />
                <KnownCoverIdentity
                  data={linkedIdentities || []}
                  loading={identityLoading}
                  id="knowCoverIdentity"
                />
              </Sections>
            </AllSections>
          </div>
        </MiddleContainer>
        <ReportDialog vessel={currentVessel} />
        <ImageProfileDialog />
      </Holder>
    </PageTransition>
  )
}

const Holder = styled.div``

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4em;
`
const PropsHolder = styled.div`
  max-width: 50em;
`

const LeftDiv = styled.div`
  position: ${(props) => (props.scrollToggle ? "" : "static")};
  width: 340px;
`
const FlexHolder = styled.div`
  position: ${(props) => (props.scrollToggle ? "fixed" : "static")};
  top: ${(props) => (props.scrollToggle ? "60px" : "0px")};
  transition: top 0.8s, position 0.8s;
`

const AllSections = styled.div`
  width: ${(props) => (props.scrollToggle ? "90%" : "100%")};
  position: relative;
  margin: auto;
  left: ${(props) => (props.scrollToggle ? "0px" : "0")};

  @media (min-width: 1000px) {
    width: 73%;
  }
`

const IdentityTag = styled.div`
  position: relative;
  font-size: 1.05em;
  font-weight: 400;
  color: #000000;
  line-height: 1.2;
  letter-spacing: 0.05em;
  background-color: ${(props) => `${props.color}30`};
  border-radius: 2.5em;
  padding: 0.3em 0.7em 0.3em 1.65em;
  height: min-content;
  &:before {
    content: "";
    position: absolute;
    top: 0.55em;
    left: 0.5em;
    z-index: 1;
    width: 0.725em;
    height: 0.725em;
    background-color: ${(props) => props.color};
    border-radius: 0.5em;
  }
`
