import { useEffect } from "react"
import { Routes, useLocation, useNavigate } from "react-router-dom"

import { checkUser } from "api/auth"
import { useStore } from "state/store"
import { eraseCookieForLogout, getCookie } from "utils"
import { Boundry as ErrorBoundry } from "components/Exceptions"
import { PublicRoutes } from "routes"

export default function ProtectedRoute({
  component: Component,
  ...restOfProps
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const currentUser = useStore((state) => state.currentUser)
  const setCurrentUser = useStore((state) => state.setCurrentUser)
  const setAcceptedEula = useStore((state) => state.setAcceptedEula)

  useEffect(() => {
    const access_token = getCookie("gac_access_token")
    const refresh_token = getCookie("gac_refresh_token")

    if (currentUser === null && (access_token || refresh_token)) {
      checkUser().then((user) => {
        if (user) {
          setCurrentUser(user)
          setAcceptedEula(user.accepted_eula)
        } else {
          //logout
          eraseCookieForLogout()
          setAcceptedEula(true)
        }
      })
    } else if (!access_token && !refresh_token) {
      if (
        PublicRoutes.findIndex((route) => route.path === location.pathname) > -1
      ) {
        return
      }
      navigate("/login")
    }
  }, [
    location.pathname,
    currentUser,
    navigate,
    setCurrentUser,
    setAcceptedEula,
  ])

  return (
    <ErrorBoundry>
      <Routes {...restOfProps} render={(props) => <Component {...props} />} />
    </ErrorBoundry>
  )
}
