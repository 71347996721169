import { memo, useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { toast } from "react-toastify"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import { ProgressSpinner } from "primereact/progressspinner"
import { FilterMatchMode, FilterOperator } from "primereact/api"

import { RRP_KEYS } from "dataset/rrp"
import { USER_KEYS } from "dataset/user"
import { toggleUser } from "api/auth"
import { useStore } from "state/store"
import ProgressLoader from "components/ProgressLoader"
import PaginatorTemplate from "components/PaginatorTemplate"
import TrashIcon from "assets/images/icon-trash.svg"

function UserDataTable({
  value,
  isLoading,
  editable = false,
  setDeleteModalToggle,
  refetch,
  setClickedUserId,
  resetAddUserDialog,
}) {
  const setAddUserDialogVisible = useStore(
    (state) => state.setAddUserDialogVisible,
  )
  const setRrpPermissionDialogVisible = useStore(
    (state) => state.setRrpPermissionDialogVisible,
  )
  const rrpDialogData = useStore((state) => state.rrpDialogData)
  const setRrpDialogData = useStore((state) => state.setRrpDialogData)
  const setDataTablePagination = useStore(
    (state) => state.setDataTablePagination,
  )

  const [toggleLoader, setToggleLoader] = useState(false)
  const [clickedUser, setClickedUser] = useState()
  const [toggledUsers, setToggledUsers] = useState([])
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(10)

  const paginatorTemplate = PaginatorTemplate()

  const handleToggle = async (id, user_features) => {
    const tempArray = [...toggledUsers]
    const idIndex = tempArray.indexOf(id)
    setClickedUser(id)

    if (idIndex !== -1) {
      tempArray.splice(idIndex, 1)
    } else {
      tempArray.push(id)
    }
    setToggledUsers(tempArray)
    let inputData
    if (user_features?.length > 0) {
      inputData = {
        user_features: [],
      }
    } else {
      inputData = {
        user_features: [0],
      }
    }
    setToggleLoader(true)
    await toggleUser(id, inputData)
      .then((res) => {
        refetch()
        setToggleLoader(false)
      })
      .catch(() => {
        toast.error("Failed to update the user.")
      })
  }

  const buttonStylesToggled = {
    position: "relative",
    marginLeft: "20px",
    width: "40px",
    height: "10px",
    borderRadius: "30px",
    cursor: "pointer",
    background: "rgba(241, 40, 40, 0.37)",
  }

  const buttonStylesNonToggled = {
    position: "relative",
    marginLeft: "20px",
    width: "40px",
    height: "10px",
    borderRadius: "30px",
    cursor: "pointer",
    background: "rgba(49, 46, 46, 0.12)",
  }

  const innerCircleStylesToggled = {
    position: "absolute",
    zIndex: 999,
    width: "20px",
    height: "20px",
    bottom: "-5px",
    borderRadius: "50%",
    transition: "0.3s ease-in-out",
    left: "40%",
    transform: "translateX(20%)",
    background: "rgba(241, 40, 40, 0.705)",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  }

  const innerCircleStylesNonToggled = {
    position: "absolute",
    zIndex: 999,
    width: "20px",
    height: "20px",
    bottom: "-5px",
    borderRadius: "50%",
    transition: "0.3s ease-in-out",
    left: "0",
    transform: "none",
    background: "white",

    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  }

  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const handleDelete = (id) => {
    setDeleteModalToggle(true)
    setAddUserDialogVisible(false)
    setClickedUserId(id)
  }

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "country.name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  })

  const columns = [
    {
      field: "email",
      header: "Email",
      style: { width: "30%" },
      sortable: true,
      body: (rowData) => {
        return <span>{rowData.email}</span>
      },
    },
    {
      field: "first_name",
      header: "First Name",
      style: { width: "15%" },
      sortable: true,
      body: (rowData) => {
        return <span>{rowData.first_name}</span>
      },
    },
    {
      field: "last_name",
      header: "Last Name",
      style: { width: "15%" },
      sortable: true,
      body: (rowData) => {
        return <span>{rowData.last_name}</span>
      },
    },
    {
      field: "organization",
      header: "Organization",
      style: { width: "15%" },
      sortable: true,
      body: (rowData) => {
        return (
          <span>{rowData?.organization ? rowData?.organization : "-"}</span>
        )
      },
    },

    {
      field: "role",
      header: "Role",
      style: { width: "10%" },
      sortable: true,
      body: (rowData) => {
        return (
          <span>
            {rowData.role === 0 ? (
              <span
                style={{
                  background: "rgba(131, 129, 129, 0.12)",
                  padding: "5px",
                  borderRadius: "5px",
                  fontWeight: "500",
                }}
              >
                {" "}
                Admin
              </span>
            ) : (
              <span
                style={{
                  background: "rgba(247, 81, 81, 0.12)",
                  padding: "5px",
                  borderRadius: "5px",
                  fontWeight: "500",
                }}
              >
                {" "}
                User
              </span>
            )}
          </span>
        )
      },
    },
    {
      field: "accepted_eula",
      header: "Accepted EULA",
      style: { width: "15%" },
      sortable: true,
      body: (rowData) => {
        return <span>{rowData.accepted_eula ? "True" : "False"}</span>;
      },
    },
    {
      field: "insights_requests",
      header: "Can make Insights Requests",
      style: { width: "15%" },
      sortable: false,
      body: (rowData) => {
        return (
          <div className="flex justify-content-center align-items-center">
            {toggleLoader && rowData?.id === clickedUser ? (
              <ProgressSpinner
                style={{ width: "1.375em", height: "1.375em" }}
                strokeWidth="3"
              />
            ) : (
              <div>
                <div
                  style={
                    rowData?.user_features?.length < 1
                      ? buttonStylesNonToggled
                      : buttonStylesToggled
                  }
                  onClick={() =>
                    handleToggle(rowData?.id, rowData?.user_features)
                  }
                >
                  <div
                    style={
                      rowData?.user_features?.length < 1
                        ? innerCircleStylesNonToggled
                        : innerCircleStylesToggled
                    }
                  >
                    <p style={{ opacity: 0 }}>{rowData?.first_name}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      },
    },
    {
      field: "id",
      header: "Action",
      style: { width: "10%" },
      sortable: false,
      body: (rowData) => {
        return (
          <ImageButton
            src={TrashIcon}
            onClick={() => handleDelete(rowData?.id)}
          />
        )
      },
    },
  ]

  const onPage = (event) => {
    setFirst(event.first)
    setRows(event.rows)
    setDataTablePagination(true)
  }

  const handleRowClick = (e) => {
    setRrpPermissionDialogVisible(true)
    setRrpDialogData(e.value)
  }

  useEffect(() => {
    if (rrpDialogData && value) {
      const _rrpDialogData = value.find(
        (d) => d[RRP_KEYS.id] === rrpDialogData[RRP_KEYS.id],
      )
      setRrpDialogData(_rrpDialogData)
    }
  }, [value])

  if (isLoading) {
    return <ProgressLoader />
  }

  const onGlobalFilterChange = (event) => {
    const value = event.target.value
    let _filters = { ...filters }

    _filters["global"].value = value

    setFilters(_filters)
  }

  const values = filters["global"] ? filters["global"].value : ""

  return (
    <div>
      <div className="mb-4 flex justify-content-between">
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={values || ""}
              onChange={(e) => onGlobalFilterChange(e)}
              placeholder="Search users..."
            />
          </span>
        </div>
        <div>
          <button
            className="border-none"
            style={{
              background: "#F75151",
              padding: "15px",
              borderRadius: "4px",
              color: "white",
              fontWeight: 700,
              cursor: "pointer",
            }}
            onClick={() => {
              setAddUserDialogVisible(true)
              setDeleteModalToggle(false)
              resetAddUserDialog()
            }}
          >
            + <span style={{ marginLeft: "8px" }}> Add User</span>
          </button>
        </div>
      </div>
      <DataTable
        value={value}
        paginator
        paginatorTemplate={paginatorTemplate}
        first={first}
        rows={rows}
        onPage={onPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        selectionMode={editable ? "single" : undefined}
        onSelectionChange={handleRowClick}
        sortField={USER_KEYS.first_name}
        sortOrder={1}
        emptyMessage="No Users found."
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}
        selection={selectedCustomer}
        dataKey="id"
      >
        {columns.map((column, index) => (
          <Column key={index} {...column} />
        ))}
      </DataTable>
    </div>
  )
}

export default memo(UserDataTable)

const ImageButton = styled.img`
  height: 1.2em;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  :hover {
    opacity: 0.5;
  }
`
