import styled from "styled-components"

export default function InfoAmountBadge({ children, ...props }) {
  return (
    <Holder {...props}>
      <div>
        <i className="pi pi-info-circle" />
      </div>
      <div>{children}</div>
    </Holder>
  )
}

const Holder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  border-radius: 0.4em;
  background-color: ${(props) => props.theme.colors.primary};
  color: #ffffff;
  font-weight: 700;
  font-size: 0.75em;
  padding: 0.4em 0.6em;
`
