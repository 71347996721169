import Navbar from "components/Navbar"
import Footer from "components/Footer"

import Home from "pages/Home"
import HomeOld from "pages/Home-old"
import VesselDatabase from "pages/VesselDatabase"
import ImageryLibrary from "pages/ImageryLibrary"
import OilShipmentTracker from "pages/OilShipmentTracker"
import InteractiveMap from "pages/InteractiveMap"
import VesselProfile from "pages/VesselProfile"
import SignIn from "pages/SignIn"
import UserProfile from "pages/UserProfile"
import RapidResponseProgram from "pages/RapidResponseProgram"
import Eula from "pages/Eula"
import About from "pages/About"

const PageWithHeaderFooter = ({ component }) => {
  return (
    <>
      <Navbar />
      {component}
      <Footer />
    </>
  )
}

const PageWithHeader = ({ component }) => {
  return (
    <>
      <Navbar />
      {component}
    </>
  )
}

export const PrivateRoutes = [
  {
    path: "/",
    title: "Home",
    isPrimaryRoute: true,
    component: <PageWithHeaderFooter component={<Home />} />,
    children: [],
  },
  // {
  //   path: "/",
  //   title: "Home",
  //   isPrimaryRoute: true,
  //   component: <PageWithHeaderFooter component={<HomeOld />} />,
  //   children: [
  //     {
  //       path: "/about",
  //       title: "About",
  //       component: <div>About</div>,
  //     },
  //     {
  //       path: "/partners",
  //       title: "Partners",
  //       component: <div>Partners</div>,
  //     },
  //     {
  //       path: "/contactus",
  //       title: "Contact Us",
  //       component: <div>Contact Us</div>,
  //     },
  //   ],
  // },
  {
    path: null,
    title: "Targeting",
    isPrimaryRoute: true,
    component: null,
    children: [
      {
        path: "/vessel-database",
        title: "Vessel Database",
        component: <PageWithHeaderFooter component={<VesselDatabase />} />,
        children: [],
      },
      {
        path: "/imagery-library",
        title: "Imagery Library",
        component: <PageWithHeader component={<ImageryLibrary />} />,
        children: [],
      },
      {
        path: "/interactive-map",
        title: "Interactive Map",
        component: <PageWithHeader component={<InteractiveMap />} />,
        children: [],
      },
      {
        path: "/oil-shipment-tracker",
        title: "Oil Shipment Tracker",
        component: <PageWithHeaderFooter component={<OilShipmentTracker />} />,
        children: [],
      },
    ],
  },
  {
    path: "/vessel-profile",
    title: "Vessel Profile",
    component: <PageWithHeaderFooter component={<VesselProfile />} />,
    children: [],
  },
  {
    path: "/insights",
    title: "Insights",
    isPrimaryRoute: true,
    component: <PageWithHeaderFooter component={<RapidResponseProgram />} />,
    children: [],
  },
  {
    path: "https://sso.teachable.com/secure/1607165/identity/login/password",
    title: "Learning",
    isPrimaryRoute: true,
    component: null,
    children: [],
  },
  {
    path: "/profile",
    title: "My Profile",
    component: <PageWithHeaderFooter component={<UserProfile />} />,
    children: [],
  },
]

export const PublicRoutes = [
  {
    path: "/login",
    title: "Log In",
    component: <SignIn />,
    children: [],
  },
  {
    path: "/about-us",
    title: "About Us",
    component: <About />,
    children: [],
  },
  {
    path: "/eula",
    title: "Eula",
    component: <Eula />,
    children: [],
  },
]

export const PrivacyPolicyRoute = {
  path: "https://c4ads.org/privacy-policy/",
}

export const ChangelogRoute = {
  path: "https://arion.launchnotes.io/",
}
