import { memo } from "react"
import styled from "styled-components"
import { Slider } from "primereact/slider"

function TimelineHoverRanger({ value, max = 100 }) {
  return (
    <Holder>
      <Container>
        <Slider value={value} range min={0} max={max} step={1} />
      </Container>
    </Holder>
  )
}

export default memo(TimelineHoverRanger)

const Holder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  padding-left: 65px;
  padding-bottom: 35px;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .p-slider {
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    .p-slider-range {
      background-color: rgba(247, 81, 81, 0.1);
      /* border: 1px solid #000; */
    }
    .p-slider-handle {
      background: #f05261;
      width: 8px;
      height: 3em;
      border-radius: 4px;
      cursor: col-resize;
      padding: 0;
      margin: 0;
      visibility: hidden;
      /* border: 1px solid #000; */
      &:focus {
        box-shadow: 0 0 1px 1px rgb(26 52 76 / 20%);
        background: rgb(248 252 165);
      }
      &:hover {
        background: rgb(248 252 165);
      }
    }
    .p-slider-handle-start {
      transform: translate(-50%, -50%);
    }
    .p-slider-handle-end {
      transform: translate(-50%, -50%);
    }
    .p-slider-handle-active {
      background: rgb(248 252 165);
    }
  }
`
