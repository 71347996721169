import { useState } from "react"
import styled from "styled-components"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { InputTextarea } from "primereact/inputtextarea"

import { useStore } from "state/store"
import { H7, H8 } from "components/Labels"

import CheckImage from "assets/images/check.svg"
import { reportRequest } from "api/vessels"
import { VESSEL_KEYS } from "dataset/vessel"
import { toast } from "react-toastify"
import { usePostHog } from 'posthog-js/react'

export default function ReportDialog({ vessel }) {
  const posthog = usePostHog()

  const profileReportDialogVisible = useStore(
    (state) => state.profileReportDialogVisible,
  )
  const setProfileReportDialogVisible = useStore(
    (state) => state.setProfileReportDialogVisible,
  )
  const currentUser = useStore((state) => state.currentUser)

  const [report, setReport] = useState("")

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const onHide = () => {
    setProfileReportDialogVisible(false)
  }

  const Header = () => {
    return (
      <div>
        <div className="flex align-items-center gap-3">
          <H7>Submit Feedback</H7>
        </div>
      </div>
    )
  }

  const Footer = () => {
    return (
      <div>
        <Button
          label={isSending ? "Sending" : "Submit"}
          className="p-button-raised"
          disabled={report.length === 0}
          loading={isSending}
          onClick={() => {
            setIsSending(true)
            posthog.capture("Vessel feedback submitted")
            reportRequest(vessel[VESSEL_KEYS.id], currentUser.email, report)
              .then(({ status }) => {
                if (status === 200) {
                  onHide()
                  toast.success("Issue reported successfully.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  })
                } else {
                  toast(`Sending has failed ${status}`)
                }
                setIsSending(false)
              })
              .catch((err) => {
                setIsSending(false)
                toast(`Sending has failed ${err.message}`)
              })
          }}
        />
      </div>
    )
  }

  return (
    <StyledDialog
      header={Header}
      footer={Footer}
      visible={profileReportDialogVisible}
      onHide={onHide}
    >
      <Content>
        <H8>Provide feedback on or report an issue with this profile.</H8>
        <TextArea
          className="mt-2"
          autoResize={true}
          rows={5}
          value={report}
          onChange={(e) => {
            setReport(e.target.value)
          }}
          placeholder="Share your thoughts here..."
        />
        <div className="flex mt-2">
          <ValidationMesssage>
            {report.length === 0 ? "Description must not be empty" : ""}
          </ValidationMesssage>
        </div>
        <SubmittedDialog
          visible={isSubmitted}
          onHide={() => {
            setIsSubmitted(false)
          }}
        >
          <SubmittedDialogContent>
            <img src={CheckImage} alt="" />
            <H7>Submitted</H7>
          </SubmittedDialogContent>
        </SubmittedDialog>
      </Content>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)`
  min-width: 300px;
  width: 50vw;
  max-width: 1200px;
`

const Content = styled.div`
  padding: 1.5em 0;
`

const TextArea = styled(InputTextarea)`
  width: 100%;
`

const SubmittedDialog = styled(Dialog)`
  min-width: 350px;
  .p-dialog-header {
    border-bottom: none;
    padding: 0.5em;
  }
`

const SubmittedDialogContent = styled.div`
  padding: 0 1.5em 1em 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
`

const ValidationMesssage = styled.div`
  font-size: 0.9em;
  color: #f43f53;
`
