import { SplitButton } from "primereact/splitbutton"
import { useStore } from "state/store"
import { SORT_DATA } from "dataset/voi"
import { useRef, useState } from "react"

export default function SortButton() {
  const voiSortOption = useStore((state) => state.voiSortOption)
  const setVOISortOption = useStore((state) => state.setVOISortOption)
  const ref = useRef(null)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const items = [
    {
      label: "Newest first",
      icon: "",
      command: () => {
        setVOISortOption(SORT_DATA[0])
      },
    },
    {
      label: "Oldest first",
      icon: "",
      command: () => {
        setVOISortOption(SORT_DATA[1])
      },
    },
  ]
  return (
    <SplitButton
      ref={ref}
      label={voiSortOption.label}
      model={items}
      onShow={() => setIsPopupOpen(true)}
      onHide={() => setIsPopupOpen(false)}
      onClick={() => {
        if (isPopupOpen) {
          ref.current.hide()
        } else {
          ref.current.show()
        }
      }}
      className="p-button-info"
    ></SplitButton>
  )
}
