import { memo } from "react"
import styled from "styled-components"
import Sticky from "wil-react-sticky"
import { device } from "theme/device"

import "./Sidebar.css"
import { useStore } from "state/store"

function Sidebar() {
  const scrollToTarget = (url) => {
    const targetElement = document.getElementById(url)
    targetElement.scrollIntoView({ behavior: "smooth" })
  }
  const currentSectionInView = useStore((state) => state.currentSectionInView)
  return (
    <Holder>
      <Sticky>
        <Container id="sidebar-item">
          <li
            id="whattoknow"
            style={{
              color: currentSectionInView === "what_to_know" ? "#F75151" : "",
              cursor: "pointer",
              fontWeight: "semi-bold",
              listStyle: "none",
            }}
            onClick={() => scrollToTarget("anchor_profile_what_to_know", 1)}
          >
            What to Know
          </li>
          <li
            id="analystnotes"
            style={{
              color: currentSectionInView === "analyst_notes" ? "#F75151" : "",
              cursor: "pointer",
              fontWeight: "semi-bold",
              listStyle: "none",
            }}
            onClick={() => scrollToTarget("anchor_profile_analyst_notes", 2)}
          >
            Analyst Notes
          </li>
          <li
            id="vesselidentifiers"
            style={{
              color:
                currentSectionInView === "vessel_identifiers" ? "#F75151" : "",
              cursor: "pointer",
              fontWeight: "semi-bold",
              listStyle: "none",
            }}
            onClick={() =>
              scrollToTarget("anchor_profile_vessel_identifiers", 3)
            }
          >
            Vessel Identifiers
          </li>
          <li
            id="vesselnetwork"
            style={{
              color: currentSectionInView === "vessel_network" ? "#F75151" : "",
              cursor: "pointer",
              fontWeight: "semi-bold",
              listStyle: "none",
            }}
            onClick={() => scrollToTarget("anchor_profile_vessel_network", 4)}
          >
            Vessel Network
          </li>
          <li
            id="dprk"
            style={{
              color:
                currentSectionInView === "linked_shipments" ? "#F75151" : "",
              cursor: "pointer",
              fontWeight: "semi-bold",
              listStyle: "none",
            }}
            onClick={() => scrollToTarget("anchor_profile_linked_shipments", 5)}
          >
            Known DPRK-linked Shipments
          </li>
          <li
            id="coveridentities"
            style={{
              color:
                currentSectionInView === "cover_identities" ? "#F75151" : "",
              cursor: "pointer",
              fontWeight: "semi-bold",
              listStyle: "none",
            }}
            onClick={() => scrollToTarget("anchor_profile_cover_identities", 6)}
          >
            Known Cover Identities
          </li>
        </Container>
      </Sticky>
    </Holder>
  )
}

export default memo(Sidebar)

const Holder = styled.div`
  position: relative;
  min-width: 19em;
  width: 19em;
  @media ${device.pad} {
    display: none;
  }
`

const Container = styled.div`
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1.5em;
  width: max-content;
  height: min-content;
`
