import { memo, useState, useEffect } from "react"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { FilterMatchMode } from "primereact/api"

import { USER_ROLES } from "dataset/user"
import { H42 } from "components/Labels"
import ProgressLoader from "components/ProgressLoader"
import PaginatorTemplate from "components/PaginatorTemplate"
import PermissionActionSwitch from "./PermissionActionSwitch"

function PermissionDataTable({
  value = [],
  isLoading = false,
  search = "",
  rowAmount = 10,
  selectedUsers = [],
  setSelectedUsers = () => {},
  showAction = true,
}) {
  const [filters, setFilters] = useState({
    global: { value: "", matchMode: FilterMatchMode.CONTAINS },
    // email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  })

  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(rowAmount)

  const paginatorTemplate = PaginatorTemplate()

  const onPage = (event) => {
    setFirst(event.first)
    setRows(event.rows)
  }

  const columns = [
    {
      field: "email",
      header: "Account",
      style: { width: "60%" },
      sortable: true,
      body: (rowData) => {
        return <span>{rowData.email}</span>
      },
    },
    {
      field: "role",
      header: "Type",
      style: { width: "20%" },
      sortable: true,
      body: (rowData) => {
        return (
          <H42>{USER_ROLES.find((d) => d.id === rowData.role)?.type || ""}</H42>
        )
      },
    },
    showAction && {
      field: null,
      header: "Granted",
      style: { width: "20%" },
      sortable: false,
      body: (rowData) => {
        const handleSwitch = (checked) => {
          if (checked) {
            setSelectedUsers([...selectedUsers, rowData.id])
          } else {
            setSelectedUsers(selectedUsers.filter((d) => d !== rowData.id))
          }
        }
        return <PermissionActionSwitch data={rowData} onChange={handleSwitch} />
      },
    },
  ]

  useEffect(() => {
    let _filters = { ...filters }
    _filters["global"].value = search
    setFilters(_filters)
  }, [search])

  if (isLoading) {
    return <ProgressLoader />
  }

  return (
    <DataTable
      value={value}
      paginator
      filters={filters}
      paginatorTemplate={paginatorTemplate}
      first={first}
      rows={rows}
      onPage={onPage}
      rowsPerPageOptions={[5, 10, 25, 50]}
    >
      {columns.map((column, index) => (
        <Column key={index} {...column} />
      ))}
    </DataTable>
  )
}

export default memo(PermissionDataTable)
