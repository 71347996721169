export const USER_KEYS = {
  accepted_eula: "accepted_eula",
  email: "email",
  first_name: "first_name",
  id: "id",
  last_name: "last_name",
  organization: "organization",
  role: "role",
  saved_images: "saved_images",
  saved_vessels: "saved_vessels",
  title: "title",
  user_features: "user_features",
}

export const USER_ROLES = [
  {
    id: 0,
    type: "Admin",
    label: "Administator",
  },
  {
    id: 1,
    type: "User",
    label: "User",
  },
]
