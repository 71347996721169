import { api } from "api"
import { HISTORICAL_VESSEL_KEYS } from "dataset/vessel"

/**
 * Get all vessels
 * @returns
 */
export const getVesselsRequest = async () => {
  try {
    const { data } = await api.get(`/vessels`)
    return data
  } catch (error) {
    return null
  }
}

/**
 * Get vessel
 * @returns
 */
export const getVesselRequest = async (vessel_id) => {
  try {
    const { data } = await api.get(`/vessels/?id=${vessel_id}`)
    return data[0]
  } catch (error) {
    return null
  }
}

/**
 * Get vessels by ids
 * @param {Array} vessel_ids
 * @returns
 */
export const getHistoricalVesselsRequest = async (vessel_ids) => {
  const result = []
  try {
    for (let i = 0; i < vessel_ids.length; i++) {
      const vessel_id = vessel_ids[i]
      const { data } = await api.get(`/hist_target_vessels/${vessel_id}`)
      if (data[HISTORICAL_VESSEL_KEYS.cover_historical_id] === null) {
        result.push(data)
      } else {
        const cData = [data]
        const d = await api.get(
          `/hist_cover_ids/${data[HISTORICAL_VESSEL_KEYS.cover_historical_id]}`,
        )
        cData.push(d.data)
        result.push(cData)
      }
    }
    return result
  } catch (error) {
    return []
  }
}

/**
 * Get linked_identities of vessel
 * @param {Object}
 * @returns
 */
export const getLinkedIdentitiesRequest = async (vessel_id) => {
  if (!vessel_id) {
    return null
  }

  try {
    const { data } = await api.get(`/vessels/${vessel_id}/linked_identities`)
    return data
  } catch (error) {
    return null
  }
}

/**
 * Get images of vessel
 * @param {String} vessel_id
 * @returns
 */
export const getVesselImagesRequest = async (vessel_id) => {
  if (!vessel_id) {
    return null
  }

  try {
    const { data } = await api.get(`/vessels/${vessel_id}/images`)
    return data
  } catch (error) {
    return null
  }
}

/**
 * Get shipments of vessel
 * @param {String} vessel_id
 * @returns
 */
export const getVesselShipmentsRequest = async (vessel_id) => {
  if (!vessel_id) {
    return null
  }

  try {
    const { data } = await api.get(`/vessels/${vessel_id}/shipments`)
    return data
  } catch (error) {
    return null
  }
}

/**
 * Get owners of vessel
 * @param {String} vessel_id
 * @returns
 */
export const getRegisteredownersRequest = async (vessel_id) => {
  if (!vessel_id) {
    return null
  }

  try {
    const { data } = await api.get(`/registeredowners/?vessel_id=${vessel_id}`)
    return data.results
  } catch (error) {
    return null
  }
}

/**
 * Get managers of vessel
 * @param {String} vessel_id
 * @returns
 */
export const managersRequest = async (vessel_id) => {
  if (!vessel_id) {
    return null
  }

  try {
    const { data } = await api.get(`/shipmanagers/?vessel_id=${vessel_id}`)
    return data.results
  } catch (error) {
    return null
  }
}

/**
 * Get operators of vessel
 * @param {String} vessel_id
 * @returns
 */
export const operatorsRequest = async (vessel_id) => {
  if (!vessel_id) {
    return null
  }

  try {
    const { data } = await api.get(`/operators/?vessel_id=${vessel_id}`)
    return data.results
  } catch (error) {
    return null
  }
}

/**
 * Get companies of vessel
 * @param {String} vessel_id
 * @returns
 */
export const companiesRequest = async (vessel_id) => {
  if (!vessel_id) {
    return null
  }

  try {
    const { data } = await api.get(`/doccompanies/?vessel_id=${vessel_id}`)
    return data.results
  } catch (error) {
    return null
  }
}

/**
 * Get technical managers of vessel
 * @param {String} vessel_id
 * @returns
 */
export const technicalManagersRequest = async (vessel_id) => {
  if (!vessel_id) {
    return null
  }

  try {
    const { data } = await api.get(`/technicalmanagers/?vessel_id=${vessel_id}`)
    return data.results
  } catch (error) {
    return null
  }
}

/**
 * Intercom Report
 * @param {string} email
 * @param {string} message
 * @returns {Object}
 */
export const reportRequest = async (vessel_id, email, message) => {
  try {
    const response = await api.post(`/vessels/${vessel_id}/submit_feedback/`, {
      email: email,
      message_body: message,
    })
    return response
  } catch (error) {
    throw new Error(error?.response?.data?.detail)
  }
}
