import { memo, useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import { toast } from "react-toastify"
import { useMutation } from "@tanstack/react-query"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useNavigate } from "react-router-dom"
import ReactMarkdown from "react-markdown"
import { Divider } from "primereact/divider"
import { Button } from "primereact/button"

import { useStore } from "state/store"
import {
  VESSEL_IDENTITY_TYPE,
  VESSEL_KEYS,
  VESSEL_REDFLAG,
  VESSEL_TYPE,
} from "dataset/vessel"
import { formatDateString, getValueForHumans } from "utils"
import { saveVesselOrImage, deleteVesselOrImage } from "api/auth"
import { IMAGE_KEYS } from "dataset/image"

import { H12, H24, H25, H27, H8 } from "components/Labels"
import InfoAmountBadge from "components/Badges/InfoAmountBadge"

import NoVesselImage from "assets/images/no-vessel.svg"
import { usePostHog } from 'posthog-js/react'
import { Skeleton } from "primereact/skeleton"

function ListItem({ data }) {
  const navigate = useNavigate()

  const posthog = usePostHog()

  const currentUser = useStore((state) => state.currentUser)
  const totalImages = useStore((state) => state.totalImages)
  const savedVessels = useStore((state) => state.savedVessels)
  const toggleSavedVessels = useStore((state) => state.toggleSavedVessels)
  const [thumbnail, setThumbnail] = useState("")
  const [isImageLoading, setIsImageLoading] = useState(true)

  const isBooked = useMemo(() => {
    return savedVessels.some(
      (identity) => identity[VESSEL_KEYS.id] === data[VESSEL_KEYS.id],
    )
  }, [savedVessels, data])

  const identities = data[VESSEL_KEYS.linked_ids_count] || 0
  const identitiesOPRef = useRef(null)
  let identitiesOPContent = ""
  if (data[VESSEL_KEYS.red_flags]?.length > 2) {
    for (let i = 2; i < data[VESSEL_KEYS.red_flags].length; i++) {
      identitiesOPContent +=
        VESSEL_REDFLAG.find((v) => v.value === data[VESSEL_KEYS.red_flags][i])
          .label + ", "
    }
  }

  useEffect(() => {
    if (data) {
      if (data[VESSEL_KEYS.images].length > 0) {
        const imageId = data[VESSEL_KEYS.images][0]
        const image = totalImages.find((i) => i[IMAGE_KEYS.id] === imageId)
        if (image) {
          setThumbnail(image[IMAGE_KEYS.s3_path])
        } else {
          setThumbnail("")
        }
      } else {
        setThumbnail("")
      }
    }
  }, [data])

  const {
    mutate: saveVesselOrImageMutate,
    isLoading: saveVesselOrImageLoading,
  } = useMutation(saveVesselOrImage, {
    onSuccess: ({ status }) => {
      if (status === 200) {
        posthog.capture("Vessel saved")
        toast.success("Vessel saved successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      } else {
        toast.error("Error while saving vessel.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      }
    },
  })

  const {
    mutate: deleteVesselOrImageMutate,
    isLoading: deleteVesselOrImageLoading,
  } = useMutation(deleteVesselOrImage, {
    onSuccess: ({ status }) => {
      if (status === 200) {
        posthog.capture("Vessel unsaved")
        toast.success("Vessel removed successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      } else {
        toast.error("Error while saving vessel.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      }
    },
  })

  const save = (e) => {
    const params = {
      userId: currentUser.id,
      objectId: data[VESSEL_KEYS.id],
    }
    if (isBooked) {
      deleteVesselOrImageMutate(params)
    } else {
      saveVesselOrImageMutate(params)
    }
    toggleSavedVessels(data)
    e.stopPropagation()
  }

  return (
    <div className="col-12 border-none">
      <Card
        onClick={() => {
          navigate(`/vessel-profile?id=${data[VESSEL_KEYS.id]}`)
        }}
      >
        <Photo>
          {thumbnail === "" ? (
            <NoImage src={NoVesselImage} />
          ) : (
            <>
              {isImageLoading ? (
                <SkeletonContainer>
                  <Skeleton width="100%" height="100%" />
                </SkeletonContainer>
              ) : null}
              <LazyLoadImage
                beforeLoad={() => {
                  setIsImageLoading(true)
                }}
                afterLoad={() => {
                  setIsImageLoading(false)
                }}
                src={thumbnail}
                alt={data[VESSEL_KEYS.target_name]}
                width="auto"
                height="100%"
                effect="blur"
              />
            </>
          )}
        </Photo>
        <div className="p-3 w-full">
          <div className="flex flex-wrap gap-2 justify-content-between">
            <div>
              <div>
                <H24
                  style={{
                    color: VESSEL_IDENTITY_TYPE.find(
                      (d) => d.value === data[VESSEL_KEYS.identity],
                    )?.color,
                  }}
                >
                  {
                    VESSEL_IDENTITY_TYPE.find(
                      (d) => d.value === data[VESSEL_KEYS.identity],
                    )?.label
                  }
                </H24>
              </div>
              <div className="mt-2 flex gap-4">
                <H25>{data[VESSEL_KEYS.target_name]}</H25>
                {identities > 0 && (
                  <InfoAmountBadge>{`${identities} identities`}</InfoAmountBadge>
                )}
              </div>
            </div>
            <div className="flex gap-4 align-items-center">
              <div>
                <H8>
                  {`Last Updated: ${formatDateString(
                    data[VESSEL_KEYS.update_date],
                  )}`}
                </H8>
              </div>
              <BookButton
                icon={`pi ${isBooked ? "pi-bookmark-fill" : "pi-bookmark"}`}
                className="p-button-rounded p-button-text p-button-secondary"
                onClick={save}
              />
            </div>
          </div>
          <div className="mt-3 flex flex-wrap gap-3 justify-content-between">
            <InfoList>
              <div style={{ minWidth: "3em" }}>
                <H8>IMO</H8>
                <H12>{getValueForHumans(data[VESSEL_KEYS.target_imo])}</H12>
              </div>
              <StyledDivider layout="vertical" />
              <div style={{ minWidth: "3em" }}>
                <H8>MMSI</H8>
                <H12>{getValueForHumans(data[VESSEL_KEYS.target_mmsi])}</H12>
              </div>
              <StyledDivider layout="vertical" />
              <div style={{ minWidth: "3em" }}>
                <H8>TYPE</H8>
                <H12>
                  {getValueForHumans(
                    VESSEL_TYPE.find((d) => d.value === data[VESSEL_KEYS.type])
                      ?.label,
                  )}
                </H12>
              </div>
              <StyledDivider layout="vertical" />
              <div style={{ minWidth: "3em" }}>
                <H8>FLAG</H8>
                <H12>{getValueForHumans(data[VESSEL_KEYS.target_flag])}</H12>
              </div>
            </InfoList>
            <TagList>
              {data[VESSEL_KEYS.red_flags].map((value, index) => (
                <Tag key={index}>
                  {VESSEL_REDFLAG.find((d) => d.value === value)?.label}
                </Tag>
              ))}
            </TagList>
          </div>
          <Description className="mt-3">
            <H27>
              <ReactMarkdown>{data[VESSEL_KEYS.analyst_notes]}</ReactMarkdown>
            </H27>
          </Description>
        </div>
      </Card>
    </div>
  )
}

export default memo(ListItem)

const Card = styled.div`
  width: 100%;
  min-height: 7em;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid rgba(4, 61, 93, 0.26);
  border-radius: 0.5em;
  overflow: hidden;
  transition: all 0.2s ease-out;
  cursor: pointer;
  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
`

const Photo = styled.div`
  min-width: 13em;
  width: 13em;
  max-width: 13em;
  max-height: 15em;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #edecec;
  position: relative;
`

const InfoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  height: 2.3em;
  overflow: hidden;
`

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  height: 2.3em;
  overflow: hidden;
`

const Tag = styled.div`
  padding: 0.6em 0.8em;
  background-color: #e4e4e4;
  border-radius: 0.25em;
  min-width: max-content;
  height: min-content;
  font-size: 0.625em;
  font-weight: 700;
  color: #1d1d1b;
  line-height: 1;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`

const Description = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledDivider = styled(Divider)`
  margin: 0 0.8em !important;
  padding: 0 !important;
`

const BookButton = styled(Button)`
  color: black !important;
`
const NoImage = styled.img``

const SkeletonContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
