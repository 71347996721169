import styled from "styled-components"
import SwitchSelector from "react-switch-selector"
import { useStore } from "state/store"

export default function SavedSelectButton({ className, setSuggestions }) {
  const isSavedVessels = useStore((state) => state.isSavedVessels)
  const setIsSavedVessels = useStore((state) => state.setIsSavedVessels)

  const onChange = (v) => {
    setSuggestions([])
    if (v === 0) {
      setIsSavedVessels(false)
    } else {
      setIsSavedVessels(true)
    }
  }

  const options = [
    {
      label: (
        <Label active={isSavedVessels === false}>
          <i className="pi pi-bars"></i>
          <span>All Vessels</span>
        </Label>
      ),
      value: 0,
      backgroundColor: "#e8eaec",
      selectedBackgroundColor: "#1d1d1b",
    },
    {
      label: (
        <Label active={isSavedVessels === true}>
          <i className="pi pi-bookmark"></i>
          <span>Saved Vessels</span>
        </Label>
      ),
      value: 1,
      backgroundColor: "#e8eaec",
      selectedBackgroundColor: "#1d1d1b",
    },
  ]

  return (
    <div className={className} style={{ width: "18em", height: "3em" }}>
      <SwitchSelector
        onChange={onChange}
        options={options}
        optionBorderRadius={32}
        wrapperBorderRadius={30}
        selectionIndicatorMargin={4}
        initialSelectedIndex={isSavedVessels ? 1 : 0}
      />
    </div>
  )
}

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 0.875em;
  * {
    font-size: 1em !important;
    color: ${(props) => (props.active ? "white" : "black")};
  }
`
