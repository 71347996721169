import { SplitButton } from "primereact/splitbutton"
import { useStore } from "state/store"

import InfoIcon from "components/Icons/InfoIcon"

import GridIcon from "assets/images/icon-grid.svg"
import ListIcon from "assets/images/icon-list.svg"
import { useRef, useState } from "react"

const Icons = {
  grid: <InfoIcon src={GridIcon} />,
  list: <InfoIcon src={ListIcon} />,
}

const Labels = {
  grid: "Gallery",
  list: "List",
}

export default function ViewModeButton() {
  const voiLayout = useStore((state) => state.voiLayout)
  const setVOILayout = useStore((state) => state.setVOILayout)
  const ref = useRef(null)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const items = [
    {
      label: Labels.grid,
      icon: Icons.grid,
      command: () => {
        setVOILayout("grid")
      },
    },
    {
      label: Labels.list,
      icon: Icons.list,
      command: () => {
        setVOILayout("list")
      },
    },
  ]
  return (
    <SplitButton
      ref={ref}
      icon={Icons[voiLayout]}
      label={Labels[voiLayout]}
      model={items}
      onShow={() => setIsPopupOpen(true)}
      onHide={() => setIsPopupOpen(false)}
      onClick={() => {
        if (isPopupOpen) {
          ref.current.hide()
        } else {
          ref.current.show()
        }
      }}
      className="p-button-info"
    ></SplitButton>
  )
}
