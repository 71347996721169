import { useEffect, useState } from "react"
import styled from "styled-components"
import { toast } from "react-toastify"
import { useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import { TabView, TabPanel } from "primereact/tabview"

import { getUsersRequest } from "api/users"
import { createSingleUser } from "api/auth"
import { checkPermissions } from "utils"
import { useStore } from "state/store"
import { MiddleContainer } from "components/Containers"
import PageTransition from "components/PageTransition"
import UserPlan from "./components/UserPlan"
import AccessControl from "components/AccessControl"
import AccountInfo from "./components/AccountInfo"
import Admin from "./components/Admin"
import DeleteModal from "./components/DeleteModal"
import AddUserDialog from "./components/AddUserDialog"
import ConfirmUserDetailsModal from "./components/ConfirmUserDetailsModal"
import PatternImage from "assets/images/ost-pattern.svg"
import { usePostHog } from 'posthog-js/react'

const RRP = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("/insights")
  })
  return "Redirecting..."
}

const UserProfile = () => {
  const currentUser = useStore((state) => state.currentUser)
  const posthog = usePostHog()
  const [confirmUserDetailsModalToggle, setConfirmUserDetailsModalToggle] =
    useState(false)
  const [deleteModalToggle, setDeleteModalToggle] = useState(false)

  const [clickedUserId, setClickedUserId] = useState()

  const {
    data: users,
    isLoading: isUserLoading,
    refetch,
  } = useQuery(["getUsersRequest"], () => getUsersRequest())

  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    title: "",
  }
  const [userData, setUserData] = useState(initialData)
  const [creationType, setCreationType] = useState("singleAccount")
  const [checkbox, setCheckbox] = useState(false)
  const [role, setRole] = useState("user")

  const handleChange = (e, name) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: e,
    }))
  }

  const tabHeaderTemplate = (options) => {
    const selectedColor = "#122945"
    const unselectedColor = "#536378"
    const userAccountSvg = (
      <svg
        width="10"
        height="13"
        viewBox="0 0 10 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.333252 12.334C0.333252 11.0963 0.824917 9.90932 1.70009 9.03415C2.57526 8.15898 3.76224 7.66732 4.99992 7.66732C6.2376 7.66732 7.42458 8.15898 8.29975 9.03415C9.17492 9.90932 9.66659 11.0963 9.66659 12.334H0.333252ZM4.99992 7.08398C3.06617 7.08398 1.49992 5.51773 1.49992 3.58398C1.49992 1.65023 3.06617 0.0839844 4.99992 0.0839844C6.93367 0.0839844 8.49992 1.65023 8.49992 3.58398C8.49992 5.51773 6.93367 7.08398 4.99992 7.08398Z"
          fill={options.selected ? selectedColor : unselectedColor}
        />
      </svg>
    )

    const tabIcon = (name) => {
      switch (name) {
        case "Account Info":
          return userAccountSvg
        case "Admin":
          return userAccountSvg
        case "Rapid Response Program":
          return userAccountSvg
        default:
          return null
      }
    }

    return (
      <button
        type="button"
        onClick={options.onClick}
        className={options.className}
      >
        <span className="mr-1">
          {tabIcon(options.titleElement?.props?.children)}
        </span>
        {options.titleElement}
      </button>
    )
  }

  const handleSubmit = async () => {
    if (
      userData?.email?.length &&
      userData?.firstName?.length
      // userData?.lastName?.length
    ) {
      const payload = {
        email: userData?.email,
        first_name: userData?.firstName,
        last_name: userData?.lastName || "",
        role: role === "Administrator" ? 0 : 1,
        organization: userData?.organization,
        title: userData?.title || "",
        user_features: checkbox === true ? [0] : [],
      }
      await createSingleUser(payload)
        .then(() => {
          posthog.capture("User created", {
            "new_user": userData?.email
          })
          toast.success("User created successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        })
        .catch(() => {
          toast.error("Error while creating user.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        })
    } else {
      toast.error("Please complete all required fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }
  }

  const resetAddUserDialog = () => {
    setUserData(initialData)
    setCheckbox(false)
    setRole("user")
    setCreationType("singleAccount")
  }

  return (
    <PageTransition>
      <Holder>
        {/* Delete User Modal */}
        {deleteModalToggle && (
          <DeleteModalBackDrop>
            <RemoveUserModalHolder>
              <DeleteModal
                setDeleteModalToggle={setDeleteModalToggle}
                clickedUserId={clickedUserId}
                refetch={refetch}
              />
            </RemoveUserModalHolder>
          </DeleteModalBackDrop>
        )}
        {/* Add User Modal */}
        <AddUserDialog
          creationType={creationType}
          setCreationType={setCreationType}
          setRole={setRole}
          userData={userData}
          role={role}
          checkbox={checkbox}
          setCheckbox={setCheckbox}
          setConfirmUserDetailsModalToggle={setConfirmUserDetailsModalToggle}
          handleChange={handleChange}
        />
        {/* Confirm User Modal */}
        {confirmUserDetailsModalToggle && (
          <ConfirmModalBackdrop>
            <ConfirmUserModalHolder>
              <ConfirmUserDetailsModal
                creationType={creationType}
                userData={userData}
                role={role}
                checkbox={checkbox}
                setConfirmUserDetailsModalToggle={
                  setConfirmUserDetailsModalToggle
                }
                handleSubmit={handleSubmit}
              />
            </ConfirmUserModalHolder>
          </ConfirmModalBackdrop>
        )}
        <MiddleContainerUserProfile>
          <UserPlan />
          <TabViewStyled className="mt-6">
            <TabPanel header="Account Info" headerTemplate={tabHeaderTemplate}>
              <AccountInfo />
            </TabPanel>
            {checkPermissions(currentUser?.permissions, ["control:admin"]) && (
              <TabPanel header="Admin" headerTemplate={tabHeaderTemplate}>
                <AccessControl
                  allowedPermissions={["control:admin"]}
                  renderNoAccess={() => "Access Denied"}
                >
                  <Admin
                    setDeleteModalToggle={setDeleteModalToggle}
                    users={users}
                    isUserLoading={isUserLoading}
                    setClickedUserId={setClickedUserId}
                    refetch={refetch}
                    resetAddUserDialog={resetAddUserDialog}
                  />
                </AccessControl>
              </TabPanel>
            )}
            {checkPermissions(currentUser?.permissions, ["control:admin"]) && (
              <TabPanel
                header="Rapid Response Program"
                headerTemplate={tabHeaderTemplate}
              >
                <AccessControl
                  allowedPermissions={["control:admin"]}
                  renderNoAccess={() => "Access Denied"}
                >
                  <RRP />
                </AccessControl>
              </TabPanel>
            )}
          </TabViewStyled>
        </MiddleContainerUserProfile>
      </Holder>
    </PageTransition>
  )
}

export default UserProfile

const MiddleContainerUserProfile = styled(MiddleContainer)`
  min-height: calc(100vh - 12.5em);
  padding-top: 13rem;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${PatternImage});
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.6;
  }
`
const Holder = styled.div`
  background: linear-gradient(180deg, #dde4ec 0%, rgba(255, 255, 255, 0) 100%);
  position: relative;
`

const TabViewStyled = styled(TabView)`
  .p-tabview-nav {
    padding: 0.6em 1.5em;
    margin-bottom: 1em;
  }
  li {
    margin-left: 24px;
  }
  li:first-child {
    margin-left: 0;
  }
  .p-tabview-panels,
  .p-tabview-nav {
    justify-content: flex-start;
    background: transparent;
    li.p-highlight .p-tabview-nav-link {
      background: #e1e4e8;
      font-weight: 700;
      color: #122945;
    }

    li .p-tabview-nav-link {
      background: transparent;
      padding: 1.2em 1.5em;
      font-size: 14px;
    }

    .p-unselectable-text:first-child,
    .p-unselectable-text:first-child .p-tabview-nav-link {
      border-radius: 10px 0px 0px 0px;
    }

    li.p-unselectable-text:nth-child(2),
    li.p-unselectable-text:nth-child(2) .p-tabview-nav-link {
      border-radius: 0 10px 0px 0px;
    }
  }
`

const AddUserModalHolder = styled.div`
  background: white;
  z-index: 3;
  margin: auto;
  width: 40%;
  position: relative;
  padding-top: 28px;
  padding-bottom: 60px;
  border-radius: 4px;

  @media (max-width: 1600px) {
    width: 50%;
  }

  @media (max-width: 1100px) {
    width: 70%;
  }

  @media (max-width: 800px) {
    width: 95%;
  }
`

const ConfirmUserModalHolder = styled.div`
  background: white;
  z-index: 3;
  margin: auto;
  width: 30%;
  position: relative;
  padding-top: 28px;
  padding-bottom: 30px;
  border-radius: 10px;

  @media (max-width: 1600px) {
    width: 50%;
  }

  @media (max-width: 1100px) {
    width: 70%;
  }

  @media (max-width: 800px) {
    width: 95%;
  }
`

const RemoveUserModalHolder = styled.div`
  background: white;
  z-index: 3;
  margin: auto;
  width: 30%;
  position: relative;
  padding-top: 28px;
  padding-bottom: 30px;
  border-radius: 10px;

  @media (max-width: 1600px) {
    width: 50%;
  }

  @media (max-width: 1100px) {
    width: 70%;
  }

  @media (max-width: 800px) {
    width: 95%;
  }
`

const DeleteModalBackDrop = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(5px);
`

const AddUserModalBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px);
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
`

const ConfirmModalBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`
