import { useRef, useState } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { OverlayPanel } from "primereact/overlaypanel"
import { useClickAway } from "react-use"

import { eraseCookieForLogout } from "utils"
import { useStore } from "state/store"
import AvatarIcon from "assets/images/icon-avatar.svg"
import ArrowIcon from "assets/images/icon-arrow-down-white.svg"

export default function UserPopover() {
  const navigate = useNavigate()

  const currentUser = useStore((state) => state.currentUser)
  const setGeneralFeedbackDialogVisible = useStore(
    (state) => state.setGeneralFeedbackDialogVisible,
  )
  const setCurrentUser = useStore((state) => state.setCurrentUser)
  const resetStore = useStore((state) => state.resetStore)

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const menuRef = useRef(null)
  const popoverRef = useRef(null)

  const signIn = [
    {
      label: "Sign in",
      icon: "pi pi-sign-in",
      command: () => {
        navigate("/login")
      },
    },
  ]

  const signedInMenus = [
    {
      label: "Profile",
      icon: (
        <svg
          width="16"
          height="21"
          viewBox="0 0 16 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 21C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13C10.1217 13 12.1566 13.8429 13.6569 15.3431C15.1571 16.8434 16 18.8783 16 21H14C14 19.4087 13.3679 17.8826 12.2426 16.7574C11.1174 15.6321 9.5913 15 8 15C6.4087 15 4.88258 15.6321 3.75736 16.7574C2.63214 17.8826 2 19.4087 2 21H0ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10Z"
            fill="#1A1F2C"
          />
        </svg>
      ),
      command: () => {
        navigate("/profile")
      },
    },
    {
      label: "Submit Feedback",
      icon: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.564 13L0 15.8V1.00001C0 0.787839 0.0842854 0.584356 0.234315 0.434327C0.384344 0.284298 0.587827 0.200012 0.8 0.200012H15.2C15.4122 0.200012 15.6157 0.284298 15.7657 0.434327C15.9157 0.584356 16 0.787839 16 1.00001V12.2C16 12.4122 15.9157 12.6157 15.7657 12.7657C15.6157 12.9157 15.4122 13 15.2 13H3.564ZM3.0104 11.4H14.4V1.80001H1.6V12.508L3.0104 11.4ZM7.2 5.80001H8.8V7.40001H7.2V5.80001ZM4 5.80001H5.6V7.40001H4V5.80001ZM10.4 5.80001H12V7.40001H10.4V5.80001Z"
            fill="#1A1F2C"
          />
        </svg>
      ),
      command: () => {
        setGeneralFeedbackDialogVisible(true)
      },
    },
    {
      label: "Changelog",
      icon: (
        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.81818 4.25565L10.1818 14.4375L12.8429 8.22729H16V6.77274H11.8844L10.1818 10.7444L5.81818 0.562561L3.15709 6.77274H0V8.22729H4.11564L5.81818 4.25565Z"
            fill="#1A1F2C"
          />
        </svg>
      ),
      id: "whats_new_link",
      command: () => {},
    },
    {
      label: "Logout",
      icon: (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.842105 16.921C0.618765 16.921 0.404572 16.8323 0.246647 16.6744C0.0887217 16.5165 0 16.3023 0 16.0789V0.921024C0 0.697684 0.0887217 0.483491 0.246647 0.325565C0.404572 0.16764 0.618765 0.0789185 0.842105 0.0789185H12.6316C12.8549 0.0789185 13.0691 0.16764 13.227 0.325565C13.385 0.483491 13.4737 0.697684 13.4737 0.921024V3.44734H11.7895V1.76313H1.68421V15.2368H11.7895V13.5526H13.4737V16.0789C13.4737 16.3023 13.385 16.5165 13.227 16.6744C13.0691 16.8323 12.8549 16.921 12.6316 16.921H0.842105ZM11.7895 11.8684V9.34208H5.89474V7.65787H11.7895V5.13155L16 8.49997L11.7895 11.8684Z"
            fill="#1A1F2C"
          />
        </svg>
      ),
      command: () => {
        eraseCookieForLogout()
        setCurrentUser(null)
        navigate("/")
        resetStore()
      },
    },
  ]

  const items = [
    ...(!currentUser ? signIn : []),
    ...(currentUser ? signedInMenus : []),
  ]

  useClickAway(popoverRef, () => {
    if (menuIsOpen) {
      setTimeout(() => {
        if (menuRef.current) {
          menuRef.current.hide()
        }
      }, 400)
    }
  })

  return (
    <>
      <Holder
        ref={popoverRef}
        onClick={(e) => {
          if (menuRef.current) {
            menuRef.current.toggle(e)
          }
        }}
      >
        <Avatar src={AvatarIcon} />
        <UserName>{currentUser?.first_name || "Anonymous"}</UserName>
        <Arrow src={ArrowIcon} menuIsOpen={menuIsOpen} />
      </Holder>
      <Menu
        ref={menuRef}
        showCloseIcon={false}
        dismissable={true}
        onShow={() => setMenuIsOpen(true)}
        onHide={() => setMenuIsOpen(true)}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            id={item.id || `popup_menu_item_${index}`}
            onClick={(e) => {
              item.command()
              if (menuRef.current) {
                menuRef.current.hide()
              }
            }}
          >
            {typeof item.icon === "string" ? (
              <i className={item.icon} />
            ) : (
              item.icon
            )}
            <span>{item.label}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const Holder = styled.div`
  display: flex;
  align-items: center;
  border-radius: 1em;
  background-color: #7d8a9a;
  padding: 0.2em;
  cursor: pointer;
  transition: all 0.2s ease-out;
  :hover {
    background-color: #6d7a8a;
  }
`

const Avatar = styled.img``

const UserName = styled.div`
  color: #fff;
  margin: 0 0.5em;
`

const Arrow = styled.img`
  margin-right: 0.5em;
  transform: ${(props) =>
    props.menuIsOpen ? "rotate(-180deg)" : "rotate(0deg)"};
  transition: all 0.1s ease-in-out;
`

const Menu = styled(OverlayPanel)`
  .p-overlaypanel-content {
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 99999999;
  }
`
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  position: relative;
  padding: 0.7em;
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  transition: all 0.2s ease-out;
  :hover {
    background-color: #e5e5e5;
  }
`
