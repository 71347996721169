import { memo, useState, useEffect } from "react"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"

import { useStore } from "state/store"
import { RRP_KEYS, RRP_PRODUCT_TYPE } from "dataset/rrp"
import { formatDateString, strToFileSize } from "utils"
import PaginatorTemplate from "components/PaginatorTemplate"
import { H42 } from "components/Labels"
import ProgressLoader from "components/ProgressLoader"
import RRPActionElement from "./RRPActionElement"

function RRPDataTable({ value, isLoading, editable = false }) {
  const setRrpPermissionDialogVisible = useStore(
    (state) => state.setRrpPermissionDialogVisible,
  )
  const rrpDialogData = useStore((state) => state.rrpDialogData)
  const setRrpDialogData = useStore((state) => state.setRrpDialogData)
  const setDataTablePagination = useStore(
    (state) => state.setDataTablePagination,
  )

  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(10)

  const paginatorTemplate = PaginatorTemplate()

  const columns = [
    {
      field: "file_name",
      header: "Name",
      style: { width: "50%" },
      sortable: true,
      body: (rowData) => {
        return <span>{rowData.file_name}</span>
      },
    },
    {
      field: "product_type",
      header: "Product Type",
      style: { width: "15%" },
      sortable: true,
      body: (rowData) => {
        return (
          <H42>
            {RRP_PRODUCT_TYPE.find((d) => d.value === rowData.product_type)
              ?.label || ""}
          </H42>
        )
      },
    },
    {
      field: "upload_date",
      header: "Upload Date",
      style: { width: "15%" },
      sortable: true,
      body: (rowData) => {
        return <H42>{formatDateString(rowData.upload_date)}</H42>
      },
    },
    {
      field: "file_size",
      header: "Size",
      style: { width: "15%" },
      sortable: true,
      body: (rowData) => {
        return <H42>{strToFileSize(rowData.file_size.toString())}</H42>
      },
    },
    {
      field: "s3_path",
      header: "",
      style: { width: "15%" },
      sortable: false,
      body: (rowData) => {
        return <RRPActionElement data={rowData} />
      },
    },
  ]

  const onPage = (event) => {
    setFirst(event.first)
    setRows(event.rows)
    setDataTablePagination(true)
  }

  const handleRowClick = (e) => {
    setRrpPermissionDialogVisible(true)
    setRrpDialogData(e.value)
  }

  useEffect(() => {
    if (rrpDialogData && value) {
      const _rrpDialogData = value.find(
        (d) => d[RRP_KEYS.id] === rrpDialogData[RRP_KEYS.id],
      )
      setRrpDialogData(_rrpDialogData)
    }
  }, [value])

  if (isLoading) {
    return <ProgressLoader />
  }

  return (
    <DataTable
      value={value}
      paginator
      paginatorTemplate={paginatorTemplate}
      first={first}
      rows={rows}
      onPage={onPage}
      rowsPerPageOptions={[5, 10, 25, 50]}
      selectionMode={editable ? "single" : undefined}
      onSelectionChange={handleRowClick}
      sortField={RRP_KEYS.upload_date}
      sortOrder={-1}
    >
      {columns.map((column, index) => (
        <Column key={index} {...column} />
      ))}
    </DataTable>
  )
}

export default memo(RRPDataTable)
