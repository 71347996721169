import PageTransition from "components/PageTransition"
import styled from "styled-components"
import Banner from "./components/Banner/Banner"
import ImageryLibrary from "./components/ImageryLibrary/ImageryLibrary"
import DataBase from "./components/VesselDatabase/Database"
import MaritimeMap from "./components/MaritimeMap/Maritimemap"
import OilShipmentTracker from "./components/OilShipmentTracker/OilShipmentTracker"

export default function Home() {
  return (
    <PageTransition>
      <Holder>
        <Banner />
        <DataBase className="database" />
        <ImageryLibrary />
        <MaritimeMap />
        <OilShipmentTracker />
      </Holder>
    </PageTransition>
  )
}

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 120vh;
  color: black;
`
