import { useEffect, useState } from "react"
import styled from "styled-components"
import { useStore } from "state/store"
import { device } from "theme/device"

export default function LaunchNotesWidget() {
  const currentUser = useStore((state) => state.currentUser)

  const [currentScrollPos, setCurrentScrollPos] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setCurrentScrollPos(window.pageYOffset)
    }
    window.addEventListener("scroll", handleScroll)
  }, [])

  if (currentUser === null) {
    return null
  }

  return (
    <Holder
      scrollpos={currentScrollPos}
      id="launchnotes-widget"
      dangerouslySetInnerHTML={{
        __html: `
      <launchnotes-embed
        token="${process.env.REACT_APP_LAUNCHNOTES_TOKEN}"
        project="${process.env.REACT_APP_LAUNCHNOTES_PROJECT}"
        toggle-selector="#whats_new_link"
        heading="Product Updates"
        heading-color="#FFF"
        subheading="The latest features, improvements, and bug fixes"
        subheading-color="rgba(255, 255, 255, 0.8)"
        primary-color="#225b7b"
        widget-placement="top-end"
        widget-offset-skidding="0"
        widget-offset-distance="0"
        show-unread=true
        anonymous-user-id="${currentUser.id}"
        unread-placement="right-start"
        unread-offset-skidding="-4"
        unread-offset-distance="4"
        unread-background-color="#0080ff"
        unread-text-color="#ffffff"
        ></launchnotes-embed>`,
      }}
    ></Holder>
  )
}

const Holder = styled.div`
  position: absolute;
  top: calc(4.5em - ${(props) => props.scrollpos}px);
  left: calc(50vw + 20em);
  z-index: 99999;
  @media ${device.laptop} {
    left: calc(50vw + 10em);
  }
  @media ${device.pad} {
    left: 3em;
  }
  @media ${device.mobileL} {
    left: 1em;
  }
`
