import React, { memo } from "react"
import styled from "styled-components"

import { formatDateString } from "utils"
import { LINKED_IDENTITY_KEYS } from "dataset/linkedIdentity"

import { H17 } from "components/Labels"
import InfoBadge from "components/Badges/InfoBadge"
import AmountBadge from "components/Badges/AmountBadge"
import PaginateDataTable from "../PaginateDataTable"

function KnownCoverIdentity(props) {
  const { data, loading } = props

  const formatData = (records = []) => {
    let arr = records.map((record) => {
      return {
        ...record,
        date_first_observed: formatDateString(record?.date_first_observed),
        date_last_observed: formatDateString(record?.date_last_observed),
      }
    })

    arr.forEach((item) => {
      for (var key in item) {
        if (item[key] === null) {
          item[key] = "N\\A"
        }
      }
    })

    return arr
  }

  const sourceBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData[LINKED_IDENTITY_KEYS.source_link] === "N\\A" ? (
          <span>{rowData[LINKED_IDENTITY_KEYS.source]}</span>
        ) : (
          <a
            target="_blank"
            href={rowData[LINKED_IDENTITY_KEYS.source_link]}
            rel="noreferrer"
          >
            {rowData[LINKED_IDENTITY_KEYS.source]}
          </a>
        )}
      </React.Fragment>
    )
  }

  const Columns = [
    {
      field: LINKED_IDENTITY_KEYS.date_first_observed,
      header: "Date first observed",
      width: "20%",
    },
    {
      field: LINKED_IDENTITY_KEYS.date_last_observed,
      header: "Date last observed",
      width: "20%",
    },
    {
      field: LINKED_IDENTITY_KEYS.name,
      header: "Name",
      width: "15%",
    },
    {
      field: LINKED_IDENTITY_KEYS.imo,
      header: "IMO",
      width: "15%",
    },
    {
      field: LINKED_IDENTITY_KEYS.mmsi,
      header: "MMSI",
      width: "15%",
    },
    {
      field: LINKED_IDENTITY_KEYS.source,
      header: "SOURCE",
      width: "15%",
      body: sourceBodyTemplate,
    },
  ]

  const formatted = formatData(data)

  return (
    <Holder id="anchor_profile_cover_identities">
      <div className="flex gap-1 align-items-center justify-content-between">
        <div className="flex gap-1 align-items-center">
          <H17>Known Cover Identities</H17>
          <InfoBadge label="Known Cover Identities" />
        </div>
        <AmountBadge>{`${formatted.length} Identities`}</AmountBadge>
      </div>
      <PaginateDataTable
        value={formatted}
        columns={Columns}
        responsiveLayout="scroll"
        loading={loading}
      />
    </Holder>
  )
}

export default memo(KnownCoverIdentity)

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  min-height: 80vh;
  scroll-margin-top: 20px;
`
