import { memo, useEffect, useState } from "react"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import styled from "styled-components"
import { usePostHog } from 'posthog-js/react'

import { useStore } from "state/store"
import { DatePicker } from "components/Form"

function DatePickerDialog({ start, end, visible, onHide, setIsFiltered }) {
  const posthog = usePostHog()
  const filterStartDate = useStore((state) => state.filterStartDate)
  const setFilterStartDate = useStore((state) => state.setFilterStartDate)
  const filterEndDate = useStore((state) => state.filterEndDate)
  const setFilterEndDate = useStore((state) => state.setFilterEndDate)

  const [startDate, setStartDate] = useState(filterStartDate)
  const [endDate, setEndDate] = useState(filterEndDate)

  const handleApply = () => {
    if (filterStartDate !== startDate) {
      setFilterStartDate(startDate)
      setIsFiltered(true)
    }
    if (filterEndDate !== endDate) {
      setFilterEndDate(endDate)
      setIsFiltered(true)
    }

    posthog.capture("Image searched")

    onHide()
  }

  const handleReset = () => {
    setStartDate(start)
    setEndDate(end)
  }

  useEffect(() => {
    if (visible) {
      setStartDate(filterStartDate)
      setEndDate(filterEndDate)
    }
  }, [visible])

  return (
    <Holder visible={visible} onHide={onHide} header="Date Filter">
      <Content>
        <DateRange>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            inline
            calendarClassName="gac-datepicker-inline"
          />
          <Divider />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            inline
            calendarClassName="gac-datepicker-inline"
            minDate={startDate}
          />
        </DateRange>
        <Footer>
          <Button
            className="p-button p-button-secondary"
            text
            onClick={handleReset}
            label="Reset"
          />
          <Button
            className="p-button p-button-primary"
            onClick={handleApply}
            label="Apply"
          />
        </Footer>
      </Content>
    </Holder>
  )
}

export default memo(DatePickerDialog)

const Holder = styled(Dialog)`
  min-width: 300px;
  width: 50vw;
  max-width: 730px;
  .p-dialog-content {
    padding: 1em;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`

const DateRange = styled.div`
  display: flex;
  align-items: center;
`

const Divider = styled.div`
  width: 2px;
  height: 200px;
  background: #f4f4f4;
  margin: 0 1em;
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.8em 0.8em 0 0.8em;
  width: 100%;
  gap: 1em;
`
