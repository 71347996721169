import { api } from "api"

/**
 * Make request to fetch shipments
 * @returns {Object}
 */
export const getShipmentsRequest = async (params = {}) => {
  try {
    const response = await api.get(`/shipments/`, { params })
    return response
  } catch (error) {
    console.log("error", error)
    throw new Error(error?.response?.data?.detail)
  }
}

/**
 * Make request to fetch shipments
 * @returns {Object}
 */
export const getShipmentsChoicesRequest = async (params = {}) => {
  try {
    const response = await api.get(`/shipments/choices/`, { params })
    return response
  } catch (error) {
    throw new Error(error?.response?.data?.detail)
  }
}

/**
 * Make request to fetch chart data
 * @returns {Object}
 */
export const postShipmentsAggregateRequest = async (params = {}) => {
  try {
    const { data } = await api.post(`/shipments/aggregate/`, params)
    return data
  } catch (error) {
    throw new Error(error?.response?.data?.detail)
  }
}
