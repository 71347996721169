import { memo } from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { H17 } from "components/Labels"

function AnalystNotes({ identity = {} }) {
  return (
    <Container id="anchor_profile_analyst_notes">
      <Holder>
        <H17>Analyst Notes</H17>
        {!identity?.analyst_notes && "N/A"}
        {identity?.analyst_notes && (
          <ReactMarkdownStyled>{identity?.analyst_notes}</ReactMarkdownStyled>
        )}
      </Holder>
    </Container>
  )
}

export default memo(AnalystNotes)

const Container = styled.div`
  scroll-margin-top: 20px;
`

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`

const ReactMarkdownStyled = styled(ReactMarkdown)`
  font-size: 0.875em;
  font-weight: 400;
  color: #000000;
  line-height: 1.7;
  letter-spacing: 0.01em;
  opacity: 0.7;
  ul {
    padding: 1em;
  }
`
