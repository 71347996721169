import { api } from "api"
import { toast } from "react-toastify"
import { getCookie, bootUserGuiding } from "utils"
/**
 * Do Sign In
 * @param {Object} creds
 * @returns {Object}
 */
export const doSignIn = async (creds) => {
  try {
    const { data } = await api.post(`/users/login/`, creds, { noAlert: true })
    return data
  } catch (error) {
    console.log(error)
    throw new Error(error?.response?.data?.error)
  }
}

/**
 * @param {File} file
 * @returns
 */
export const createBulkUser = async (file) => {
  try {
    const formData = new FormData()
    formData.append("csvfile", file)

    //Change content-type to multipart/form-data
    api.interceptors.request.use((config) => {
      config.headers["Content-Type"] = "multipart/form-data"
      return config
    })

    const { data } = await api.post(`/users/bulk_create/`, formData)
    toast.success("Users created successfully")
    return data
  } catch (error) {
    toast.error("Error while creating users.")
    throw new Error(error?.response?.data?.error)
  }
}

/**
 * Make request to send reset password email
 * @param {string} email
 * @returns {Object}
 */
export const updatePasswordRequest = async (email) => {
  try {
    const response = await api.post(`/users/update_password/`, { email })
    return response
  } catch (error) {
    throw new Error(error?.response?.data?.detail)
  }
}

/**
 * Make request to get user details email
 * @param
 * @returns {Object}
 */
export const checkUser = async () => {
  if (
    !getCookie("gac_user_email") ||
    (!getCookie("gac_access_token") && !getCookie("gac_refresh_token"))
  ) {
    return null
  }

  try {
    const email = getCookie("gac_user_email")
    const response = await api.get(`/users/?email=${email}`)
    const users = response?.data|| []
    if (users.length > 0) {
      let user = users[0]
      user.permissions = user?.role === 0 ? ["*"] : []

      //Boot userGuide
      bootUserGuiding()

      return user
    }
    return null
  } catch (error) {
    console.error(error?.response)
    return null
    //throw new Error(error?.response?.data?.detail)
  }
}

/**
 * Make request to update user details
 * @param {string} id
 * @param {Object} data
 * @returns {Object}
 */
export const updateUser = async (data) => {
  const { id, ...restData } = data
  try {
    const response = await api.patch(`/users/${id}/`, restData)
    return response
  } catch (error) {
    throw new Error(error?.response?.data?.detail)
  }
}

/**
 * Make request to save vessel or image
 * @param {string} userId
 * @param {string} objectId
 * @param {string} objectType
 * @returns {Object}
 */
export const saveVesselOrImage = async ({
  userId,
  objectId,
  objectType = "vessels",
}) => {
  try {
    const body = {
      object_type: objectType,
      object_id: objectId,
    }
    const response = await api.post(`/users/${userId}/add_saved_object/`, body)
    return response
  } catch (error) {
    throw new Error(error?.response?.data?.detail)
  }
}

/**
 * Make request to get saved vessels/images of a user
 * @param {string} userId
 * @param {string} objectType
 * @returns {Object}
 */
export const getSavedVesselOrImage = async ({
  userId,
  objectType = "vessels",
}) => {
  try {
    const body = {
      object_type: objectType,
    }
    const response = await api.post(`/users/${userId}/get_saved_objects/`, body)
    return response
  } catch (error) {
    throw new Error(error?.response?.data?.detail)
  }
}

/**
 * Make request to save vessel or image
 * @param {string} userId
 * @param {string} objectId
 * @param {string} objectType
 * @returns {Object}
 */
export const deleteVesselOrImage = async ({
  userId,
  objectId,
  objectType = "vessels",
}) => {
  try {
    const body = {
      object_type: objectType,
      object_id: objectId,
    }
    const response = await api.post(
      `/users/${userId}/delete_saved_object/`,
      body,
    )
    return response
  } catch (error) {
    throw new Error(error?.response?.data?.detail)
  }
}
export const createSingleUser = async (creds) => {
  try {
    const { data } = await api.post(`/users/`, creds)
    return data
  } catch (error) {
    throw new Error(error?.response?.data?.error)
  }
}

export const deleteUser = async (userId) => {
  try {
    const response = await api.delete(`/users/${userId}`)
    console.log(response, "response")
    return response
  } catch (error) {
    throw new Error(error?.response?.data?.detail)
  }
}

export const toggleUser = async (userId, inputData) => {
  try {
    const response = await api.patch(`/users/${userId}`, inputData)
    return response
  } catch (error) {
    throw new Error(error?.response?.data?.detail)
  }
}
