import areaOfInterestData from "assets/images/Banner/json/areas-of-interest-animation.json"
import oilShipmentData from "assets/images/Banner/json/oil-shipment-tracker-animation.json"
import satelliteIconData from "assets/images/Banner/json/satellite-animation.json"
import vesselIconData from "assets/images/Banner/json/vessel-card-animation.json"
import areaOfInteresticonData from "assets/images/Banner/white-areas-of-interest-icon.svg"
import oilShipmenticonData from "assets/images/Banner/white-oil-shipment-icon.svg"
import satelliteIconiconData from "assets/images/Banner/white-satellite-icon.svg"
import vesselIconiconData from "assets/images/Banner/white-vessels-icon.svg"

export const targetCenterData = [
  {
    title: <>Vessel Database</>,
    content:
      "Dive into the profiles of DPRK-linked vessels of interest and the cover identities they use to evade detection",
    options: {
      loop: true,
      autoplay: false,
      animationData: vesselIconData,
      rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
    },
    icon: vesselIconiconData,
    link: "/vessel-database",
  },
  {
    title: <>Imagery Library</>,
    content:
      "Access the world’s most comprehensive database of public and commercial imagery of DPRK-linked vessels of interest",
    options: {
      loop: true,
      autoplay: false,
      animationData: satelliteIconData,
      rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
    },
    icon: satelliteIconiconData,
    link: "/imagery-library",
  },
  {
    title: <>Interactive Map</>,
    content:
      "Explore an interactive map featuring in-depth profiles on countries and areas of interest impacted by DPRK maritime sanctions evasion",
    options: {
      loop: true,
      autoplay: false,
      animationData: areaOfInterestData,
      rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
    },
    icon: areaOfInteresticonData,
    link: "/interactive-map",
  },
  {
    title: <>Oil Shipment Tracker</>,
    content:
      "Investigate DPRK oil procurement trends and shipments since October 2017 alongside linked vessels",
    options: {
      loop: false,
      autoplay: false,
      animationData: oilShipmentData,
      rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
    },
    icon: oilShipmenticonData,
    link: "/oil-shipment-tracker",
  },
]
