import { useEffect, useState } from "react"
import styled from "styled-components"
import { useMutation } from "@tanstack/react-query"
import * as am5 from "@amcharts/amcharts5"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive"
import * as am5xy from "@amcharts/amcharts5/xy"
import moment from "moment"

import { useStore } from "state/store"
import { postShipmentsAggregateRequest } from "api/shipments"
import enums from "./enum"

const parseChartSettings = (settings) => {
  let y_axis = settings?.yAxis?.toLowerCase()
  if (y_axis === "number_of_shipments") {
    y_axis = "shipments"
  } else if (y_axis === "number_of_ships") {
    y_axis = "ships"
  }
  let final = {
    x_axis: settings?.xAxis?.toLowerCase(),
    y_axis,
  }

  if (settings?.dateRange?.startDate) {
    final["shipment_from"] = settings?.dateRange?.startDate
  }

  if (settings?.dateRange?.endDate) {
    final["shipment_to"] = settings?.dateRange?.endDate
  }

  if (settings.yAxis === "VOLUME") {
    final["volume"] = settings?.volumeRange
  }
  return final
}

const parseChartData = (rawData = []) => {
  if (rawData.length === 0) return []
  let final = rawData.map(({ date, y_axis_value }) => {
    if (date && y_axis_value) {
      const timestamp = moment(date).utc().valueOf()
      return {
        value: Math.round(y_axis_value),
        date: timestamp,
      }
    }
    return null
  })

  final = final.filter((v) => v)
  return final.length > 0 ? final : rawData
}

export default function GACBarChart() {
  const [show, setShow] = useState(true)
  const chartSettings = useStore((state) => state.chartSettings)
  const chartParams = parseChartSettings(chartSettings)
  const [chartData, setChartData] = useState([])
  const { chartFilterDialogVisible } = useStore((state) => state)
  const { mutate: getChartDataMutate, isLoading: chartIsLoading } = useMutation(
    postShipmentsAggregateRequest,
    {
      onSuccess: (data) => {
        if (data) {
          const parsedData = parseChartData(data)
          setChartData(parsedData)
        } else {
          setChartData([])
        }
      },
    },
  )

  const {
    xAxis: xAxisValue,
    yAxis: yAxisValue,
    volumeRange: volumeRangeValue,
  } = chartSettings

  useEffect(() => {
    const chartParams = parseChartSettings(chartSettings)
    let y_axis = chartSettings?.yAxis?.toLowerCase()
    if (y_axis === "number_of_shipments") {
      setShow(false)
    } else if (y_axis === "number_of_ships") {
      setShow(false)
    }

    getChartDataMutate(chartParams)
    // setShow(false)
  }, [chartSettings])

  useEffect(() => {
    /* Chart code */
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new("chartdiv")

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Responsive.new(root),
    ])

    // Set timezone
    // https://www.amcharts.com/docs/v5/getting-started/root-element/#Time_zone
    root.timezone = am5.Timezone.new("UTC")

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: !chartFilterDialogVisible,
        panY: !chartFilterDialogVisible,
        wheelX: !chartFilterDialogVisible ? "panX" : null,
        wheelY: !chartFilterDialogVisible ? "zoomX" : null,
        pinchZoomX: !chartFilterDialogVisible,
      }),
    )

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}))
    cursor.lineY.set("visible", false)
    cursor.lineX.set("visible", !chartFilterDialogVisible)

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 })
    xRenderer.grid.template.set("visible", false)
    xRenderer.labels.template.setAll({
      rotation: -90,
      //centerY: am5.p50,
      //centerX: am5.p100,
      //paddingRight: 15,
    })

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.3,
        baseInterval: {
          timeUnit: chartParams.x_axis,
          count: 1,
        },
        renderer: xRenderer,
        tooltip: chartFilterDialogVisible ? null : am5.Tooltip.new(root, {}),
      }),
    )

    xAxis.children.push(
      am5.Label.new(root, {
        text: enums[xAxisValue],
        x: am5.p50,
        centerX: am5.p50,
      }),
    )

    let yRenderer = am5xy.AxisRendererY.new(root, {})
    yRenderer.grid.template.set("visible", false)
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: yRenderer,
      }),
    )

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text:
          enums[yAxisValue] +
          "     " +
          (volumeRangeValue && volumeRangeValue > 0
            ? volumeRangeValue + "% Laden"
            : ""),
        y: am5.p50,
        centerX: am5.p50,
        color: am5.color("rgba(90, 91, 123, 0.73)"),
      }),
    )

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: chartFilterDialogVisible
          ? null
          : am5.Tooltip.new(root, {
              labelText: "{valueY}",
            }),
      }),
    )
    series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 })

    series.columns.template.set(
      "fillGradient",
      am5.LinearGradient.new(root, {
        stops: [
          {
            color: am5.color(0x122a47),
          },
          {
            color: am5.color("rgba(34, 91, 123, 0.73)"),
          },
        ],
        rotation: 90,
      }),
    )

    series.columns.template.set("stroke", false)

    xAxis.data.setAll(chartData)
    series.data.setAll(chartData)
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000)

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    if (chartParams.x_axis === "day") {
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
        }),
      )
    }

    // Create a loading indicator
    var indicator = root.container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        layer: 1000,
        background: am5.Rectangle.new(root, {
          fill: am5.color(0xffffff),
          fillOpacity: 0.7,
        }),
      }),
    )

    indicator.children.push(
      am5.Label.new(root, {
        text: "Loading...",
        fontSize: 25,
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
      }),
    )

    var hourglass = indicator.children.push(
      am5.Graphics.new(root, {
        width: 32,
        height: 32,
        fill: am5.color(0x000000),
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
        dy: -45,
        svgPath:
          "M12 5v10l9 9-9 9v10h24V33l-9-9 9-9V5H12zm20 29v5H16v-5l8-8 8 8zm-8-12-8-8V9h16v5l-8 8z",
      }),
    )

    var hourglassanimation = hourglass.animate({
      key: "rotation",
      to: 180,
      loops: Infinity,
      duration: 2000,
      easing: am5.ease.inOut(am5.ease.cubic),
    })

    if (chartIsLoading) {
      hourglassanimation.play()
      indicator.show()
    } else {
      hourglassanimation.pause()
      indicator.hide()
    }

    chart.appear(1000, 100)

    return () => {
      root.dispose()
    }
  }, [chartData, chartFilterDialogVisible])

  return (
    <Holder>
      <ChartContainer id="chartdiv"></ChartContainer>
      <HideDiv>&nbsp;</HideDiv>
    </Holder>
  )
}

const Holder = styled.section`
  position: relative;
  width: 100%;
  height: 32em;
  padding-top: 2em;
`
const ChartContainer = styled.div`
  height: 30em;
`
const HideDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 22px;
  z-index: 5;
  background-color: #fff;
`
