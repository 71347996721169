import styled from "styled-components"
import PatternImage from "assets/images/topology-pattern.svg"

export default function PageHeadContainer({ children }) {
  return <Holder>{children}</Holder>
}

const Holder = styled.div`
  position: relative;
  padding: 15em 0 3em 0;
  background: linear-gradient(180deg, #dde4ec 20%, rgba(255, 255, 255, 0) 100%);
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: url(${PatternImage});
    background-repeat: repeat;
    background-size: auto 80%;
    background-position: center;
  }
`
