import { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Column } from "primereact/column"
import { FilterMatchMode } from "primereact/api"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { FilterService } from "primereact/api"
import { useMutation } from "@tanstack/react-query"
import moment from "moment"

import { getShipmentsRequest, getShipmentsChoicesRequest } from "api/shipments"
import { useStore } from "state/store"
import { formatDateString } from "utils"

import PaginateDataTable from "../PaginateDataTable"
import Select2 from "../Select2"
import DateRangePicker from "../DateRangePicker"

import SortIcon from "assets/images/icon-sort.svg"
import FilterIcon from "assets/images/icon-dt-filter.svg"
import { usePostHog } from 'posthog-js/react'

FilterService.register("DATE_BETWEEN", (rowDate, filter, ...args) => {
  if (rowDate === "Invalid date" && !filter) return true
  if (filter) {
    const startDate = filter[0]
    const endDate = filter[1]
    if (startDate && endDate) {
      return moment(rowDate).utc().isBetween(startDate, endDate, "days", true)
    } else if (!startDate && endDate) {
      const newStartDate = moment("01/01/2000", "MM/DD/YYYY").utc()
      return moment(rowDate)
        .utc()
        .isBetween(newStartDate, endDate, "days", true)
    } else if (startDate && !endDate) {
      const currentDate = moment().utc()
      return moment(rowDate)
        .utc()
        .isBetween(startDate, currentDate, "days", true)
    }
  }

  return true
})

const orderByNumerical = [
  { label: "Sort by", value: "0" },
  { label: "Numerical sort", value: "1" },
]

const orderByAlphabetically = [
  { label: "Sort by", value: "0" },
  { label: " Alphabetically sort", value: "1" },
]

const dateBodyTemplate = (rowData) => {
  return formatDateString(rowData.date)
}

const sourceBodyTemplate = (rowData) => {
  return (
    <a
      href={rowData.source}
      target="_blank"
      rel="noreferrer"
      className="text-blue-500"
    >
      {rowData.source_name}{" "}
      <span>
        <i className="pi pi-arrow-up-right text-xs"></i>
      </span>
    </a>
  )
}

const nameBodyTemplate = (rowData) => {
  let vessels = rowData?.vessels || []
  if (vessels.length === 0) {
    return rowData?.name || "N/A"
  }
  const url = `/vessel-profile?id=${vessels[0]}`
  return (
    <Link to={url} target="_blank" rel="noreferrer" className="text-blue-500">
      {rowData.name}
    </Link>
  )
}

const parseShipments = (rawShipments = []) => {
  return rawShipments.map((shipment) => {
    return {
      date: shipment?.shipment_date
        ? moment(shipment.shipment_date).utc().valueOf()
        : 0,
      location: shipment?.location,
      name: shipment?.target_name,
      uid: shipment?.internal_id,
      imo: shipment?.target_imo,
      reg_flag: shipment?.reg_flag,
      dwt: shipment?.dwt,
      source: shipment?.source_link,
      source_name: shipment?.source,
      vessels: shipment?.vessels,
    }
  })
}

const parseFilterChoices = (choices = {}) => {
  if (Object.keys(choices).length === 0) return {}
  let final = {}
  const keyMappings = {
    location: "location",
    target_name: "name",
    internal_id: "uid",
    target_imo: "imo",
    reg_flag: "reg_flag",
    dwt: "dwt",
    source_link: "source",
    source: "source_name",
  }
  for (const col in choices) {
    if (["dwt"].includes(col)) {
      final[keyMappings[col]] = [
        Math.min(...choices[col]),
        Math.max(...choices[col]),
      ]
    } else {
      final[keyMappings[col]] = [...choices[col]]
        .filter((choice) => choice)
        .map((choice) => ({ value: choice, label: choice }))
    }
  }
  return final
}

const getAutoCompleteOptions = (shipments = []) => {
  let uids = shipments.map((ship) => {
    return ship?.uid
  })
  uids = uids.filter((item, index) => uids.indexOf(item) === index)

  let targetNames = shipments.map((ship) => {
    return ship?.name
  })
  targetNames = targetNames.filter(
    (item, index) => targetNames.indexOf(item) === index,
  )

  if (targetNames.length > 0 && uids.length > 0) {
    uids = uids.map((uid) => {
      return {
        name: uid,
        value: uid,
      }
    })
    targetNames = targetNames.map((name) => {
      return {
        name: name,
        value: name,
      }
    })
    return {
      internal_id: uids,
      target_name: targetNames,
    }
  }
  return {}
}

export default function Shipments() {
  const posthog = usePostHog()

  const setAutoCompleteOptions = useStore(
    (state) => state.setAutoCompleteOptions,
  )
  const shipmentsFilter = useStore((state) => state.shipmentsFilter)
  const chartSettings = useStore((state) => state.chartSettings)
  const setChartSettings = useStore((state) => state.setChartSettings)

  const [filters, setFilters] = useState({})
  const [selectAll, setSelectAll] = useState({})
  const [tableFilters, setTableFilters] = useState({})
  const [tableSorts, setTableSorts] = useState({})
  const [multiSortMeta, setMultiSortMeta] = useState([])

  const [Data, setData] = useState([])
  const [filterOptions, setFilterOptions] = useState({})

  const { mutate: getShipmentsMutate, isLoading: iIsLoading } = useMutation(
    getShipmentsRequest,
    {
      onSuccess: (response) => {
        if (response.data) {
          const Data = parseShipments(response.data)
          setData(Data)
          setInitialDateRange(Data)
          const options = getAutoCompleteOptions(Data)
          setAutoCompleteOptions(options)
        }
      },
    },
  )

  const { mutate: getShipmentFilterOptionsMutate, isLoading: fIsLoading } =
    useMutation(getShipmentsChoicesRequest, {
      onSuccess: (response) => {
        if (response.data) {
          const filterOptions = parseFilterChoices(response.data)
          setFilterOptions(filterOptions)
          initTableFilters()
        }
      },
    })

  useEffect(() => {
    getShipmentsMutate(shipmentsFilter)
    getShipmentFilterOptionsMutate()
  }, [shipmentsFilter])

  const clearFilter = () => {
    initTableFilters()
    initTableSorts()
    let chartSettingsClone = { ...chartSettings }
    chartSettingsClone["dateRange"] = { startDate: null, endDate: null }
    setChartSettings(chartSettingsClone)
  }

  useEffect(() => {
    const { dateRange = {} } = chartSettings
    let { startDate = null, endDate = null } = dateRange || {}

    if (startDate) {
      startDate = moment(startDate, "YYYY-MM-DD").utc().toDate()
    }
    if (endDate) {
      endDate = moment(endDate, "YYYY-MM-DD").utc().toDate()
    }

    let tableFiltersCopy = { ...tableFilters }
    tableFiltersCopy["date"] =
      !startDate && !endDate ? null : [startDate, endDate]

    //Prevent this if the date is set from dateTable column
    if (chartSettings?.from === "popup") {
      setTableFilters(tableFiltersCopy)
    }

    if (startDate && endDate) {
      let filtersCopy = { ...filters }
      filtersCopy["date"] = {
        ...filtersCopy["date"],
        value: tableFiltersCopy["date"],
      }
      setFilters(filtersCopy)
    }
  }, [chartSettings])

  const resetColFilterOptions = (col) => {
    const isRange = col === "dwt"
    const isDateRange = col === "date"
    let tableFiltersClone = { ...tableFilters }
    if (isRange) {
      const [dwtMin, dwtMax] = filterOptions[col] || [0, 2000]
      tableFiltersClone[col] = { min: dwtMin, max: dwtMax }
    } else if (isDateRange) {
      tableFiltersClone[col] = null
    } else {
      tableFiltersClone[col] = {
        value: filterOptions[col],
      }
    }
    setTableFilters(tableFiltersClone)
  }

  const initTableSorts = () => {
    setTableSorts({
      date: "0",
      location: "0",
      name: "0",
      uid: "0",
      imo: "0",
      reg_flag: "0",
      dwt: "0",
    })
    setMultiSortMeta([])
  }

  const initTableFilters = () => {
    setFilters({
      date: { value: null, matchMode: "DATE_BETWEEN" },
      location: { value: null, matchMode: FilterMatchMode.IN },
      name: { value: null, matchMode: FilterMatchMode.IN },
      uid: { value: null, matchMode: FilterMatchMode.IN },
      imo: { value: null, matchMode: FilterMatchMode.IN },
      reg_flag: { value: null, matchMode: FilterMatchMode.IN },
      dwt: { value: null, matchMode: FilterMatchMode.BETWEEN },
    })
    let tableFiltersClone = { ...tableFilters }
    const [dwtMin, dwtMax] = filterOptions.dwt || [0, 2000]
    tableFiltersClone["dwt"] = { min: dwtMin, max: dwtMax }

    tableFiltersClone["location"] = {
      value: filterOptions.location,
    }
    tableFiltersClone["name"] = {
      value: filterOptions.name,
    }
    tableFiltersClone["uid"] = {
      value: filterOptions.uid,
    }
    tableFiltersClone["imo"] = {
      value: filterOptions.imo,
    }
    tableFiltersClone["reg_flag"] = {
      value: filterOptions.reg_flag,
    }
    tableFiltersClone["date"] = null

    setTableFilters(tableFiltersClone)
    setSelectAll({
      location: true,
      name: true,
      uid: true,
      imo: true,
      reg_flag: true,
    })
  }

  const handleSort = (e, col) => {
    let tableSortsCopy = { ...tableSorts }
    tableSortsCopy[col] = e.value
    setTableSorts(tableSortsCopy)
  }

  const SortBodyTemplate = ({ columnName }) => {
    if (
      columnName === "location" ||
      columnName === "name" ||
      columnName === "uid" ||
      columnName === "reg_flag"
    ) {
      return (
        <SortDropDown
          className="sort-dd"
          value={tableSorts[columnName] || "0"}
          options={orderByAlphabetically}
          onChange={(e) => handleSort(e, columnName)}
          placeholder="Sort by"
          dropdownIcon=""
        />
      )
    } else {
      return (
        <SortDropDown
          className="sort-dd"
          value={tableSorts[columnName] || "0"}
          options={orderByNumerical}
          onChange={(e) => handleSort(e, columnName)}
          placeholder="Sort by"
          dropdownIcon=""
        />
      )
    }
  }

  const handleFilterDateChange = (options, value) => {
    let tableFiltersCopy = { ...tableFilters }
    tableFiltersCopy["date"] = value
    setTableFilters(tableFiltersCopy)

    options.filterCallback(value)
  }

  const filterDateBodyTemplate = (options) => {
    return (
      <>
        <SortBodyTemplate columnName={"date"} />
        <Separator />
        <DateRangePicker
          id="range"
          className="mt-3 flex flex-column gap-1"
          value={tableFilters?.date || null}
          onChange={(value) => handleFilterDateChange(options, value)}
        />
      </>
    )
  }

  const handleFilterRangeChange = (options, col, val, field) => {
    let tableFiltersCopy = { ...tableFilters }
    tableFiltersCopy[col] = { ...tableFilters[col], [field]: val }
    setTableFilters(tableFiltersCopy)
    options.filterCallback([
      tableFiltersCopy[col].min || 0,
      tableFiltersCopy[col].max || 5000,
    ])
  }

  const filterRangeFilterBodyTemplate = (options, col) => {
    return (
      <>
        <SortBodyTemplate columnName={"dwt"} />
        <Separator />
        <div className="flex align-items-center mt-3 gap-2">
          <div className="flex-grow-1 max-w-6rem">
            <span className="text-black-alpha-90">Minimum</span>
            <InputTextStyled
              type="number"
              onChange={(e) =>
                handleFilterRangeChange(options, col, e.target.value, "min")
              }
              value={tableFilters?.[col]?.min}
              className="p-inputtext-sm mt-1"
            />
          </div>
          <span className="flex-shrink-0 font-bold text-black-alpha-90 relative mt-4 text-xs">
            <i className="pi pi-minus"></i>
          </span>
          <div className="flex-grow-1 max-w-6rem">
            <span className="text-black-alpha-90">Maximum</span>
            <InputTextStyled
              type="number"
              onChange={(e) =>
                handleFilterRangeChange(options, col, e.target.value, "max")
              }
              value={tableFilters?.[col]?.max}
              className="p-inputtext-sm mt-1"
            />
          </div>
        </div>
      </>
    )
  }

  const handleFilterOptionChange = (value, col, options = null) => {
    let tableFiltersCopy = { ...tableFilters }
    tableFiltersCopy[col] = { ...tableFilters[col], value }
    setTableFilters(tableFiltersCopy)

    let newSelectAll = { ...selectAll }
    newSelectAll[col] = value.length === filterOptions[col].length
    setSelectAll(newSelectAll)

    if (options) {
      let optionValues = value.map((v) => v.value)
      options.filterCallback(optionValues)
    }
  }

  const handleSelectAllFilterOptions = (e, col) => {
    let newSelectAll = { ...selectAll }
    newSelectAll[col] = e.checked
    setSelectAll(newSelectAll)
    handleFilterOptionChange(e.checked ? filterOptions[col] : [], col)
  }

  const filterBodyTemplate = (options, col) => {
    return (
      <>
        <SortBodyTemplate columnName={col} />
        <Separator />
        <div className="mt-3">
          <Select2
            className="rs-basic-single"
            classNamePrefix="rs"
            isSearchable={true}
            isClearable={false}
            name={col}
            placeholder={"Enter value"}
            options={filterOptions[col] || []}
            value={tableFilters[col]?.value || []}
            menuIsOpen={true}
            maxMenuHeight={200}
            isMulti={true}
            hideSelectedOptions={false}
            controlShouldRenderValue={false}
            onChange={(v) => handleFilterOptionChange(v, col, options)}
            selectProps={{
              selectAll: selectAll[col],
              col: col,
              onSelectAll: (e) => handleSelectAllFilterOptions(e, col),
            }}
          />
        </div>
      </>
    )
  }

  const handleColClearFilter = (col) => {
    resetColFilterOptions(col)
    setSelectAll({
      ...selectAll,
      [col]: true,
    })
    setTableSorts({
      ...tableSorts,
      [col]: "0",
    })

    if (col === "date") {
      let chartSettingsClone = { ...chartSettings }
      chartSettingsClone["dateRange"] = { startDate: null, endDate: null }
      setChartSettings(chartSettingsClone)
    }
  }

  const handleColFilterApply = (options) => {
    let sortArray = Object.keys(tableSorts).map((field) => {
      return {
        field,
        order: parseInt(tableSorts[field]),
      }
    })
    sortArray = sortArray.filter((item) => item.order !== 0)
    setMultiSortMeta(sortArray)

    if (options.field === "date") {
      let [startDate = null, endDate = null] = tableFilters["date"] || [
        null,
        null,
      ]
      if (startDate) {
        startDate = moment(startDate).utc().format("YYYY-MM-DD")
      }
      if (endDate) {
        endDate = moment(endDate).utc().format("YYYY-MM-DD")
      }
      const dateRange = { startDate, endDate }
      let chartSettingsClone = { ...chartSettings }
      chartSettingsClone["dateRange"] = dateRange
      delete chartSettingsClone["from"]
      setChartSettings(chartSettingsClone)
    }
    posthog.capture("Oil shipment table filtered")
  }

  const setInitialDateRange = (datas) => {
    const dates = datas
      .map((d) => d.date)
      .filter((d) => d > 0)
      .sort((a, b) => a - b)

    const startDate = dates[0]
    const endDate = dates[dates.length - 1]

    let tableFiltersCopy = { ...tableFilters }
    tableFiltersCopy["date"] =
      !startDate && !endDate
        ? null
        : [moment(startDate).utc().toDate(), moment(endDate).utc().toDate()]

    setTableFilters(tableFiltersCopy)
    if (startDate && endDate) {
      let filtersCopy = { ...filters }
      filtersCopy["date"] = {
        ...filtersCopy["date"],
        value: tableFiltersCopy["date"],
      }
      //setFilters(filtersCopy)
    }
  }

  const Columns = [
    {
      field: "date",
      header: "date",
      style: { width: "12.5%" },
      filter: true,
      filterPlaceholder: "Search by date",
      showFilterMatchModes: false,
      body: dateBodyTemplate,
      onFilterClear: () => handleColClearFilter("date"),
      onFilterApplyClick: handleColFilterApply,
      filterElement: filterDateBodyTemplate,
      filterMenuClassName: "dt-filter-date",
    },
    {
      field: "location",
      header: "Location",
      style: { width: "12.5%" },
      filter: true,
      filterPlaceholder: "Search by location",
      showFilterMatchModes: false,
      onFilterClear: () => handleColClearFilter("location"),
      onFilterApplyClick: handleColFilterApply,
      filterElement: (options) => filterBodyTemplate(options, "location"),
    },
    {
      field: "name",
      header: "Name",
      style: { width: "12.5%" },
      filter: true,
      filterPlaceholder: "Search by name",
      showFilterMatchModes: false,
      body: nameBodyTemplate,
      onFilterClear: () => handleColClearFilter("name"),
      onFilterApplyClick: handleColFilterApply,
      filterElement: (options) => filterBodyTemplate(options, "name"),
    },
    {
      field: "uid",
      header: "UID",
      style: { width: "12.5%" },
      filter: true,
      filterPlaceholder: "Search by UID",
      showFilterMatchModes: false,
      onFilterClear: () => handleColClearFilter("uid"),
      onFilterApplyClick: handleColFilterApply,
      filterElement: (options) => filterBodyTemplate(options, "uid"),
    },
    {
      field: "imo",
      header: "IMO",
      style: { width: "12.5%" },
      filter: true,
      filterPlaceholder: "Search by IMO",
      showFilterMatchModes: false,
      onFilterClear: () => handleColClearFilter("imo"),
      onFilterApplyClick: handleColFilterApply,
      filterElement: (options) => filterBodyTemplate(options, "imo"),
    },
    {
      field: "reg_flag",
      header: "Reg Flag",
      style: { width: "12.5%" },
      filter: true,
      filterPlaceholder: "Search by Reg flag",
      showFilterMatchModes: false,
      onFilterClear: () => handleColClearFilter("reg_flag"),
      onFilterApplyClick: handleColFilterApply,
      filterElement: (options) => filterBodyTemplate(options, "reg_flag"),
    },
    {
      field: "dwt",
      header: "DWT",
      style: { width: "12.5%" },
      filter: true,
      filterPlaceholder: "Search by DWT",
      showFilterMatchModes: false,
      onFilterClear: () => handleColClearFilter("dwt"),
      onFilterApplyClick: handleColFilterApply,
      filterElement: (options) => filterRangeFilterBodyTemplate(options, "dwt"),
    },
    {
      field: "source",
      header: "Source",
      style: { width: "8.5%" },
      body: sourceBodyTemplate,
    },
  ]

  const appliedFilters = Object.values(filters).filter((f) => f.value)
  return (
    <Holder id="anchor_profile_linked_shipments">
      <div className="flex align-items-center">
        {(appliedFilters.length > 0 || multiSortMeta.length > 0) && (
          <>
            <span>{appliedFilters.length} Filters Applied</span>
            <span
              className="pl-3 pr-3"
              style={{ color: "#D9D9D9", fontSize: "0.5em" }}
            >
              <i className="pi pi-circle-fill"></i>
            </span>
            <span>{multiSortMeta.length} Sorted Column(s)</span>
            <span
              className="pl-3"
              style={{ color: "#D9D9D9", fontSize: "0.5em" }}
            >
              <i className="pi pi-circle-fill"></i>
            </span>
            <Button
              label="Reset All"
              className="p-button-danger p-button-text"
              onClick={clearFilter}
            />
          </>
        )}
      </div>
      <PaginateDataTable
        value={Data}
        filters={filters}
        filterDisplay="menu"
        loading={iIsLoading && fIsLoading}
        globalFilterFields={[
          "date",
          "location",
          "name",
          "uid",
          "imo",
          "reg_flag",
          "dwt",
        ]}
        //header={header}
        emptyMessage="No records found."
        onFilter={(e) => setFilters(e.filters)}
        sortMode="multiple"
        multiSortMeta={[...multiSortMeta]}
        onSort={(e) => console.log("onSort", e)}
      >
        {Columns.map((columnProps, index) => (
          <Column key={index} {...columnProps} />
        ))}
      </PaginateDataTable>
    </Holder>
  )
}

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 2em;
  .p-column-filter-menu-button {
    span {
      &:before {
        content: "";
        background-image: url(${FilterIcon});
        background-repeat: no-repeat;
        background-position: center;
        width: 2em;
        height: 100%;
        position: absolute;
        top: 0;
        left: 3px;
       }
      }
    }
  }
`

const SortDropDown = styled(Dropdown)`
  background-image: none !important;
  border-bottom-left-radius: 0.375em !important;
  border-bottom-right-radius: 0.375em !important;
  .p-inputtext {
    padding: 0.4em 1em;
  }

  .p-dropdown-trigger-icon {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0, 0, 0, 0.5);
  }
  .p-dropdown-label {
    padding-left: 2.2em;
    :before {
      content: "";
      background-image: url(${SortIcon});
      background-repeat: no-repeat;
      background-position: left;
      background-size: 55%;
      width: 2em;
      height: 100%;
      position: absolute;
      top: 0;
      left: 5px;
    }
  }
`

const InputTextStyled = styled(InputText)`
  border-bottom-left-radius: 0.375em !important;
  border-bottom-right-radius: 0.375em !important;
  border: 2px solid #dfdfdf !important;
  background: none !important;
  background-image: none !important;
`

const Separator = styled.div`
  position: relative;
  height: 1.5em;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 0;
    border-bottom: 1px solid #dfdfdf;
  }
`
