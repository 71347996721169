import { H7 } from "components/Labels"
import { IMAGE_KEYS } from "dataset/image"
import { Dialog } from "primereact/dialog"
import { useStore } from "state/store"
import styled from "styled-components"
import { formatDateString, getLatLng } from "utils"

export default function ImageProfileMetaDialog() {
  const profileMetaDialogVisible = useStore(
    (state) => state.profileMetaDialogVisible,
  )

  const setProfileMetaDialogVisible = useStore(
    (state) => state.setProfileMetaDialogVisible,
  )

  const currentImage = useStore((state) => state.currentImage)

  const Header = () => {
    return (
      <div>
        <div className="flex align-items-center gap-3">
          <H7>{currentImage[IMAGE_KEYS.title]}</H7>
        </div>
      </div>
    )
  }

  const onHide = () => {
    setProfileMetaDialogVisible(false)
  }

  return (
    <Holder header={Header} visible={profileMetaDialogVisible} onHide={onHide}>
      <Content className="formgrid grid">
        <div className="field col-4">
          <Key>Date of Capture:</Key>
        </div>
        <div className="field col-8">
          <Value>{formatDateString(currentImage[IMAGE_KEYS.date_taken])}</Value>
        </div>
        <div className="field col-4">
          <Key>Date of Upload:</Key>
        </div>
        <div className="field col-8">
          <Value>{formatDateString(currentImage[IMAGE_KEYS.date_input])}</Value>
        </div>
        <div className="field col-4">
          <Key>UID:</Key>
        </div>
        <div className="field col-8">
          <Value>{currentImage[IMAGE_KEYS.internal_id]}</Value>
        </div>
        <div className="field col-4">
          <Key>Coordinates:</Key>
        </div>
        <div className="field col-8">
          <Value>{`${getLatLng(currentImage[IMAGE_KEYS.coord])}`}</Value>
        </div>
        <div className="field col-4">
          <Key>Publishing Source:</Key>
        </div>
        <div className="field col-8">
          <Value>{currentImage[IMAGE_KEYS.source]}</Value>
        </div>
        <div className="field col-4">
          <Key>Location:</Key>
        </div>
        <div className="field col-8">
          <Value>{currentImage[IMAGE_KEYS.region_aoi]}</Value>
        </div>
        <div className="field col-4">
          <Key>Sensor:</Key>
        </div>
        <div className="field col-8">
          <Value>{currentImage[IMAGE_KEYS.sensor]}</Value>
        </div>
      </Content>
    </Holder>
  )
}

const Holder = styled(Dialog)`
  min-width: 300px;
  width: 50vw;
  max-width: 700px;
  .p-dialog-content {
    border-bottom-right-radius: 0.75em;
    border-bottom-left-radius: 0.75em;
  }
`

const Content = styled.div`
  padding: 1.5em 0;
`

const Key = styled.span`
  font-size: 0.9em;
  font-weight: 700;
  color: #7a8796;
  line-height: 1.2;
  letter-spacing: 0.01em;
`

const Value = styled.span`
  font-size: 0.9em;
  font-weight: 700;
  color: #000000;
  line-height: 1.2;
  letter-spacing: 0.01em;
`
