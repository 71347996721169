import styled from "styled-components"

export default function InfoIcon({ src }) {
  return <Holder src={src} />
}

const Holder = styled.img`
  width: 1.2em;
  height: 1.2em;
  margin: 0 0.6em 0 0;
`
