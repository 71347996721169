import { useState, useEffect } from "react"
import styled from "styled-components"
import { useStore } from "state/store"
import classnames from "classnames"
import { InputText } from "primereact/inputtext"
import { Message } from "primereact/message"
import { Button } from "primereact/button"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { updateUser } from "api/auth"
import { getRoleName } from "utils"
import ResetPasswordRequestDialog from "components/ResetPasswordRequestDialog"
import { toast } from "react-toastify"

const AccountInfo = () => {
  let currentUser = useStore((state) => state.currentUser)
  const setCurrentUser = useStore((state) => state.setCurrentUser)
  currentUser =
    currentUser && Object.keys(currentUser).length > 0
      ? currentUser
      : {
          id: "",
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          organization: "",
          title: "",
          role: "",
        }

  const [userInfo, setUserInfo] = useState(currentUser)
  const [showChangePwd, setShowChangePwd] = useState(false)
  const [editable, setEditable] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const handleChange = (key, value) => {
    let newUserInfo = { ...userInfo }
    newUserInfo[key] = value
    setUserInfo(newUserInfo)
  }

  const showPopup = (e) => {
    e.preventDefault()
    setShowChangePwd(true)
  }

  const toggleEditMode = () => {
    setEditable(!editable)
  }

  useEffect(() => {
    const {
      id = "",
      first_name = "",
      last_name = "",
      email = "",
      password = "",
      organization = "",
      title = "",
      role = "",
    } = currentUser
    setUserInfo({
      id,
      first_name,
      last_name,
      email,
      password,
      organization,
      title,
      role,
    })
  }, [currentUser.id])

  useEffect(() => {
    let edited =
      userInfo.first_name !== currentUser.first_name ||
      userInfo.last_name !== currentUser.last_name
    setIsDirty(edited)
  }, [userInfo])

  const queryClient = useQueryClient()
  const mutation = useMutation(updateUser, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["userDetails"])
      setIsDirty(false)
      setEditable(false)
      setShowSuccess(true)
      setCurrentUser(null)
      setTimeout(() => {
        setShowSuccess(false)
      }, 1000)
    },
  })
  const updateUserDetails = () => {
    if (!userInfo.id || !userInfo?.first_name) return
    let firstNameError = false
    let lastNameError = false
    if (userInfo?.first_name?.length > 80) {
      toast.error("First name needs to be shortened", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      firstNameError = true
    }
    if (userInfo?.last_name?.length > 80) {
      toast.error("Last name needs to be shortened", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      lastNameError = true
    }
    if (firstNameError || lastNameError) {
      return null
    }
    let input = {
      id: userInfo?.id,
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
    }
    mutation.mutate(input)
  }

  return (
    <Container className="p-fluid grid mt-2">
      {showSuccess && (
        <div className="col-12">
          <Message
            severity="success"
            text="Details updated successfully"
            className="mb-4"
          ></Message>
        </div>
      )}
      <div className="field col-12 sm:col-6">
        <span className="p-float-label">
          <InputTextStyled
            id="first_name"
            className={classnames("w-full", {
              editable: editable,
            })}
            readOnly={!editable}
            value={userInfo?.first_name || ""}
            onChange={(e) => handleChange("first_name", e.target.value)}
          />
          <label htmlFor="first_name">First name</label>
        </span>
      </div>

      <div className="field col-12 sm:col-6">
        <span className="p-float-label">
          <InputTextStyled
            id="last_name"
            className={classnames("w-full", {
              editable: editable,
            })}
            readOnly={!editable}
            value={userInfo?.last_name || ""}
            onChange={(e) => handleChange("last_name", e.target.value)}
          />
          <label htmlFor="last_name">Last name</label>
        </span>
      </div>

      <div className="field col-12">
        <span className="p-float-label cursor-not-allowed">
          <InputTextStyled
            id="email"
            className="w-full pointer-events-none"
            type="email"
            value={userInfo?.email || ""}
            readOnly
            onChange={(e) => handleChange("email", e.target.value)}
          />
          <label htmlFor="email">Email</label>
        </span>
      </div>

      <div className="field col-12 relative">
        <span className="p-float-label">
          <InputTextStyled
            type="password"
            id="password"
            className="w-full"
            readOnly={true}
            value={userInfo?.password || "EnterYourPassword"}
            onChange={(e) => handleChange("password", e.target.value)}
          />
          <label htmlFor="password">Password</label>
        </span>
        <a className="change-password" href="#" onClick={showPopup}>
          Change Password
        </a>
      </div>

      <div className="field col-12">
        <span className="p-float-label cursor-not-allowed">
          <InputTextStyled
            id="organization"
            className="w-full pointer-events-none"
            value={userInfo?.organization || " "}
            readOnly
            onChange={(e) => handleChange("organization", e.target.value)}
          />
          <label htmlFor="organization">Organization</label>
        </span>
      </div>

      <div className="field col-12">
        <span className="p-float-label cursor-not-allowed">
          <InputTextStyled
            id="title"
            className="w-full pointer-events-none"
            value={userInfo?.title || " "}
            readOnly
            onChange={(e) => handleChange("title", e.target.value)}
          />
          <label htmlFor="title">Title</label>
        </span>
      </div>

      <div className="field col-12">
        <span className="p-float-label cursor-not-allowed">
          <InputTextStyled
            id="role"
            className="w-full pointer-events-none"
            value={getRoleName(userInfo?.role)}
            readOnly
            onChange={(e) => handleChange("role", e.target.value)}
          />
          <label htmlFor="role">Role</label>
        </span>
      </div>

      <div>
        <StyledButton
          type="submit"
          className={classnames("w-full justify-content-center", {
            "p-button-secondary": editable,
          })}
          onClick={editable ? updateUserDetails : toggleEditMode}
          icon={""}
        >
          {editable ? `Save` : `Edit`}
        </StyledButton>
      </div>

      <ResetPasswordRequestDialog
        visible={showChangePwd}
        existingEmail={currentUser?.email}
        onHide={() => setShowChangePwd(false)}
      />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  z-index: 1;
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }

  .p-float-label input:focus ~ label,
  .p-float-label input.p-filled ~ label {
    top: -1.8em !important;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.4);
  }
  label {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.4);
  }

  a.change-password {
    position: absolute;
    right: 2em;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    color: #f75151;
    font-size: 0.8em;
  }
  .cursor-not-allowed {
    cursor: not-allowed;
  }
`

const StyledButton = styled(Button)`
  padding: 0.8em;
  background: #f75151;
  line-height: 14px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

const InputTextStyled = styled(InputText)`
  border: none;
  padding: 0.9em;
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.75em;
  background: rgb(239 242 248 / 90%);
  border-radius: 6px;

  transition: all 0.5s ease;

  box-shadow: inset 0 0 0 1px #e1e4e8, inset 0 0 0 1px #e1e4e8,
    inset 0 0 0 1px #e1e4e8, inset 0 0 0 1px #e1e4e8;

  &:enabled:focus {
    box-shadow: inset 0 0 0 1px #e1e4e8, inset 0 0 0 1px #e1e4e8,
      inset 0 0 0 1px #e1e4e8, inset 0 0 0 1px #e1e4e8;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 50px #e1e4e8 inset; /* Change the color to your own background color */
  }

  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #e1e4e8 inset; /*your box-shadow*/
  }
  &[value]:not([value=""]) {
    -webkit-box-shadow: 0 0 0 50px #e1e4e8 inset; /*your box-shadow*/
  }

  &.editable {
    border: 1px solid #f75151;
  }
`

export default AccountInfo
