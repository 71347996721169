import styled from "styled-components"

export default function CircleButton({
  background = "white",
  icon,
  onClick,
  size = "3em",
}) {
  return (
    <Holder background={background} onClick={onClick} size={size}>
      <img src={icon} alt="" />
    </Holder>
  )
}

const Holder = styled.div`
  border-radius: 50%;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background: ${(props) => props.background};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease-out;
  &:hover {
    opacity: 0.7;
  }
  > img {
    width: 1.5em;
    height: 1.5em;
  }
`
