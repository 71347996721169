import { memo } from "react"
import styled from "styled-components"
import { HISTORICAL_VESSEL_KEYS } from "dataset/vessel"

function VesselItem({ data, start = true, end = true }) {
  if (!data) {
    return null
  }

  return (
    <Holder className="flex flex-column" start={start} end={end}>
      {data[HISTORICAL_VESSEL_KEYS.name]}
    </Holder>
  )
}

export default memo(VesselItem)

const Holder = styled.div`
  border-top-left-radius: ${(props) => (props.start ? "3px" : 0)};
  border-bottom-left-radius: ${(props) => (props.start ? "3px" : 0)};
  border-top-right-radius: ${(props) => (props.end ? "3px" : 0)};
  border-bottom-right-radius: ${(props) => (props.end ? "3px" : 0)};
  border-top: 1px solid #beced4;
  border-bottom: 1px solid #beced4;
  border-left: 0px solid #beced4;
  border-right: ${(props) => (props.end ? "1px solid #beced4" : "unset")};
  background-color: ${(props) => (props.start ? "#2d3f4f" : "#f05261")};
  padding: 0.5em 0.7em;
  margin-right: ${(props) => (props.end ? "1em" : 0)};
  font-size: 0.8em;
  font-weight: 100;
  color: #ffffff;
  line-height: 1;
  letter-spacing: 0.01em;
`
