import { api } from "api"

const uploadCSV = async (file, url) => {
  try {
    const { status, data } = await api.post(url, file)
    if (status === 200) {
      return true
    }
  } catch (e) {
    return false
  }
}

export { uploadCSV }
