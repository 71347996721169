import React, { memo } from "react"
import { TabView, TabPanel } from "primereact/tabview"
import styled from "styled-components"

import { useStore } from "state/store"
import SearchAutoComplete from "../components/SearchAutoComplete"
import "./index.css"

function TabSelector(props) {
  const selecteds = useStore((state) => state.selectedFilters)

  const activeIndex = props.tabIndex
  const setActiveIndex = props.setTabIndex

  const CountryIcon = (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.65683 3.3007C2.80686 4.18289 2.2865 5.3308 2.18321 6.55147C2.07992 7.77215 2.40001 8.99117 3.08967 10.0036C3.77932 11.0161 4.79651 11.7603 5.97024 12.1111C7.14397 12.4619 8.40269 12.398 9.53483 11.93C9.6115 11.43 9.4435 10.95 9.37483 10.782C9.2215 10.4087 8.71616 9.7707 7.87283 8.88603C7.6475 8.64936 7.66216 8.46736 7.74283 7.92803L7.7515 7.86736C7.80616 7.49803 7.89816 7.27936 9.1415 7.08203C9.7735 6.98203 9.9395 7.23403 10.1695 7.58403L10.2468 7.6987C10.4655 8.0187 10.6275 8.09203 10.8722 8.2027C10.9822 8.2527 11.1188 8.31603 11.3022 8.41936C11.7368 8.66803 11.7368 8.9487 11.7368 9.56336V9.63336C11.7368 9.89403 11.7115 10.1234 11.6715 10.3227C12.1831 9.68064 12.5396 8.92919 12.7132 8.12682C12.8869 7.32446 12.873 6.49285 12.6727 5.69672C12.4724 4.90059 12.0911 4.16143 11.5584 3.53679C11.0257 2.91215 10.356 2.41887 9.6015 2.09536C9.23283 2.34403 8.72683 2.6967 8.55016 2.9387C8.46016 3.06203 8.33216 3.69336 7.91683 3.74536C7.80883 3.7587 7.66283 3.74936 7.50816 3.73936C7.0935 3.7127 6.52683 3.67603 6.3455 4.1687C6.23016 4.4807 6.21016 5.3287 6.58283 5.7687C6.64283 5.8387 6.65416 5.9687 6.6135 6.1147C6.56016 6.30603 6.45283 6.4227 6.41883 6.4467C6.35483 6.40936 6.22683 6.2607 6.1395 6.16003C5.93083 5.9167 5.6695 5.61336 5.33216 5.52003C5.2095 5.48603 5.07483 5.45803 4.9435 5.43003C4.5775 5.35336 4.1635 5.26603 4.06683 5.0607C3.99616 4.91003 3.99683 4.7027 3.99683 4.48403C3.99683 4.20603 3.99683 3.89203 3.86083 3.58736C3.81355 3.47864 3.74406 3.38099 3.65683 3.3007ZM7.50016 13.6654C3.81816 13.6654 0.833496 10.6807 0.833496 6.9987C0.833496 3.3167 3.81816 0.332031 7.50016 0.332031C11.1822 0.332031 14.1668 3.3167 14.1668 6.9987C14.1668 10.6807 11.1822 13.6654 7.50016 13.6654Z"
        fill={activeIndex === 1 ? "#F8F8F8" : "black"}
      />
    </svg>
  )
  const AOIIcon = (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.02427 3.67223L12.9494 2.26105V13.9472L2.47866 10.9246L4.02427 3.67223Z"
        fill="#122A47"
        fillOpacity="0.2"
        stroke={activeIndex ? "black" : "#F8F8F8"}
      />
      <circle
        cx="12.9449"
        cy="1.77889"
        r="1.55623"
        fill={activeIndex ? "black" : "#F8F8F8"}
      />
      <circle
        cx="12.9449"
        cy="14.2242"
        r="1.55623"
        fill={activeIndex ? "black" : "#F8F8F8"}
      />
      <circle
        cx="2.05574"
        cy="11.1148"
        r="1.55623"
        fill={activeIndex ? "black" : "#F8F8F8"}
      />
      <circle
        cx="3.61629"
        cy="3.34139"
        r="1.55623"
        fill={activeIndex ? "black" : "#F8F8F8"}
      />
    </svg>
  )

  function selectAoi(counrty) {
    props.setsidebarOpen(false)
    setTimeout(() => {
      props.setsidebarOpen(true)
    }, 700)
    if (counrty.aoi_polygon !== null) {
      props.changeMapAOIPosition(counrty.aoi_polygon.coordinates[0][0])
    } else {
      props.changeMapAOIPosition(counrty.aoi_point.coordinates)
    }
    props.selectAOI(counrty)
  }
  function selectCountry(country) {
    if (props.sidebarOpen) {
      props.setsidebarOpen(false)
      setTimeout(() => {
        props.setsidebarOpen(true)
      }, 700)
    }
    props.changeMapCountryPosition(country)
  }

  function changeTab(index) {
    setActiveIndex(index)
    props.tabChange(index)
  }

  return (
    <div>
      <Holder
        className="interactive-map-tab-selector"
        style={{
          zIndex: 5999,
          marginTop: "12px",
          padding: "10px",
          background: "#D1D0D0",
        }}
      >
        <TabView
          style={{ borderRadius: "50px" }}
          activeIndex={activeIndex}
          onTabChange={(e) => changeTab(e.index)}
        >
          <TabPanel
            headerStyle={{
              width: "100%",
              padding: "6px",
            }}
            header="AOI"
            leftIcon={AOIIcon}
          ></TabPanel>
          <TabPanel
            headerStyle={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              borderRadius: "50px",
            }}
            header="Country"
            id="mmk"
            leftIcon={CountryIcon}
            style={{ position: "relative" }}
          ></TabPanel>
        </TabView>
      </Holder>
      <div className="my-3 ">
        {activeIndex === 1 ? (
          <SearchAutoComplete
            legend={false}
            name="country"
            onChangeCountry={selectCountry}
          />
        ) : (
          <SearchAutoComplete
            totalFilter={
              selecteds.filter((i) => i.value === true).length > 0
                ? selecteds.filter((i) => i.value === true).length
                : undefined
            }
            name="aoi"
            onChangeCountry={selectAoi}
            legend={true}
          />
        )}
      </div>
    </div>
  )
}

export default memo(TabSelector)

const Holder = styled.div`
  border-radius: 150px;
  width: 100%;
`
