import { Fragment, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { Button } from "primereact/button"

import { PrivateRoutes } from "routes"
import { device } from "theme/device"
import { useStore } from "state/store"
import NavMenuButton from "components/NavMenuButton"
import { MiddleContainer } from "components/Containers"
import FeedbackDialog from "./FeedbackDialog"
import UserPopover from "./UserPopover"
import NavItemDesktop from "./NavItemDesktop"
import LogoIcon from "assets/images/arion-logo-white.svg"

export default function Navbar() {
  const navigate = useNavigate()
  const location = useLocation()
  const isPadScreen = useMediaQuery({ query: device.pad })

  const menuVisible = useStore((state) => state.menuVisible)
  const setMenuVisible = useStore((state) => state.setMenuVisible)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <Holder isTransparent={location.pathname === "/"}>
      <FeedbackDialog />
      <MiddleContainer>
        <FlexHolder>
          <a href="/" target="_parent" rel="noreferrer">
            <Logo alt="logo" src={LogoIcon} />
          </a>
          {isPadScreen ? (
            <>
              <NavMenuButton />
              <AnimatePresence>
                {menuVisible && (
                  <NavMobile
                    transition={{ duration: 0.4 }}
                    initial={{ x: "-100%" }}
                    animate={{ x: 0 }}
                    exit={{ x: "-100%" }}
                  >
                    <SmallLogo alt="logo" src={LogoIcon} />
                    <CloseButton
                      icon="pi pi-times"
                      className="p-button-rounded p-button-text p-button-icon-only"
                      onClick={() => {
                        setMenuVisible(false)
                      }}
                    />
                    {PrivateRoutes.map((route) => {
                      return (
                        <Fragment key={`mobile-nav-${route.path}`}>
                          <NavItemMobile
                            key={`mobile-nav-item-${route.path}`}
                            selected={route.path === location.pathname}
                            onClick={() => {
                              if (route.path?.includes("https://")) {
                                window.open(route.path, "_blank")
                                return
                              }

                              navigate(route.path)
                              setMenuVisible(false)
                            }}
                          >
                            {route.title}
                          </NavItemMobile>
                          {route.children.map((child) => (
                            <NavItemMobile
                              key={`mobile-nav-sub-item-${child.path}`}
                              selected={child.path === location.pathname}
                              className="pl-6"
                              onClick={() => {
                                if (child.path?.includes("https://")) {
                                  window.open(child.path, "_blank")
                                  return
                                }

                                navigate(child.path)
                                setMenuVisible(false)
                              }}
                            >
                              - {`${child.title}`}
                            </NavItemMobile>
                          ))}
                        </Fragment>
                      )
                    })}
                  </NavMobile>
                )}
              </AnimatePresence>
            </>
          ) : (
            <Nav>
              {PrivateRoutes.map((route) => {
                if (!route.isPrimaryRoute) {
                  return null
                }

                return (
                  <NavItemDesktop
                    key={`desktop-nav-item-${route.path}`}
                    path={route.path}
                    title={route.title}
                    children={route.children}
                  />
                )
              })}
              <UserPopover />
            </Nav>
          )}
        </FlexHolder>
      </MiddleContainer>
    </Holder>
  )
}

const Holder = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: inherit;
  background: ${(props) =>
    props.isTransparent
      ? "transparent"
      : "linear-gradient(89.37deg,rgba(18, 42, 71, 1) -0.74%,#225b7b 85.93%)"};
`

const FlexHolder = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  @media ${device.pad} {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 1em;
  }
`

const Logo = styled.img`
  height: 2.5em;
  margin: 1em;
`

const SmallLogo = styled.img`
  padding-left: 5vw;
  width: 35vw;
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 1.3em;
  right: 1em;
  z-index: 1;
`

const Nav = styled.ul`
  display: flex;
  align-items: center;
  width: 67%;
  justify-content: space-between;
`

const NavMobile = styled(motion.nav)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5em;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 30%;
  bottom: 0;
  padding: 2em 1em 2em 0;
  background-color: #132b44;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.75);
`
const NavItemMobile = styled.div`
  color: ${({ selected }) => (selected ? "#f2f2f2" : "#7B8694")};
  font-weight: ${({ selected }) => (selected ? 700 : 100)};
  font-size: 1.5em;
  width: 100%;
  padding: 0.5em 1em;
  text-align: left;
  border-left: ${({ selected }) =>
    selected ? "5px solid #f43f53" : "5px solid #ffffff00"};
  transition: all 300ms ease-out;
  cursor: pointer;
  @media ${device.mobileL} {
    font-size: 1em;
  }
`
