import { memo, useState } from "react"
import styled from "styled-components"
import { isArray } from "@amcharts/amcharts5/.internal/core/util/Type"
import { TabPanel, TabView } from "primereact/tabview"
import { Button } from "primereact/button"

import { uploadCSV } from "api/admin"
import UploadFile from "./UploadFile"
import SingleAccountConfirmDialog from "./SingleAccountConfirmDialog"
import BulkAccountConfirmDialog from "./BulkAccountConfirmDialog"
import UserDataTable from "./UserDataTable"

const Admin = ({
  setDeleteModalToggle,
  users,
  isUserLoading,
  setClickedUserId,
  refetch,
  resetAddUserDialog,
}) => {
  const [uploadItems, setUploadItems] = useState([
    {
      id: 1,
      title: "Upload Vessel CSV",
      url: "/vessels/csvupload/ ",
    },
    {
      id: 2,
      title: "Upload Linked Identities CSV",
      url: "/linked-identities/csvupload/",
    },
    {
      id: 3,
      title: "Upload Imagery CSV",
      url: "/images/csvupload/",
    },
    {
      id: 4,
      title: "Upload AOI CSV",
      url: "/aoi/csvupload/",
    },
    {
      id: 5,
      title: "Upload Countries CSV",
      url: "/countries/csvupload/",
    },
    {
      id: 7,
      title: "Upload Shipments CSV",
      url: "/shipments/csvupload/",
    },
    {
      id: 8,
      title: "Upload Crew Members CSV",
      url: "/crew-members/csvupload/",
    },
  ])
  const [bulkAccounts, setBulkAccounts] = useState([])
  const [bulkCsvFile, setBulkCsvFile] = useState()
  const setChangedFile = (file, item) => {
    const index = uploadItems.indexOf(item)
    item.file = file
    let data = uploadItems
    data[index] = item
    setUploadItems(data)
  }

  const uploadAll = () => {
    let data = uploadItems.filter((item) => item.file !== undefined)
    data.map(async (item) => {
      const i = await uploadCSV(item.file, item.url)
      console.log(i)
    })
  }

  const tabHeaderTemplate = (options) => {
    return (
      <button
        type="button"
        onClick={options.onClick}
        className={options.className}
      >
        {options.titleElement}
      </button>
    )
  }

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [organization, setOrganization] = useState("")
  const [title, setTitle] = useState("")
  const [userType, setUserType] = useState(0)
  const [openSingleAccountConfirmModal, setOpenSingleAccountConfirmModal] =
    useState(false)
  const [openBulkAccountConfirmModal, setOpenBulkAccountConfirmModal] =
    useState(false)

  return (
    <Container>
      <TabViewStyled className="w-full">
        <TabPanel header="Users" headerTemplate={tabHeaderTemplate}>
          <UserDataTable
            value={users}
            isLoading={isUserLoading}
            setDeleteModalToggle={setDeleteModalToggle}
            setClickedUserId={setClickedUserId}
            refetch={refetch}
            resetAddUserDialog={resetAddUserDialog}
          />
        </TabPanel>

        <TabPanel header="CSV Upload" headerTemplate={tabHeaderTemplate}>
          {isArray(uploadItems) &&
            uploadItems.map((item) => (
              <UploadFileContainer key={item.id}>
                <UploadFile
                  key={item.id}
                  setFile={(file) => setChangedFile(file, item)}
                  data={item}
                />
              </UploadFileContainer>
            ))}

          <Button
            label="Upload"
            className="p-button p-button-primary"
            onClick={() => uploadAll()}
          />
        </TabPanel>
      </TabViewStyled>
      <SingleAccountConfirmDialog
        firstName={firstName}
        lastName={lastName}
        emailAddress={emailAddress}
        role={userType}
        organization={organization}
        userType={userType}
        title={title}
        visible={openSingleAccountConfirmModal}
        onHide={() => {
          setOpenSingleAccountConfirmModal(false)
        }}
      />
      <BulkAccountConfirmDialog
        bulkCsvFile={bulkCsvFile}
        bulkAccounts={bulkAccounts}
        visible={openBulkAccountConfirmModal}
        onHide={() => {
          setOpenBulkAccountConfirmModal(false)
        }}
        onSubmitted={() => {
          setBulkCsvFile(null)
          setBulkAccounts([])
        }}
      />
    </Container>
  )
}

export default memo(Admin)

const Container = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: start;
`

const UploadFileContainer = styled.div`
  margin-bottom: 24px;
`

const TabViewStyled = styled(TabView)`
  .p-tabview-nav {
    padding: 0.6em 1.5em;
    margin-bottom: 1em;
  }
  li {
    margin-left: 24px;
  }
  li:first-child {
    margin-left: 0;
  }
  .p-tabview-panels,
  .p-tabview-nav {
    justify-content: flex-start;
    background: transparent;
    li.p-highlight .p-tabview-nav-link {
      background: #e1e4e8;
      font-weight: 700;
      color: #122945;
    }

    li .p-tabview-nav-link {
      background: transparent;
      padding: 1.2em 1.5em;
      font-size: 14px;
    }

    .p-unselectable-text:first-child,
    .p-unselectable-text:first-child .p-tabview-nav-link {
      border-radius: 10px 0px 0px 0px;
    }

    li.p-unselectable-text:nth-child(2),
    li.p-unselectable-text:nth-child(2) .p-tabview-nav-link {
      border-radius: 0 10px 0px 0px;
    }
  }
`
