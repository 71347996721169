import { useState, memo, useCallback } from "react"
import styled from "styled-components"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"

import { useStore } from "state/store"
import { deleteRrpRequest } from "api/rrp"

function RRPRemoveProductDialog() {
  const rrpRemoveProductDialogVisible = useStore(
    (state) => state.rrpRemoveProductDialogVisible,
  )
  const setRrpRemoveProductDialogVisible = useStore(
    (state) => state.setRrpRemoveProductDialogVisible,
  )
  const selectedRrpProductData = useStore(
    (state) => state.selectedRrpProductData,
  )
  const setSelectedRrpProductData = useStore(
    (state) => state.setSelectedRrpProductData,
  )
  const setNeedGetRrp = useStore((state) => state.setNeedGetRrp)

  const [isDeleting, setIsDeleting] = useState(false)

  const handleHide = () => {
    setRrpRemoveProductDialogVisible(false)
  }

  const handleDelete = useCallback(async () => {
    setIsDeleting(true)

    await deleteRrpRequest(selectedRrpProductData)

    setIsDeleting(false)
    setNeedGetRrp(true)
    handleHide()

    setSelectedRrpProductData(null)
  }, [selectedRrpProductData])

  return (
    <Holder
      visible={rrpRemoveProductDialogVisible}
      onHide={handleHide}
      showHeader={false}
    >
      <Content>
        <Title>Remove Product</Title>
        <Description>Are you sure you want to remove this product?</Description>
        <CloseButton
          icon="pi pi-times"
          className="p-button-rounded p-button-text p-button-secondary"
          aria-label="Cancel"
          onClick={handleHide}
        />
        <Button
          label="Remove Product"
          className="p-button-primary mt-2"
          onClick={handleDelete}
          disabled={isDeleting}
          loading={isDeleting}
        />
      </Content>
    </Holder>
  )
}

export default memo(RRPRemoveProductDialog)

const Holder = styled(Dialog)`
  min-width: 25em;
  width: 25em;
  .p-dialog-content {
    position: relative;
    padding: 1em;
    border-radius: 10px;
  }
`

const Content = styled.div`
  padding: 0em 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 2em !important;
  height: 2em !important;
  padding: 0.1em !important;
`

const Title = styled.div`
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.8;
`

const Description = styled.div`
  font-size: 1em;
  font-weight: 100;
  line-height: 1.8;
  text-align: center;
`
