import { memo } from "react"
import styled from "styled-components"
import { Tooltip } from "primereact/tooltip"
import classnames from "classnames"
import { H17, H18, H19 } from "components/Labels"
import { convertTextToSlug } from "utils"
import { device } from "theme/device"
import { VESSEL_REDFLAG, VESSEL_KEYS } from "dataset/vessel"
import FlagIcon from "assets/images/icon-flag.svg"

function WhatToKnow({ identity }) {
  return (
    <Holder id="anchor_profile_what_to_know">
      <H17>What to Know</H17>
      <List className="grid grid-nogutter w-full">
        {identity[VESSEL_KEYS.red_flags].length === 0 ? (
          <div>N/A</div>
        ) : (
          identity[VESSEL_KEYS.red_flags].map((value, index) => {
            const v = VESSEL_REDFLAG.find((d) => d.value === value)

            return (
              <Item key={index} className="col-12 md:col-6 lg:col-4">
                <div
                  className={classnames(
                    "flex align-items-start",
                    convertTextToSlug(v.title),
                  )}
                >
                  <img src={FlagIcon} alt="Flag Icon" className="mr-2 mt-1" />
                  <div>
                    <Title>{v.title}</Title>
                    <Subtitle>{v.info}</Subtitle>
                  </div>
                </div>
                {v.info.length > 70 && (
                  <TooltipStyled
                    mouseTrack={true}
                    target={"." + convertTextToSlug(v.title)}
                  >
                    <TooltipItem>
                      <img src={FlagIcon} alt="Flag Icon" />
                      <TooltipTitle>{v.title}</TooltipTitle>
                      <TooltipSubtitle>{v.info}</TooltipSubtitle>
                    </TooltipItem>
                  </TooltipStyled>
                )}
              </Item>
            )
          })
        )}
      </List>
    </Holder>
  )
}

export default memo(WhatToKnow)

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  scroll-margin-top: 20px;
`

const List = styled.div`
  padding: 2em 1em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.6em;
`

const Item = styled.div`
  position: relative;
  transition: all 0.5s ease-out;
  border: 1px solid transparent;
  > img {
    // position: absolute;
    // top: 0.6em;
    // left: 0.2em;
    // width: 1.2em;
    // height: 1.2em;
    cursor: pointer;
  }
`

const Title = styled(H18)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  @media ${device.tablet} {
    white-space: pre-wrap;
    overflow: auto;
    text-overflow: initial;
  }
`
const Subtitle = styled(H19)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3.5em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media ${device.tablet} {
    height: initial;
    overflow: auto;
    text-overflow: initial;
  }
`
const TooltipStyled = styled(Tooltip)`
  .p-tooltip-text {
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03),
      0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
  }
`

const TooltipItem = styled.div`
  position: relative;
  width: 20em;
  padding: 0.5em 1em 1em 2em !important;
  > img {
    position: absolute;
    top: 0.6em;
    left: 0.2em;
    width: 1.2em;
    height: 1.2em;
  }
`
const TooltipTitle = styled(H18)`
  margin-bottom: 1em;
`
const TooltipSubtitle = styled(H19)``
