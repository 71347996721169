import { useState } from "react"
import styled from "styled-components"
import { H22, H23 } from "components/Labels"
import { device } from "theme/device"
import { useStore } from "state/store"
import { VESSEL_KEYS } from "dataset/vessel"

export default function AggregateStatistics() {
  const totalVessels = useStore((state) => state.totalVessels)
  const setActiveVesselTab = useStore((state) => state.setActiveVesselTab)
  const [currentCategory, setCurrentCategory] = useState("TOTAL")
  const handleClick = (e, category) => {
    e.preventDefault()
    setCurrentCategory(category)
    setActiveVesselTab(category)
  }
  return (
    <Holder>
      <Item onClick={(e) => handleClick(e, "TOTAL")}>
        <H22>Total Vessels and Profiles</H22>
        <H23>{totalVessels.length}</H23>
        <Bar active={currentCategory === "TOTAL"} />
      </Item>
      <Item onClick={(e) => handleClick(e, "UNSC-SANCTIONED")}>
        <H22>UNSC-sanctioned</H22>
        <H23>
          {
            totalVessels.filter((d) => {
              if (d[VESSEL_KEYS.red_flags].includes(0)) {
                return true
              } else {
                return false
              }
            })?.length
          }
        </H23>
        <Bar active={currentCategory === "UNSC-SANCTIONED"} />
      </Item>
      <Item onClick={(e) => handleClick(e, "OFAC-SANCTIONED")}>
        <H22>OFAC-sanctioned</H22>
        <H23>
          {
            totalVessels.filter((d) => {
              if (d[VESSEL_KEYS.red_flags].includes(2)) {
                return true
              } else {
                return false
              }
            })?.length
          }
        </H23>
        <Bar active={currentCategory === "OFAC-SANCTIONED"} />
      </Item>
    </Holder>
  )
}

const Holder = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`

const Item = styled.div`
  min-width: 15em;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  @media ${device.pad} {
    min-width: 10em;
    > div {
      display: flex;
      flex-wrap: wrap;
    }
  }
  @media ${device.mobileL} {
    min-width: 5em;
    > div {
      display: flex;
      flex-wrap: wrap;
    }
  }
`

const Bar = styled.div`
  width: 80%;
  height: 0.3125em;
  background-color: ${(props) =>
    props.active ? props.theme.colors.primary : props.theme.colors.secondary};
  margin-top: 1em;
`
