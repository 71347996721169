import styled from "styled-components"
import { flag } from "country-emoji"

export default function CountryHoverOverlay(props) {
  return (
    <Holder>
      <PopUpDialogContent>
        <PopUpTitleContainer>
          <PopUpCountryTitle onClick={props.click}>
            {props.data.name} {flag(props.data.name)}
          </PopUpCountryTitle>
        </PopUpTitleContainer>
        <IconContainer onClick={props.click} className="popup-up-action-button">
          <i className="pi pi-arrow-right"></i>
        </IconContainer>
      </PopUpDialogContent>
    </Holder>
  )
}

const Holder = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
`

const PopUpTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: start;
`

const PopUpDialogContent = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > i {
    font-size: 12rem;
    color: white;
  }
`
const PopUpCountryTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  padding: 0px 10px;
  color: white;
  cursor: pointer;
`

// const PopUpLabelContainer = styled.div`
//   width: 100%;
//   display: flex;
//   justify-items: start;
//   padding: 0px 10px;
//   margin-top: 4px;
// `
// const PopUpLabel = styled.div`
//   background-color: #2c3e50;
//   color: white;
//   font-weight: bold;
//   padding: 2px 5px;
//   margin-right: 4px;
//   border-radius: 2px;
// `
const IconContainer = styled.div`
  cursor: pointer;
  color: white;
  margin-left: 2rem;
  margin-right: 0 !important;
`
