import { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { RadioButton } from "primereact/radiobutton"
import { Checkbox } from "primereact/checkbox"
import { InputText } from "primereact/inputtext"
import { Dialog } from "primereact/dialog"
import { Divider } from "primereact/divider"

import { useStore } from "state/store"
import { H8 } from "components/Labels"
import BulkAccountConfirmDialog from "../Admin/BulkAccountConfirmDialog"
import UploadIcon from "assets/images/upload.svg"

export default function AddUserDialog({
  creationType,
  setCreationType,
  setRole,
  userData,
  role,
  checkbox,
  setCheckbox,
  handleChange,
  setConfirmUserDetailsModalToggle,
}) {
  const addUserDialogVisible = useStore((state) => state.addUserDialogVisible)
  const setAddUserDialogVisible = useStore(
    (state) => state.setAddUserDialogVisible,
  )

  const [file, setFile] = useState()
  const [bulkAccounts, setBulkAccounts] = useState([])
  const [selectedFile, setSelectedFile] = useState("")
  const [openBulkAccountConfirmModal, setOpenBulkAccountConfirmModal] =
    useState(false)

  const fileRef = useRef()

  const changeHandler = (event) => {
    const file = event.target.files[0]
    if (file) {
      setFile(file)
      const reader = new FileReader()

      reader.onload = (e) => {
        const contents = e.target.result
        const csvData = contents.split("\n").map((row) => row.split(","))
        //Convert to JSON
        const headers = csvData[0]
        const jsonData = csvData.slice(1).map((row) => {
          const obj = {}
          headers.forEach((header, index) => {
            obj[header] = row[index]
          })
          return obj
        })
        // jsonData.pop()
        setBulkAccounts(jsonData)
      }

      reader.readAsText(file)
    }
  }

  const clickOnSelect = () => {
    fileRef.current.click()
  }
  useEffect(() => {
    if (file) {
      setSelectedFile(file.name)
    } else {
      setSelectedFile("")
    }
  }, [file])

  return (
    <>
      <Holder
        visible={addUserDialogVisible}
        onHide={() => {
          setAddUserDialogVisible(false)
          setFile(null)
          setBulkAccounts([])
          setSelectedFile("")
        }}
        header="Add User"
      >
        <p className="mt-2">Creation Type</p>
        <RadioGroup className="mt-2">
          <div className="flex align-items-center">
            <RadioButton
              value="singleAccount"
              onChange={(e) => setCreationType(e.value)}
              checked={creationType === "singleAccount"}
            />
            <label htmlFor="singleAccount" className="ml-2 text-sm">
              Single Account
            </label>
          </div>
          <div className="flex align-items-center">
            <RadioButton
              value="bulkAccount"
              onChange={(e) => setCreationType(e.value)}
              checked={creationType === "bulkAccount"}
            />
            <label htmlFor="bulkAccount" className="ml-2 text-sm">
              Bulk Account
            </label>
          </div>
        </RadioGroup>
        <Divider />
        <p className="mt-4">User Details</p>
        {creationType === "singleAccount" ? (
          <div>
            <div>
              <Paragraph> Role</Paragraph>
              <RadioGroup>
                <div className="flex align-items-center">
                  <RadioButton
                    value="User"
                    onChange={(e) => setRole(e.value)}
                    checked={role === "User"}
                  />
                  <label htmlFor="user" className="ml-2 text-sm">
                    User
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    value="Administrator"
                    onChange={(e) => setRole(e.value)}
                    checked={role === "Administrator"}
                  />
                  <label htmlFor="admin" className="ml-2 text-sm">
                    Administrator
                  </label>
                </div>
              </RadioGroup>
            </div>
            <InputsHolder>
              <SingleInput>
                <p style={{ marginBottom: "10px", fontSize: "12px" }}>
                  First Name
                </p>
                <InputText
                  style={{ width: "100%" }}
                  placeholder="First Name"
                  onChange={(e) => handleChange(e.target.value, "firstName")}
                  value={userData?.firstName}
                  name="firstname"
                />
              </SingleInput>
              <SingleInput>
                <ItemsPara>Last Name</ItemsPara>
                <InputText
                  style={{ width: "100%" }}
                  placeholder="Last Name"
                  onChange={(e) => handleChange(e.target.value, "lastName")}
                  value={userData?.lastName}
                  name="lastname"
                />
              </SingleInput>
              <SingleInput>
                <ItemsPara> Email Address </ItemsPara>
                <InputText
                  style={{ width: "100%" }}
                  placeholder="Email Address"
                  onChange={(e) => handleChange(e.target.value, "email")}
                  value={userData?.email}
                  name="email"
                />
              </SingleInput>
              <SingleInput>
                <ItemsPara>Organization</ItemsPara>
                <InputText
                  style={{ width: "100%" }}
                  placeholder="Organization"
                  onChange={(e) => handleChange(e.target.value, "organization")}
                  value={userData?.organization}
                  name="organization"
                />
              </SingleInput>
              <SingleInput>
                <ItemsPara>Title</ItemsPara>
                <InputText
                  style={{ width: "100%" }}
                  placeholder="Title"
                  onChange={(e) => handleChange(e.target.value, "title")}
                  value={userData?.title}
                  name="Analyst"
                />
              </SingleInput>
            </InputsHolder>
            <div style={{ display: "flex", gap: "10px", marginTop: "30px" }}>
              <Checkbox
                onChange={(e) => setCheckbox(e.checked)}
                checked={checkbox}
              ></Checkbox>
              <p className="text-sm">
                Can Make Insights Bespoke Requests
              </p>
            </div>
            <ButtonHolder>
              <Button
                onClick={() => {
                  setConfirmUserDetailsModalToggle(true)
                  setAddUserDialogVisible(false)
                }}
                disabled={
                  userData?.firstName === "" ||
                  // userData?.lastName === "" ||
                  userData?.email === "" ||
                  userData?.organization === ""
                  // userData?.title === ""
                    ? true
                    : false
                }
              >
                Confirm
              </Button>
            </ButtonHolder>
          </div>
        ) : (
          <div>
            <H8 className="mt-3">
              Upload a CSV file for bulk user creation. This process currently
              only works with CSV exports of the "User Base" spreadsheet from
              the Arion Notion space
            </H8>
            <BrowseFile className="mt-2">
              <Label htmlFor="fileInput">
                <img src={UploadIcon} alt="Upload" />
                <p onClick={() => clickOnSelect()}>
                  {selectedFile ? selectedFile : "Browse or Drop File Here"}
                </p>
              </Label>

              <input
                onChange={changeHandler}
                accept=".csv"
                ref={fileRef}
                multiple={false}
                type="file"
                id="fileInput"
                style={{ display: "none" }}
              />
            </BrowseFile>
            <ButtonHolder>
              <Button
                onClick={() => {
                  setOpenBulkAccountConfirmModal(true)
                }}
              >
                Confirm
              </Button>
            </ButtonHolder>
          </div>
        )}
      </Holder>
      <BulkAccountConfirmDialog
        bulkCsvFile={file}
        bulkAccounts={bulkAccounts}
        visible={openBulkAccountConfirmModal}
        onHide={() => {
          setOpenBulkAccountConfirmModal(false)
        }}
        onSubmitted={() => {
          setFile(null)
          setBulkAccounts([])
        }}
      />
    </>
  )
}

const Holder = styled(Dialog)`
  min-width: 300px;
  width: 50vw;
  max-width: 1200px;
  .p-dialog-content {
    padding: 1em;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

const RadioGroup = styled.div`
  display: flex;
  gap: 1em;
`
const InputsHolder = styled.div`
  margin-top: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
`

const SingleInput = styled.div``

const Button = styled.button`
  font-size: 0.9rem;
  background: rgb(252, 38, 38);
  color: white;
  padding-right: 15px;
  border-radius: 5px;
  padding-left: 15px;
  font-weight: 700;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
`

const ButtonHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`

const Paragraph = styled.p`
  margin-bottom: 8px;
  font-size: 13px;
  margin-top: 15px;
`

const ItemsPara = styled.p`
  margin-bottom: 10px;
  font-size: 12px;
`
const BrowseFile = styled.div`
  border: 0.1px dashed gray;
  padding: 70px;
`

const Label = styled.label`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2;
  cursor: pointer;
`
