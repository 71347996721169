import React from "react"
import styled from "styled-components"

import CircleButton from "components/Buttons/CircleButton"
import ZoomOutIcon from "assets/images/icon-minus.svg"
import ZoomInIcon from "assets/images/icon-plus.svg"
// import QuestionIcon from "assets/images/question-icon.svg"

import SatelliteIcon from "assets/images/icon-satellite.png"
import StreetIcon from "assets/images/icon-street.png"
export default function MapTool(props) {
  return (
    <>
      <ToolBarContainer>
        {/* <CircleButton icon={QuestionIcon} /> */}

        <CircleButton onClick={() => props.zoomIn()} icon={ZoomInIcon} />
        <CircleButton onClick={() => props.zoomOut()} icon={ZoomOutIcon} />
        <MapModeButton onClick={() => props.changeMode()}>
          <img src={!props.isSatellite ? SatelliteIcon : StreetIcon} alt="" />
        </MapModeButton>
      </ToolBarContainer>
      {props.children}
    </>
  )
}

const ToolBarContainer = styled.div`
  position: absolute;
  right: 1.5em;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.6em;
  transition: all 0.8s ease-out;
  bottom: 2.3rem;
`

const MapModeButton = styled.div`
  border-radius: 10px;
  width: 4em;
  height: 4em;
  border: 2px solid #eee;
  margin-top: 1em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`
