import styled from "styled-components"

import { OTHER_TOOLS, TOOLS } from "dataset/tool"
import { device } from "theme/device"
import PageTransition from "components/PageTransition"
import { MiddleContainer } from "components/Containers"
import { H43, H44, H46 } from "components/Labels"
import SignupForm from "./components/SignupForm"
import ToolCard from "./components/ToolCard"
import Footer from "components/Footer"
import ArionBackImage from "assets/images/about/bg.jpg"
import AboutBackImage from "assets/images/about/about-pattern.png"
import LogoIcon from "assets/images/arion-logo-white.svg"
import PatternImage from "assets/images/about/pattern.svg"
import EmbeddedSignupForm from "./components/EmbeddedSignupForm"

export default function About() {
  return (
    <PageTransition>
      <Holder>
        <ArionSection>
          <MiddleContainer>
            <div className="grid py-8">
              <div className="col-12 lg:col-6">
                <Logo src={LogoIcon} />
                <H43 className="mt-6">About Arion</H43>
                <H44 className="mt-4">
                  The premier tool for DPRK maritime sanctions enforcement and
                  due diligence.
                  <br />
                  <br />
                  Finding all the information you need to enforce DPRK sanctions
                  can be tough. Built upon data from years of cutting-edge
                  maritime monitoring, Arion is a C4ADS platform providing all
                  that you need to know about North Korea's maritime sanctions
                  evasion activities.
                </H44>
              </div>
              <div className="col-12 lg:col-6 lg:pl-8">
                {/* <SignupForm /> */}
                <EmbeddedSignupForm />
              </div>
            </div>
          </MiddleContainer>
        </ArionSection>
        <ToolSection>
          <MiddleContainer>
            <ToolTitle className="mt-6">
              Tools to Investigate DPRK maritime sanctions evasion networks and
              trends
            </ToolTitle>
            <ToolContainer className="mt-4 mb-6">
              {TOOLS.map((tool, index) => (
                <ToolCard key={index} {...tool} />
              ))}
              {OTHER_TOOLS.map((tool, index) => (
                <ToolCard key={index} {...tool} />
              ))}
            </ToolContainer>
          </MiddleContainer>
        </ToolSection>
        <AboutSection>
          <MiddleContainer>
            <AboutTitle className="mt-6">About Us</AboutTitle>
            <AboutDescription className="mt-4">
              C4ADS is a nonprofit organization with a mission to defeat the
              illicit networks that threaten global peace and security.
              <br />
              <br />
              We use diverse sources of information like corporate databases,
              maritime signals intelligence, port records, academic
              publications, and satellite data to accurately map the entangled
              commercial networks countries like North Korea use to pursue
              malign national security objectives. In addition to conducting
              deep dive investigations into vessels and corporate networks that
              are behind illicit shipments, our team of analysts monitors a
              fleet of over 300 high-risk vessels 24/7 to identify illicit
              activities in near real-time.
              <br />
              <br />
              For years, we have worked to bolster our partners on the
              frontlines of counterproliferation and sanctions enforcement with
              actionable analysis, data, and capacity building. But despite some
              major advancements, we noticed stakeholders across both the public
              and private sectors kept running into the same issues, like
            </AboutDescription>
            <AboutTipContainer className="mt-6">
              <AboutTip>
                <q>
                  Why isn’t it easier to find out if an entity is sanctioned?
                </q>
              </AboutTip>
              <AboutTip>
                <q>
                  There is no good way to learn what sanctions require me to do.
                </q>
              </AboutTip>
              <AboutTip>
                <q>
                  Key information about bad actors is siloed, and we never get
                  what we need to take action.
                </q>
              </AboutTip>
            </AboutTipContainer>
            <AboutDescription className="mt-6 mb-6">
              In coordination with Global Affairs Canada, C4ADS sought to create
              a platform that would resolve these longstanding issues and serve
              as a one stop shop for stakeholder needs on DPRK maritime
              sanctions risk. Our mission is to serve as the primary resource of
              the international community for targeting and capacity-building
              information on North Korea maritime sanctions evasion.
              <br />
              <br />
              <b>
                To learn more about C4ADS and our other initiatives, visit us at
                &nbsp;
                <a
                  href="https://c4ads.org/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#F75151", textDecoration: "underline" }}
                >
                  c4ads.org.
                </a>
              </b>
            </AboutDescription>
          </MiddleContainer>
        </AboutSection>
        <Footer />
      </Holder>
    </PageTransition>
  )
}

const Holder = styled.div`
  width: 100%;
`

const ArionSection = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 50em;
  background-image: url(${ArionBackImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180.07deg,
      #122a47 0%,
      #122a4777 50%,
      #122a47 100%
    );
    background-repeat: no-repeat;
    background-position: cover;
  }
`

const Logo = styled.img`
  width: 20em;
`

const ToolSection = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 10em;
  background-image: url(${PatternImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

const ToolTitle = styled(H46)`
  max-width: 26em;
`

const ToolContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  @media ${device.mobileL} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const AboutSection = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 20em;
  background-color: #122a47;
  background-image: url(${AboutBackImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      #122a47 0%,
      rgba(18, 42, 71, 0.5) 52.37%,
      #122a47 100%
    );
    background-repeat: no-repeat;
    background-position: cover;
  }
`

const AboutTitle = styled(H46)`
  max-width: 26em;
  color: white;
`

const AboutDescription = styled.div`
  color: white;
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
`

const AboutTipContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  column-gap: 1em;
  row-gap: 3em;
  @media ${device.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const AboutTip = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:after {
    content: "";
    position: absolute;
    top: -30%;
    right: 0;
    height: 160%;
    border-left: 1px solid #f75151;
    @media ${device.tablet} {
      display: none;
    }
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
  > q {
    max-width: 12em;
    text-align: center;
    font-size: 1.5em;
    color: white;
  }
`
