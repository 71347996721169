export const TOOLS = [
  {
    title: "Vessel Database",
    description:
      "Dive into the profiles of DPRK-linked vessels of interest and the cover identities they use to evade detection.",
    link: "/vessel-database",
  },
  {
    title: "Imagery Library",
    description:
      "Access the world’s most comprehensive database of public and commercial imagery of DPRK-linked vessels of interest.",
    link: "/imagery-library",
  },
  {
    title: "Interactive Map",
    description:
      "Explore an interactive map featuring in-depth profiles on countries and areas of interest impacted by DPRK maritime sanctions evasion.",
    link: "/interactive-map",
  },
  {
    title: "Oil Shipment Tracker",
    description:
      "Investigate DPRK oil procurement trends and shipments since October 2017 alongside linked vessels.",
    link: "/oil-shipment-tracker",
  },
]

export const OTHER_TOOLS = [
  {
    title: "Insights",
    description: "Get Analytical and Compliance Support from C4ADS.",
    link: "/insights",
  },
  {
    title: "Learning",
    description: "Build your knowledge base and level-up your investigations. ",
    link: "https://sso.teachable.com/secure/1607165/identity/login/password",
  },
]
