import { useRef } from "react"
import styled from "styled-components"
import { OverlayPanel } from "primereact/overlaypanel"

import InfoIcon from "assets/images/icon-info-red.svg"
import { H35 } from "components/Labels"

export default function InfoBadge({
  label = "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
}) {
  const overlayPanelRef = useRef(null)
  return (
    <>
      <Icon
        src={InfoIcon}
        onMouseOver={(e) => {
          overlayPanelRef.current.toggle(e)
        }}
        onMouseLeave={() => {
          overlayPanelRef.current.hide()
        }}
      />
      <StyledPanel ref={overlayPanelRef}>
        <H35>{label}</H35>
      </StyledPanel>
    </>
  )
}

const Icon = styled.img`
  width: 1.5em;
  height: 1.5em;
`

const StyledPanel = styled(OverlayPanel)`
  max-width: 23em;
`
