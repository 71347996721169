import { useState } from "react"
import { Dialog } from "primereact/dialog"
import classnames from "classnames"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Message } from "primereact/message"
import { useFormik } from "formik"
import styled from "styled-components"
import { usePostHog } from 'posthog-js/react'
import { updatePasswordRequest } from "api/auth"
import { getCookie } from "utils"
import IconSuccess from "assets/images/icon-reset-password-success.svg"

const Success = (props) => {
  return (
    <DialogSuccess visible={true} {...props}>
      <div className="text-center mb-3">
        <img src={IconSuccess} alt="96" />
      </div>
      <strong>
        A password reset email has been sent if an account exists.
      </strong>
    </DialogSuccess>
  )
}

export default function ResetPasswordRequestDialog({
  visible = false,
  onHide,
  existingEmail = "",
}) {
  const posthog = usePostHog()
  const [showSuccess, setShowSuccess] = useState(false)
  const [error, setError] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)

  let isSignedIn = !!getCookie("gac_access_token")

  const formik = useFormik({
    initialValues: { email: existingEmail },
    validate: (data) => {
      let errors = {}

      if (!data.email) {
        errors.email = "Email is required."
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Invalid email address. E.g. example@email.com"
      }
      return errors
    },
    onSubmit: (data) => {
      if (isProcessing) {
        return
      }

      setIsProcessing(true)
      updatePasswordRequest(data.email)
        .then(({ status }) => {
          if (status === 200 || !isSignedIn) {
            setShowSuccess(true)
            posthog.capture("Password reset")
          } else {
            setError("Something went wrong.")
          }
          setIsProcessing(false)
        })
        .catch((err) => {
          if (!isSignedIn) {
            setShowSuccess(true)
          } else {
            setError(err.message)
          }
          setIsProcessing(false)
        })
    },
  })

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name])
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    )
  }

  const handleOnHide = () => {
    setShowSuccess(false)
    onHide()
  }

  if (showSuccess) {
    return <Success visible={showSuccess} onHide={handleOnHide} />
  }
  return (
    <>
      <DialogStyled header="Reset Password" visible={visible} onHide={onHide}>
        <Holder>
          <form onSubmit={formik.handleSubmit}>
            {error && (
              <Message severity="error" className="w-full mb-3" text={error} />
            )}
            <span className={classnames("p-float-label")}>
              <InputTextStyled
                id="email"
                name="email"
                className={classnames("w-full", {
                  "cursor-not-allowed": !!existingEmail,
                })}
                value={existingEmail ? existingEmail : formik.values.email}
                onChange={formik.handleChange}
                readOnly={existingEmail}
                autoFocus
              />
              <label htmlFor="email">Email</label>
            </span>
            {getFormErrorMessage("email")}
            <div className="mt-3">
              <Button
                type="submit"
                color="primary"
                className="w-full justify-content-center"
                disabled={isProcessing}
              >
                {isProcessing ? "Please wait..." : "Reset"}
              </Button>
            </div>
          </form>
        </Holder>
      </DialogStyled>
    </>
  )
}

const DialogStyled = styled(Dialog)`
  .p-dialog-header {
    padding: 1em;
    width: 50vw;
    max-width: 520px;
  }
  .p-dialog-content {
    border-bottom-right-radius: 0.75em;
    border-bottom-left-radius: 0.75em;
  }
  .cursor-not-allowed {
    cursor: not-allowed;
  }
`

const DialogSuccess = styled(Dialog)`
  .p-dialog-header {
    padding: 10px 10px 0 10px;
    width: 50vw;
    max-width: 520px;
    border-bottom: none;
  }
  .p-dialog-content {
    text-align: center;
    border-bottom-right-radius: 0.75em;
    border-bottom-left-radius: 0.75em;
    padding-bottom: 2.5rem;
  }
`

const Holder = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const InputTextStyled = styled(InputText)`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 0.5em;
  padding: 0.9em;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.75em;
  color: #000000;
`
