import { memo, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useMutation } from "@tanstack/react-query"
import { toast } from "react-toastify"
import { Button } from "primereact/button"
import { Skeleton } from "primereact/skeleton"
import { Divider } from "primereact/divider"
import { usePostHog } from 'posthog-js/react'

import { saveVesselOrImage, deleteVesselOrImage } from "api/auth"
import { getHistoricalVesselsRequest } from "api/vessels"
import { useStore } from "state/store"
import { IMAGE_KEYS } from "dataset/image"
import { formatDateString } from "utils"
import { H18, H8 } from "components/Labels"
import ImageTag from "./ImageTag"
import VesselItem from "./VesselItem"

import ClockActiveIcon from "assets/images/icon-clock.svg"
import ClockInActiveIcon from "assets/images/icon-clock-inactive.svg"
import RegionActiveIcon from "assets/images/icon-region.svg"
import RegionInActiveIcon from "assets/images/icon-region-inactive.svg"

function ImageCard({ data, className }) {
  const posthog = usePostHog()

  const [vessels, setVessels] = useState([])

  const { mutate: vesselMutate, isLoading: vesselLoading } = useMutation(
    getHistoricalVesselsRequest,
    {
      onSuccess: (res) => {
        if (res) {
          setVessels(res)
        }
      },
    },
  )

  const navigate = useNavigate()

  const currentUser = useStore((state) => state.currentUser)

  const savedImages = useStore((state) => state.savedImages)
  const toggleSavedImages = useStore((state) => state.toggleSavedImages)

  const isBooked = useMemo(() => {
    return savedImages.some(
      (image) => image[IMAGE_KEYS.id] === data?.[IMAGE_KEYS.id],
    )
  }, [savedImages, data])

  useEffect(() => {
    if (data) {
      vesselMutate(data[IMAGE_KEYS.target_vessels])
    }
  }, [data])

  const {
    mutate: saveVesselOrImageMutate,
    isLoading: saveVesselOrImageLoading,
  } = useMutation(saveVesselOrImage, {
    onSuccess: ({ status }) => {
      if (status === 200) {
        toast.success("Image saved successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
        posthog.capture("Image saved")
      } else {
        toast.error("Error while saving image.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      }
    },
  })

  const {
    mutate: deleteVesselOrImageMutate,
    isLoading: deleteVesselOrImageLoading,
  } = useMutation(deleteVesselOrImage, {
    onSuccess: ({ status }) => {
      if (status === 200) {
        toast.success("Image removed successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
        posthog.capture("Image unsaved")
      } else {
        toast.error("Error while saving image.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      }
    },
  })

  const handleSave = (e) => {
    const params = {
      userId: currentUser.id,
      objectId: data?.[IMAGE_KEYS.id],
      objectType: "images",
    }
    if (isBooked) {
      deleteVesselOrImageMutate(params)
    } else {
      saveVesselOrImageMutate(params)
    }

    toggleSavedImages(data)
    e.stopPropagation()
  }

  const handleClick = () => {
    navigate(`/imagery-library?id=${data[IMAGE_KEYS.id]}`)
    posthog.capture("Image viewed")
  }

  if (!data) {
    return null
  }

  return (
    <Holder className={className} onClick={handleClick}>
      <Card>
        <Photo>
          <LazyLoadImage
            src={data[IMAGE_KEYS.s3_path]}
            alt={data[IMAGE_KEYS.title]}
            width="105%"
            height="auto"
            effect="blur"
          />
        </Photo>
        <Content>
          <Title>{data[IMAGE_KEYS.title]}</Title>
          {vesselLoading ? (
            <Skeleton width="100%" height="1.6em" className="mt-2" />
          ) : (
            <div className="flex gap-1 mt-2 align-items-center">
              {vessels.length > 0 && Array.isArray(vessels[0]) ? (
                <ItemHolder className="flex">
                  {vessels[0].map((data, i) => {
                    return (
                      <VesselItem
                        key={"vessel-item-" + i.toString()}
                        data={data}
                        start={i === 0 ? 1 : 0}
                        end={i === vessels[0].length - 1 ? 1 : 0}
                      />
                    )
                  })}
                </ItemHolder>
              ) : (
                <ItemHolder className="flex">
                  <VesselItem data={vessels[0]} start={1} end={1} />
                </ItemHolder>
              )}
              {vessels.length > 1 && <Badge>{`+${vessels.length - 1}`}</Badge>}
            </div>
          )}
          <Divider />
          <div className="flex justify-content-between align-items-center gap-2">
            <H8>{`Captured : ${formatDateString(
              data[IMAGE_KEYS.date_taken],
            )}`}</H8>
            <div className="flex align-items-center">
              <ImageTag
                active={data[IMAGE_KEYS.date_taken] === null ? 0 : 1}
                activeImage={ClockActiveIcon}
                inActiveImage={ClockInActiveIcon}
              />
              <ImageTag
                active={data[IMAGE_KEYS.coord] === null ? 0 : 1}
                activeImage={RegionActiveIcon}
                inActiveImage={RegionInActiveIcon}
              />
              <BookButton
                icon={`pi ${isBooked ? "pi-bookmark-fill" : "pi-bookmark"}`}
                className="p-button-rounded p-button-info"
                onClick={handleSave}
              />
            </div>
          </div>
        </Content>
      </Card>
    </Holder>
  )
}

export default memo(ImageCard)

const Holder = styled.div`
  cursor: pointer;
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid rgba(4, 61, 93, 0.26);
  border-radius: 0.5em;
  overflow: hidden;
  z-index: 0;
`
const Photo = styled.div`
  width: 100%;
  height: 12em;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #edecec;
`

const Content = styled.div`
  padding: 1em;
`

const ItemHolder = styled.div``

const Title = styled(H18)`
  width: 100%;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8em;
`

const BookButton = styled(Button)`
  background-color: transparent !important;
  color: black !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 2.5em !important;
  height: 2.5em !important;
`

const Badge = styled.div`
  border-radius: 3px;
  border: 1px solid #beced4;
  background-color: #f05261;
  padding: 0.5em 0.7em;
  font-size: 0.8em;
  font-weight: 100;
  color: #ffffff;
  line-height: 1;
  letter-spacing: 0.01em;
`
