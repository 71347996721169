import { memo } from "react"
import styled from "styled-components"
import { formatDateString } from "utils"
import { H17 } from "components/Labels"
import InfoBadge from "components/Badges/InfoBadge"
import AmountBadge from "components/Badges/AmountBadge"
import PaginateDataTable from "../PaginateDataTable"

function Shipments({ data = [], loading }) {
  const formatData = (rawShipments = []) => {
    let arr = rawShipments.map((shipment) => {
      return {
        shipment_date: formatDateString(shipment?.shipment_date),
        location: shipment?.location || "N/A",
        reg_flag: shipment?.reg_flag || "N/A",
        event: shipment?.event_verbose || "N/A",
        source_link: shipment?.source_link,
        source: shipment?.source,
      }
    })

    arr = arr.sort(
      (a, b) => b.shipment_date?.valueOf() - a.shipment_date?.valueOf(),
    )
    arr = arr.map((ship) => {
      return {
        ...ship,
        shipment_date: formatDateString(ship.shipment_date),
      }
    })
    return arr
  }

  const sourceBodyTemplate = (rowData) => {
    return (
      <a href={rowData.source_link} target="_blank" rel="noreferrer">
        {rowData.source}
      </a>
    )
  }

  const Columns = [
    { field: "shipment_date", header: "date", width: "17%" },
    { field: "location", header: "location", width: "17%" },
    { field: "reg_flag", header: "Flag", width: "17%" },
    { field: "event", header: "shipment event", width: "22%" },
    {
      field: "source",
      header: "source",
      width: "17%",
      body: sourceBodyTemplate,
    },
  ]

  const formatted = formatData(data)
  return (
    <Holder id="anchor_profile_linked_shipments">
      <div className="flex gap-1 align-items-center justify-content-between">
        <div className="flex gap-1 align-items-center">
          <H17>Known DPRK-linked Shipments</H17>
          <InfoBadge label="Known DPRK-linked Shipments" />
        </div>
        <AmountBadge>{`${data.length} Shipments`}</AmountBadge>
      </div>
      <PaginateDataTable
        value={formatted}
        columns={Columns}
        loading={loading}
        responsiveLayout="scroll"
      />
    </Holder>
  )
}

export default memo(Shipments)

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  scroll-margin-top: 20px;
`
