import { useState, useMemo, useEffect, useCallback } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { useMeasure } from "react-use"
import { useLocation, useNavigate } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import styled from "styled-components"
import { toast } from "react-toastify"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Swiper, SwiperSlide } from "swiper/react"
import { usePostHog } from 'posthog-js/react'
import { Button } from "primereact/button"
import { Divider } from "primereact/divider"

import { formatDateString } from "utils"
import { getHistoricalVesselsRequest } from "api/vessels"
import { getImageDownloadRequest } from "api/images"
import { saveVesselOrImage, deleteVesselOrImage } from "api/auth"
import { useStore } from "state/store"
import { device } from "theme/device"
import { IMAGE_KEYS } from "dataset/image"
import { H10, H11, H12, H8 } from "components/Labels"
import ImageProfileMetaDialog from "components/ImageProfileMetaDialog"
import Loader from "components/Loader"
import VesselSlideItem from "./VesselSlideItem"
import LocationIcon from "assets/images/icon-location.svg"
import SensorIcon from "assets/images/icon-sensor.svg"
import SourceIcon from "assets/images/icon-source.svg"
import ArrowIcon from "assets/images/icon-round-arrow.svg"
import "./ImageProfileDialog.css"

export default function ImageProfileDialog() {
  const location = useLocation()
  const navigate = useNavigate()

  const posthog = usePostHog()

  const profileGalleryDialogVisible = useStore(
    (state) => state.profileGalleryDialogVisible,
  )
  const setProfileGalleryDialogVisible = useStore(
    (state) => state.setProfileGalleryDialogVisible,
  )
  const currentUser = useStore((state) => state.currentUser)
  const currentImage = useStore((state) => state.currentImage)
  const profileMetaDialogVisible = useStore(
    (state) => state.profileMetaDialogVisible,
  )
  const setProfileMetaDialogVisible = useStore(
    (state) => state.setProfileMetaDialogVisible,
  )
  const savedImages = useStore((state) => state.savedImages)
  const toggleSavedImages = useStore((state) => state.toggleSavedImages)
  const imageCollection = useStore((state) => state.imageCollection)

  const [swiper, setSwiper] = useState(null)
  const [swiperRef, swiperMeasure] = useMeasure()

  const [vessels, setVessels] = useState([])

  const { mutate: vesselMutate, isLoading: vesselLoading } = useMutation(
    getHistoricalVesselsRequest,
    {
      onSuccess: (data) => {
        if (data) {
          setVessels(data)
        }
      },
    },
  )

  const [isDownloading, setIsDownloading] = useState(false)

  const isBooked = useMemo(() => {
    return savedImages.some(
      (image) => image[IMAGE_KEYS.id] === currentImage?.[IMAGE_KEYS.id],
    )
  }, [savedImages, currentImage])

  useEffect(() => {
    if (currentImage) {
      vesselMutate(currentImage[IMAGE_KEYS.target_vessels])
    }
  }, [currentImage, profileGalleryDialogVisible])

  const handleKeyPress = useCallback((event) => {
    switch (event.key) {
      case "Escape":
        if (profileMetaDialogVisible) {
          setProfileMetaDialogVisible(false)
        } else {
          handleClose()
        }
        break
      case "ArrowRight":
        handleNext()
        break
      case "ArrowLeft":
        handlePrevious()
        break
      default:
        break
    }
  })

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress)
    return () => {
      document.removeEventListener("keydown", handleKeyPress)
    }
  }, [handleKeyPress])

  const {
    mutate: saveVesselOrImageMutate,
    isLoading: saveVesselOrImageLoading,
  } = useMutation(saveVesselOrImage, {
    onSuccess: ({ status }) => {
      if (status === 200) {
        toast.success("Image saved successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
        posthog.capture("Image saved")
      } else {
        toast.error("Error while saving image.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      }
    },
  })

  const {
    mutate: deleteVesselOrImageMutate,
    isLoading: deleteVesselOrImageLoading,
  } = useMutation(deleteVesselOrImage, {
    onSuccess: ({ status }) => {
      if (status === 200) {
        toast.success("Image removed successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
        posthog.capture("Image unsaved")
      } else {
        toast.error("Error while saving image.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      }
    },
  })

  const save = () => {
    const params = {
      userId: currentUser.id,
      objectId: currentImage[IMAGE_KEYS.id],
      objectType: "images",
    }
    if (isBooked) {
      deleteVesselOrImageMutate(params)
    } else {
      saveVesselOrImageMutate(params)
    }
    toggleSavedImages(currentImage)
  }

  const directTo = (image) => {
    if (location.pathname === "/imagery-library") {
      navigate(`/imagery-library?id=${image[IMAGE_KEYS.id]}`)
    } else if (location.pathname === "/vessel-profile") {
      let strs = location.search.split("&")
      navigate(`/vessel-profile${strs[0]}&imageid=${image[IMAGE_KEYS.id]}`)
    }
  }

  const handlePrevious = () => {
    if (currentImage) {
      const idx = imageCollection.findIndex(
        (item) => item[IMAGE_KEYS.id] === currentImage[IMAGE_KEYS.id],
      )
      if (idx > 0) {
        const image = imageCollection[idx - 1]
        directTo(image)
        posthog.capture("Image viewed")
      }
    }
  }

  const handleNext = () => {
    if (currentImage) {
      const idx = imageCollection.findIndex(
        (item) => item[IMAGE_KEYS.id] === currentImage[IMAGE_KEYS.id],
      )
      if (idx < imageCollection.length - 1) {
        const image = imageCollection[idx + 1]
        directTo(image)
        posthog.capture("Image viewed")
      }
    }
  }

  const handleClose = () => {
    setProfileGalleryDialogVisible(false)

    if (location.pathname === "/imagery-library") {
      navigate("/imagery-library")
    } else if (location.pathname === "/vessel-profile") {
      let strs = location.search.split("&")
      navigate(`/vessel-profile${strs[0]}`)
    }
  }

  const handleDownload = () => {
    setIsDownloading(true)
    getImageDownloadRequest(currentImage[IMAGE_KEYS.id]).then((response) => {
      const url = URL.createObjectURL(response)
      const a = document.createElement("a")
      a.href = url
      a.download = currentImage[IMAGE_KEYS.file_name]
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      setIsDownloading(false)

      posthog.capture("Image downloaded")
    })
  }

  const slidesPerView = swiperMeasure.width / 380

  const vesselItems = useMemo(() => {
    const result = []
    for (let i = 0; i < vessels.length; i++) {
      const v = vessels[i]
      if (Array.isArray(v)) {
        v.forEach((vessel) => {
          result.push(vessel)
        })
      } else {
        result.push(v)
      }
    }
    return result
  }, [vessels])

  return (
    <AnimatePresence>
      {profileGalleryDialogVisible && currentImage && (
        <Holder
          transition={{ duration: 0.5 }}
          initial={{ y: 50, opacity: 0 }}
          exit={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          <Loader visible={vesselLoading} />
          <Content>
            <CloseButton
              icon="pi pi-times"
              className="p-button-rounded p-button-text"
              aria-label="Close"
              onClick={() => handleClose()}
            />
            <PreviousButton
              icon="pi pi-angle-left"
              className="p-button-rounded p-button-outlined"
              aria-label="Previous"
              onClick={() => handlePrevious()}
            />
            <NextButton
              icon="pi pi-angle-right"
              className="p-button-rounded p-button-outlined"
              aria-label="Next"
              onClick={() => handleNext()}
            />
            <Viewer>
              <LazyLoadImage
                width="auto"
                height="100%"
                src={currentImage[IMAGE_KEYS.s3_path]}
                alt=""
                effect="blur"
                style={{ display: vesselLoading ? "none" : "flex" }}
              />
            </Viewer>
            <Details className="grid grid-nogutter flex justify-content-between">
              <div className="col-12 lg:col-4">
                <div className="flex gap-2 w-full justify-content-between">
                  <div>
                    <H10>{currentImage[IMAGE_KEYS.title]}</H10>
                  </div>
                  <div className="flex gap-2">
                    <ActionButton
                      icon="pi pi-download"
                      className="p-button-rounded p-button-third"
                      aria-label="Download"
                      loading={isDownloading}
                      disabled={isDownloading}
                      onClick={handleDownload}
                    />
                    <ActionButton
                      icon={`pi ${
                        isBooked ? "pi-bookmark-fill" : "pi-bookmark"
                      }`}
                      className="p-button-rounded p-button-third"
                      aria-label="Bookmark"
                      onClick={save}
                    />
                    <ActionButton
                      icon="pi pi-ellipsis-v"
                      className="p-button-rounded p-button-third"
                      aria-label="View document"
                      onClick={() => {
                        setProfileMetaDialogVisible(true)
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap gap-2 align-items-center mt-5">
                  <div>
                    <H11>Date of Capture: </H11>
                    <H12>
                      {formatDateString(currentImage[IMAGE_KEYS.date_taken])}
                    </H12>
                  </div>
                </div>
                <div className="flex flex-wrap gap-2 align-items-center justify-content-between mt-3">
                  <div className="flex gap-2">
                    <div>
                      <img src={LocationIcon} alt="Location" />
                    </div>
                    <div>
                      <H8>Location</H8>
                      <H12>{currentImage[IMAGE_KEYS.region_aoi]}</H12>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div>
                      <img src={SensorIcon} alt="Sensor" />
                    </div>
                    <div>
                      <H8>Sensor</H8>
                      <H12>{currentImage[IMAGE_KEYS.sensor]}</H12>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div>
                      <img src={SourceIcon} alt="Source" />
                    </div>
                    <div>
                      <H8>Source</H8>
                      <H12>{currentImage[IMAGE_KEYS.source]}</H12>
                    </div>
                  </div>
                </div>
              </div>
              <Divider layout="vertical" className="hidden lg:block" />
              <Divider className="lg:hidden" />
              <div className="col-12 lg:col-7">
                <div className="flex gap-2 w-full justify-content-between">
                  <div>
                    <H12>Vessels Pictured:</H12>
                  </div>
                  {slidesPerView < vesselItems.length ? (
                    <div className="flex gap-2">
                      <ArrowButton
                        flip={true}
                        onClick={() => {
                          swiper.slidePrev()
                        }}
                      >
                        <img src={ArrowIcon} alt="Arrow" />
                      </ArrowButton>
                      <ArrowButton
                        flip={false}
                        onClick={() => {
                          swiper.slideNext()
                        }}
                      >
                        <img src={ArrowIcon} alt="Arrow" />
                      </ArrowButton>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  ref={swiperRef}
                  className="flex gap-2 align-items-center mt-2"
                >
                  <Swiper
                    className="profile-gallery-vessel-swiper"
                    slidesPerView={slidesPerView}
                    spaceBetween={0}
                    scrollbar={{ draggable: true, hide: true }}
                    grabCursor={true}
                    preventInteractionOnTransition={false}
                    onInit={setSwiper}
                    centeredSlides={false}
                  >
                    {vessels.map((group, index) => {
                      if (Array.isArray(group)) {
                        return group.map((data, i) => {
                          return (
                            <SwiperSlide key={index + "-" + i}>
                              <VesselSlideItem
                                data={data}
                                start={i === 0 ? 1 : 0}
                                end={i === group.length - 1 ? 1 : 0}
                                onClick={() => {
                                  if (data.cover_vessel_id) {
                                    navigate(
                                      `/vessel-profile?id=${data.cover_vessel_id}`,
                                    )
                                  } else if (data.target_vessel_id) {
                                    navigate(
                                      `/vessel-profile?id=${data.target_vessel_id}`,
                                    )
                                  }
                                  setProfileGalleryDialogVisible(false)
                                }}
                              />
                            </SwiperSlide>
                          )
                        })
                      } else {
                        return (
                          <SwiperSlide key={index}>
                            <VesselSlideItem
                              data={group}
                              start={1}
                              end={1}
                              onClick={() => {
                                navigate(
                                  `/vessel-profile?id=${group.target_vessel_id}`,
                                )
                                setProfileGalleryDialogVisible(false)
                              }}
                            />
                          </SwiperSlide>
                        )
                      }
                    })}
                  </Swiper>
                </div>
              </div>
            </Details>
            <ImageProfileMetaDialog />
          </Content>
        </Holder>
      )}
    </AnimatePresence>
  )
}

const Holder = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #000000af;
`

const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 1900px;
  height: 100%;
  padding: 5em 5em 0 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  @media ${device.pad} {
    padding: 0.5em 0.5em 0 0.5em;
  }
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 1;
  color: white !important;
`
const PreviousButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 1em;
  z-index: 1;
  color: white !important;
  transform: translateY(-50%);
`
const NextButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 1em;
  z-index: 1;
  color: white !important;
  transform: translateY(-50%);
`

const Viewer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  overflow: hidden;
  background-color: #111111;
`

const Details = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  padding: 1em;
`

const ActionButton = styled(Button)`
  color: black;
  width: 2.25em !important;
  height: 2.25em !important;
`

const ArrowButton = styled.div`
  width: 1.3em;
  height: 1.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease-out;
  transform: rotateZ(${(props) => (props.flip ? 180 : 0)}deg);
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  > img {
    width: 100%;
  }
`
