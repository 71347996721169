import styled from "styled-components"

import NoVesselsImage from "assets/images/no-vessels.svg"
import { H37, H38 } from "components/Labels"
import BookmarkIcon from "assets/images/icon-bookmark.svg"

export default function Nodata({ showEmptySavedVessel = false }) {
  return (
    <Holder className="my-6">
      <div>
        <img src={NoVesselsImage} alt="No vessels found" />
      </div>
      <H38 className="font-semibold">
        {showEmptySavedVessel ? "No Saved Vessels" : "No Results"}
      </H38>
      <SubTitle>
        <H37>
          {showEmptySavedVessel ? (
            <span>
              You can save a vessel by clicking on the bookmark icon bookmark
              &nbsp;
              <Bookmark alt="bookmark" src={BookmarkIcon} />
              &nbsp; on a Card or Profile page in the Vessel Database
            </span>
          ) : (
            "Try adjusting your search or filter to find what you’re looking for"
          )}
        </H37>
      </SubTitle>
    </Holder>
  )
}

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`

const SubTitle = styled.div`
  max-width: 50em;
  text-align: center;
  line-height: 1.8;
`

const Bookmark = styled.img`
  width: 1.5em;
  margin-bottom: -0.5em;
`
