import { memo } from "react"
import styled from "styled-components"
import { H17, H20, H5 } from "components/Labels"
import InfoBadge from "components/Badges/InfoBadge"
import { VESSEL_KEYS } from "dataset/vessel"
import { getValueForHumans } from "utils"
import snPLogo from "assets/images/spgloballogo.png"

function VesselIdentifiers({ identity }) {
  return (
    <Holder id="anchor_profile_vessel_identifiers">
      <div className="flex gap-1 align-items-center">
        <div className="flex align-items-center">
          <H17>Vessel Identifiers</H17>
          <InfoBadge label="Vessel Identifiers" />
        </div>
        {identity?.analyst_identifiers === false && (
          <img
            className="ml-auto"
            src={snPLogo}
            alt="S&P Global Logo"
            width="150"
          />
        )}
      </div>
      <List className="grid grid-nogutter">
        <ListItem className="col-12 lg:col-6">
          <Key>IMO Number</Key>
          <Value>{getValueForHumans(identity[VESSEL_KEYS.target_imo])}</Value>
        </ListItem>
        <ListItem className="col-12 lg:col-6">
          <Key>Gross Tonnage</Key>
          <Value>
            {getValueForHumans(identity[VESSEL_KEYS.gross_tonnage])}
          </Value>
        </ListItem>
        <ListItem className="col-12 lg:col-6">
          <Key>MMSI Number</Key>
          <Value>{getValueForHumans(identity[VESSEL_KEYS.target_mmsi])}</Value>
        </ListItem>
        <ListItem className="col-12 lg:col-6">
          <Key>Deadweight Tonnage</Key>
          <Value>{getValueForHumans(identity[VESSEL_KEYS.deadweight])}</Value>
        </ListItem>
        <ListItem className="col-12 lg:col-6">
          <Key>Call Sign</Key>
          <Value>{getValueForHumans(identity[VESSEL_KEYS.call_sign])}</Value>
        </ListItem>
        <ListItem className="col-12 lg:col-6">
          <Key>Year of Build</Key>
          <Value>{getValueForHumans(identity[VESSEL_KEYS.build_year])}</Value>
        </ListItem>
        <ListItem className="col-12 lg:col-6">
          <Key>Flag</Key>
          <Value>{getValueForHumans(identity[VESSEL_KEYS.target_flag])}</Value>
        </ListItem>
        <ListItem className="col-12 lg:col-6">
          <Key>Status</Key>
          <Value>
            {getValueForHumans(identity[VESSEL_KEYS.status_verbose])}
          </Value>
        </ListItem>
        <ListItem className="col-12 lg:col-6">
          <Key>Vessel Type</Key>
          <Value>{getValueForHumans(identity[VESSEL_KEYS.type_verbose])}</Value>
        </ListItem>
        <ListItem className="col-12 lg:col-6">
          <Key>Length</Key>
          <Value>{getValueForHumans(identity[VESSEL_KEYS.length])}</Value>
        </ListItem>
      </List>
    </Holder>
  )
}

export default memo(VesselIdentifiers)

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  scroll-margin-top: 20px;
`

const List = styled.ul`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.6em;
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
`

const Key = styled(H20)`
  flex: 1;
  background-color: #f7f7f7;
  padding: 0.7em 1.5em;
  height: 100%;
`

const Value = styled(H5)`
  flex: 1;
  padding: 0.7em 1.5em;
  height: 100%;
`
