export const RRP_PRODUCT_TYPE = [
  {
    key: 0,
    value: 0,
    label: "Subscription",
  },
  {
    key: 1,
    value: 1,
    label: "Bespoke",
  },
]

export const RRP_ANALYSIS_TYPE = [
  {
    key: 0,
    value: 0,
    label: "Targeting Update",
  },
  {
    key: 1,
    value: 1,
    label: "Flag Change Update",
  },
  {
    key: 2,
    value: 2,
    label: "UN Report Extraction",
  },
]

export const RRP_KEYS = {
  file_name: "file_name",
  file_size: "file_size",
  id: "id",
  internal_id: "internal_id",
  product_type: "product_type",
  s3_path: "s3_path",
  upload_date: "upload_date",
  users: "users",
}
