import { sortFlags } from "utils"

const VESSEL_KEYS = {
  id: "id",
  target_name: "target_name",
  status: "status",
  status_verbose: "status_verbose",
  length: "length",
  breadth: "breadth",
  type: "type",
  type_verbose: "type_verbose",
  target_imo: "target_imo",
  target_mmsi: "target_mmsi",
  target_aliases: "target_aliases", //
  internal_id: "internal_id", //
  red_flags: "red_flags",
  analyst_notes: "analyst_notes",
  target_flag: "target_flag",
  call_sign: "call_sign", //
  gross_tonnage: "gross_tonnage", //
  deadweight: "deadweight", //
  build_year: "build_year", //
  identity: "identity", //
  identity_verbose: "identity_verbose", //
  linked_ids_count: "linked_ids_count", //
  update_date: "update_date",
  images: "images",
}

const HISTORICAL_VESSEL_KEYS = {
  id: "id",
  internal_id: "internal_id", //
  type_verbose: "type_verbose", //
  identity_verbose: "identity_verbose", //
  name: "name", //
  imo: "imo", //
  mmsi: "mmsi", //
  flag: "flag", //
  target_vessel_id: "target_vessel_id", //
  cover_historical_id: "cover_historical_id", //
}

function getFlags(data) {
  let values = []
  data.forEach((d) => {
    if (!values.includes(d[VESSEL_KEYS.target_flag])) {
      values.push(d[VESSEL_KEYS.target_flag])
    }
  })

  let result = []
  values.forEach((v) => {
    if (v?.toLowerCase() === "unknown") {
      return
    }
    result.push({
      label: v?.toLowerCase() === "0" ? "N/A" : v,
      value: v,
    })
  })
  result = sortFlags(result)
  return result
}

const VESSEL_REDFLAG = [
  {
    key: "UNSC_SANCTIONED",
    label: "UNSC-Sanctioned",
    value: 0,
    title: "UNSC-Sanctioned",
    info: "The UN Security Council has sanctioned this VOI.",
  },
  {
    key: "UNPOE_IDENTIFIED",
    label: "UNPOE-Identified",
    value: 1,
    title: "UNPOE-Identified",
    info: "The UN Panel of Experts has investigated this VOI in connection with DPRK sanctions evasion activities. The UNSC has not sanctioned this VOI.",
  },
  {
    key: "OFAC_SANCTIONED",
    label: "OFAC-Sanctioned",
    value: 2,
    title: "OFAC-Sanctioned",
    info: "The US Department of the Treasury’s Office of Foreign Assets Control (OFAC) has sanctioned this VOI.",
  },
  {
    key: "OFAC_IDENTIFIED",
    label: "OFAC-Identified",
    value: 3,
    title: "OFAC-Identified",
    info: "The US Department of the Treasury’s Office of Foreign Assets Control (OFAC) has identified this VOI in connection with DPRK sanctions evasion activities. OFAC has not sanctioned this VOI.",
  },
  {
    key: "DEROGATORY_REPORTING",
    label: "Derogatory Reporting",
    value: 4,
    title: "Derogatory Reporting",
    info: "C4ADS or public reporting has identified this VOI as being possibly linked to DPRK sanctions evasion activities.",
  },
  {
    key: "IDENTITY_TAMPERING",
    label: "Identity Tampering",
    value: 5,
    title: "Identity Tampering",
    info: "This VOI has transmitted false data on AIS and/or made alterations to its physical features to misrepresent its identity.",
  },
  {
    key: "IDENTITY_LAUNDERING",
    label: "Identity Laundering",
    value: 6,
    title: "Identity Laundering",
    info: "This VOI or identity was involved in a vessel identity laundering operation.",
  },
]

const VESSEL_STATUS = [
  {
    key: "NONE",
    label: "No Status",
    value: 0,
    color: "#ce2e0e",
  },
  {
    key: "BROKEN_UP",
    label: "Broken Up",
    value: 1,
    color: "#ce2e0e",
  },
  {
    key: "IN_SERVICE",
    label: "In Service/Commission",
    value: 2,
    color: "#0ece84",
  },
  {
    key: "LAID_UP",
    label: "Laid Up",
    value: 3,
    color: "#ccce0e",
  },
  {
    key: "LAUNCHED",
    label: "Launched",
    value: 4,
    color: "#0ece84",
  },
  {
    key: "NEVER_EXISTED",
    label: "Never Existed",
    value: 5,
    color: "#ce2e0e",
  },
  {
    key: "TO_BE_BROKEN",
    label: "To Be Broken",
    value: 6,
    color: "#ccce0e",
  },
  {
    key: "TOTAL_LOSS",
    label: "Total Loss",
    value: 7,
    color: "#ce2e0e",
  },
  {
    key: "IN_CASUALTY",
    label: "In Casualty Or Repairing",
    value: 8,
    color: "#ccce0e",
  },
]

const VESSEL_TYPE = [
  {
    key: "NONE",
    label: "N/A",
    value: 0,
  },
  {
    key: "AGGREGATES_CARRIER",
    label: "Aggregates Carrier",
    value: 1,
  },
  {
    key: "ASPHALT_BITUMEN_TANKER",
    label: "Asphalt/Bitumen Tanker",
    value: 2,
  },
  {
    key: "BULK_CARRIER",
    label: "Bulk Carrier",
    value: 3,
  },
  {
    key: "BUNKERING_TANKER",
    label: "Bunkering Tanker",
    value: 4,
  },
  {
    key: "BUNKER_TANKER_OIL",
    label: "Bunkering Tanker (Oil)",
    value: 5,
  },
  {
    key: "CHEMICAL_TANKER",
    label: "Chemical Tanker",
    value: 6,
  },
  {
    key: "CHEMICAL_PRODUCTS_TANKER",
    label: "Chemical/Products Tanker",
    value: 7,
  },
  {
    key: "COAL_OIL_TANKER",
    label: "Coal/Oil Mixture Tanker",
    value: 8,
  },
  {
    key: "CONTAINER_SHIP",
    label: "Container Ship (Fully Cellular)",
    value: 9,
  },
  {
    key: "CRUDE_OIL_TANKER",
    label: "Crude Oil Tanker",
    value: 10,
  },
  {
    key: "CRUDE_OIL_PRODUCTS_TANKER",
    label: "Crude/Oil Products Tanker",
    value: 11,
  },
  {
    key: "FISHING_VESSEL",
    label: "Fishing Vessel",
    value: 12,
  },
  {
    key: "GENERAL_CARGO_SHIP",
    label: "General Cargo Ship",
    value: 13,
  },
  {
    key: "GENERAL_CARGO_SHIP_RORO",
    label: "General Cargo Ship (with Ro-Ro facility)",
    value: 14,
  },
  {
    key: "GRAB_DREDGER",
    label: "Grab Dredger",
    value: 15,
  },
  {
    key: "HOPPER_MOTOR",
    label: "Hopper, Motor",
    value: 16,
  },
  {
    key: "MOLTEN_SULPHUR_TANKER",
    label: "Molten Sulphur Tanker",
    value: 17,
  },
  {
    key: "PRODUCTS_TANKER",
    label: "Products Tanker",
    value: 18,
  },
  {
    key: "UNSPECIFIED_TANKER",
    label: "Tanker (unspecified)",
    value: 19,
  },
]

const VESSEL_IDENTITY_TYPE = [
  {
    key: "REAL",
    label: "Real",
    value: 1,
    info: "This identity belongs to a real, physical vessel.",
    color: "#000000",
  },
  {
    key: "REAL_SPOOFED",
    label: "Real (Spoofed)",
    value: 2,
    info: "One or more DPRK-linked vessels have fraudulently transmitted the identifiers of this real vessel.",
    color: "#ce2e0e",
  },
  {
    key: "FRAUDULENT",
    label: "Fraudulent",
    value: 3,
    info: "This identity was likely created using fraudulent information.",
    color: "#ce2e0e",
  },
]

export {
  getFlags,
  VESSEL_KEYS,
  VESSEL_TYPE,
  VESSEL_STATUS,
  VESSEL_REDFLAG,
  VESSEL_IDENTITY_TYPE,
  HISTORICAL_VESSEL_KEYS,
}
