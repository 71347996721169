import { memo, useEffect, useState } from "react"
import styled from "styled-components"
import { Dialog } from "primereact/dialog"
import { Checkbox } from "primereact/checkbox"
import { MultiSelect } from "primereact/multiselect"
import { Button } from "primereact/button"
import { isEqual } from "lodash-es"
import { usePostHog } from 'posthog-js/react'

import { H8, H9, H29 } from "components/Labels"
import useHideSelectOptionsOnScroll from "hooks/useHideSelectOptionsOnScroll"

function FilterDialog({
  visible,
  onHide,
  locations,
  sensors,
  sources,
  selectedLocations,
  setSelectedLocations,
  selectedSensors,
  setSelectedSensors,
  selectedSources,
  setSelectedSources,
  setIsFiltered,
}) {
  const posthog = usePostHog()
  const [_locations, _setLocations] = useState(selectedLocations)
  const [_sensors, _setSensors] = useState(selectedSensors)
  const [_sources, _setSources] = useState(selectedSources)

  const handleChangeSensor = (e) => {
    let _selected = [..._sensors]

    if (e.checked) {
      _selected.push(e.value)
    } else {
      for (let i = 0; i < _selected.length; i++) {
        const selectedCategory = _selected[i]

        if (selectedCategory === e.value) {
          _selected.splice(i, 1)
          break
        }
      }
    }

    _setSensors(_selected)
  }

  const handleChangeSource = (e) => {
    let _selected = [..._sources]

    if (e.checked) {
      _selected.push(e.value)
    } else {
      for (let i = 0; i < _selected.length; i++) {
        const selectedCategory = _selected[i]

        if (selectedCategory === e.value) {
          _selected.splice(i, 1)
          break
        }
      }
    }

    _setSources(_selected)
  }

  const handleChangeLocation = (e) => {
    _setLocations(e.value)
  }

  const panelHeaderTemplate = (options) => {
    return <></>
  }

  const itemTemplate = (option) => {
    return <div className="max-w-20rem">{option}</div>
  }

  const handleReset = () => {
    _setLocations([])
    _setSensors([])
    _setSources([])
  }

  const handleApply = () => {
    if (!isEqual(_locations, selectedLocations)) {
      setSelectedLocations(_locations)
      setIsFiltered(true)
    }

    if (!isEqual(_sensors, selectedSensors)) {
      setSelectedSensors(_sensors)
      setIsFiltered(true)
    }

    if (!isEqual(_sources, selectedSources)) {
      setSelectedSources(_sources)
      setIsFiltered(true)
    }

    posthog.capture("Image searched")

    onHide()
  }

  useHideSelectOptionsOnScroll()

  useEffect(() => {
    if (visible) {
      _setLocations(selectedLocations)
      _setSensors(selectedSensors)
      _setSources(selectedSources)
    }
  }, [visible, selectedLocations, selectedSensors, selectedSources])

  return (
    <Holder visible={visible} onHide={onHide} header="Filter">
      <Content>
        <div>
          <H9>Sensor</H9>
          <H8 className="mt-2">
            The operator of the instrument that captured the image
          </H8>
          <div className="grid mt-3">
            {sensors.map((d, index) => (
              <div
                key={d}
                className="col-12 md:col-6 lg:col-4 flex align-items-center"
              >
                <Checkbox
                  inputId={`cb-sensor-${index}`}
                  value={d}
                  onChange={handleChangeSensor}
                  checked={_sensors.some((item) => item === d)}
                />
                <label
                  htmlFor={`cb-sensor-${index}`}
                  className="p-checkbox-label pl-2"
                >
                  {d}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-5">
          <H9>Publishing Source</H9>
          <H8 className="mt-2">The entity that published the image</H8>
          <div className="grid mt-3">
            {sources.map((d, index) => (
              <div
                key={d}
                className="col-12 md:col-6 lg:col-4 flex align-items-center"
              >
                <Checkbox
                  inputId={`cb-source-${index}`}
                  value={d}
                  onChange={handleChangeSource}
                  checked={_sources.some((item) => item === d)}
                />
                <label
                  htmlFor={`cb-source-${index}`}
                  className="p-checkbox-label pl-2"
                >
                  {d}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-5">
          <H9>Location</H9>
          <H8 className="mt-2">
            An approximate description of the location where the image was
            captured
          </H8>
          <MultiSelect
            value={_locations}
            options={locations}
            onChange={handleChangeLocation}
            placeholder="Select locations"
            className="mt-2 w-full"
            panelHeaderTemplate={panelHeaderTemplate}
            itemTemplate={itemTemplate}
            filter={true}
            maxSelectedLabels={5}
            scrollHeight="30em"
          />
          <div className="flex flex-wrap gap-2 mt-2">
            {_locations?.map((d, index) => (
              <Chip key={d}>
                <H29>{d}</H29>
                <Button
                  icon="pi pi-times"
                  className="p-button-sm p-button-rounded p-button-text"
                  onClick={() => {
                    _setLocations(_locations.filter((f) => f !== d))
                  }}
                />
              </Chip>
            ))}
          </div>
        </div>
      </Content>
      <Footer>
        <Button
          className="p-button p-button-secondary"
          text
          onClick={handleReset}
          label="Reset"
        />
        <Button
          className="p-button p-button-primary"
          onClick={handleApply}
          label="Apply"
        />
      </Footer>
    </Holder>
  )
}

export default memo(FilterDialog)

const Holder = styled(Dialog)`
  min-width: 300px;
  width: 50vw;
  max-width: 1200px;
  .p-dialog-content {
    padding: 1em;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

const Content = styled.div`
  padding: 0em 1em;
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1em;
  padding: 1em 1em 0.5em 1em;
`

const Chip = styled.div`
  display: flex;
  align-items: center;
  background-color: #fcdbdc;
  border-radius: 3em;
  padding: 0.25em 0.2em 0.25em 0.7em;
  > button {
    padding: 0.8em !important;
    margin: 0 !important;
    width: 1em !important;
    height: 1em !important;
    > span {
      font-size: 0.7em !important;
    }
  }
`
