import styled from "styled-components"
import InfoIcon from "components/Icons/InfoIcon"
import { Button } from "primereact/button"
import FilterIcon from "assets/images/icon-filter.png"
import { useStore } from "state/store"

export default function FilterButton() {
  const setFilterDialogVisible = useStore(
    (state) => state.setFilterDialogVisible,
  )
  let amount = useStore((state) => state.filterItemAmount)
  const currentUrl = useStore((state) => state.currentUrl)
  return (
    <ButtonStyled
      type="button"
      label="Filter"
      icon={<InfoIcon src={FilterIcon} />}
      className="p-button-info p-filter-indication"
      badge={(currentUrl === undefined || currentUrl === null) && amount}
      badgeClassName="p-badge-danger p-badge-md"
      onClick={() => {
        setFilterDialogVisible(true)
      }}
    />
  )
}

const ButtonStyled = styled(Button)`
  .p-button.p-badge {
    width: 2.125em;
    height: 2.125em;
    line-height: 2.125em;
  }
`
