import axios from "axios"
import { toast } from "react-toastify"
import { getCookie, eraseCookie, setCookie } from "utils"
const options = {
  baseURL: process.env.REACT_APP_API_URL,
  mode: "no-cors", // no-cors, *cors, same-origin
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  credentials: "same-origin", // include, *same-origin, omit
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
}

const showToastError = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  })
}

const showToastInfo = (message) => {
  toast.info(message, {
    position: "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "colored",
  })
}

const api = axios.create(options)

let tokenRefreshing = false

const getTokenFromRefreshToken = async () => {
  tokenRefreshing = true
  const refresh_token = getCookie("gac_refresh_token")
  try {
    const { data } = await api.post(`/tokens/request_access_token/`, {
      refresh_token,
    })
    setCookie("gac_access_token", data.access_token, 1)
    return data.access_token
  } catch (error) {
    console.error(error?.response)
    return null
  }
}

api.interceptors.request.use(async (config) => {
  const publicRoutes = [
    "/users/login/",
    "/users/update_password/",
    "/tokens/request_access_token/",
  ]
  let access_token = getCookie("gac_access_token")
  if (!access_token && !publicRoutes.includes(config.url)) {
    if (!tokenRefreshing) {
      showToastInfo("Re-Authenticating...")
    }
    access_token = await getTokenFromRefreshToken()
  }
  const headers = {
    "Content-Type": "application/json",
    ...(access_token ? { Authorization: "Bearer " + access_token } : {}),
  }
  return {
    ...config,
    headers,
    cancelToken: new axios.CancelToken((cancel) => {
      if (!access_token && !publicRoutes.includes(config.url)) {
        console.log("Request cancelled due absence of access token")
        cancel("Request cancelled due absence of access token")
      }
    }),
  }
})

// response interceptor
api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    //logout user
    const skip = []
    const requestUri = error?.response?.config?.url
    const noAlert = error?.response?.config?.noAlert
    if (skip.includes(requestUri)) {
      console.info(error.response.status, `for ${requestUri}`)
      return Promise.reject(error)
    }
    if (!noAlert) {
      if (error?.response?.status === 400) {
        showToastError("Bad server request")
      } else if (error?.response?.status === 401) {
        console.log("token expired")
        showToastError("User is unauthenticated")
        eraseCookie("gac_access_token")
      } else if (error?.response?.status === 403) {
        showToastError("User is forbidden")
      } else if (error?.response?.status === 404) {
        //showToastError("Invalid API Endpoint")
      } else if (error?.response?.status === 429) {
        showToastError("Too Many Requests")
      } else if (error?.response?.status === 500) {
        showToastError("Internal Server Error")
      } else if (error?.response?.status === 502) {
        showToastError("Bad Gateway")
      } else if (error?.response?.status === 504) {
        showToastError("Gateway timeout")
      } else {
        showToastError(`Unknown Error`)
      }
    }
    return Promise.reject(error)
  },
)

export { api }
