import { useState } from "react"
import styled, { css } from "styled-components"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useLocation, useNavigate } from "react-router-dom"

import { IMAGE_KEYS } from "dataset/image"

export default function ProfileGallery({ data }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  if (data.length === 0) {
    return null
  }

  return (
    <div className="grid grid-nogutter">
      {data[0] && (
        <div className="col-12 lg:col-8">
          <LFrame
            onClick={() => {
              navigate(
                `${location.pathname}${location.search}&imageid=${
                  data[0][IMAGE_KEYS.id]
                }`,
              )
            }}
          >
            <LazyLoadImage
              src={data[0][IMAGE_KEYS.s3_path]}
              alt=""
              effect="blur"
            />
          </LFrame>
        </div>
      )}
      <div className="col-12 lg:col-4 grid-nogutter">
        {data[1] && (
          <div className="col">
            <MFrame
              onClick={() => {
                navigate(
                  `${location.pathname}${location.search}&imageid=${
                    data[1][IMAGE_KEYS.id]
                  }`,
                )
              }}
            >
              <LazyLoadImage
                src={data[1][IMAGE_KEYS.s3_path]}
                alt=""
                effect="blur"
              />
            </MFrame>
          </div>
        )}
        <div className="col flex grid-nogutter">
          {data[2] && (
            <div className="col-6">
              <SFrame
                onClick={() => {
                  navigate(
                    `${location.pathname}${location.search}&imageid=${
                      data[2][IMAGE_KEYS.id]
                    }`,
                  )
                }}
              >
                <LazyLoadImage
                  src={data[2][IMAGE_KEYS.s3_path]}
                  alt=""
                  effect="blur"
                />
              </SFrame>
            </div>
          )}
          {data[3] && (
            <div className="col-6">
              <SFrame
                onClick={() => {
                  navigate(
                    `${location.pathname}${location.search}&imageid=${
                      data[3][IMAGE_KEYS.id]
                    }`,
                  )
                }}
              >
                <LazyLoadImage
                  src={data[3][IMAGE_KEYS.s3_path]}
                  alt=""
                  effect="blur"
                />
                {!isExpanded && data.length > 4 && (
                  <Opaque
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsExpanded(true)
                    }}
                  >
                    {`+${data.length - 3}`}
                    <br />
                    images
                  </Opaque>
                )}
              </SFrame>
            </div>
          )}
        </div>
      </div>
      {isExpanded &&
        data.map((item, index) => {
          if (index < 4) {
            return null
          }
          return (
            <div key={index} className="col-12 lg:col-4">
              <LFrame
                onClick={() => {
                  navigate(
                    `${location.pathname}${location.search}&imageid=${
                      item[IMAGE_KEYS.id]
                    }`,
                  )
                }}
              >
                <LazyLoadImage
                  src={item[IMAGE_KEYS.s3_path]}
                  alt=""
                  effect="blur"
                />
              </LFrame>
            </div>
          )
        })}
    </div>
  )
}

const Frame = css`
  position: relative;
  background: ${(props) => props.theme.colors.third_5};
  border-radius: 1em;
  border: 2px solid #fff;
  overflow: hidden;
  margin: 0.5em;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    border: 2px solid ${(props) => props.theme.colors.primary};
  }
  > span {
    width: 100%;
    height: 100%;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

const LFrame = styled.div`
  ${Frame}
  height: calc(30vh + 1em);
`

const MFrame = styled.div`
  ${Frame}
  height: 15vh;
`

const SFrame = styled.div`
  ${Frame}
  height: 15vh;
`
const Opaque = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  font-weight: 700;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
  &:hover {
    background: rgba(0, 0, 0, 0);
  }
`
