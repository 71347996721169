const BoundingBox = {
  AF: ["Afghanistan", [60.53, 29.32, 75.16, 38.49]],
  AI: ["Anguilla", [-63.0597631, 18.1, -63.0597631, 18.35]],
  AO: ["Angola", [11.64, -17.93, 24.08, -4.44]],
  AL: ["Albania", [19.3, 39.62, 21.02, 42.69]],
  AE: ["United Arab Emirates", [51.58, 22.5, 56.4, 26.06]],
  AR: ["Argentina", [-73.42, -55.25, -53.63, -21.83]],
  AM: ["Armenia", [43.58, 38.74, 46.51, 41.25]],
  AQ: ["Antarctica", [-180.0, -90.0, 180.0, -63.27]],
  TF: ["French Southern Territories", [68.72, -49.78, 70.56, -48.63]],
  AU: ["Australia", [113.34, -43.63, 153.57, -10.67]],
  AT: ["Austria", [9.48, 46.43, 16.98, 49.04]],
  AZ: ["Azerbaijan", [44.79, 38.27, 50.39, 41.86]],
  BI: ["Burundi", [29.02, -4.5, 30.75, -2.35]],
  BE: ["Belgium", [2.51, 49.53, 6.16, 51.48]],
  BJ: ["Benin", [0.77, 6.14, 3.8, 12.24]],
  BF: ["Burkina Faso", [-5.47, 9.61, 2.18, 15.12]],
  BD: ["Bangladesh", [88.08, 20.67, 92.67, 26.45]],
  BG: ["Bulgaria", [22.38, 41.23, 28.56, 44.23]],
  BS: ["Bahamas", [-78.98, 23.71, -77.0, 27.04]],
  BA: ["Bosnia and Herzegovina", [15.75, 42.65, 19.6, 45.23]],
  BY: ["Belarus", [23.2, 51.32, 32.69, 56.17]],
  BZ: ["Belize", [-89.23, 15.89, -88.11, 18.5]],
  BO: ["Bolivia", [-69.59, -22.87, -57.5, -9.76]],
  BR: ["Brazil", [-73.99, -33.77, -34.73, 5.24]],
  BN: ["Brunei", [114.2, 4.01, 115.45, 5.45]],
  BT: ["Bhutan", [88.81, 26.72, 92.1, 28.3]],
  BW: ["Botswana", [19.9, -26.83, 29.43, -17.66]],
  CF: ["Central African Republic", [14.46, 2.27, 27.37, 11.14]],
  CA: ["Canada", [-141.0, 41.68, -52.65, 73.23]],
  CH: ["Switzerland", [6.02, 45.78, 10.44, 47.83]],
  CL: ["Chile", [-75.64, -55.61, -66.96, -17.58]],
  CN: ["China", [73.68, 18.2, 135.03, 53.46]],
  CI: ["Ivory Coast", [-8.6, 4.34, -2.56, 10.52]],
  CM: ["Cameroon", [8.49, 1.73, 16.01, 12.86]],
  CD: ["Congo (Kinshasa)", [12.18, -13.26, 31.17, 5.26]],
  CK: ["Cook Islands", [199, -22.2367, 200.7777, -20.2367]],
  CG: ["Congo (Brazzaville)", [11.09, -5.04, 18.45, 3.73]],
  CO: ["Colombia", [-78.99, -4.3, -66.88, 12.44]],
  CR: ["Costa Rica", [-85.94, 8.23, -82.55, 11.22]],
  CU: ["Cuba", [-84.97, 19.86, -74.18, 23.19]],
  CY: ["Cyprus", [32.26, 34.57, 34.0, 35.17]],
  CZ: ["Czech Republic", [12.24, 48.56, 18.85, 51.12]],
  DE: ["Germany", [5.99, 47.3, 15.02, 54.98]],
  DJ: ["Djibouti", [41.66, 10.93, 43.32, 12.7]],
  DK: ["Denmark", [8.09, 54.8, 12.69, 57.73]],
  DO: ["Dominican Republic", [-71.95, 17.6, -68.32, 19.88]],
  DZ: ["Algeria", [-8.68, 19.06, 12.0, 37.12]],
  EC: ["Ecuador", [-80.97, -4.96, -75.23, 1.38]],
  EG: ["Egypt", [24.7, 22.0, 36.87, 31.59]],
  ER: ["Eritrea", [36.32, 12.46, 43.08, 18.0]],
  ES: ["Spain", [-9.39, 35.95, 3.04, 43.75]],
  EE: ["Estonia", [23.34, 57.47, 28.13, 59.61]],
  ET: ["Ethiopia", [32.95, 3.42, 47.79, 14.96]],
  FI: ["Finland", [20.65, 59.85, 31.52, 70.16]],
  FJ: ["Fiji", [-180.0, -18.29, 180.0, -16.02]],
  FK: ["Falkland Islands", [-61.2, -52.3, -57.75, -51.1]],
  FR: ["France", [-5.0, 42.5, 9.56, 51.15]],
  GA: ["Gabon", [8.8, -3.98, 14.43, 2.33]],
  GB: ["United Kingdom", [-7.57, 49.96, 1.68, 58.64]],
  GE: ["Georgia", [39.96, 41.06, 46.64, 43.55]],
  GH: ["Ghana", [-3.24, 4.71, 1.06, 11.1]],
  GN: ["Guinea", [-15.13, 7.31, -7.83, 12.59]],
  GM: ["Gambia", [-16.84, 13.13, -13.84, 13.88]],
  GW: ["Guinea Bissau", [-16.68, 11.04, -13.7, 12.63]],
  GQ: ["Equatorial Guinea", [9.31, 1.01, 11.29, 2.28]],
  GR: ["Greece", [20.15, 34.92, 26.6, 41.83]],
  GL: ["Greenland", [-73.3, 60.04, -12.21, 83.65]],
  GT: ["Guatemala", [-92.23, 13.74, -88.23, 17.82]],
  GY: ["Guyana", [-61.41, 1.27, -56.54, 8.37]],
  HK: ["Hong Kong", [114.1598179, 22.1, 114.1598179, 22.5]],
  HN: ["Honduras", [-89.35, 12.98, -83.15, 16.01]],
  HR: ["Croatia", [13.66, 42.48, 19.39, 46.5]],
  HT: ["Haiti", [-74.46, 18.03, -71.62, 19.92]],
  HU: ["Hungary", [16.2, 45.76, 22.71, 48.62]],
  ID: ["Indonesia", [95.29, -10.36, 141.03, 5.48]],
  IN: ["India", [68.18, 7.97, 97.4, 35.49]],
  IE: ["Ireland", [-9.98, 51.67, -6.03, 55.13]],
  IR: ["Iran", [44.11, 25.08, 63.32, 39.71]],
  IQ: ["Iraq", [38.79, 29.1, 48.57, 37.39]],
  IS: ["Iceland", [-24.33, 63.5, -13.61, 66.53]],
  IL: ["Israel", [34.27, 29.5, 35.84, 33.28]],
  IT: ["Italy", [6.75, 36.62, 18.48, 47.12]],
  JM: ["Jamaica", [-78.34, 17.7, -76.2, 18.52]],
  JO: ["Jordan", [34.92, 29.2, 39.2, 33.38]],
  JP: ["Japan", [129.41, 31.03, 145.54, 45.55]],
  KZ: ["Kazakhstan", [46.47, 40.66, 87.36, 55.39]],
  KE: ["Kenya", [33.89, -4.68, 41.86, 5.51]],
  KG: ["Kyrgyzstan", [69.46, 39.28, 80.26, 43.3]],
  KH: ["Cambodia", [102.35, 10.49, 107.61, 14.57]],
  KR: ["South Korea", [126.12, 34.39, 129.47, 38.61]],
  KW: ["Kuwait", [46.57, 28.53, 48.42, 30.06]],
  LA: ["Laos", [100.12, 13.88, 107.56, 22.46]],
  LB: ["Lebanon", [35.13, 33.09, 36.61, 34.64]],
  LR: ["Liberia", [-11.44, 4.36, -7.54, 8.54]],
  LY: ["Libya", [9.32, 19.58, 25.16, 33.14]],
  LK: ["Sri Lanka", [79.7, 5.97, 81.79, 9.82]],
  LS: ["Lesotho", [27.0, -30.65, 29.33, -28.65]],
  LT: ["Lithuania", [21.06, 53.91, 26.59, 56.37]],
  LU: ["Luxembourg", [5.67, 49.44, 6.24, 50.13]],
  LV: ["Latvia", [21.06, 55.62, 28.18, 57.97]],
  MA: ["Morocco", [-17.02, 21.42, -1.12, 35.76]],
  MD: ["Moldova", [26.62, 45.49, 30.02, 48.47]],
  MG: ["Madagascar", [43.25, -25.6, 50.48, -12.04]],
  MH: ["Marshall Islands", [166.2286376, 9.5896166, 166.2286376, 9.5896166]],
  MX: ["Mexico", [-117.13, 14.54, -86.81, 32.72]],
  MK: ["Macedonia", [20.46, 40.84, 22.95, 42.32]],
  ML: ["Mali", [-12.17, 10.1, 4.27, 24.97]],
  MM: ["Myanmar", [92.3, 9.93, 101.18, 28.34]],
  ME: ["Montenegro", [18.45, 41.88, 20.34, 43.52]],
  MN: ["Mongolia", [87.75, 41.6, 119.77, 52.05]],
  MZ: ["Mozambique", [30.18, -26.74, 40.78, -10.32]],
  MR: ["Mauritania", [-17.06, 14.62, -4.92, 27.4]],
  MW: ["Malawi", [32.69, -16.8, 35.77, -9.23]],
  MY: ["Malaysia", [100.09, 0.77, 119.18, 6.93]],
  NA: ["Namibia", [11.73, -29.05, 25.08, -16.94]],
  NC: ["New Caledonia", [164.03, -22.4, 167.12, -20.11]],
  NE: ["Niger", [0.3, 11.66, 15.9, 23.47]],
  NG: ["Nigeria", [2.69, 4.24, 14.58, 13.87]],
  NI: ["Nicaragua", [-87.67, 10.73, -83.15, 15.02]],
  NL: ["Netherlands", [3.31, 50.8, 7.09, 53.51]],
  NO: ["Norway", [4.99, 58.08, 31.29, 70.92]],
  NP: ["Nepal", [80.09, 26.4, 88.17, 30.42]],
  NU: ["Nieu", [-173.8, -20.75, -166.86, -17.0530613]],
  NZ: ["New Zealand", [166.51, -46.64, 178.52, -34.45]],
  OM: ["Oman", [52.0, 16.65, 59.81, 26.4]],
  PK: ["Pakistan", [60.87, 23.69, 77.84, 37.13]],
  PA: ["Panama", [-82.97, 7.22, -77.24, 9.61]],
  PE: ["Peru", [-81.41, -18.35, -68.67, -0.06]],
  PH: ["Philippines", [117.17, 5.58, 126.54, 18.51]],
  PG: ["Papua New Guinea", [141.0, -10.65, 156.02, -2.5]],
  PL: ["Poland", [14.07, 49.03, 24.03, 54.85]],
  PR: ["Puerto Rico", [-67.24, 17.95, -65.59, 18.52]],
  KP: ["North Korea", [124.27, 37.67, 130.78, 42.99]],
  PT: ["Portugal", [-9.53, 36.84, -6.39, 42.28]],
  PY: ["Paraguay", [-62.69, -27.55, -54.29, -19.34]],
  QA: ["Qatar", [50.74, 24.56, 51.61, 26.11]],
  RO: ["Romania", [20.22, 43.69, 29.63, 48.22]],
  RU: ["Russia", [-180.0, 41.15, 180.0, 81.25]],
  RW: ["Rwanda", [29.02, -2.92, 30.82, -1.13]],
  SA: ["Saudi Arabia", [34.63, 16.35, 55.67, 32.16]],
  SD: ["Sudan", [21.94, 8.62, 38.41, 22.0]],
  SG: ["Singapore", [103.35, 1, 103.819836, 1.8]],
  SS: ["South Sudan", [23.89, 3.51, 35.3, 12.25]],
  SN: ["Senegal", [-17.63, 12.33, -11.47, 16.6]],
  SB: ["Solomon Islands", [156.49, -10.83, 162.4, -6.6]],
  SL: ["Sierra Leone", [-13.25, 6.79, -10.23, 10.05]],
  SV: ["El Salvador", [-90.1, 13.15, -87.72, 14.42]],
  SO: ["Somalia", [40.98, -1.68, 51.13, 12.02]],
  RS: ["Serbia", [18.83, 42.25, 22.99, 46.17]],
  SC: ["Seychelles", [55.491977, -4.479574, 55.491977, -4.779574]],
  SR: ["Suriname", [-58.04, 1.82, -53.96, 6.03]],
  SK: ["Slovakia", [16.88, 47.76, 22.56, 49.57]],
  SI: ["Slovenia", [13.7, 45.45, 16.56, 46.85]],
  SE: ["Sweden", [11.03, 55.36, 23.9, 69.11]],
  SZ: ["Swaziland", [30.68, -27.29, 32.07, -25.66]],
  SY: ["Syria", [35.7, 32.31, 42.35, 37.23]],
  TD: ["Chad", [13.54, 7.42, 23.89, 23.41]],
  TG: ["Togo", [-0.05, 5.93, 1.87, 11.02]],
  TH: ["Thailand", [97.38, 5.69, 105.59, 20.42]],
  TJ: ["Tajikistan", [67.44, 36.74, 74.98, 40.96]],
  TM: ["Turkmenistan", [52.5, 35.27, 66.55, 42.75]],
  TL: ["East Timor", [124.97, -9.39, 127.34, -8.27]],
  TT: ["Trinidad and Tobago", [-61.95, 10.0, -60.9, 10.89]],
  TN: ["Tunisia", [7.52, 30.31, 11.49, 37.35]],
  TR: ["Turkey", [26.04, 35.82, 44.79, 42.14]],
  TW: ["Taiwan", [120.11, 21.97, 121.95, 25.3]],
  TZ: ["Tanzania", [29.34, -11.72, 40.32, -0.95]],
  UG: ["Uganda", [29.58, -1.44, 35.04, 4.25]],
  UA: ["Ukraine", [22.09, 44.36, 40.08, 52.34]],
  UY: ["Uruguay", [-58.43, -34.95, -53.21, -30.11]],
  US: ["United States", [-125.0, 25.0, -66.96, 49.5]],
  UZ: ["Uzbekistan", [55.93, 37.14, 73.06, 45.59]],
  VE: ["Venezuela", [-73.3, 0.72, -59.76, 12.16]],
  VG: ["Virgin Islands (British)", [-64.6158528, 18.5, -64.6158528, 18.35]],
  VN: ["Vietnam", [102.17, 8.6, 109.34, 23.35]],
  VU: ["Vanuatu", [166.63, -16.6, 167.84, -14.63]],
  PS: ["West Bank", [34.93, 31.35, 35.55, 32.53]],
  YE: ["Yemen", [42.6, 12.59, 53.11, 19.0]],
  ZA: ["South Africa", [16.34, -34.82, 32.83, -22.09]],
  ZM: ["Zambia", [21.89, -17.96, 33.49, -8.24]],
  ZW: ["Zimbabwe", [25.26, -22.27, 32.85, -15.51]],
}

const Countries = [
  {
    name: "Afghanistan",
    "alpha-2": "AF",
    "alpha-3": "AFG",
    "country-code": "004",
    "iso_3166-2": "ISO 3166-2:AF",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Åland Islands",
    "alpha-2": "AX",
    "alpha-3": "ALA",
    "country-code": "248",
    "iso_3166-2": "ISO 3166-2:AX",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Albania",
    "alpha-2": "AL",
    "alpha-3": "ALB",
    "country-code": "008",
    "iso_3166-2": "ISO 3166-2:AL",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Algeria",
    "alpha-2": "DZ",
    "alpha-3": "DZA",
    "country-code": "012",
    "iso_3166-2": "ISO 3166-2:DZ",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "American Samoa",
    "alpha-2": "AS",
    "alpha-3": "ASM",
    "country-code": "016",
    "iso_3166-2": "ISO 3166-2:AS",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Andorra",
    "alpha-2": "AD",
    "alpha-3": "AND",
    "country-code": "020",
    "iso_3166-2": "ISO 3166-2:AD",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Angola",
    "alpha-2": "AO",
    "alpha-3": "AGO",
    "country-code": "024",
    "iso_3166-2": "ISO 3166-2:AO",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Anguilla",
    "alpha-2": "AI",
    "alpha-3": "AIA",
    "country-code": "660",
    "iso_3166-2": "ISO 3166-2:AI",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Antarctica",
    "alpha-2": "AQ",
    "alpha-3": "ATA",
    "country-code": "010",
    "iso_3166-2": "ISO 3166-2:AQ",
    region: "",
    "sub-region": "",
    "intermediate-region": "",
    "region-code": "",
    "sub-region-code": "",
    "intermediate-region-code": "",
  },
  {
    name: "Antigua and Barbuda",
    "alpha-2": "AG",
    "alpha-3": "ATG",
    "country-code": "028",
    "iso_3166-2": "ISO 3166-2:AG",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Argentina",
    "alpha-2": "AR",
    "alpha-3": "ARG",
    "country-code": "032",
    "iso_3166-2": "ISO 3166-2:AR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Armenia",
    "alpha-2": "AM",
    "alpha-3": "ARM",
    "country-code": "051",
    "iso_3166-2": "ISO 3166-2:AM",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Aruba",
    "alpha-2": "AW",
    "alpha-3": "ABW",
    "country-code": "533",
    "iso_3166-2": "ISO 3166-2:AW",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Australia",
    "alpha-2": "AU",
    "alpha-3": "AUS",
    "country-code": "036",
    "iso_3166-2": "ISO 3166-2:AU",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Austria",
    "alpha-2": "AT",
    "alpha-3": "AUT",
    "country-code": "040",
    "iso_3166-2": "ISO 3166-2:AT",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Azerbaijan",
    "alpha-2": "AZ",
    "alpha-3": "AZE",
    "country-code": "031",
    "iso_3166-2": "ISO 3166-2:AZ",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Bahamas",
    "alpha-2": "BS",
    "alpha-3": "BHS",
    "country-code": "044",
    "iso_3166-2": "ISO 3166-2:BS",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Bahrain",
    "alpha-2": "BH",
    "alpha-3": "BHR",
    "country-code": "048",
    "iso_3166-2": "ISO 3166-2:BH",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Bangladesh",
    "alpha-2": "BD",
    "alpha-3": "BGD",
    "country-code": "050",
    "iso_3166-2": "ISO 3166-2:BD",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Barbados",
    "alpha-2": "BB",
    "alpha-3": "BRB",
    "country-code": "052",
    "iso_3166-2": "ISO 3166-2:BB",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Belarus",
    "alpha-2": "BY",
    "alpha-3": "BLR",
    "country-code": "112",
    "iso_3166-2": "ISO 3166-2:BY",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Belgium",
    "alpha-2": "BE",
    "alpha-3": "BEL",
    "country-code": "056",
    "iso_3166-2": "ISO 3166-2:BE",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Belize",
    "alpha-2": "BZ",
    "alpha-3": "BLZ",
    "country-code": "084",
    "iso_3166-2": "ISO 3166-2:BZ",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Benin",
    "alpha-2": "BJ",
    "alpha-3": "BEN",
    "country-code": "204",
    "iso_3166-2": "ISO 3166-2:BJ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Bermuda",
    "alpha-2": "BM",
    "alpha-3": "BMU",
    "country-code": "060",
    "iso_3166-2": "ISO 3166-2:BM",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "Bhutan",
    "alpha-2": "BT",
    "alpha-3": "BTN",
    "country-code": "064",
    "iso_3166-2": "ISO 3166-2:BT",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Bolivia (Plurinational State of)",
    "alpha-2": "BO",
    "alpha-3": "BOL",
    "country-code": "068",
    "iso_3166-2": "ISO 3166-2:BO",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    "alpha-2": "BQ",
    "alpha-3": "BES",
    "country-code": "535",
    "iso_3166-2": "ISO 3166-2:BQ",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Bosnia and Herzegovina",
    "alpha-2": "BA",
    "alpha-3": "BIH",
    "country-code": "070",
    "iso_3166-2": "ISO 3166-2:BA",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Botswana",
    "alpha-2": "BW",
    "alpha-3": "BWA",
    "country-code": "072",
    "iso_3166-2": "ISO 3166-2:BW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "Bouvet Island",
    "alpha-2": "BV",
    "alpha-3": "BVT",
    "country-code": "074",
    "iso_3166-2": "ISO 3166-2:BV",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Brazil",
    "alpha-2": "BR",
    "alpha-3": "BRA",
    "country-code": "076",
    "iso_3166-2": "ISO 3166-2:BR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "British Indian Ocean Territory",
    "alpha-2": "IO",
    "alpha-3": "IOT",
    "country-code": "086",
    "iso_3166-2": "ISO 3166-2:IO",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Brunei Darussalam",
    "alpha-2": "BN",
    "alpha-3": "BRN",
    "country-code": "096",
    "iso_3166-2": "ISO 3166-2:BN",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Bulgaria",
    "alpha-2": "BG",
    "alpha-3": "BGR",
    "country-code": "100",
    "iso_3166-2": "ISO 3166-2:BG",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Burkina Faso",
    "alpha-2": "BF",
    "alpha-3": "BFA",
    "country-code": "854",
    "iso_3166-2": "ISO 3166-2:BF",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Burundi",
    "alpha-2": "BI",
    "alpha-3": "BDI",
    "country-code": "108",
    "iso_3166-2": "ISO 3166-2:BI",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Cabo Verde",
    "alpha-2": "CV",
    "alpha-3": "CPV",
    "country-code": "132",
    "iso_3166-2": "ISO 3166-2:CV",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Cambodia",
    "alpha-2": "KH",
    "alpha-3": "KHM",
    "country-code": "116",
    "iso_3166-2": "ISO 3166-2:KH",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Cameroon",
    "alpha-2": "CM",
    "alpha-3": "CMR",
    "country-code": "120",
    "iso_3166-2": "ISO 3166-2:CM",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Canada",
    "alpha-2": "CA",
    "alpha-3": "CAN",
    "country-code": "124",
    "iso_3166-2": "ISO 3166-2:CA",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "Cayman Islands",
    "alpha-2": "KY",
    "alpha-3": "CYM",
    "country-code": "136",
    "iso_3166-2": "ISO 3166-2:KY",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Central African Republic",
    "alpha-2": "CF",
    "alpha-3": "CAF",
    "country-code": "140",
    "iso_3166-2": "ISO 3166-2:CF",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Chad",
    "alpha-2": "TD",
    "alpha-3": "TCD",
    "country-code": "148",
    "iso_3166-2": "ISO 3166-2:TD",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Chile",
    "alpha-2": "CL",
    "alpha-3": "CHL",
    "country-code": "152",
    "iso_3166-2": "ISO 3166-2:CL",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "China",
    "alpha-2": "CN",
    "alpha-3": "CHN",
    "country-code": "156",
    "iso_3166-2": "ISO 3166-2:CN",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Christmas Island",
    "alpha-2": "CX",
    "alpha-3": "CXR",
    "country-code": "162",
    "iso_3166-2": "ISO 3166-2:CX",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Cocos (Keeling) Islands",
    "alpha-2": "CC",
    "alpha-3": "CCK",
    "country-code": "166",
    "iso_3166-2": "ISO 3166-2:CC",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Colombia",
    "alpha-2": "CO",
    "alpha-3": "COL",
    "country-code": "170",
    "iso_3166-2": "ISO 3166-2:CO",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Comoros",
    "alpha-2": "KM",
    "alpha-3": "COM",
    "country-code": "174",
    "iso_3166-2": "ISO 3166-2:KM",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Congo",
    "alpha-2": "CG",
    "alpha-3": "COG",
    "country-code": "178",
    "iso_3166-2": "ISO 3166-2:CG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Congo, Democratic Republic of the",
    "alpha-2": "CD",
    "alpha-3": "COD",
    "country-code": "180",
    "iso_3166-2": "ISO 3166-2:CD",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Cook Islands",
    "alpha-2": "CK",
    "alpha-3": "COK",
    "country-code": "184",
    "iso_3166-2": "ISO 3166-2:CK",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Costa Rica",
    "alpha-2": "CR",
    "alpha-3": "CRI",
    "country-code": "188",
    "iso_3166-2": "ISO 3166-2:CR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Côte d'Ivoire",
    "alpha-2": "CI",
    "alpha-3": "CIV",
    "country-code": "384",
    "iso_3166-2": "ISO 3166-2:CI",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Croatia",
    "alpha-2": "HR",
    "alpha-3": "HRV",
    "country-code": "191",
    "iso_3166-2": "ISO 3166-2:HR",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Cuba",
    "alpha-2": "CU",
    "alpha-3": "CUB",
    "country-code": "192",
    "iso_3166-2": "ISO 3166-2:CU",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Curaçao",
    "alpha-2": "CW",
    "alpha-3": "CUW",
    "country-code": "531",
    "iso_3166-2": "ISO 3166-2:CW",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Cyprus",
    "alpha-2": "CY",
    "alpha-3": "CYP",
    "country-code": "196",
    "iso_3166-2": "ISO 3166-2:CY",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Czechia",
    "alpha-2": "CZ",
    "alpha-3": "CZE",
    "country-code": "203",
    "iso_3166-2": "ISO 3166-2:CZ",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Denmark",
    "alpha-2": "DK",
    "alpha-3": "DNK",
    "country-code": "208",
    "iso_3166-2": "ISO 3166-2:DK",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Djibouti",
    "alpha-2": "DJ",
    "alpha-3": "DJI",
    "country-code": "262",
    "iso_3166-2": "ISO 3166-2:DJ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Dominica",
    "alpha-2": "DM",
    "alpha-3": "DMA",
    "country-code": "212",
    "iso_3166-2": "ISO 3166-2:DM",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Dominican Republic",
    "alpha-2": "DO",
    "alpha-3": "DOM",
    "country-code": "214",
    "iso_3166-2": "ISO 3166-2:DO",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Ecuador",
    "alpha-2": "EC",
    "alpha-3": "ECU",
    "country-code": "218",
    "iso_3166-2": "ISO 3166-2:EC",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Egypt",
    "alpha-2": "EG",
    "alpha-3": "EGY",
    "country-code": "818",
    "iso_3166-2": "ISO 3166-2:EG",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "El Salvador",
    "alpha-2": "SV",
    "alpha-3": "SLV",
    "country-code": "222",
    "iso_3166-2": "ISO 3166-2:SV",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Equatorial Guinea",
    "alpha-2": "GQ",
    "alpha-3": "GNQ",
    "country-code": "226",
    "iso_3166-2": "ISO 3166-2:GQ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Eritrea",
    "alpha-2": "ER",
    "alpha-3": "ERI",
    "country-code": "232",
    "iso_3166-2": "ISO 3166-2:ER",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Estonia",
    "alpha-2": "EE",
    "alpha-3": "EST",
    "country-code": "233",
    "iso_3166-2": "ISO 3166-2:EE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Eswatini",
    "alpha-2": "SZ",
    "alpha-3": "SWZ",
    "country-code": "748",
    "iso_3166-2": "ISO 3166-2:SZ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "Ethiopia",
    "alpha-2": "ET",
    "alpha-3": "ETH",
    "country-code": "231",
    "iso_3166-2": "ISO 3166-2:ET",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Falkland Islands (Malvinas)",
    "alpha-2": "FK",
    "alpha-3": "FLK",
    "country-code": "238",
    "iso_3166-2": "ISO 3166-2:FK",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Faroe Islands",
    "alpha-2": "FO",
    "alpha-3": "FRO",
    "country-code": "234",
    "iso_3166-2": "ISO 3166-2:FO",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Fiji",
    "alpha-2": "FJ",
    "alpha-3": "FJI",
    "country-code": "242",
    "iso_3166-2": "ISO 3166-2:FJ",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "Finland",
    "alpha-2": "FI",
    "alpha-3": "FIN",
    "country-code": "246",
    "iso_3166-2": "ISO 3166-2:FI",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "France",
    "alpha-2": "FR",
    "alpha-3": "FRA",
    "country-code": "250",
    "iso_3166-2": "ISO 3166-2:FR",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "French Guiana",
    "alpha-2": "GF",
    "alpha-3": "GUF",
    "country-code": "254",
    "iso_3166-2": "ISO 3166-2:GF",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "French Polynesia",
    "alpha-2": "PF",
    "alpha-3": "PYF",
    "country-code": "258",
    "iso_3166-2": "ISO 3166-2:PF",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "French Southern Territories",
    "alpha-2": "TF",
    "alpha-3": "ATF",
    "country-code": "260",
    "iso_3166-2": "ISO 3166-2:TF",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Gabon",
    "alpha-2": "GA",
    "alpha-3": "GAB",
    "country-code": "266",
    "iso_3166-2": "ISO 3166-2:GA",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Gambia",
    "alpha-2": "GM",
    "alpha-3": "GMB",
    "country-code": "270",
    "iso_3166-2": "ISO 3166-2:GM",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Georgia",
    "alpha-2": "GE",
    "alpha-3": "GEO",
    "country-code": "268",
    "iso_3166-2": "ISO 3166-2:GE",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Germany",
    "alpha-2": "DE",
    "alpha-3": "DEU",
    "country-code": "276",
    "iso_3166-2": "ISO 3166-2:DE",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Ghana",
    "alpha-2": "GH",
    "alpha-3": "GHA",
    "country-code": "288",
    "iso_3166-2": "ISO 3166-2:GH",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Gibraltar",
    "alpha-2": "GI",
    "alpha-3": "GIB",
    "country-code": "292",
    "iso_3166-2": "ISO 3166-2:GI",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Greece",
    "alpha-2": "GR",
    "alpha-3": "GRC",
    "country-code": "300",
    "iso_3166-2": "ISO 3166-2:GR",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Greenland",
    "alpha-2": "GL",
    "alpha-3": "GRL",
    "country-code": "304",
    "iso_3166-2": "ISO 3166-2:GL",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "Grenada",
    "alpha-2": "GD",
    "alpha-3": "GRD",
    "country-code": "308",
    "iso_3166-2": "ISO 3166-2:GD",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Guadeloupe",
    "alpha-2": "GP",
    "alpha-3": "GLP",
    "country-code": "312",
    "iso_3166-2": "ISO 3166-2:GP",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Guam",
    "alpha-2": "GU",
    "alpha-3": "GUM",
    "country-code": "316",
    "iso_3166-2": "ISO 3166-2:GU",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Guatemala",
    "alpha-2": "GT",
    "alpha-3": "GTM",
    "country-code": "320",
    "iso_3166-2": "ISO 3166-2:GT",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Guernsey",
    "alpha-2": "GG",
    "alpha-3": "GGY",
    "country-code": "831",
    "iso_3166-2": "ISO 3166-2:GG",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "Channel Islands",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "830",
  },
  {
    name: "Guinea",
    "alpha-2": "GN",
    "alpha-3": "GIN",
    "country-code": "324",
    "iso_3166-2": "ISO 3166-2:GN",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Guinea-Bissau",
    "alpha-2": "GW",
    "alpha-3": "GNB",
    "country-code": "624",
    "iso_3166-2": "ISO 3166-2:GW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Guyana",
    "alpha-2": "GY",
    "alpha-3": "GUY",
    "country-code": "328",
    "iso_3166-2": "ISO 3166-2:GY",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Haiti",
    "alpha-2": "HT",
    "alpha-3": "HTI",
    "country-code": "332",
    "iso_3166-2": "ISO 3166-2:HT",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Heard Island and McDonald Islands",
    "alpha-2": "HM",
    "alpha-3": "HMD",
    "country-code": "334",
    "iso_3166-2": "ISO 3166-2:HM",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Holy See",
    "alpha-2": "VA",
    "alpha-3": "VAT",
    "country-code": "336",
    "iso_3166-2": "ISO 3166-2:VA",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Honduras",
    "alpha-2": "HN",
    "alpha-3": "HND",
    "country-code": "340",
    "iso_3166-2": "ISO 3166-2:HN",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Hong Kong",
    "alpha-2": "HK",
    "alpha-3": "HKG",
    "country-code": "344",
    "iso_3166-2": "ISO 3166-2:HK",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Hungary",
    "alpha-2": "HU",
    "alpha-3": "HUN",
    "country-code": "348",
    "iso_3166-2": "ISO 3166-2:HU",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Iceland",
    "alpha-2": "IS",
    "alpha-3": "ISL",
    "country-code": "352",
    "iso_3166-2": "ISO 3166-2:IS",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "India",
    "alpha-2": "IN",
    "alpha-3": "IND",
    "country-code": "356",
    "iso_3166-2": "ISO 3166-2:IN",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Indonesia",
    "alpha-2": "ID",
    "alpha-3": "IDN",
    "country-code": "360",
    "iso_3166-2": "ISO 3166-2:ID",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Iran (Islamic Republic of)",
    "alpha-2": "IR",
    "alpha-3": "IRN",
    "country-code": "364",
    "iso_3166-2": "ISO 3166-2:IR",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Iraq",
    "alpha-2": "IQ",
    "alpha-3": "IRQ",
    "country-code": "368",
    "iso_3166-2": "ISO 3166-2:IQ",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Ireland",
    "alpha-2": "IE",
    "alpha-3": "IRL",
    "country-code": "372",
    "iso_3166-2": "ISO 3166-2:IE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Isle of Man",
    "alpha-2": "IM",
    "alpha-3": "IMN",
    "country-code": "833",
    "iso_3166-2": "ISO 3166-2:IM",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Israel",
    "alpha-2": "IL",
    "alpha-3": "ISR",
    "country-code": "376",
    "iso_3166-2": "ISO 3166-2:IL",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Italy",
    "alpha-2": "IT",
    "alpha-3": "ITA",
    "country-code": "380",
    "iso_3166-2": "ISO 3166-2:IT",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Jamaica",
    "alpha-2": "JM",
    "alpha-3": "JAM",
    "country-code": "388",
    "iso_3166-2": "ISO 3166-2:JM",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Japan",
    "alpha-2": "JP",
    "alpha-3": "JPN",
    "country-code": "392",
    "iso_3166-2": "ISO 3166-2:JP",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Jersey",
    "alpha-2": "JE",
    "alpha-3": "JEY",
    "country-code": "832",
    "iso_3166-2": "ISO 3166-2:JE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "Channel Islands",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "830",
  },
  {
    name: "Jordan",
    "alpha-2": "JO",
    "alpha-3": "JOR",
    "country-code": "400",
    "iso_3166-2": "ISO 3166-2:JO",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Kazakhstan",
    "alpha-2": "KZ",
    "alpha-3": "KAZ",
    "country-code": "398",
    "iso_3166-2": "ISO 3166-2:KZ",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Kenya",
    "alpha-2": "KE",
    "alpha-3": "KEN",
    "country-code": "404",
    "iso_3166-2": "ISO 3166-2:KE",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Kiribati",
    "alpha-2": "KI",
    "alpha-3": "KIR",
    "country-code": "296",
    "iso_3166-2": "ISO 3166-2:KI",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Korea (Democratic People's Republic of)",
    "alpha-2": "KP",
    "alpha-3": "PRK",
    "country-code": "408",
    "iso_3166-2": "ISO 3166-2:KP",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Korea, Republic of",
    "alpha-2": "KR",
    "alpha-3": "KOR",
    "country-code": "410",
    "iso_3166-2": "ISO 3166-2:KR",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Kuwait",
    "alpha-2": "KW",
    "alpha-3": "KWT",
    "country-code": "414",
    "iso_3166-2": "ISO 3166-2:KW",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Kyrgyzstan",
    "alpha-2": "KG",
    "alpha-3": "KGZ",
    "country-code": "417",
    "iso_3166-2": "ISO 3166-2:KG",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Lao People's Democratic Republic",
    "alpha-2": "LA",
    "alpha-3": "LAO",
    "country-code": "418",
    "iso_3166-2": "ISO 3166-2:LA",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Latvia",
    "alpha-2": "LV",
    "alpha-3": "LVA",
    "country-code": "428",
    "iso_3166-2": "ISO 3166-2:LV",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Lebanon",
    "alpha-2": "LB",
    "alpha-3": "LBN",
    "country-code": "422",
    "iso_3166-2": "ISO 3166-2:LB",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Lesotho",
    "alpha-2": "LS",
    "alpha-3": "LSO",
    "country-code": "426",
    "iso_3166-2": "ISO 3166-2:LS",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "Liberia",
    "alpha-2": "LR",
    "alpha-3": "LBR",
    "country-code": "430",
    "iso_3166-2": "ISO 3166-2:LR",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Libya",
    "alpha-2": "LY",
    "alpha-3": "LBY",
    "country-code": "434",
    "iso_3166-2": "ISO 3166-2:LY",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Liechtenstein",
    "alpha-2": "LI",
    "alpha-3": "LIE",
    "country-code": "438",
    "iso_3166-2": "ISO 3166-2:LI",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Lithuania",
    "alpha-2": "LT",
    "alpha-3": "LTU",
    "country-code": "440",
    "iso_3166-2": "ISO 3166-2:LT",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Luxembourg",
    "alpha-2": "LU",
    "alpha-3": "LUX",
    "country-code": "442",
    "iso_3166-2": "ISO 3166-2:LU",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Macao",
    "alpha-2": "MO",
    "alpha-3": "MAC",
    "country-code": "446",
    "iso_3166-2": "ISO 3166-2:MO",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Madagascar",
    "alpha-2": "MG",
    "alpha-3": "MDG",
    "country-code": "450",
    "iso_3166-2": "ISO 3166-2:MG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Malawi",
    "alpha-2": "MW",
    "alpha-3": "MWI",
    "country-code": "454",
    "iso_3166-2": "ISO 3166-2:MW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Malaysia",
    "alpha-2": "MY",
    "alpha-3": "MYS",
    "country-code": "458",
    "iso_3166-2": "ISO 3166-2:MY",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Maldives",
    "alpha-2": "MV",
    "alpha-3": "MDV",
    "country-code": "462",
    "iso_3166-2": "ISO 3166-2:MV",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Mali",
    "alpha-2": "ML",
    "alpha-3": "MLI",
    "country-code": "466",
    "iso_3166-2": "ISO 3166-2:ML",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Malta",
    "alpha-2": "MT",
    "alpha-3": "MLT",
    "country-code": "470",
    "iso_3166-2": "ISO 3166-2:MT",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Marshall Islands",
    "alpha-2": "MH",
    "alpha-3": "MHL",
    "country-code": "584",
    "iso_3166-2": "ISO 3166-2:MH",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Martinique",
    "alpha-2": "MQ",
    "alpha-3": "MTQ",
    "country-code": "474",
    "iso_3166-2": "ISO 3166-2:MQ",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Mauritania",
    "alpha-2": "MR",
    "alpha-3": "MRT",
    "country-code": "478",
    "iso_3166-2": "ISO 3166-2:MR",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Mauritius",
    "alpha-2": "MU",
    "alpha-3": "MUS",
    "country-code": "480",
    "iso_3166-2": "ISO 3166-2:MU",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Mayotte",
    "alpha-2": "YT",
    "alpha-3": "MYT",
    "country-code": "175",
    "iso_3166-2": "ISO 3166-2:YT",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Mexico",
    "alpha-2": "MX",
    "alpha-3": "MEX",
    "country-code": "484",
    "iso_3166-2": "ISO 3166-2:MX",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Micronesia (Federated States of)",
    "alpha-2": "FM",
    "alpha-3": "FSM",
    "country-code": "583",
    "iso_3166-2": "ISO 3166-2:FM",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Moldova, Republic of",
    "alpha-2": "MD",
    "alpha-3": "MDA",
    "country-code": "498",
    "iso_3166-2": "ISO 3166-2:MD",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Monaco",
    "alpha-2": "MC",
    "alpha-3": "MCO",
    "country-code": "492",
    "iso_3166-2": "ISO 3166-2:MC",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Mongolia",
    "alpha-2": "MN",
    "alpha-3": "MNG",
    "country-code": "496",
    "iso_3166-2": "ISO 3166-2:MN",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Montenegro",
    "alpha-2": "ME",
    "alpha-3": "MNE",
    "country-code": "499",
    "iso_3166-2": "ISO 3166-2:ME",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Montserrat",
    "alpha-2": "MS",
    "alpha-3": "MSR",
    "country-code": "500",
    "iso_3166-2": "ISO 3166-2:MS",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Morocco",
    "alpha-2": "MA",
    "alpha-3": "MAR",
    "country-code": "504",
    "iso_3166-2": "ISO 3166-2:MA",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Mozambique",
    "alpha-2": "MZ",
    "alpha-3": "MOZ",
    "country-code": "508",
    "iso_3166-2": "ISO 3166-2:MZ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Myanmar",
    "alpha-2": "MM",
    "alpha-3": "MMR",
    "country-code": "104",
    "iso_3166-2": "ISO 3166-2:MM",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Namibia",
    "alpha-2": "NA",
    "alpha-3": "NAM",
    "country-code": "516",
    "iso_3166-2": "ISO 3166-2:NA",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "Nauru",
    "alpha-2": "NR",
    "alpha-3": "NRU",
    "country-code": "520",
    "iso_3166-2": "ISO 3166-2:NR",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Nepal",
    "alpha-2": "NP",
    "alpha-3": "NPL",
    "country-code": "524",
    "iso_3166-2": "ISO 3166-2:NP",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Netherlands",
    "alpha-2": "NL",
    "alpha-3": "NLD",
    "country-code": "528",
    "iso_3166-2": "ISO 3166-2:NL",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "New Caledonia",
    "alpha-2": "NC",
    "alpha-3": "NCL",
    "country-code": "540",
    "iso_3166-2": "ISO 3166-2:NC",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "New Zealand",
    "alpha-2": "NZ",
    "alpha-3": "NZL",
    "country-code": "554",
    "iso_3166-2": "ISO 3166-2:NZ",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Nicaragua",
    "alpha-2": "NI",
    "alpha-3": "NIC",
    "country-code": "558",
    "iso_3166-2": "ISO 3166-2:NI",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Niger",
    "alpha-2": "NE",
    "alpha-3": "NER",
    "country-code": "562",
    "iso_3166-2": "ISO 3166-2:NE",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Nigeria",
    "alpha-2": "NG",
    "alpha-3": "NGA",
    "country-code": "566",
    "iso_3166-2": "ISO 3166-2:NG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Niue",
    "alpha-2": "NU",
    "alpha-3": "NIU",
    "country-code": "570",
    "iso_3166-2": "ISO 3166-2:NU",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Norfolk Island",
    "alpha-2": "NF",
    "alpha-3": "NFK",
    "country-code": "574",
    "iso_3166-2": "ISO 3166-2:NF",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "North Macedonia",
    "alpha-2": "MK",
    "alpha-3": "MKD",
    "country-code": "807",
    "iso_3166-2": "ISO 3166-2:MK",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Northern Mariana Islands",
    "alpha-2": "MP",
    "alpha-3": "MNP",
    "country-code": "580",
    "iso_3166-2": "ISO 3166-2:MP",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Norway",
    "alpha-2": "NO",
    "alpha-3": "NOR",
    "country-code": "578",
    "iso_3166-2": "ISO 3166-2:NO",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Oman",
    "alpha-2": "OM",
    "alpha-3": "OMN",
    "country-code": "512",
    "iso_3166-2": "ISO 3166-2:OM",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Pakistan",
    "alpha-2": "PK",
    "alpha-3": "PAK",
    "country-code": "586",
    "iso_3166-2": "ISO 3166-2:PK",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Palau",
    "alpha-2": "PW",
    "alpha-3": "PLW",
    "country-code": "585",
    "iso_3166-2": "ISO 3166-2:PW",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Palestine, State of",
    "alpha-2": "PS",
    "alpha-3": "PSE",
    "country-code": "275",
    "iso_3166-2": "ISO 3166-2:PS",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Panama",
    "alpha-2": "PA",
    "alpha-3": "PAN",
    "country-code": "591",
    "iso_3166-2": "ISO 3166-2:PA",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Papua New Guinea",
    "alpha-2": "PG",
    "alpha-3": "PNG",
    "country-code": "598",
    "iso_3166-2": "ISO 3166-2:PG",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "Paraguay",
    "alpha-2": "PY",
    "alpha-3": "PRY",
    "country-code": "600",
    "iso_3166-2": "ISO 3166-2:PY",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Peru",
    "alpha-2": "PE",
    "alpha-3": "PER",
    "country-code": "604",
    "iso_3166-2": "ISO 3166-2:PE",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Philippines",
    "alpha-2": "PH",
    "alpha-3": "PHL",
    "country-code": "608",
    "iso_3166-2": "ISO 3166-2:PH",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Pitcairn",
    "alpha-2": "PN",
    "alpha-3": "PCN",
    "country-code": "612",
    "iso_3166-2": "ISO 3166-2:PN",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Poland",
    "alpha-2": "PL",
    "alpha-3": "POL",
    "country-code": "616",
    "iso_3166-2": "ISO 3166-2:PL",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Portugal",
    "alpha-2": "PT",
    "alpha-3": "PRT",
    "country-code": "620",
    "iso_3166-2": "ISO 3166-2:PT",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Puerto Rico",
    "alpha-2": "PR",
    "alpha-3": "PRI",
    "country-code": "630",
    "iso_3166-2": "ISO 3166-2:PR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Qatar",
    "alpha-2": "QA",
    "alpha-3": "QAT",
    "country-code": "634",
    "iso_3166-2": "ISO 3166-2:QA",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Réunion",
    "alpha-2": "RE",
    "alpha-3": "REU",
    "country-code": "638",
    "iso_3166-2": "ISO 3166-2:RE",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Romania",
    "alpha-2": "RO",
    "alpha-3": "ROU",
    "country-code": "642",
    "iso_3166-2": "ISO 3166-2:RO",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Russian Federation",
    "alpha-2": "RU",
    "alpha-3": "RUS",
    "country-code": "643",
    "iso_3166-2": "ISO 3166-2:RU",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Rwanda",
    "alpha-2": "RW",
    "alpha-3": "RWA",
    "country-code": "646",
    "iso_3166-2": "ISO 3166-2:RW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Saint Barthélemy",
    "alpha-2": "BL",
    "alpha-3": "BLM",
    "country-code": "652",
    "iso_3166-2": "ISO 3166-2:BL",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    "alpha-2": "SH",
    "alpha-3": "SHN",
    "country-code": "654",
    "iso_3166-2": "ISO 3166-2:SH",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Saint Kitts and Nevis",
    "alpha-2": "KN",
    "alpha-3": "KNA",
    "country-code": "659",
    "iso_3166-2": "ISO 3166-2:KN",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Saint Lucia",
    "alpha-2": "LC",
    "alpha-3": "LCA",
    "country-code": "662",
    "iso_3166-2": "ISO 3166-2:LC",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Saint Martin (French part)",
    "alpha-2": "MF",
    "alpha-3": "MAF",
    "country-code": "663",
    "iso_3166-2": "ISO 3166-2:MF",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Saint Pierre and Miquelon",
    "alpha-2": "PM",
    "alpha-3": "SPM",
    "country-code": "666",
    "iso_3166-2": "ISO 3166-2:PM",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "Saint Vincent and the Grenadines",
    "alpha-2": "VC",
    "alpha-3": "VCT",
    "country-code": "670",
    "iso_3166-2": "ISO 3166-2:VC",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Samoa",
    "alpha-2": "WS",
    "alpha-3": "WSM",
    "country-code": "882",
    "iso_3166-2": "ISO 3166-2:WS",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "San Marino",
    "alpha-2": "SM",
    "alpha-3": "SMR",
    "country-code": "674",
    "iso_3166-2": "ISO 3166-2:SM",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Sao Tome and Principe",
    "alpha-2": "ST",
    "alpha-3": "STP",
    "country-code": "678",
    "iso_3166-2": "ISO 3166-2:ST",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Saudi Arabia",
    "alpha-2": "SA",
    "alpha-3": "SAU",
    "country-code": "682",
    "iso_3166-2": "ISO 3166-2:SA",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Senegal",
    "alpha-2": "SN",
    "alpha-3": "SEN",
    "country-code": "686",
    "iso_3166-2": "ISO 3166-2:SN",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Serbia",
    "alpha-2": "RS",
    "alpha-3": "SRB",
    "country-code": "688",
    "iso_3166-2": "ISO 3166-2:RS",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Seychelles",
    "alpha-2": "SC",
    "alpha-3": "SYC",
    "country-code": "690",
    "iso_3166-2": "ISO 3166-2:SC",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Sierra Leone",
    "alpha-2": "SL",
    "alpha-3": "SLE",
    "country-code": "694",
    "iso_3166-2": "ISO 3166-2:SL",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Singapore",
    "alpha-2": "SG",
    "alpha-3": "SGP",
    "country-code": "702",
    "iso_3166-2": "ISO 3166-2:SG",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Sint Maarten (Dutch part)",
    "alpha-2": "SX",
    "alpha-3": "SXM",
    "country-code": "534",
    "iso_3166-2": "ISO 3166-2:SX",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Slovakia",
    "alpha-2": "SK",
    "alpha-3": "SVK",
    "country-code": "703",
    "iso_3166-2": "ISO 3166-2:SK",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Slovenia",
    "alpha-2": "SI",
    "alpha-3": "SVN",
    "country-code": "705",
    "iso_3166-2": "ISO 3166-2:SI",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Solomon Islands",
    "alpha-2": "SB",
    "alpha-3": "SLB",
    "country-code": "090",
    "iso_3166-2": "ISO 3166-2:SB",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "Somalia",
    "alpha-2": "SO",
    "alpha-3": "SOM",
    "country-code": "706",
    "iso_3166-2": "ISO 3166-2:SO",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "South Africa",
    "alpha-2": "ZA",
    "alpha-3": "ZAF",
    "country-code": "710",
    "iso_3166-2": "ISO 3166-2:ZA",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    "alpha-2": "GS",
    "alpha-3": "SGS",
    "country-code": "239",
    "iso_3166-2": "ISO 3166-2:GS",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "South Sudan",
    "alpha-2": "SS",
    "alpha-3": "SSD",
    "country-code": "728",
    "iso_3166-2": "ISO 3166-2:SS",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Spain",
    "alpha-2": "ES",
    "alpha-3": "ESP",
    "country-code": "724",
    "iso_3166-2": "ISO 3166-2:ES",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Sri Lanka",
    "alpha-2": "LK",
    "alpha-3": "LKA",
    "country-code": "144",
    "iso_3166-2": "ISO 3166-2:LK",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Sudan",
    "alpha-2": "SD",
    "alpha-3": "SDN",
    "country-code": "729",
    "iso_3166-2": "ISO 3166-2:SD",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Suriname",
    "alpha-2": "SR",
    "alpha-3": "SUR",
    "country-code": "740",
    "iso_3166-2": "ISO 3166-2:SR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Svalbard and Jan Mayen",
    "alpha-2": "SJ",
    "alpha-3": "SJM",
    "country-code": "744",
    "iso_3166-2": "ISO 3166-2:SJ",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Sweden",
    "alpha-2": "SE",
    "alpha-3": "SWE",
    "country-code": "752",
    "iso_3166-2": "ISO 3166-2:SE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Switzerland",
    "alpha-2": "CH",
    "alpha-3": "CHE",
    "country-code": "756",
    "iso_3166-2": "ISO 3166-2:CH",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Syrian Arab Republic",
    "alpha-2": "SY",
    "alpha-3": "SYR",
    "country-code": "760",
    "iso_3166-2": "ISO 3166-2:SY",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Taiwan, Province of China",
    "alpha-2": "TW",
    "alpha-3": "TWN",
    "country-code": "158",
    "iso_3166-2": "ISO 3166-2:TW",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Tajikistan",
    "alpha-2": "TJ",
    "alpha-3": "TJK",
    "country-code": "762",
    "iso_3166-2": "ISO 3166-2:TJ",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Tanzania, United Republic of",
    "alpha-2": "TZ",
    "alpha-3": "TZA",
    "country-code": "834",
    "iso_3166-2": "ISO 3166-2:TZ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Thailand",
    "alpha-2": "TH",
    "alpha-3": "THA",
    "country-code": "764",
    "iso_3166-2": "ISO 3166-2:TH",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Timor-Leste",
    "alpha-2": "TL",
    "alpha-3": "TLS",
    "country-code": "626",
    "iso_3166-2": "ISO 3166-2:TL",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Togo",
    "alpha-2": "TG",
    "alpha-3": "TGO",
    "country-code": "768",
    "iso_3166-2": "ISO 3166-2:TG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Tokelau",
    "alpha-2": "TK",
    "alpha-3": "TKL",
    "country-code": "772",
    "iso_3166-2": "ISO 3166-2:TK",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Tonga",
    "alpha-2": "TO",
    "alpha-3": "TON",
    "country-code": "776",
    "iso_3166-2": "ISO 3166-2:TO",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Trinidad and Tobago",
    "alpha-2": "TT",
    "alpha-3": "TTO",
    "country-code": "780",
    "iso_3166-2": "ISO 3166-2:TT",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Tunisia",
    "alpha-2": "TN",
    "alpha-3": "TUN",
    "country-code": "788",
    "iso_3166-2": "ISO 3166-2:TN",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Turkey",
    "alpha-2": "TR",
    "alpha-3": "TUR",
    "country-code": "792",
    "iso_3166-2": "ISO 3166-2:TR",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Turkmenistan",
    "alpha-2": "TM",
    "alpha-3": "TKM",
    "country-code": "795",
    "iso_3166-2": "ISO 3166-2:TM",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Turks and Caicos Islands",
    "alpha-2": "TC",
    "alpha-3": "TCA",
    "country-code": "796",
    "iso_3166-2": "ISO 3166-2:TC",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Tuvalu",
    "alpha-2": "TV",
    "alpha-3": "TUV",
    "country-code": "798",
    "iso_3166-2": "ISO 3166-2:TV",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Uganda",
    "alpha-2": "UG",
    "alpha-3": "UGA",
    "country-code": "800",
    "iso_3166-2": "ISO 3166-2:UG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Ukraine",
    "alpha-2": "UA",
    "alpha-3": "UKR",
    "country-code": "804",
    "iso_3166-2": "ISO 3166-2:UA",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "United Arab Emirates",
    "alpha-2": "AE",
    "alpha-3": "ARE",
    "country-code": "784",
    "iso_3166-2": "ISO 3166-2:AE",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    "alpha-2": "GB",
    "alpha-3": "GBR",
    "country-code": "826",
    "iso_3166-2": "ISO 3166-2:GB",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "United States of America",
    "alpha-2": "US",
    "alpha-3": "USA",
    "country-code": "840",
    "iso_3166-2": "ISO 3166-2:US",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "United States Minor Outlying Islands",
    "alpha-2": "UM",
    "alpha-3": "UMI",
    "country-code": "581",
    "iso_3166-2": "ISO 3166-2:UM",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Uruguay",
    "alpha-2": "UY",
    "alpha-3": "URY",
    "country-code": "858",
    "iso_3166-2": "ISO 3166-2:UY",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Uzbekistan",
    "alpha-2": "UZ",
    "alpha-3": "UZB",
    "country-code": "860",
    "iso_3166-2": "ISO 3166-2:UZ",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Vanuatu",
    "alpha-2": "VU",
    "alpha-3": "VUT",
    "country-code": "548",
    "iso_3166-2": "ISO 3166-2:VU",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    "alpha-2": "VE",
    "alpha-3": "VEN",
    "country-code": "862",
    "iso_3166-2": "ISO 3166-2:VE",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Viet Nam",
    "alpha-2": "VN",
    "alpha-3": "VNM",
    "country-code": "704",
    "iso_3166-2": "ISO 3166-2:VN",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Virgin Islands (British)",
    "alpha-2": "VG",
    "alpha-3": "VGB",
    "country-code": "092",
    "iso_3166-2": "ISO 3166-2:VG",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Virgin Islands (U.S.)",
    "alpha-2": "VI",
    "alpha-3": "VIR",
    "country-code": "850",
    "iso_3166-2": "ISO 3166-2:VI",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Wallis and Futuna",
    "alpha-2": "WF",
    "alpha-3": "WLF",
    "country-code": "876",
    "iso_3166-2": "ISO 3166-2:WF",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Western Sahara",
    "alpha-2": "EH",
    "alpha-3": "ESH",
    "country-code": "732",
    "iso_3166-2": "ISO 3166-2:EH",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Yemen",
    "alpha-2": "YE",
    "alpha-3": "YEM",
    "country-code": "887",
    "iso_3166-2": "ISO 3166-2:YE",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Zambia",
    "alpha-2": "ZM",
    "alpha-3": "ZMB",
    "country-code": "894",
    "iso_3166-2": "ISO 3166-2:ZM",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Zimbabwe",
    "alpha-2": "ZW",
    "alpha-3": "ZWE",
    "country-code": "716",
    "iso_3166-2": "ISO 3166-2:ZW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
]

const countries_coords = [
  {
    id: "1",
    long_name: "Afghanistan",
    short_name: "AF",
    center_lat: "33.939110",
    center_lng: "67.709953",
    sw_lat: "29.377200",
    sw_lng: "60.517000",
    ne_lat: "38.490877",
    ne_lng: "74.889862",
  },
  {
    short_name: "NU",
    center_lat: "-19.0530613",
    center_lng: "-169.9240994",
  },
  {
    short_name: "HK",
    center_lat: "22.2821184",
    center_lng: "114.1598179",
  },
  {
    short_name: "BGD",
    center_lat: "23.8103",
    center_lng: "90.4125",
  },
  {
    short_name: "CK",
    center_lat: "-21.2184194",
    center_lng: "-159.7801351",
  },
  {
    short_name: "AE",
    center_lat: "24.3868306",
    center_lng: "54.5586204",
  },
  {
    short_name: "MM",
    center_lat: "16.871311",
    center_lng: "96.199379",
  },
  {
    short_name: "MH",
    center_lat: "9.5896166",
    center_lng: "166.2286376",
  },
  {
    short_name: "AI",
    center_lat: "18.2151965",
    center_lng: "-63.0597631",
  },
  {
    short_name: "VG",
    center_lat: "18.4243253",
    center_lng: "-64.6158528",
  },

  {
    id: "2",
    long_name: "Albania",
    short_name: "AL",
    center_lat: "41.153332",
    center_lng: "20.168331",
    sw_lat: "39.644730",
    sw_lng: "19.263904",
    ne_lat: "42.661082",
    ne_lng: "21.057239",
  },
  {
    id: "3",
    long_name: "Algeria",
    short_name: "DZ",
    center_lat: "28.033886",
    center_lng: "1.659626",
    sw_lat: "18.968147",
    sw_lng: "-8.666667",
    ne_lat: "37.089821",
    ne_lng: "12.000000",
  },
  {
    id: "4",
    long_name: "Andorra",
    short_name: "AD",
    center_lat: "42.506285",
    center_lng: "1.521801",
    sw_lat: "42.428749",
    sw_lng: "1.408705",
    ne_lat: "42.655791",
    ne_lng: "1.786639",
  },
  {
    id: "5",
    long_name: "Angola",
    short_name: "AO",
    center_lat: "-11.202692",
    center_lng: "17.873887",
    sw_lat: "-18.039104",
    sw_lng: "11.669562",
    ne_lat: "-4.387944",
    ne_lng: "24.084444",
  },
  {
    id: "6",
    long_name: "Antigua",
    short_name: "Antigua",
    center_lat: "17.074656",
    center_lng: "-61.817521",
    sw_lat: "16.997626",
    sw_lng: "-61.906251",
    ne_lat: "17.173760",
    ne_lng: "-61.673155",
  },
  {
    id: "7",
    long_name: "Argentina",
    short_name: "AR",
    center_lat: "-38.416097",
    center_lng: "-63.616672",
    sw_lat: "-55.057715",
    sw_lng: "-73.560360",
    ne_lat: "-21.780814",
    ne_lng: "-53.637481",
  },
  {
    id: "8",
    long_name: "Armenia",
    short_name: "AM",
    center_lat: "40.069099",
    center_lng: "45.038189",
    sw_lat: "38.840244",
    sw_lng: "43.447212",
    ne_lat: "41.300993",
    ne_lng: "46.634222",
  },
  {
    id: "9",
    long_name: "Australia",
    short_name: "AU",
    center_lat: "-25.274398",
    center_lng: "133.775136",
    sw_lat: "-54.777219",
    sw_lng: "112.921454",
    ne_lat: "-9.221084",
    ne_lng: "159.255528",
  },
  {
    id: "10",
    long_name: "Austria",
    short_name: "AT",
    center_lat: "47.516231",
    center_lng: "14.550072",
    sw_lat: "46.372336",
    sw_lng: "9.530783",
    ne_lat: "49.020608",
    ne_lng: "17.160686",
  },
  {
    id: "11",
    long_name: "Azerbaijan",
    short_name: "AZ",
    center_lat: "40.143105",
    center_lng: "47.576927",
    sw_lat: "38.391990",
    sw_lng: "44.764683",
    ne_lat: "41.912340",
    ne_lng: "50.467866",
  },
  {
    id: "12",
    long_name: "The Bahamas",
    short_name: "BS",
    center_lat: "25.034280",
    center_lng: "-77.396280",
    sw_lat: "20.912131",
    sw_lng: "-80.474947",
    ne_lat: "27.263362",
    ne_lng: "-72.712069",
  },
  {
    id: "13",
    long_name: "Bahrain",
    short_name: "BH",
    center_lat: "26.066700",
    center_lng: "50.557700",
    sw_lat: "25.556458",
    sw_lng: "50.378151",
    ne_lat: "26.330394",
    ne_lng: "50.824846",
  },
  {
    id: "14",
    long_name: "Bangladesh",
    short_name: "BD",
    center_lat: "23.684994",
    center_lng: "90.356331",
    sw_lat: "20.754380",
    sw_lng: "88.008589",
    ne_lat: "26.634243",
    ne_lng: "92.680115",
  },
  {
    id: "15",
    long_name: "Barbados",
    short_name: "BB",
    center_lat: "13.193887",
    center_lng: "-59.543198",
    sw_lat: "13.045000",
    sw_lng: "-59.651030",
    ne_lat: "13.335126",
    ne_lng: "-59.420098",
  },
  {
    id: "16",
    long_name: "Belarus",
    short_name: "BY",
    center_lat: "53.709807",
    center_lng: "27.953389",
    sw_lat: "51.262011",
    sw_lng: "23.178338",
    ne_lat: "56.171872",
    ne_lng: "32.776820",
  },
  {
    id: "17",
    long_name: "Belgium",
    short_name: "BE",
    center_lat: "50.503887",
    center_lng: "4.469936",
    sw_lat: "49.497013",
    sw_lng: "2.544795",
    ne_lat: "51.505145",
    ne_lng: "6.408124",
  },
  {
    id: "18",
    long_name: "Belize",
    short_name: "BZ",
    center_lat: "17.189877",
    center_lng: "-88.497650",
    sw_lat: "15.885619",
    sw_lng: "-89.227588",
    ne_lat: "18.495942",
    ne_lng: "-87.491727",
  },
  {
    id: "19",
    long_name: "Benin",
    short_name: "BJ",
    center_lat: "9.307690",
    center_lng: "2.315834",
    sw_lat: "6.234832",
    sw_lng: "0.776667",
    ne_lat: "12.408611",
    ne_lng: "3.843343",
  },
  {
    id: "20",
    long_name: "Bhutan",
    short_name: "BT",
    center_lat: "27.514162",
    center_lng: "90.433601",
    sw_lat: "26.702016",
    sw_lng: "88.746473",
    ne_lat: "28.360825",
    ne_lng: "92.125232",
  },
  {
    id: "21",
    long_name: "Bolivia",
    short_name: "BO",
    center_lat: "-16.290154",
    center_lng: "-63.588653",
    sw_lat: "-22.898090",
    sw_lng: "-69.644990",
    ne_lat: "-9.669323",
    ne_lng: "-57.453803",
  },
  {
    id: "22",
    long_name: "Botswana",
    short_name: "BW",
    center_lat: "-22.328474",
    center_lng: "24.684866",
    sw_lat: "-26.907545",
    sw_lng: "19.998905",
    ne_lat: "-17.778137",
    ne_lng: "29.375304",
  },
  {
    id: "23",
    long_name: "Brazil",
    short_name: "BR",
    center_lat: "-14.235004",
    center_lng: "-51.925280",
    sw_lat: "-33.751748",
    sw_lng: "-73.982817",
    ne_lat: "5.271602",
    ne_lng: "-29.345024",
  },
  {
    id: "24",
    long_name: "Brunei",
    short_name: "BN",
    center_lat: "4.535277",
    center_lng: "114.727669",
    sw_lat: "4.002508",
    sw_lng: "114.076063",
    ne_lat: "5.082646",
    ne_lng: "115.363562",
  },
  {
    id: "25",
    long_name: "Bulgaria",
    short_name: "BG",
    center_lat: "42.733883",
    center_lng: "25.485830",
    sw_lat: "41.235447",
    sw_lng: "22.357345",
    ne_lat: "44.215233",
    ne_lng: "28.609263",
  },
  {
    id: "26",
    long_name: "Burkina Faso",
    short_name: "BF",
    center_lat: "12.238333",
    center_lng: "-1.561593",
    sw_lat: "9.393889",
    sw_lng: "-5.521111",
    ne_lat: "15.085111",
    ne_lng: "2.404293",
  },
  {
    id: "27",
    long_name: "Burundi",
    short_name: "BI",
    center_lat: "-3.373056",
    center_lng: "29.918886",
    sw_lat: "-4.469228",
    sw_lng: "29.000993",
    ne_lat: "-2.309987",
    ne_lng: "30.850173",
  },
  {
    id: "28",
    long_name: "Cambodia",
    short_name: "KH",
    center_lat: "12.565679",
    center_lng: "104.990963",
    sw_lat: "9.276808",
    sw_lng: "102.333542",
    ne_lat: "14.690179",
    ne_lng: "107.627687",
  },
  {
    id: "29",
    long_name: "Cameroon",
    short_name: "CM",
    center_lat: "7.369722",
    center_lng: "12.354722",
    sw_lat: "1.655900",
    sw_lng: "8.494763",
    ne_lat: "13.083335",
    ne_lng: "16.194408",
  },
  {
    id: "30",
    long_name: "Canada",
    short_name: "CA",
    center_lat: "56.130366",
    center_lng: "-106.346771",
    sw_lat: "41.676556",
    sw_lng: "-141.001870",
    ne_lat: "83.115061",
    ne_lng: "-52.619409",
  },
  {
    id: "31",
    long_name: "Cape Verde",
    short_name: "CV",
    center_lat: "15.120142",
    center_lng: "-23.605172",
    sw_lat: "14.802351",
    sw_lng: "-25.360994",
    ne_lat: "17.205287",
    ne_lng: "-22.657778",
  },
  {
    id: "32",
    long_name: "Chad",
    short_name: "TD",
    center_lat: "15.454166",
    center_lng: "18.732207",
    sw_lat: "7.442975",
    sw_lng: "13.470000",
    ne_lat: "23.449235",
    ne_lng: "24.000001",
  },
  {
    id: "33",
    long_name: "Chile",
    short_name: "CL",
    center_lat: "-35.675147",
    center_lng: "-71.542969",
    sw_lat: "-55.979781",
    sw_lng: "-109.454881",
    ne_lat: "-17.498329",
    ne_lng: "-66.418202",
  },
  {
    id: "34",
    long_name: "China",
    short_name: "CN",
    center_lat: "35.861660",
    center_lng: "104.195397",
    sw_lat: "18.153522",
    sw_lng: "73.499414",
    ne_lat: "53.560974",
    ne_lng: "134.772810",
  },
  {
    id: "35",
    long_name: "Colombia",
    short_name: "CO",
    center_lat: "4.570868",
    center_lng: "-74.297333",
    sw_lat: "-4.227110",
    sw_lng: "-81.735930",
    ne_lat: "13.397350",
    ne_lng: "-66.851923",
  },
  {
    id: "36",
    long_name: "Comoros",
    short_name: "KM",
    center_lat: "-11.645500",
    center_lng: "43.333300",
    sw_lat: "-12.413821",
    sw_lng: "43.219421",
    ne_lat: "-11.364639",
    ne_lng: "44.540570",
  },
  {
    id: "37",
    long_name: "Congo",
    short_name: "CG",
    center_lat: "-0.228021",
    center_lng: "15.827659",
    sw_lat: "-5.028949",
    sw_lng: "11.149548",
    ne_lat: "3.707791",
    ne_lng: "18.643611",
  },
  {
    id: "38",
    long_name: "Costa Rica",
    short_name: "CR",
    center_lat: "9.748917",
    center_lng: "-83.753428",
    sw_lat: "5.499153",
    sw_lng: "-87.094513",
    ne_lat: "11.219681",
    ne_lng: "-82.552657",
  },
  {
    id: "39",
    long_name: "Croatia",
    short_name: "HR",
    center_lat: "45.100000",
    center_lng: "15.200000",
    sw_lat: "42.392265",
    sw_lng: "13.489691",
    ne_lat: "46.555223",
    ne_lng: "19.448052",
  },
  {
    id: "40",
    long_name: "Cuba",
    short_name: "CU",
    center_lat: "21.521757",
    center_lng: "-77.781167",
    sw_lat: "19.825899",
    sw_lng: "-85.071247",
    ne_lat: "23.276752",
    ne_lng: "-74.132223",
  },
  {
    id: "41",
    long_name: "Cyprus",
    short_name: "CY",
    center_lat: "35.126413",
    center_lng: "33.429859",
    sw_lat: "34.632303",
    sw_lng: "32.268708",
    ne_lat: "35.707200",
    ne_lng: "34.604500",
  },
  {
    id: "42",
    long_name: "Czech Republic",
    short_name: "CZ",
    center_lat: "49.817492",
    center_lng: "15.472962",
    sw_lat: "48.551808",
    sw_lng: "12.090589",
    ne_lat: "51.055719",
    ne_lng: "18.859236",
  },
  {
    id: "43",
    long_name: "Denmark",
    short_name: "DK",
    center_lat: "56.1554671",
    center_lng: "10.4309955",
    sw_lat: "54.464597",
    sw_lng: "8.072241",
    ne_lat: "57.751813",
    ne_lng: "15.197281",
  },
  {
    id: "44",
    long_name: "Djibouti",
    short_name: "DJ",
    center_lat: "11.825138",
    center_lng: "42.590275",
    sw_lat: "10.931944",
    sw_lng: "41.759722",
    ne_lat: "12.713396",
    ne_lng: "43.416973",
  },
  {
    id: "45",
    long_name: "Dominica",
    short_name: "DM",
    center_lat: "15.414999",
    center_lng: "-61.370976",
    sw_lat: "15.207682",
    sw_lng: "-61.479830",
    ne_lat: "15.640064",
    ne_lng: "-61.240303",
  },
  {
    id: "46",
    long_name: "Timor-Leste",
    short_name: "TL",
    center_lat: "-8.874217",
    center_lng: "125.727539",
    sw_lat: "-9.504195",
    sw_lng: "124.041738",
    ne_lat: "-8.126807",
    ne_lng: "127.341635",
  },
  {
    id: "47",
    long_name: "Ecuador",
    short_name: "EC",
    center_lat: "-1.831239",
    center_lng: "-78.183406",
    sw_lat: "-5.014351",
    sw_lng: "-92.013048",
    ne_lat: "1.687211",
    ne_lng: "-75.188794",
  },
  {
    id: "48",
    long_name: "Egypt",
    short_name: "EG",
    center_lat: "26.820553",
    center_lng: "30.802498",
    sw_lat: "22.000000",
    sw_lng: "24.696775",
    ne_lat: "31.671535",
    ne_lng: "36.894545",
  },
  {
    id: "49",
    long_name: "El Salvador",
    short_name: "SV",
    center_lat: "13.794185",
    center_lng: "-88.896530",
    sw_lat: "13.155431",
    sw_lng: "-90.126810",
    ne_lat: "14.450557",
    ne_lng: "-87.683752",
  },
  {
    id: "50",
    long_name: "Eritrea",
    short_name: "ER",
    center_lat: "15.179384",
    center_lng: "39.782334",
    sw_lat: "12.354723",
    sw_lng: "36.433348",
    ne_lat: "18.021210",
    ne_lng: "43.142977",
  },
  {
    id: "51",
    long_name: "Estonia",
    short_name: "EE",
    center_lat: "58.595272",
    center_lng: "25.013607",
    sw_lat: "57.509316",
    sw_lng: "21.764313",
    ne_lat: "59.685685",
    ne_lng: "28.210139",
  },
  {
    id: "52",
    long_name: "Ethiopia",
    short_name: "ET",
    center_lat: "9.145000",
    center_lng: "40.489673",
    sw_lat: "3.404136",
    sw_lng: "32.997734",
    ne_lat: "14.894215",
    ne_lng: "48.000000",
  },
  {
    id: "53",
    long_name: "Fiji",
    short_name: "FJ",
    center_lat: "-17.713371",
    center_lng: "178.065032",
    sw_lat: "-20.669430",
    sw_lng: "176.909494",
    ne_lat: "-12.479535",
    ne_lng: "-178.230107",
  },
  {
    id: "54",
    long_name: "Finland",
    short_name: "FI",
    center_lat: "61.924110",
    center_lng: "25.748151",
    sw_lat: "59.705440",
    sw_lng: "20.547411",
    ne_lat: "70.092293",
    ne_lng: "31.587100",
  },
  {
    id: "55",
    long_name: "France",
    short_name: "FR",
    center_lat: "46.227638",
    center_lng: "2.213749",
    sw_lat: "41.342328",
    sw_lng: "-5.141228",
    ne_lat: "51.089166",
    ne_lng: "9.560068",
  },
  {
    id: "56",
    long_name: "Gabon",
    short_name: "GA",
    center_lat: "-0.803689",
    center_lng: "11.609444",
    sw_lat: "-3.958372",
    sw_lng: "8.699053",
    ne_lat: "2.318109",
    ne_lng: "14.520556",
  },
  {
    id: "57",
    long_name: "The Gambia",
    short_name: "GM",
    center_lat: "13.443182",
    center_lng: "-15.310139",
    sw_lat: "13.065183",
    sw_lng: "-16.813631",
    ne_lat: "13.826389",
    ne_lng: "-13.798611",
  },
  {
    id: "58",
    long_name: "Georgia",
    short_name: "GE",
    center_lat: "32.165622",
    center_lng: "-82.900075",
    sw_lat: "30.355591",
    sw_lng: "-85.605165",
    ne_lat: "35.000659",
    ne_lng: "-80.840787",
  },
  {
    id: "59",
    long_name: "Germany",
    short_name: "DE",
    center_lat: "51.165691",
    center_lng: "10.451526",
    sw_lat: "47.270112",
    sw_lng: "5.866342",
    ne_lat: "55.058347",
    ne_lng: "15.041896",
  },
  {
    id: "60",
    long_name: "Ghana",
    short_name: "GH",
    center_lat: "7.946527",
    center_lng: "-1.023194",
    sw_lat: "4.738874",
    sw_lng: "-3.260786",
    ne_lat: "11.166668",
    ne_lng: "1.199363",
  },
  {
    id: "61",
    long_name: "Greece",
    short_name: "GR",
    center_lat: "39.074208",
    center_lng: "21.824312",
    sw_lat: "34.801021",
    sw_lng: "19.372423",
    ne_lat: "41.748878",
    ne_lng: "29.645148",
  },
  {
    id: "62",
    long_name: "Grenada",
    short_name: "GD",
    center_lat: "12.116500",
    center_lng: "-61.679000",
    sw_lat: "11.984873",
    sw_lng: "-61.802728",
    ne_lat: "12.530183",
    ne_lng: "-61.377997",
  },
  {
    id: "63",
    long_name: "Guatemala",
    short_name: "GT",
    center_lat: "15.783471",
    center_lng: "-90.230759",
    sw_lat: "13.740021",
    sw_lng: "-92.231836",
    ne_lat: "17.815697",
    ne_lng: "-88.225615",
  },
  {
    id: "64",
    long_name: "Guinea",
    short_name: "GN",
    center_lat: "9.945587",
    center_lng: "-9.696645",
    sw_lat: "7.190909",
    sw_lng: "-15.078206",
    ne_lat: "12.674617",
    ne_lng: "-7.637853",
  },
  {
    id: "65",
    long_name: "Guinea-Bissau",
    short_name: "GW",
    center_lat: "11.803749",
    center_lng: "-15.180413",
    sw_lat: "10.859970",
    sw_lng: "-16.711736",
    ne_lat: "12.684723",
    ne_lng: "-13.627504",
  },
  {
    id: "66",
    long_name: "Guyana",
    short_name: "GY",
    center_lat: "4.860416",
    center_lng: "-58.930180",
    sw_lat: "1.164724",
    sw_lng: "-61.414905",
    ne_lat: "8.546628",
    ne_lng: "-56.491120",
  },
  {
    id: "67",
    long_name: "Haiti",
    short_name: "HT",
    center_lat: "18.971187",
    center_lng: "-72.285215",
    sw_lat: "18.022078",
    sw_lng: "-74.480910",
    ne_lat: "20.089614",
    ne_lng: "-71.621754",
  },
  {
    id: "68",
    long_name: "Honduras",
    short_name: "HN",
    center_lat: "15.199999",
    center_lng: "-86.241905",
    sw_lat: "12.984225",
    sw_lng: "-89.355148",
    ne_lat: "17.417104",
    ne_lng: "-83.127534",
  },
  {
    id: "69",
    long_name: "Hungary",
    short_name: "HU",
    center_lat: "47.162494",
    center_lng: "19.503304",
    sw_lat: "45.737089",
    sw_lng: "16.113387",
    ne_lat: "48.585234",
    ne_lng: "22.898122",
  },
  {
    id: "70",
    long_name: "Iceland",
    short_name: "IS",
    center_lat: "64.963051",
    center_lng: "-19.020835",
    sw_lat: "63.296102",
    sw_lng: "-24.546524",
    ne_lat: "66.566318",
    ne_lng: "-13.495815",
  },
  {
    id: "71",
    long_name: "India",
    short_name: "IN",
    center_lat: "20.593684",
    center_lng: "78.962880",
    sw_lat: "6.753516",
    sw_lng: "68.162386",
    ne_lat: "35.504475",
    ne_lng: "97.395555",
  },
  {
    id: "72",
    long_name: "Indonesia",
    short_name: "ID",
    center_lat: "-0.789275",
    center_lng: "113.921327",
    sw_lat: "-11.007436",
    sw_lng: "95.009707",
    ne_lat: "6.076912",
    ne_lng: "141.019562",
  },
  {
    id: "73",
    long_name: "Iran",
    short_name: "IR",
    center_lat: "32.427908",
    center_lng: "53.688046",
    sw_lat: "25.059429",
    sw_lng: "44.031891",
    ne_lat: "39.781676",
    ne_lng: "63.333337",
  },
  {
    id: "74",
    long_name: "Iraq",
    short_name: "IQ",
    center_lat: "33.223191",
    center_lng: "43.679291",
    sw_lat: "29.061208",
    sw_lng: "38.793603",
    ne_lat: "37.380932",
    ne_lng: "48.575916",
  },
  {
    id: "75",
    long_name: "Ireland",
    short_name: "IE",
    center_lat: "53.412910",
    center_lng: "-8.243890",
    sw_lat: "51.421938",
    sw_lng: "-10.669580",
    ne_lat: "55.388490",
    ne_lng: "-5.994700",
  },
  {
    id: "76",
    long_name: "Israel",
    short_name: "IL",
    center_lat: "31.046051",
    center_lng: "34.851612",
    sw_lat: "29.479700",
    sw_lng: "34.267387",
    ne_lat: "33.332805",
    ne_lng: "35.896244",
  },
  {
    id: "77",
    long_name: "Italy",
    short_name: "IT",
    center_lat: "41.871940",
    center_lng: "12.567380",
    sw_lat: "35.492920",
    sw_lng: "6.626720",
    ne_lat: "47.092000",
    ne_lng: "18.520502",
  },
  {
    id: "78",
    long_name: "Jamaica",
    short_name: "JM",
    center_lat: "18.109581",
    center_lng: "-77.297508",
    sw_lat: "17.705724",
    sw_lng: "-78.368846",
    ne_lat: "18.525310",
    ne_lng: "-76.183159",
  },
  {
    id: "79",
    long_name: "Japan",
    short_name: "JP",
    center_lat: "36.204824",
    center_lng: "138.252924",
    sw_lat: "24.046045",
    sw_lng: "122.933830",
    ne_lat: "45.522772",
    ne_lng: "153.987430",
  },
  {
    id: "80",
    long_name: "Jordan",
    short_name: "JO",
    center_lat: "30.585164",
    center_lng: "36.238414",
    sw_lat: "29.185036",
    sw_lng: "34.958337",
    ne_lat: "33.374688",
    ne_lng: "39.301154",
  },
  {
    id: "81",
    long_name: "Kazakhstan",
    short_name: "KZ",
    center_lat: "48.019573",
    center_lng: "66.923684",
    sw_lat: "40.568584",
    sw_lng: "46.493672",
    ne_lat: "55.441984",
    ne_lng: "87.315415",
  },
  {
    id: "82",
    long_name: "Kenya",
    short_name: "KE",
    center_lat: "-0.023559",
    center_lng: "37.906193",
    sw_lat: "-4.679682",
    sw_lng: "33.909821",
    ne_lat: "5.033421",
    ne_lng: "41.906832",
  },
  {
    id: "83",
    long_name: "Kiribati",
    short_name: "KI",
    center_lat: "1.870919",
    center_lng: "-157.362601",
    sw_lat: "-11.446519",
    sw_lng: "169.521532",
    ne_lat: "4.699959",
    ne_lng: "-150.196032",
  },
  {
    id: "84",
    long_name: "North Korea",
    short_name: "KP",
    center_lat: "40.339852",
    center_lng: "127.510093",
    sw_lat: "37.673332",
    sw_lng: "124.173553",
    ne_lat: "43.011590",
    ne_lng: "130.688500",
  },
  {
    id: "85",
    long_name: "South Korea",
    short_name: "KR",
    center_lat: "35.907757",
    center_lng: "127.766922",
    sw_lat: "33.106110",
    sw_lng: "124.608139",
    ne_lat: "38.616931",
    ne_lng: "130.923218",
  },
  {
    id: "86",
    long_name: "Kosovo",
    short_name: "XK",
    center_lat: "42.581372",
    center_lng: "20.889336",
    sw_lat: "41.857641",
    sw_lng: "20.014284",
    ne_lat: "43.268899",
    ne_lng: "21.789867",
  },
  {
    id: "87",
    long_name: "Kuwait",
    short_name: "KW",
    center_lat: "29.311660",
    center_lng: "47.481766",
    sw_lat: "28.524446",
    sw_lng: "46.553040",
    ne_lat: "30.103699",
    ne_lng: "48.430458",
  },
  {
    id: "88",
    long_name: "Kyrgyzstan",
    short_name: "KG",
    center_lat: "41.204380",
    center_lng: "74.766098",
    sw_lat: "39.180254",
    sw_lng: "69.250998",
    ne_lat: "43.265357",
    ne_lng: "80.226559",
  },
  {
    id: "89",
    long_name: "Laos",
    short_name: "LA",
    center_lat: "19.856270",
    center_lng: "102.495496",
    sw_lat: "13.909720",
    sw_lng: "100.083214",
    ne_lat: "22.502872",
    ne_lng: "107.694830",
  },
  {
    id: "90",
    long_name: "Latvia",
    short_name: "LV",
    center_lat: "56.879635",
    center_lng: "24.603189",
    sw_lat: "55.674777",
    sw_lng: "20.962343",
    ne_lat: "58.085569",
    ne_lng: "28.241403",
  },
  {
    id: "91",
    long_name: "Lebanon",
    short_name: "LB",
    center_lat: "33.854721",
    center_lng: "35.862285",
    sw_lat: "33.055026",
    sw_lng: "35.103778",
    ne_lat: "34.692090",
    ne_lng: "36.623720",
  },
  {
    id: "92",
    long_name: "Lesotho",
    short_name: "LS",
    center_lat: "-29.609988",
    center_lng: "28.233608",
    sw_lat: "-30.675579",
    sw_lng: "27.011231",
    ne_lat: "-28.570801",
    ne_lng: "29.455709",
  },
  {
    id: "93",
    long_name: "Liberia",
    short_name: "LR",
    center_lat: "6.428055",
    center_lng: "-9.429499",
    sw_lat: "4.315414",
    sw_lng: "-11.474248",
    ne_lat: "8.551986",
    ne_lng: "-7.369255",
  },
  {
    id: "94",
    long_name: "Libya",
    short_name: "LY",
    center_lat: "26.335100",
    center_lng: "17.228331",
    sw_lat: "19.500430",
    sw_lng: "9.391466",
    ne_lat: "33.166787",
    ne_lng: "25.146954",
  },
  {
    id: "95",
    long_name: "Liechtenstein",
    short_name: "LI",
    center_lat: "47.166000",
    center_lng: "9.555373",
    sw_lat: "47.048290",
    sw_lng: "9.471620",
    ne_lat: "47.270547",
    ne_lng: "9.635650",
  },
  {
    id: "96",
    long_name: "Lithuania",
    short_name: "LT",
    center_lat: "55.169438",
    center_lng: "23.881275",
    sw_lat: "53.896879",
    sw_lng: "20.954368",
    ne_lat: "56.450321",
    ne_lng: "26.835591",
  },
  {
    id: "97",
    long_name: "Luxembourg",
    short_name: "LU",
    center_lat: "49.815273",
    center_lng: "6.129583",
    sw_lat: "49.447779",
    sw_lng: "5.735670",
    ne_lat: "50.182820",
    ne_lng: "6.530970",
  },
  {
    id: "98",
    long_name: "Madagascar",
    short_name: "MG",
    center_lat: "-18.766947",
    center_lng: "46.869107",
    sw_lat: "-25.606572",
    sw_lng: "43.185139",
    ne_lat: "-11.951964",
    ne_lng: "50.483780",
  },
  {
    id: "99",
    long_name: "Malawi",
    short_name: "MW",
    center_lat: "-13.254308",
    center_lng: "34.301525",
    sw_lat: "-17.135278",
    sw_lng: "32.678889",
    ne_lat: "-9.367154",
    ne_lng: "35.924166",
  },
  {
    id: "100",
    long_name: "Malaysia",
    short_name: "MY",
    center_lat: "4.210484",
    center_lng: "101.975766",
    sw_lat: "0.853821",
    sw_lng: "99.640573",
    ne_lat: "7.363468",
    ne_lng: "119.269362",
  },
  {
    id: "101",
    long_name: "Maldives",
    short_name: "MV",
    center_lat: "1.977247",
    center_lng: "73.536103",
    sw_lat: "-0.703585",
    sw_lng: "72.638581",
    ne_lat: "7.106280",
    ne_lng: "73.759654",
  },
  {
    id: "102",
    long_name: "Mali",
    short_name: "ML",
    center_lat: "17.570692",
    center_lng: "-3.996166",
    sw_lat: "10.147811",
    sw_lng: "-12.238885",
    ne_lat: "25.000012",
    ne_lng: "4.266667",
  },
  {
    id: "103",
    long_name: "Malta",
    short_name: "MT",
    center_lat: "35.937496",
    center_lng: "14.375416",
    sw_lat: "35.805811",
    sw_lng: "14.183548",
    ne_lat: "36.082224",
    ne_lng: "14.575500",
  },
  {
    id: "104",
    long_name: "Marshall Islands",
    short_name: "MH",
    center_lat: "7.131474",
    center_lng: "171.184478",
    sw_lat: "4.572956",
    sw_lng: "160.797959",
    ne_lat: "14.673255",
    ne_lng: "172.170181",
  },
  {
    id: "105",
    long_name: "Mauritania",
    short_name: "MR",
    center_lat: "21.007890",
    center_lng: "-10.940835",
    sw_lat: "14.721273",
    sw_lng: "-17.070134",
    ne_lat: "27.294445",
    ne_lng: "-4.833334",
  },
  {
    id: "106",
    long_name: "Mauritius",
    short_name: "MU",
    center_lat: "-20.348404",
    center_lng: "57.552152",
    sw_lat: "-20.525512",
    sw_lng: "56.514367",
    ne_lat: "-10.336323",
    ne_lng: "63.503594",
  },
  {
    id: "107",
    long_name: "Mexico",
    short_name: "MX",
    center_lat: "23.634501",
    center_lng: "-102.552784",
    sw_lat: "14.534549",
    sw_lng: "-118.364430",
    ne_lat: "32.718763",
    ne_lng: "-86.710571",
  },
  {
    id: "108",
    long_name: "Micronesia",
    short_name: "FM",
    center_lat: "6.887351",
    center_lng: "158.215072",
    sw_lat: "3.822442",
    sw_lng: "138.054982",
    ne_lat: "10.090378",
    ne_lng: "163.035591",
  },
  {
    id: "109",
    long_name: "Moldova",
    short_name: "MD",
    center_lat: "47.411631",
    center_lng: "28.369885",
    sw_lat: "45.466904",
    sw_lng: "26.616856",
    ne_lat: "48.491944",
    ne_lng: "30.162538",
  },
  {
    id: "110",
    long_name: "Monaco",
    short_name: "MC",
    center_lat: "43.738418",
    center_lng: "7.424616",
    sw_lat: "43.724743",
    sw_lng: "7.409105",
    ne_lat: "43.751903",
    ne_lng: "7.439811",
  },
  {
    id: "111",
    long_name: "Mongolia",
    short_name: "MN",
    center_lat: "46.862496",
    center_lng: "103.846656",
    sw_lat: "41.581520",
    sw_lng: "87.737620",
    ne_lat: "52.148697",
    ne_lng: "119.931949",
  },
  {
    id: "112",
    long_name: "Montenegro",
    short_name: "ME",
    center_lat: "42.708678",
    center_lng: "19.374390",
    sw_lat: "41.849731",
    sw_lng: "18.433792",
    ne_lat: "43.558743",
    ne_lng: "20.357765",
  },
  {
    id: "113",
    long_name: "Morocco",
    short_name: "MA",
    center_lat: "31.791702",
    center_lng: "-7.092620",
    sw_lat: "27.666667",
    sw_lng: "-13.172891",
    ne_lat: "35.922507",
    ne_lng: "-0.996976",
  },
  {
    id: "114",
    long_name: "Mozambique",
    short_name: "MZ",
    center_lat: "-18.665695",
    center_lng: "35.529562",
    sw_lat: "-26.868109",
    sw_lng: "30.215550",
    ne_lat: "-10.471202",
    ne_lng: "40.839121",
  },
  {
    id: "115",
    long_name: "Namibia",
    short_name: "NA",
    center_lat: "-22.957640",
    center_lng: "18.490410",
    sw_lat: "-28.970639",
    sw_lng: "11.724247",
    ne_lat: "-16.963486",
    ne_lng: "25.261752",
  },
  {
    id: "116",
    long_name: "Nauru",
    short_name: "NR",
    center_lat: "-0.522778",
    center_lng: "166.931503",
    sw_lat: "-0.554189",
    sw_lng: "166.909549",
    ne_lat: "-0.502640",
    ne_lng: "166.958928",
  },
  {
    id: "117",
    long_name: "Nepal",
    short_name: "NP",
    center_lat: "28.394857",
    center_lng: "84.124008",
    sw_lat: "26.347779",
    sw_lng: "80.052222",
    ne_lat: "30.446945",
    ne_lng: "88.199298",
  },
  {
    id: "118",
    long_name: "The Netherlands",
    short_name: "NL",
    center_lat: "52.132633",
    center_lng: "5.291266",
    sw_lat: "50.750384",
    sw_lng: "3.357962",
    ne_lat: "53.556021",
    ne_lng: "7.227510",
  },
  {
    id: "119",
    long_name: "New Zealand",
    short_name: "NZ",
    center_lat: "-40.900557",
    center_lng: "174.885971",
    sw_lat: "-52.619418",
    sw_lng: "165.869437",
    ne_lat: "-29.231342",
    ne_lng: "-175.831536",
  },
  {
    id: "120",
    long_name: "Nicaragua",
    short_name: "NI",
    center_lat: "12.865416",
    center_lng: "-85.207229",
    sw_lat: "10.708055",
    sw_lng: "-87.691069",
    ne_lat: "15.030276",
    ne_lng: "-82.592071",
  },
  {
    id: "121",
    long_name: "Niger",
    short_name: "NE",
    center_lat: "17.607789",
    center_lng: "8.081666",
    sw_lat: "11.693756",
    sw_lng: "0.166667",
    ne_lat: "23.500000",
    ne_lng: "15.999034",
  },
  {
    id: "122",
    long_name: "Nigeria",
    short_name: "NG",
    center_lat: "9.081999",
    center_lng: "8.675277",
    sw_lat: "4.269857",
    sw_lng: "2.676932",
    ne_lat: "13.885645",
    ne_lng: "14.677981",
  },
  {
    id: "123",
    long_name: "Norway",
    short_name: "NO",
    center_lat: "60.472024",
    center_lng: "8.468946",
    sw_lat: "57.959744",
    sw_lng: "4.500096",
    ne_lat: "71.185476",
    ne_lng: "31.168268",
  },
  {
    id: "124",
    long_name: "Oman",
    short_name: "OM",
    center_lat: "21.512583",
    center_lng: "55.923255",
    sw_lat: "16.650336",
    sw_lng: "52.000002",
    ne_lat: "26.405395",
    ne_lng: "59.839397",
  },
  {
    id: "125",
    long_name: "Pakistan",
    short_name: "PK",
    center_lat: "30.375321",
    center_lng: "69.345116",
    sw_lat: "23.694695",
    sw_lng: "60.872972",
    ne_lat: "37.084107",
    ne_lng: "77.833469",
  },
  {
    id: "126",
    long_name: "Palau",
    short_name: "PW",
    center_lat: "7.514980",
    center_lng: "134.582520",
    sw_lat: "2.812743",
    sw_lng: "131.120110",
    ne_lat: "8.094023",
    ne_lng: "134.721099",
  },
  {
    id: "127",
    long_name: "Panama",
    short_name: "PA",
    center_lat: "8.537981",
    center_lng: "-80.782127",
    sw_lat: "7.203556",
    sw_lng: "-83.052241",
    ne_lat: "9.647779",
    ne_lng: "-77.158488",
  },
  {
    id: "128",
    long_name: "Papua New Guinea",
    short_name: "PG",
    center_lat: "-6.314993",
    center_lng: "143.955550",
    sw_lat: "-11.657861",
    sw_lng: "140.841970",
    ne_lat: "-0.871319",
    ne_lng: "159.492959",
  },
  {
    id: "129",
    long_name: "Paraguay",
    short_name: "PY",
    center_lat: "-23.442503",
    center_lng: "-58.443832",
    sw_lat: "-27.588334",
    sw_lng: "-62.638051",
    ne_lat: "-19.287707",
    ne_lng: "-54.258562",
  },
  {
    id: "130",
    long_name: "Peru",
    short_name: "PE",
    center_lat: "-9.189967",
    center_lng: "-75.015152",
    sw_lat: "-18.351580",
    sw_lng: "-81.328504",
    ne_lat: "-0.038777",
    ne_lng: "-68.652329",
  },
  {
    id: "131",
    long_name: "Philippines",
    short_name: "PH",
    center_lat: "12.879721",
    center_lng: "121.774017",
    sw_lat: "4.613444",
    sw_lng: "116.931557",
    ne_lat: "19.574024",
    ne_lng: "126.604384",
  },
  {
    id: "132",
    long_name: "Poland",
    short_name: "PL",
    center_lat: "51.919438",
    center_lng: "19.145136",
    sw_lat: "49.002025",
    sw_lng: "14.122864",
    ne_lat: "54.835812",
    ne_lng: "24.145893",
  },
  {
    id: "133",
    long_name: "Portugal",
    short_name: "PT",
    center_lat: "39.399872",
    center_lng: "-8.224454",
    sw_lat: "32.403740",
    sw_lng: "-31.275330",
    ne_lat: "42.154205",
    ne_lng: "-6.190209",
  },
  {
    id: "134",
    long_name: "Qatar",
    short_name: "QA",
    center_lat: "25.354826",
    center_lng: "51.183884",
    sw_lat: "24.471118",
    sw_lng: "50.750055",
    ne_lat: "26.183093",
    ne_lng: "51.643260",
  },
  {
    id: "135",
    long_name: "Romania",
    short_name: "RO",
    center_lat: "45.943161",
    center_lng: "24.966760",
    sw_lat: "43.618619",
    sw_lng: "20.261759",
    ne_lat: "48.265274",
    ne_lng: "29.757101",
  },
  {
    id: "136",
    long_name: "Russia",
    short_name: "RU",
    center_lat: "61.524010",
    center_lng: "105.318756",
    sw_lat: "41.185353",
    sw_lng: "19.640535",
    ne_lat: "81.858122",
    ne_lng: "-169.046727",
  },
  {
    id: "137",
    long_name: "Rwanda",
    short_name: "RW",
    center_lat: "-1.940278",
    center_lng: "29.873888",
    sw_lat: "-2.839840",
    sw_lng: "28.861755",
    ne_lat: "-1.047572",
    ne_lng: "30.899401",
  },
  {
    id: "138",
    long_name: "Samoa",
    short_name: "WS",
    center_lat: "-13.759029",
    center_lng: "-172.104629",
    sw_lat: "-14.076588",
    sw_lng: "-172.803676",
    ne_lat: "-13.440033",
    ne_lng: "-171.405859",
  },
  {
    id: "139",
    long_name: "San Marino",
    short_name: "SM",
    center_lat: "43.942360",
    center_lng: "12.457777",
    sw_lat: "43.893681",
    sw_lng: "12.403482",
    ne_lat: "43.992075",
    ne_lng: "12.516704",
  },
  {
    id: "140",
    long_name: "Saudi Arabia",
    short_name: "SA",
    center_lat: "23.885942",
    center_lng: "45.079162",
    sw_lat: "16.379528",
    sw_lng: "34.548998",
    ne_lat: "32.154284",
    ne_lng: "55.666700",
  },
  {
    id: "141",
    long_name: "Senegal",
    short_name: "SN",
    center_lat: "14.497401",
    center_lng: "-14.452362",
    sw_lat: "12.307289",
    sw_lng: "-17.529848",
    ne_lat: "16.693054",
    ne_lng: "-11.348607",
  },
  {
    id: "142",
    long_name: "Serbia",
    short_name: "RS",
    center_lat: "44.016521",
    center_lng: "21.005859",
    sw_lat: "42.231503",
    sw_lng: "18.838522",
    ne_lat: "46.190032",
    ne_lng: "23.006310",
  },
  {
    id: "143",
    long_name: "Seychelles",
    short_name: "SC",
    center_lat: "-4.679574",
    center_lng: "55.491977",
    sw_lat: "-10.227033",
    sw_lng: "46.197785",
    ne_lat: "-4.209786",
    ne_lng: "56.294294",
  },
  {
    id: "144",
    long_name: "Sierra Leone",
    short_name: "SL",
    center_lat: "8.460555",
    center_lng: "-11.779889",
    sw_lat: "6.899025",
    sw_lng: "-13.302007",
    ne_lat: "9.999972",
    ne_lng: "-10.271651",
  },
  {
    id: "145",
    long_name: "Singapore",
    short_name: "SG",
    center_lat: "1.352083",
    center_lng: "103.819836",
    sw_lat: "1.166398",
    sw_lng: "103.605575",
    ne_lat: "1.470772",
    ne_lng: "104.085557",
  },
  {
    id: "146",
    long_name: "Slovakia",
    short_name: "SK",
    center_lat: "48.669026",
    center_lng: "19.699024",
    sw_lat: "47.731159",
    sw_lng: "16.833182",
    ne_lat: "49.613805",
    ne_lng: "22.558934",
  },
  {
    id: "147",
    long_name: "Slovenia",
    short_name: "SI",
    center_lat: "46.151241",
    center_lng: "14.995463",
    sw_lat: "45.421674",
    sw_lng: "13.375336",
    ne_lat: "46.876659",
    ne_lng: "16.596686",
  },
  {
    id: "148",
    long_name: "Solomon Islands",
    short_name: "SB",
    center_lat: "-9.645710",
    center_lng: "160.156194",
    sw_lat: "-11.863458",
    sw_lng: "155.486241",
    ne_lat: "-6.589240",
    ne_lng: "167.283081",
  },
  {
    id: "149",
    long_name: "Somalia",
    short_name: "SO",
    center_lat: "5.152149",
    center_lng: "46.199616",
    sw_lat: "-1.662041",
    sw_lng: "40.994373",
    ne_lat: "11.988614",
    ne_lng: "51.413029",
  },
  {
    id: "150",
    long_name: "South Africa",
    short_name: "ZA",
    center_lat: "-30.559482",
    center_lng: "22.937506",
    sw_lat: "-34.833138",
    sw_lng: "16.454436",
    ne_lat: "-22.125387",
    ne_lng: "32.890991",
  },
  {
    id: "151",
    long_name: "South Sudan",
    short_name: "SS",
    center_lat: "7.963092",
    center_lng: "30.158930",
    sw_lat: "3.488980",
    sw_lng: "23.440849",
    ne_lat: "12.236389",
    ne_lng: "35.948997",
  },
  {
    id: "152",
    long_name: "Spain",
    short_name: "ES",
    center_lat: "40.463667",
    center_lng: "-3.749220",
    sw_lat: "27.637789",
    sw_lng: "-18.160788",
    ne_lat: "43.792380",
    ne_lng: "4.327784",
  },
  {
    id: "153",
    long_name: "Sri Lanka",
    short_name: "LK",
    center_lat: "7.873054",
    center_lng: "80.771797",
    sw_lat: "5.919078",
    sw_lng: "79.521983",
    ne_lat: "9.836001",
    ne_lng: "81.878703",
  },
  {
    id: "154",
    long_name: "Saint Lucia",
    short_name: "LC",
    center_lat: "13.909444",
    center_lng: "-60.978893",
    sw_lat: "13.708118",
    sw_lng: "-61.079672",
    ne_lat: "14.110932",
    ne_lng: "-60.873098",
  },
  {
    id: "155",
    long_name: "Sudan",
    short_name: "SD",
    center_lat: "12.862807",
    center_lng: "30.217636",
    sw_lat: "9.347221",
    sw_lng: "21.814939",
    ne_lat: "22.224918",
    ne_lng: "38.584219",
  },
  {
    id: "156",
    long_name: "Suriname",
    short_name: "SR",
    center_lat: "3.919305",
    center_lng: "-56.027783",
    sw_lat: "1.837306",
    sw_lng: "-58.070506",
    ne_lat: "6.009283",
    ne_lng: "-53.951024",
  },
  {
    id: "157",
    long_name: "Swaziland",
    short_name: "SZ",
    center_lat: "-26.522503",
    center_lng: "31.465866",
    sw_lat: "-27.317363",
    sw_lng: "30.791094",
    ne_lat: "-25.718520",
    ne_lng: "32.134844",
  },
  {
    id: "158",
    long_name: "Sweden",
    short_name: "SE",
    center_lat: "60.128161",
    center_lng: "18.643501",
    sw_lat: "55.328720",
    sw_lng: "10.963187",
    ne_lat: "69.059971",
    ne_lng: "24.166809",
  },
  {
    id: "159",
    long_name: "Switzerland",
    short_name: "CH",
    center_lat: "46.818188",
    center_lng: "8.227512",
    sw_lat: "45.817920",
    sw_lng: "5.956080",
    ne_lat: "47.808455",
    ne_lng: "10.492340",
  },
  {
    id: "160",
    long_name: "Syria",
    short_name: "SY",
    center_lat: "34.802075",
    center_lng: "38.996815",
    sw_lat: "32.311136",
    sw_lng: "35.716596",
    ne_lat: "37.320569",
    ne_lng: "42.376309",
  },
  {
    id: "161",
    long_name: "Taiwan",
    short_name: "TW",
    center_lat: "23.697810",
    center_lng: "120.960515",
    sw_lat: "20.563791",
    sw_lng: "116.711860",
    ne_lat: "26.387353",
    ne_lng: "122.006905",
  },
  {
    id: "162",
    long_name: "Tajikistan",
    short_name: "TJ",
    center_lat: "38.861034",
    center_lng: "71.276093",
    sw_lat: "36.671990",
    sw_lng: "67.342012",
    ne_lat: "41.044367",
    ne_lng: "75.153956",
  },
  {
    id: "163",
    long_name: "Tanzania",
    short_name: "TZ",
    center_lat: "-6.369028",
    center_lng: "34.888822",
    sw_lat: "-11.761254",
    sw_lng: "29.340000",
    ne_lat: "-0.984397",
    ne_lng: "40.444965",
  },
  {
    id: "164",
    long_name: "Thailand",
    short_name: "TH",
    center_lat: "15.870032",
    center_lng: "100.992541",
    sw_lat: "5.612729",
    sw_lng: "97.343396",
    ne_lat: "20.465143",
    ne_lng: "105.636812",
  },
  {
    id: "165",
    long_name: "Togo",
    short_name: "TG",
    center_lat: "8.619543",
    center_lng: "0.824782",
    sw_lat: "6.112358",
    sw_lng: "-0.144042",
    ne_lat: "11.139496",
    ne_lng: "1.809050",
  },
  {
    id: "166",
    long_name: "Tonga",
    short_name: "TO",
    center_lat: "-21.178986",
    center_lng: "-175.198242",
    sw_lat: "-21.473461",
    sw_lng: "-175.679616",
    ne_lat: "-15.566393",
    ne_lng: "-173.702484",
  },
  {
    id: "167",
    long_name: "Tunisia",
    short_name: "TN",
    center_lat: "33.886917",
    center_lng: "9.537499",
    sw_lat: "30.228034",
    sw_lng: "7.522313",
    ne_lat: "37.358287",
    ne_lng: "11.599217",
  },
  {
    id: "168",
    long_name: "Turkey",
    short_name: "TR",
    center_lat: "38.963745",
    center_lng: "35.243322",
    sw_lat: "35.807680",
    sw_lng: "25.663637",
    ne_lat: "42.106239",
    ne_lng: "44.818128",
  },
  {
    id: "169",
    long_name: "Turkmenistan",
    short_name: "TM",
    center_lat: "38.969719",
    center_lng: "59.556278",
    sw_lat: "35.128760",
    sw_lng: "52.447845",
    ne_lat: "42.798844",
    ne_lng: "66.707353",
  },
  {
    id: "170",
    long_name: "Tuvalu",
    short_name: "TV",
    center_lat: "-7.478442",
    center_lng: "178.679921",
    sw_lat: "-10.791659",
    sw_lng: "176.058891",
    ne_lat: "-5.642230",
    ne_lng: "179.872261",
  },
  {
    id: "171",
    long_name: "Uganda",
    short_name: "UG",
    center_lat: "1.373333",
    center_lng: "32.290275",
    sw_lat: "-1.481542",
    sw_lng: "29.573433",
    ne_lat: "4.223001",
    ne_lng: "35.033049",
  },
  {
    id: "172",
    long_name: "Ukraine",
    short_name: "UA",
    center_lat: "48.379433",
    center_lng: "31.165580",
    sw_lat: "44.386463",
    sw_lng: "22.137159",
    ne_lat: "52.379581",
    ne_lng: "40.228581",
  },
  {
    id: "173",
    long_name: "United Kingdom",
    short_name: "GB",
    center_lat: "55.378051",
    center_lng: "-3.435973",
    sw_lat: "49.562515",
    sw_lng: "-12.649357",
    ne_lat: "59.860670",
    ne_lng: "3.976555",
  },
  {
    id: "174",
    long_name: "United States",
    short_name: "US",
    center_lat: "39.500240",
    center_lng: "-98.350891",
    sw_lat: "25.820898",
    sw_lng: "-124.712891",
    ne_lat: "49.380240",
    ne_lng: "-66.940662",
  },
  {
    id: "175",
    long_name: "Uruguay",
    short_name: "UY",
    center_lat: "-32.522779",
    center_lng: "-55.765835",
    sw_lat: "-35.031419",
    sw_lng: "-58.439150",
    ne_lat: "-30.085215",
    ne_lng: "-53.184292",
  },
  {
    id: "176",
    long_name: "Uzbekistan",
    short_name: "UZ",
    center_lat: "41.377491",
    center_lng: "64.585262",
    sw_lat: "37.172257",
    sw_lng: "55.998218",
    ne_lat: "45.590075",
    ne_lng: "73.148946",
  },
  {
    id: "177",
    long_name: "Vanuatu",
    short_name: "VU",
    center_lat: "-15.376706",
    center_lng: "166.959158",
    sw_lat: "-20.252293",
    sw_lng: "166.541759",
    ne_lat: "-13.072455",
    ne_lng: "170.238460",
  },
  {
    id: "178",
    long_name: "Vatican City",
    short_name: "VA",
    center_lat: "41.902916",
    center_lng: "12.453389",
    sw_lat: "41.900198",
    sw_lng: "12.445687",
    ne_lat: "41.907561",
    ne_lng: "12.458480",
  },
  {
    id: "179",
    long_name: "Venezuela",
    short_name: "VE",
    center_lat: "6.423750",
    center_lng: "-66.589730",
    sw_lat: "0.647529",
    sw_lng: "-73.351558",
    ne_lat: "12.486694",
    ne_lng: "-59.805666",
  },
  {
    id: "180",
    long_name: "Vietnam",
    short_name: "VN",
    center_lat: "14.058324",
    center_lng: "108.277199",
    sw_lat: "8.412730",
    sw_lng: "102.144410",
    ne_lat: "23.393395",
    ne_lng: "109.468975",
  },
  {
    id: "181",
    long_name: "Yemen",
    short_name: "YE",
    center_lat: "15.552727",
    center_lng: "48.516388",
    sw_lat: "12.108166",
    sw_lng: "41.816055",
    ne_lat: "18.999633",
    ne_lng: "54.533555",
  },
  {
    id: "182",
    long_name: "Zambia",
    short_name: "ZM",
    center_lat: "-13.133897",
    center_lng: "27.849332",
    sw_lat: "-18.077418",
    sw_lng: "21.996388",
    ne_lat: "-8.203284",
    ne_lng: "33.702222",
  },
  {
    id: "183",
    long_name: "Zimbabwe",
    short_name: "ZW",
    center_lat: "-19.015438",
    center_lng: "29.154857",
    sw_lat: "-22.424523",
    sw_lng: "25.237368",
    ne_lat: "-15.609319",
    ne_lng: "33.068236",
  },
]

export { BoundingBox, Countries, countries_coords }
