import { memo } from "react"
import styled from "styled-components"

function Header({ name = "" }) {
  return (
    <Holder>
      <h4>Viewing Permissions</h4>
      <p>{name}</p>
    </Holder>
  )
}

export default memo(Header)

const Holder = styled.div`
  > h4 {
    font-size: 1.2em;
    font-weight: 600;
    color: #122945;
  }
  > p {
    font-size: 0.9em;
    font-weight: 100;
    color: #6c757d;
    margin-top: 0.5em;
  }
`
