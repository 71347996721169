import { memo } from "react"
import styled from "styled-components"
import { toast } from "react-toastify"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { H9 } from "components/Labels"
import { usePostHog } from 'posthog-js/react'

import { createSingleUser } from "api/auth"

function SingleAccountConfirmDialog({
  firstName,
  lastName,
  emailAddress,
  role,
  organization,
  title,
  visible,
  onHide,
}) {
  const posthog = usePostHog()

  const handleSubmit = async () => {
    if (emailAddress?.length && firstName?.length) {  //&& lastName?.length) {
      const payload = {
        email: emailAddress,
        first_name: firstName,
        last_name: lastName || "",
        role,
        organization,
        title: title || "",
      }
      onHide()
      await createSingleUser(payload)
        .then(() => {
          posthog.capture("User created", {
            "new_user": emailAddress
          })
          toast.success("User created successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        })
        .catch(() => {
          toast.error("Error while creating user.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        })
    } else {
      toast.error("Please complete all required fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }
  }

  return (
    <Holder visible={visible} onHide={onHide} header="Confirm user details">
      <Content>
        <div>
          <TextContainer>
            <H9>First Name: {firstName}</H9>
            <H9>Last Name: {lastName}</H9>
            <H9>Email Address: {emailAddress}</H9>
            <H9>Organization: {organization}</H9>
            <H9>Title: {title}</H9>
            <H9>Role: {role === 0 ? "Administrator" : "User"}</H9>
          </TextContainer>
        </div>
      </Content>

      <Button
        className="p-button p-button-primary"
        onClick={handleSubmit}
        label="Submit"
      />
    </Holder>
  )
}

export default memo(SingleAccountConfirmDialog)

const Holder = styled(Dialog)`
  min-width: 300px;
  width: 50vw;
  max-width: 1200px;
  .p-dialog-content {
    padding: 1em;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`
const Content = styled.div`
  padding: 0em 1em;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`
