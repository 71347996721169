import { memo } from "react"
import styled from "styled-components"

import { formatDateString } from "utils"
import { useStore } from "state/store"
import CalendarIcon from "assets/images/icon-calendar.svg"

function DateRangePicker({ onClick }) {
  const filterStartDate = useStore((state) => state.filterStartDate)
  const filterEndDate = useStore((state) => state.filterEndDate)

  return (
    <DateRanger onClick={onClick}>
      <img src={CalendarIcon} alt="calendar" />
      <span>{`${formatDateString(filterStartDate)} - ${formatDateString(
        filterEndDate,
      )}`}</span>
    </DateRanger>
  )
}

export default memo(DateRangePicker)

const DateRanger = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8em;
  cursor: pointer;
  > span {
    font-size: 1.1em;
  }
`
