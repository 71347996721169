import { useEffect, useMemo, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import styled from "styled-components"
import { isEqual } from "lodash-es"

import { getRrpRequest } from "api/rrp"
import { useStore } from "state/store"
import { USER_KEYS } from "dataset/user"
import { RRP_KEYS } from "dataset/rrp"
import { H31, H30 } from "components/Labels"
import { MiddleContainer } from "components/Containers"
import PageTransition from "components/PageTransition"
import PageHead from "./components/PageHead"
import RRPDataTable from "./components/RRPDataTable"
import PermissionDialog from "./components/PermissionDialog"
import RRPUploadDialog from "./components/RRPUploadDialog"
import PermissionControlDialog from "./components/PermissionDialog/PermissionControlDialog"
import RRPRemoveProductDialog from "./components/RRPRemoveProductDialog"
import RRPEditProductDialog from "./components/RRPEditProductDialog"

export default function RapidResponseProgram() {
  const currentUser = useStore((state) => state.currentUser)
  const needGetRrp = useStore((state) => state.needGetRrp)
  const setNeedGetRrp = useStore((state) => state.setNeedGetRrp)
  const setRrpUploadDialogVisible = useStore(
    (state) => state.setRrpUploadDialogVisible,
  )

  // Re-fetch data when permissions change
  const { data, isLoading } = useQuery(["getRrpRequest", needGetRrp], () =>
    getRrpRequest(),
  )

  const showNewRequest = useMemo(() => {
    const isAdmin = isEqual(currentUser?.[USER_KEYS.role], 0)

    if (isAdmin) {
      return true
    }

    return isEqual(currentUser?.[USER_KEYS.user_features], [0])
  }, [currentUser])
  const isAdminUser = useMemo(() => {
    return isEqual(currentUser?.[USER_KEYS.role], 0)
  }, [currentUser])

  const [searchValue, setSearchValue] = useState("")
  const [rrpData, setRrpData] = useState(data)

  const handleUpload = () => {
    setRrpUploadDialogVisible(true)
  }
  const handleNewRequest = () => {
    window.open("https://form.jotform.com/230254387765059", "_blank")
  }

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value ?? "")
  }

  useEffect(() => {
    if (needGetRrp) {
      setNeedGetRrp(false)
    }
  }, [needGetRrp])

  useEffect(() => {
    if (!data) {
      return
    }
    const filteredData = data.filter((item) => {
      return item[RRP_KEYS.file_name]
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    })
    setRrpData(filteredData)
  }, [searchValue, data])

  return (
    <PageTransition>
      <Holder>
        <PageHead>
          <div className="flex flex-wrap gap-3 justify-content-between align-items-end">
            <div>
              <H31 style={{ lineHeight: 1.7 }}>Insights</H31>
              <H30>
                Access C4ADS analysis on DPRK maritime sanctions evasion
                networks and trends directly in Arion
              </H30>
            </div>
            <div className="flex gap-2 mb-2">
              {isAdminUser && (
                <Button
                  label="Upload"
                  className="p-button-raised"
                  icon="pi pi-upload"
                  onClick={handleUpload}
                />
              )}
              {showNewRequest && (
                <Button
                  label="New Request"
                  className="p-button-raised"
                  icon="pi pi-plus"
                  onClick={handleNewRequest}
                />
              )}
            </div>
          </div>
        </PageHead>
        <MiddleContainer className="pt-4">
          <span className="p-input-icon-left w-full mb-3">
            <i className="pi pi-search" />
            <StyledInputText
              placeholder="Search Products..."
              className="w-full"
              value={searchValue}
              onChange={handleSearchChange}
            />
          </span>
          <RRPDataTable
            value={rrpData}
            isLoading={isLoading}
            editable={isAdminUser}
          />
        </MiddleContainer>
        <PermissionDialog />
        <PermissionControlDialog />
        <RRPUploadDialog />
        <RRPRemoveProductDialog />
        <RRPEditProductDialog />
      </Holder>
    </PageTransition>
  )
}

const Holder = styled.div`
  min-height: 85vh;
`

const StyledInputText = styled(InputText)`
  background: transparent;
  padding: 0.7em 1em;
`
