import { useEffect, useState } from "react"
import styled from "styled-components"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { useStore } from "state/store"
import { H34, H7, H8 } from "components/Labels"
import { Radio } from "components/Form"
import {
  RectangularRadioGroup,
  RectangularRadio,
} from "components/Form/RectangularRadio"
import GACSlider from "../Slider"

import ReportIcon from "assets/images/icon-report.svg"
import { usePostHog } from 'posthog-js/react'

export default function FilterDialog() {
  const posthog = usePostHog()

  const { chartFilterDialogVisible, setChartFilterDialogVisible } = useStore(
    (state) => state,
  )

  const setChartSettings = useStore((state) => state.setChartSettings)

  const onHide = () => {
    setChartFilterDialogVisible(false)
  }

  const Header = () => {
    return (
      <div>
        <div className="flex align-items-center gap-3">
          <Icon src={ReportIcon} alt="" />
          <H7>Chart Settings</H7>
        </div>
      </div>
    )
  }

  const Footer = () => {
    return (
      <div className="flex justify-content-end">
        {/* <Button
          label="Reset"
          className="p-button-outlined mr-2"
          onClick={handleReset}
        /> */}
        <Button
          label="Apply"
          className="p-button-raised"
          onClick={handleFilter}
        />
      </div>
    )
  }

  const [yAxis, setYAxis] = useState("VOLUME")
  const [xAxis, setXAxis] = useState("YEAR")

  const [breadthValue, setBreadthValue] = useState(30)

  const handleFilter = () => {
    let chartFilterParams = {
      yAxis,
      volumeRange: yAxis === "VOLUME" ? breadthValue : null,
      xAxis,
      from: "popup",
    }
    setChartSettings(chartFilterParams)
    posthog.capture("Oil shipment chart filtered")
    onHide()
  }

  const handleReset = () => {
    setYAxis("VOLUME")
    setXAxis("YEAR")
    setBreadthValue(0)
    setChartSettings({
      yAxis: "VOLUME",
      volumeRange: yAxis === "VOLUME" ? breadthValue : null,
      xAxis: "YEAR",
      from: "popup",
    })
    onHide()
  }

  useEffect(() => {
    let chartFilterParamsNew = {
      yAxis,
      volumeRange: yAxis === "VOLUME" ? breadthValue : null,
      xAxis,
      from: "popup",
    }
    setChartSettings(chartFilterParamsNew)
  }, [])

  return (
    <StyledDialog
      header={Header}
      footer={Footer}
      visible={chartFilterDialogVisible}
      onHide={onHide}
    >
      <Content>
        <div>
          <H34>Measurement (Y Axis)</H34>
          <H8 className="mt-2">
            Set the unit of measurement for DPRK oil shipments
          </H8>
          <RadioButtonsContainer>
            <RadioContainer>
              <Radio
                value="VOLUME"
                inputId="VOLUME"
                name="yAxis"
                onChange={(e) => setYAxis(e.value)}
                checked={yAxis === "VOLUME"}
              />
              <RadioLabel htmlFor="VOLUME">
                Volume (Barrels)
                <H8 className="mt-1">
                  Set the estimated percentage laden value for calculating the
                  volume of oil in barrels
                </H8>
              </RadioLabel>
            </RadioContainer>
            <SliderContainer className="py-2 mb-5">
              <div className="p-4 pb-5">
                <GACSlider
                  disabled={yAxis !== "VOLUME"}
                  value={breadthValue}
                  onChange={(val) => setBreadthValue(val)}
                />
              </div>
            </SliderContainer>
            <RadioContainer>
              <Radio
                inputId="NUMBER_OF_SHIPMENTS"
                value="NUMBER_OF_SHIPMENTS"
                name="yAxis"
                onChange={(e) => setYAxis(e.value)}
                checked={yAxis === "NUMBER_OF_SHIPMENTS"}
              />
              <RadioLabel htmlFor="NUMBER_OF_SHIPMENTS">
                Number of shipments
                <H8 className="mt-1">Count of shipments</H8>
              </RadioLabel>
            </RadioContainer>
            <RadioContainer>
              <Radio
                inputId="NUMBER_OF_SHIPS"
                value="NUMBER_OF_SHIPS"
                name="yAxis"
                onChange={(e) => setYAxis(e.value)}
                checked={yAxis === "NUMBER_OF_SHIPS"}
              />
              <RadioLabel htmlFor="NUMBER_OF_SHIPS">
                Number of ships
                <H8 className="mt-1">Count of unique ships</H8>
              </RadioLabel>
            </RadioContainer>
          </RadioButtonsContainer>
        </div>
        <div className="mt-5">
          <H34>Time Interval (X Axis)</H34>
          <H8 className="mt-2">Set the time interval of the chart</H8>
          {/* <RectangularRadioDefault /> */}
          <RectangularRadioGroup>
            <RectangularRadio
              id="day"
              label="Day"
              name="xAxis"
              value="DAY"
              onChange={(e) => setXAxis(e.target.defaultValue)}
              checked={xAxis === "DAY"}
            />
            <RectangularRadio
              id="month"
              label="Month"
              name="xAxis"
              value="MONTH"
              onChange={(e) => setXAxis(e.target.defaultValue)}
              checked={xAxis === "MONTH"}
            />
            <RectangularRadio
              id="year"
              label="Year"
              name="xAxis"
              value="YEAR"
              onChange={(e) => setXAxis(e.target.defaultValue)}
              checked={xAxis === "YEAR"}
            />
          </RectangularRadioGroup>
        </div>
      </Content>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)`
  min-width: 300px;
  width: 50vw;
  max-width: 1200px;
`

const Icon = styled.img`
  width: 1.2em;
  height: 1.2em;
`

const Content = styled.div`
  padding: 1.5em 0;
`

const RadioButtonsContainer = styled.div`
  margin: 1em 0;
`
const RadioContainer = styled.div`
  margin: 1em 0 1.5em 0;
  display: flex;
`

const RadioLabel = styled.label`
  font-size: 0.875em;
  font-weight: 700;
  line-height: 1em;
  padding-left: 0.9em;
`

const SliderContainer = styled.div`
  background: #f2f2f2;
  border-radius: 6px;

  .p-slider-handle {
    //position:relative;
    &:after {
      content: attr(aria-valuenow);
      position: absolute;
      padding: 0.5em;
      top: -35px;
      left: -7px;
      font-weight: 600;
      color: #f75151;
    }
  }
`
