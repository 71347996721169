import { memo } from "react"
import styled from "styled-components"
import { MiddleContainer } from "components/Containers"
import PatternImage from "assets/images/ost-pattern.svg"

function PageHead({ children }) {
  return (
    <Header>
      <MiddleContainer>{children}</MiddleContainer>
    </Header>
  )
}

export default memo(PageHead)

const Header = styled.section`
  position: relative;
  padding: 11em 0 1em 0;
  background: linear-gradient(180deg, #dde4ec 0%, rgba(255, 255, 255, 0) 100%);
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${PatternImage});
    background-repeat: no-repeat;
    background-position: center center;
  }
`
