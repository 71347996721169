import styled from "styled-components"
import { useStore } from "state/store"
import UserImage from "assets/images/icon-avatar.svg"

const UserPlan = () => {
  const currentUser = useStore((state) => state.currentUser)
  return (
    <CardStyled>
      <Left>
        <img src={UserImage} alt="" width={"64px"} />
        <div className="ml-3">
          <h2>{`${currentUser?.first_name} ${currentUser?.last_name}`}</h2>
          <p>
            {`${currentUser?.title || ""}`}
            {`${currentUser?.title && currentUser?.organization ? " | " : ""}`}
            {`${currentUser?.organization || ""}`}
          </p>
        </div>
      </Left>
    </CardStyled>
  )
}

const CardStyled = styled.article`
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(18, 42, 71, 0.1);
  border-radius: 12px;
  display: flex;
  padding: 20px;
  position: relative;
  z-index: 1;
`

const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 0.2em;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
`

export default UserPlan
