import { useState, useCallback, useEffect, useMemo, useRef, memo } from "react"
import { useMeasure } from "react-use"
import styled from "styled-components"
import InfiniteScroll from "react-infinite-scroll-component"

import { useStore } from "state/store"
import { WarningMsg } from "components/Labels"
import ImageCard from "../ImageCard"
import Nodata from "./Nodata"

function Sidebar() {
  const isSavedImages = useStore((state) => state.isSavedImages)
  const filteredImagesSaved = useStore((state) => state.filteredImagesSaved)
  const rectedImages = useStore((state) => state.rectedImages)
  const setSidebarWidth = useStore((state) => state.setSidebarWidth)
  const filterBarHeight = useStore((state) => state.filterBarHeight)
  const sidebarheight = useMemo(() => {
    return `calc(100vh - ${filterBarHeight}px - 7em)`
  }, [filterBarHeight])

  const [holderRef, holderMeasure] = useMeasure()

  const scrollRef = useRef(null)

  const className = useMemo(() => {
    let result = "col-12"
    if (holderMeasure.width > 600) {
      result = "col-6"
    }
    if (holderMeasure.width > 950) {
      result = "col-4"
    }
    return result
  }, [holderMeasure.width])

  const [hasMore, setHasMore] = useState(true)
  const [items, setItems] = useState([])

  const INCREMENT = 10

  const loadMore = useCallback(() => {
    if (rectedImages.length === items.length) {
      setHasMore(false)
      return
    }

    const newItems = rectedImages.slice(items.length, items.length + INCREMENT)
    setItems([...items, ...newItems])
  }, [items, rectedImages])

  const dataLength = isSavedImages ? filteredImagesSaved.length : items.length
  const scrollItems = isSavedImages
    ? filteredImagesSaved.map((data, index) => (
        <ImageCard key={index} data={data} className={className} />
      ))
    : items.map((data, index) => (
        <ImageCard key={index} data={data} className={className} />
      ))
  const isNoData = !dataLength

  useEffect(() => {
    scrollRef.current.scrollTop = 0
    setHasMore(true)
    setItems(rectedImages.slice(0, INCREMENT))
  }, [rectedImages])

  useEffect(() => {
    setSidebarWidth(holderMeasure.width)
  }, [holderMeasure.width])

  return (
    <Holder ref={holderRef} h={sidebarheight}>
      <WarningMsg>
        <i className="pi pi-info-circle" />{" "}
        <span>
          Some images do not include coordinate or temporal information
        </span>
      </WarningMsg>
      <List
        ref={scrollRef}
        className="mt-2"
        height={holderMeasure.height}
        id="image-scrollable-div"
      >
        {isNoData ? (
          <Nodata showEmptySavedVessel={isSavedImages} />
        ) : (
          <InfiniteScroll
            className="grid"
            dataLength={dataLength}
            next={loadMore}
            hasMore={hasMore}
            loader={<div className="p-2">Loading...</div>}
            scrollThreshold={0.95}
            scrollableTarget="image-scrollable-div"
          >
            {scrollItems}
          </InfiniteScroll>
        )}
      </List>
    </Holder>
  )
}

export default memo(Sidebar)

const Holder = styled.div`
  position: relative;
  height: ${(props) => props.h};
  overflow: hidden;
  padding: 0 1em 1em 1em;
  display: flex;
  flex-direction: column;
  min-width: 20em;
`

const List = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  border: 1px solid #bbb;
  border-radius: 4px;
  padding: 0.5em;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #6b8fa3;
    border-radius: 3px;
    border: none;
  }
`
