import { memo } from "react"
import styled from "styled-components"

function FilterButton({ icon, label, onClick, amount = 0 }) {
  return (
    <Holder onClick={onClick}>
      <img src={icon} alt={label} />
      <span>{label}</span>
      {amount > 0 && <Badge>{amount}</Badge>}
    </Holder>
  )
}

export default memo(FilterButton)

const Holder = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  min-width: 8em;
  padding: 0.7em 0.5em;
  transition: all 0.2s ease-out;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  > img {
    width: 1.3em;
    height: 1.3em;
  }
`

const Badge = styled.div`
  width: 1.7em;
  height: 1.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 0.1em;
  border-radius: 1em;
`
