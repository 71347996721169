import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import styled from "styled-components"

import { H21 } from "components/Labels"
import "./PaginatorTemplate.css"
import SkipBackIcon from "assets/images/skip-back-fill.svg"
import ArrowLeftIcon from "assets/images/arrow-left-s-line.svg"
import ArrowRightIcon from "assets/images/arrow-right-s-line.svg"
import SkipForwardIcon from "assets/images/skip-forward-fill.svg"

const PaginatorTemplate = (
  defaultDropdownOptions = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
  ],
) => {
  return {
    layout:
      "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    CurrentPageReport: (options) => {
      return (
        <H21
          style={{
            paddingRight: "2em",
            marginRight: "auto",
          }}
        >
          Showing {options.first} - {options.last} of {options.totalRecords}
        </H21>
      )
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        ...defaultDropdownOptions,
        { label: "All", value: options.totalRecords },
      ]
      return (
        <H21
          className="pagination-results"
          style={{
            paddingLeft: "2em",
            marginLeft: "auto",
          }}
        >
          Show{" "}
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />{" "}
          <ResultsText>results</ResultsText>
        </H21>
      )
    },
    FirstPageLink: (options) => {
      return (
        <StyledButton
          className={classNames(options.className)}
          style={{ background: "transparent !important" }}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <img
            src={SkipBackIcon}
            alt="To first page"
            style={{
              opacity: options.disabled ? 0.5 : 1,
            }}
          />
        </StyledButton>
      )
    },
    PrevPageLink: (options) => {
      return (
        <StyledButton
          className={classNames(options.className)}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <img
            src={ArrowLeftIcon}
            alt="To previous page"
            style={{
              opacity: options.disabled ? 0.5 : 1,
            }}
          />
        </StyledButton>
      )
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true })

        return (
          <span
            className={className}
            style={{ userSelect: "none", fontSize: "0.7em" }}
          >
            ...
          </span>
        )
      }

      return (
        <StyledButton className={options.className} onClick={options.onClick}>
          {options.page + 1}
        </StyledButton>
      )
    },
    NextPageLink: (options) => {
      return (
        <StyledButton
          className={classNames(options.className)}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <img
            src={ArrowRightIcon}
            alt="To next page"
            style={{
              opacity: options.disabled ? 0.5 : 1,
            }}
          />
        </StyledButton>
      )
    },
    LastPageLink: (options) => {
      return (
        <StyledButton
          className={classNames(options.className)}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <img
            src={SkipForwardIcon}
            alt="To last page"
            style={{
              opacity: options.disabled ? 0.5 : 1,
            }}
          />
        </StyledButton>
      )
    },
  }
}

const ResultsText = styled.span`
  margin-left: 0.5em;
`

const StyledButton = styled(Button)`
  border-radius: 0.3em !important;
  font-size: 1em !important;
  min-width: 2em !important;
  width: 2em !important;
  height: 2em !important;
`

export default PaginatorTemplate
