import bgDatabase from "assets/images/BackgroundPattern.svg"
import {
  CounterBox,
  CounterBoxHolder,
  PageTitle,
  Typography,
  TypographyHolder,
} from "components/Labels"
import { Button } from "primereact/button"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import GridItem from "../../../VesselDatabase/components/VOIDataView/GridItem"

const shipDataSample1 = {
  analyst_notes: `-   The **COURAGEOUS** (fka SEA PRIMA; IMO 8617524) is a formerly Cameroon-flagged products tanker that conducted ship-to-ship (STS) transfers with four North Korea-flagged tankers  between August and September 2019.
    -   According to a civil complaint filed by the US Attorney in the Southern District of New York, the COURAGEOUS called at Nampo Port, North Korea in early November 2019.
    -   In February 2020, the COURAGEOUS was detained by Cambodian authorities for falsely flying a Cambodian flag in Cambodian territorial waters.
    - The month before it began conducting STS transfers with North Korean tankers, the COURAGEOUS was purchased in July 2019 by the China-registered company **New Eastern Shipping Co Ltd.** During the sales negotiations, New Eastern Shipping was allegedly represented by **Kwek Kee Seng**, a Singaporean national tied to several DPRK-related sanctions evasion events and sanctioned by OFAC in 2022.`,
  breadth: 0,
  build_year: 1987,
  call_sign: "",
  deadweight: 3912,
  gross_tonnage: 2734,
  id: "44839561-2e67-4397-ace0-05c53adef94d",
  identity: 1,
  identity_verbose: "Real",
  images: [
    "fe617f7f-a7e6-438f-8603-24f07ea9e465",
    "7030163e-96c4-47a3-a4fb-60734fc4b47f",
  ],
  internal_id: "25321B3041",
  length: 89.95,
  linked_ids_count: null,
  red_flags: [1, 2, 5],
  status: 2,
  status_verbose: "In Service/Commission",
  target_aliases: ["SEA PRIMA"],
  target_flag: "Unknown",
  target_imo: 8617524,
  target_mmsi: 0,
  target_name: "COURAGEOUS",
  type: 18,
  type_verbose: "Products Tanker",
  update_date: "2022-10-10T21:32:37Z",
}
const shipDataSample2 = {
  analyst_notes: ``,
  breadth: 0,
  build_year: 1994,
  call_sign: "TRAJ3",
  deadweight: 9999,
  gross_tonnage: 5944,
  id: "01fd5304-3eee-4fda-a689-7c4d476eeeba",
  identity: 1,
  identity_verbose: "Real",
  images: [
    "0d3f9fd0-c3d0-4a75-a9c0-042f5de3c7ac",
    "c227ab03-1044-4c62-a38a-d30a6f28f31f",
  ],
  internal_id: "1B337CB5FC",
  length: 123,
  linked_ids_count: null,
  red_flags: [],
  status: 1,
  status_verbose: "Broken Up",
  target_aliases: [],
  target_flag: "Gabon",
  target_imo: 9084695,
  target_mmsi: 0,
  target_name: "BONVOY 8",
  type: 18,
  type_verbose: "Products Tanker",
  update_date: "2022-09-26T16:00:21Z",
}

function Counter({ label, value }) {
  return (
    <CounterBox>
      <Typography className="counterValue">{label}</Typography>
      <Typography className="counterTitle">{value}</Typography>
    </CounterBox>
  )
}

export default function DataBase() {
  const history = useNavigate()
  const [activeCarousel, setActiveCarousel] = useState(true)
  return (
    <BackgroundGradient>
      <Holder>
        <Container className="database">
          <FlexRow>
            <div className="image-left ">
              <div
                className={"carousel-image " + (activeCarousel ? "active" : "")}
                onClick={() => setActiveCarousel(!activeCarousel)}
              >
                <GridItem data={shipDataSample1} className="carousel-image" />
              </div>
              <div
                className={"carousel-image " + (activeCarousel ? "" : "active")}
                onClick={() => {
                  setActiveCarousel(!activeCarousel)
                }}
              >
                <GridItem data={shipDataSample2} className="carousel-image" />
              </div>
            </div>
            <CardContent>
              <PageTitle className="w-80">
                Vessels of Interest Database
              </PageTitle>
              <TypographyHolder>
                <Typography>
                  Dive into the profiles of DPRK-linked vessels of interest and
                  the cover identities they use to evade detection
                </Typography>
              </TypographyHolder>
              <Button
                icon="icon-common icon-arrow-button"
                iconPos="right"
                label="Explore"
                onClick={() => history("/vessel-database")}
              ></Button>
              <CounterBoxHolder>
                <Counter label={530} value={"Total Vessels"} />
                <Counter label={272} value={"Categories"} />
                <Counter label={6} value={"Flags"} />
              </CounterBoxHolder>
            </CardContent>
          </FlexRow>
        </Container>
      </Holder>
    </BackgroundGradient>
  )
}

const BackgroundGradient = styled.section`
  background: linear-gradient(180deg, #f8fafc 0%, #ffffff 100%);
  width: 100%;
`
const Holder = styled.div`
  width: 1440px;
  @media (max-width: 1350px) {
    width: 100%;
  }
  margin: 0 auto;
  padding: 168px 0 187px 0;
  @media (max-width: 768px) {
    padding: 200px 0 50px 0;
  }
  display: flex;
  flex-direction: column;
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;

    background-image: url(${bgDatabase});
    background-repeat: no-repeat, no-repeat;
    background-size: contain;
    background-position: left top;

    @media (max-width: 768px) {
      background-size: cover;
      background-position: top;
      opacity: 0.4;
    }
  }
  .w-80 {
    width: 80%;
  }
`
const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    display: block;
  }
`

const Container = styled.div`
  width: 1220px;
  @media (max-width: 1350px) {
    width: 90%;
  }
  margin: 0 auto;
  .slider-container {
    width: 450px;
  }
  .image-left {
    width: 35.5%;
    position: relative;

    @media (max-width: 768px) {
      width: 100%;
      min-height: 484px;
    }
  }
  .carousel-image {
    transform: scale(0.9) translate(-100px, 0px);
    @media (max-width: 768px) {
      transform: scale(0.9) translate(0px, -190px);
    }
    position: absolute;
    z-index: 0;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    &.active {
      transform: scale(1) translate(0, 0);
      z-index: 1;
    }
    > div {
      width: 100%;
    }
  }
`
const CardContent = styled.div`
  width: 50%;
  margin-left: auto;
  @media (max-width: 768px) {
    width: 96%;
  }
`
