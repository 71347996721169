import { useStore } from "state/store"
import { checkPermissions } from "utils"

const AccessControl = ({ allowedPermissions, children, renderNoAccess }) => {
  const currentUser = useStore((state) => state.currentUser)
  const permitted = checkPermissions(
    currentUser.permissions,
    allowedPermissions,
  )

  if (permitted) {
    return children
  }
  return renderNoAccess()
}

AccessControl.defaultProps = {
  allowedPermissions: [],
  permissions: [],
  renderNoAccess: () => null,
}

export default AccessControl
