import { api } from "api"
import { USER_KEYS } from "dataset/user"
import { sortBy } from "lodash-es"

/**
 * Make request to fetch rrp
 * @returns {Object}
 */
export const getUsersRequest = async () => {
  try {
    const { data } = await api.get(`/users`)
    const users = data || []
    const sortedUsers = sortBy(users, [USER_KEYS.email])
    return sortedUsers
  } catch (error) {
    console.log("error", error)
    throw new Error(error?.response?.data?.detail)
  }
}
