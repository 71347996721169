import { api } from "api"
const getCountries = async () => {
  try {
    const { status, data } = await api.get(`/countries`)
    if (status === 200) {
      return data
    }
  } catch (e) {
    return null
  }
}

const getCountryImagesById = async (countryId) => {
  try {
    const { status, data } = await api.get(
      "/countries/" + countryId + "/countryimages",
    )
    if (status === 200) {
      return data
    }
    return null
  } catch (e) {
    return null
  }
}

const getAoiImagesById = async (aoi_id) => {
  try {
    const { status, data } = await api.get("/aoi/" + aoi_id + "/aoiimages")
    if (status === 200) {
      return data
    }
    return []
  } catch (e) {
    return []
  }
}

const getCountryThumbsById = async (countryId) => {
  try {
    const { status, data } = await api.get(
      "/countries/" + countryId + "/countrythumbnails",
    )
    if (status === 200) {
      return data
    }
    return null
  } catch (e) {
    return null
  }
}

const getAoiThumbsById = async (aoiId) => {
  try {
    const { status, data } = await api.get("/aoi/" + aoiId + "/aoithumbnails")
    if (status === 200) {
      return data
    }
    return null
  } catch (e) {
    return null
  }
}

const getAOIs = async () => {
  try {
    const { status, data } = await api.get(`/aoi`)
    if (status === 200) {
      data.forEach(aoi => {
        if (aoi.name === 'East Sea') {
          aoi.name = 'East Sea / Sea of Japan';
        }
        aoi.alias_names = aoi.alias_names.map(alias => alias === 'Sea of Japan' ? '日本海' : alias)
        aoi.notes = aoi.notes.replace(/East Sea/g, 'East Sea / Sea of Japan')
      })
      return data
    }
    return null
  } catch (e) {
    return null
  }
}

const downloadAOIMapJSON = async (id) => {
  try {
    const { status, data } = await api.get(`/aoi/${id}/download/`)
    if (status === 200) {
      return data
    }
    return null
  } catch (e) {
    return null
  }
}

export {
  getCountries,
  getAOIs,
  downloadAOIMapJSON,
  getCountryImagesById,
  getCountryThumbsById,
  getAoiThumbsById,
  getAoiImagesById,
}
