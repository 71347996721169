import { useState, memo, useCallback } from "react"
import styled from "styled-components"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"

import { useStore } from "state/store"
import { renameRrpRequest } from "api/rrp"
import { RRP_KEYS } from "dataset/rrp"
import { useEffect } from "react"

function RRPEditProductDialog() {
  const rrpEditProductDialogVisible = useStore(
    (state) => state.rrpEditProductDialogVisible,
  )
  const setRrpEditProductDialogVisible = useStore(
    (state) => state.setRrpEditProductDialogVisible,
  )
  const selectedRrpProductData = useStore(
    (state) => state.selectedRrpProductData,
  )
  const setSelectedRrpProductData = useStore(
    (state) => state.setSelectedRrpProductData,
  )
  const setNeedGetRrp = useStore((state) => state.setNeedGetRrp)

  const [fileName, setFileName] = useState("")
  const [fileType, setFIleType] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleHide = () => {
    setRrpEditProductDialogVisible(false)
  }

  const handleConfirm = useCallback(async () => {
    setIsLoading(true)

    await renameRrpRequest(selectedRrpProductData[RRP_KEYS.id], fileName)

    setIsLoading(false)
    setNeedGetRrp(true)
    handleHide()

    setSelectedRrpProductData(null)
  }, [selectedRrpProductData, fileName])

  useEffect(() => {
    let file = selectedRrpProductData?.[RRP_KEYS.file_name] || ""
    setFileName(file.split(".")[0])
    setFIleType(file.split(".")[1])
  }, [selectedRrpProductData])

  return (
    <Holder
      visible={rrpEditProductDialogVisible}
      onHide={handleHide}
      showHeader={false}
    >
      <Content>
        <Title>Rename File</Title>
        <InputText
          className="w-full"
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
        />
        <FileType>{`File type: ${fileType}`}</FileType>
        <CloseButton
          icon="pi pi-times"
          className="p-button-rounded p-button-text p-button-secondary"
          aria-label="Cancel"
          onClick={handleHide}
        />
        <Button
          label="Confirm"
          className="p-button-primary mt-2"
          onClick={handleConfirm}
          disabled={isLoading}
          loading={isLoading}
        />
      </Content>
    </Holder>
  )
}

export default memo(RRPEditProductDialog)

const Holder = styled(Dialog)`
  min-width: 25em;
  width: 25em;
  .p-dialog-content {
    position: relative;
    padding: 1em;
    border-radius: 10px;
  }
`

const Content = styled.div`
  padding: 0em 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 2em !important;
  height: 2em !important;
  padding: 0.1em !important;
`

const Title = styled.div`
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.8;
`

const FileType = styled.div`
  font-size: 1em;
  font-weight: 100;
  line-height: 1.8;
  text-align: center;
`
