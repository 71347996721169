import React, { memo, useMemo } from "react"
import styled from "styled-components"
import { Checkbox } from "primereact/checkbox"

import { H41 } from "components/Labels"

const AOIItem = (props) => {
  const toggleCheckbox = (checked) => {
    let temp = [...props.selected]
    if (checked) {
      temp = [...props.selected, props.item]
      props.setSelected([...temp])
    } else {
      temp = props.selected.filter((item) => item.id !== props.item.id)
      props.setSelected([...temp])
    }

    if (temp.length === props.aois.length) {
      props.setSelectAll(true)
    } else {
      props.setSelectAll(false)
    }
    const selectedItems = temp.filter(
      (port) => port.aoi_type === props.item.aoi_type,
    ).length
    const allItems = props.aois.filter(
      (port) => port.aoi_type === props.item.aoi_type,
    ).length
    switch (props.item.aoi_type) {
      case 1: {
        props.setAllPorts(selectedItems === allItems)
        break
      }
      case 2: {
        props.setAllHotspots(selectedItems === allItems)
        break
      }
      case 3: {
        props.setAllShipyards(selectedItems === allItems)
        break
      }
      default: {
        break
      }
    }
  }
  const isChecked = useMemo(
    () => !!props.selected.filter((item) => item.id === props.item.id).length,
    [props],
  )
  return (
    <CheckboxContainer>
      <LeftSpacedElement>
        <Checkbox
          inputId="select_all"
          checked={isChecked}
          onChange={(e) => toggleCheckbox(e.checked)}
        />
      </LeftSpacedElement>
      <TextContainer
        onClick={() => {
          props.selectAOI(props.item)
          props.changePositionItem(props.item)
          if (!isChecked) {
            toggleCheckbox(true)
          }
          props.setsidebarOpen(false)
          setTimeout(() => {
            props.setsidebarOpen(true)
          }, 700)
        }}
      >
        <H41 title={props.name}>{props.item.name}</H41>
      </TextContainer>
    </CheckboxContainer>
  )
}

export default memo(AOIItem)

const TextContainer = styled.div`
  margin-left: 16px;
  cursor: pointer;
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`
const LeftSpacedElement = styled.div`
  margin-left: 18px;
`
