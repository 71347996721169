import { useEffect, useState } from "react"
import { DataView } from "primereact/dataview"

import { useStore } from "state/store"

import { VESSEL_KEYS } from "dataset/vessel"
import PaginatorTemplate from "components/PaginatorTemplate"
import ProgressLoader from "components/ProgressLoader"
import ListItem from "./ListItem"
import GridItem from "./GridItem"
import Nodata from "./Nodata"

export default function VOIDataView({
  value = [],
  loading,
  searchedValue,
  isSavedVessels = false,
  isSearch = false,
}) {
  const formattedSearchedValue = searchedValue.toUpperCase()
  const voiSortOption = useStore((state) => state.voiSortOption)
  const voiLayout = useStore((state) => state.voiLayout)
  const totalImages = useStore((state) => state.totalImages)
  const activeVesselTab = useStore((state) => state.activeVesselTab)
  const setDataTablePagination = useStore(
    (state) => state.setDataTablePagination,
  )

  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(12)

  const itemTemplate = (data, layout) => {
    if (!data) {
      return
    }

    if (layout === "list") {
      return <ListItem data={data} />
    } else if (layout === "grid") {
      return <GridItem data={data} />
    }
  }

  const [newValue, setNewValue] = useState([])

  const paginatorTemplate = PaginatorTemplate([
    { label: 12, value: 12 },
    { label: 24, value: 24 },
    { label: 48, value: 48 },
    { label: 96, value: 96 },
  ])

  useEffect(() => {
    let filteredValue = [...value]

    if (activeVesselTab === "UNSC-SANCTIONED") {
      filteredValue = filteredValue.filter((d) =>
        d[VESSEL_KEYS.red_flags].includes(0),
      )
    } else if (activeVesselTab === "OFAC-SANCTIONED") {
      filteredValue = filteredValue.filter((d) =>
        d[VESSEL_KEYS.red_flags].includes(2),
      )
    }
    filteredValue.sort((a, b) => {
      const dateA = new Date(a.update_date)
      const dateB = new Date(b.update_date)
      const aHasSearchedValue = a.target_name.includes(formattedSearchedValue)
      const bHasSearchedValue = b.target_name.includes(formattedSearchedValue)

      if (aHasSearchedValue && !bHasSearchedValue) {
        return -1
      } else if (!aHasSearchedValue && bHasSearchedValue) {
        return 1
      }

      if (voiSortOption?.order === 1) {
        return dateA - dateB
      } else if (voiSortOption?.order === -1) {
        return dateB - dateA
      }

      return 0 // No sorting
    })
    setNewValue(filteredValue)
  }, [value, activeVesselTab, voiSortOption, isSearch])

  const onPage = (event) => {
    setRows(event.rows)
    setFirst(event.first)

    setDataTablePagination(true)
  }

  if (loading) {
    return <ProgressLoader />
  }
  if (value.length === 0) {
    return <Nodata showEmptySavedVessel={isSavedVessels && !isSearch} />
  }

  if (!value.length && !totalImages.length) {
    return <Nodata showEmptySavedVessel={isSavedVessels && !isSearch} />
  }

  return (
    <DataView
      value={newValue}
      layout={voiLayout}
      itemTemplate={itemTemplate}
      paginator
      paginatorTemplate={paginatorTemplate}
      first={first}
      rows={rows}
      gutter
      sortField="update_date"
      onPage={onPage}
    ></DataView>
  )
}
