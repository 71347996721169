import { memo } from "react"
import styled from "styled-components"
import FilterIcon from "assets/images/icon-timeline-filter.svg"

function TimelineButton({ onClick }) {
  return (
    <Holder onClick={onClick}>
      <img src={FilterIcon} alt="" />
      <span>Timeline Filter</span>
    </Holder>
  )
}

export default memo(TimelineButton)

const Holder = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6em;
  background-color: white;
  padding: 0.6em 0.8em;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s ease-out;
  &:hover {
    opacity: 0.8;
  }
`
