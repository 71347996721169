import { useEffect, useState, Children } from "react"
import styled from "styled-components"
import Select, { components } from "react-select"
import { InputText } from "primereact/inputtext"
import { Checkbox } from "primereact/checkbox"

const Placeholder = (props) => {
  return null
}

const Input = (props) => {
  if (props.isHidden) {
    return <components.Input {...props} />
  }
  return (
    <span className="p-input-icon-left">
      <i className="pi pi-search" />
      <InputTextStyled {...props} />
    </span>
  )
}

const Option = ({ innerRef, children, ...props }) => {
  const selected = (props?.selectProps?.value || []).map(({ value }) => value)
  return (
    <components.Option {...props}>
      <span className="mr-2">
        <Checkbox checked={selected.includes(props?.data?.value)} />
      </span>
      {children}
    </components.Option>
  )
}

const MenuList = (props) => {
  // Function to sort children
  const sortChildren = (children) => {
    const childrenArray = Children.toArray(children) // Use Children here
    const sortedChildren = childrenArray.sort((a, b) => {
      if (a.props.children < b.props.children) return -1
      if (a.props.children > b.props.children) return 1
      return 0
    })
    return sortedChildren
  }

  return (
    <components.MenuList {...props}>
      <SelectAllContainer>
        <Checkbox
          inputId={`select-all-${props?.selectProps?.selectProps?.col}`}
          onChange={props?.selectProps?.selectProps?.onSelectAll}
          checked={props?.selectProps?.selectProps?.selectAll}
        />
        <label
          htmlFor={`select-all-${props?.selectProps?.selectProps?.col}`}
          className="font-italic cursor-pointer ml-2"
        >
          Select all
        </label>
      </SelectAllContainer>
      {sortChildren(props.children)}
    </components.MenuList>
  )
}

export default function GACSelect2(props) {
  return (
    <Select2
      components={{
        Option,
        MenuList,
        DropdownIndicator: null,
        Input,
        Placeholder,
      }}
      {...props}
    />
  )
}

const Select2 = styled(Select)`
  .rs__value-container {
    padding: 0;
  }
  .rs__menu {
    position: relative;
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-top: 1px solid #dfdfdf;
    margin-top: 1em;
    padding-top: 0.5em;

    .rs__option {
      font-weight: 600;
    }
    .rs__option--is-focused,
    .rs__option--is-selected {
      background-color: transparent;
      color: rgba(0, 0, 0, 0.87);
    }
  }
`

const SelectAllContainer = styled.div`
  padding: 0.75em;
`

const InputTextStyled = styled(InputText)`
  background-image: none !important;
  background-color: white;
  padding: 0.6em 1em;
  &:focus {
    background-color: white !important;
  }
`
