import { memo, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Slider } from "primereact/slider"

function TimelineEdgeRanger({ value, onChange, setHoverValue, max = 100 }) {
  const ref = useRef()

  const [isStartActived, setIsStartActived] = useState(false)

  const handleMouseMove = (e) => {
    const bounds = ref.current.getBoundingClientRect()
    const x = e.clientX - bounds.left

    const step = bounds.width / max
    const leftValue = Math.round(x / step)

    if (isStartActived) {
      if (value[1] > leftValue) {
        setHoverValue([leftValue, value[1]])
      }
    } else {
      if (leftValue > value[0]) {
        setHoverValue([value[0], leftValue])
      }
    }
  }

  useEffect(() => {
    if (ref.current) {
      setIsStartActived(
        ref.current
          .querySelector(".p-slider-handle-start")
          .classList.contains("p-slider-handle-active"),
      )
    }
  }, [value])

  return (
    <Holder onMouseMove={handleMouseMove}>
      <Container ref={ref}>
        <Slider
          value={value}
          onChange={onChange}
          range
          min={0}
          max={max}
          step={1}
        />
      </Container>
    </Holder>
  )
}

export default memo(TimelineEdgeRanger)

const Holder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding-left: 65px;
  padding-bottom: 35px;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .p-slider {
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    .p-slider-range {
      background-color: rgba(247, 81, 81, 0.24);
    }
    .p-slider-handle {
      /* background: #f05261; */
      width: 5px;
      height: 3em;
      border-radius: 4px;
      cursor: col-resize;
      padding: 0;
      margin: 0;
      &:focus {
        box-shadow: 0 0 1px 4px rgb(26 52 76 / 20%);
        /* background: rgb(248 252 165); */
      }
      &:hover {
        /* background: rgb(248 252 165); */
      }
    }
    .p-slider-handle-start {
      transform: translate(-50%, -50%);
    }
    .p-slider-handle-end {
      transform: translate(-50%, -50%);
    }
    .p-slider-handle-active {
      /* background: rgb(248 252 165); */
      box-shadow: 0 0 1px 4px rgb(26 52 76 / 20%);
    }
  }
`
