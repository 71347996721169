import { memo } from "react"
import styled from "styled-components"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"

import { useStore } from "state/store"
import { RRP_KEYS } from "dataset/rrp"

function PermissionControlDialog() {
  const rrpPermissionDialogVisible = useStore(
    (state) => state.rrpPermissionDialogVisible,
  )
  const setRrpPermissionDialogVisible = useStore(
    (state) => state.setRrpPermissionDialogVisible,
  )
  const rrpDialogData = useStore((state) => state.rrpDialogData)

  const handleHide = () => {
    setRrpPermissionDialogVisible(false)
  }

  return (
    <Holder
      visible={
        rrpPermissionDialogVisible &&
        rrpDialogData?.[RRP_KEYS.product_type] === 0
      }
      onHide={handleHide}
      showHeader={false}
    >
      <Content>
        <Text>
          Viewing permissions for Subscription Products cannot be modified. By
          default Subscription Products are made available to all users
        </Text>
        <CloseButton
          icon="pi pi-times"
          className="p-button-rounded p-button-text p-button-secondary"
          aria-label="Cancel"
          onClick={handleHide}
        />
      </Content>
    </Holder>
  )
}

export default memo(PermissionControlDialog)

const Holder = styled(Dialog)`
  min-width: 25em;
  width: 25em;
  .p-dialog-content {
    position: relative;
    padding: 1em;
    border-radius: 10px;
  }
`

const Content = styled.div`
  padding: 0em 0.5em;
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 2em !important;
  height: 2em !important;
  padding: 0.1em !important;
`

const Text = styled.div`
  font-size: 1em;
  font-weight: 100;
  line-height: 1.8;
  padding: 1em;
`
