const IMAGE_KEYS = {
  id: "id",
  s3_path: "s3_path",
  file_name: "file_name",
  title: "title",
  target_vessels: "target_vessels",
  linked_identities: "linked_identities",
  date_input: "date_input",
  date_taken: "date_taken",
  region_aoi: "region_aoi",
  sensor: "sensor",
  source: "source",
  source_link: "source_link",
  tags: "tags",
  internal_id: "internal_id",
  crew_id: "crew_id",
  coord: "coord",
}

export { IMAGE_KEYS }
