import { memo, useState, useEffect } from "react"
import { Checkbox } from "primereact/checkbox"

import { RRP_KEYS } from "dataset/rrp"
import { USER_KEYS } from "dataset/user"
import { useStore } from "state/store"

function PermissionActionSwitch({ data, onChange }) {
  const rrpDialogData = useStore((state) => state.rrpDialogData)

  const [checked, setChecked] = useState(false)

  const handleSwitch = (event) => {
    event.stopPropagation()
    setChecked(event.checked)
    onChange(event.checked)
  }

  useEffect(() => {
    const isGranted =
      rrpDialogData?.[RRP_KEYS.users]?.findIndex(
        (id) => id === data[USER_KEYS.id],
      ) > -1
    setChecked(isGranted)
  }, [rrpDialogData, data])

  return (
    <div className="flex w-full align-items-center justify-content-center">
      <Checkbox onChange={handleSwitch} checked={checked} />
    </div>
  )
}

export default memo(PermissionActionSwitch)
