import styled from "styled-components"

export const RectangularRadioGroup = ({ children }) => {
  return (
    <RadioGroup className="inline-flex flex-1 flex-row flex-initial justify-center">
      {children}
    </RadioGroup>
  )
}
export const RectangularRadio = ({ label, id, ...restProps }) => {
  return (
    <div className="radio bg-white border border-grey border-l-0">
      <input type="radio" id={id} {...restProps} />
      <label htmlFor={id} className="block text-center">
        {label}
      </label>
    </div>
  )
}

const RadioGroup = styled.div`
  margin-top: 1.5em;
  .radio:first-child label {
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
  }

  .radio:last-child label {
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  .radio {
    position: relative;
    input {
      visibility: hidden;
      position: absolute;
    }
    label:hover {
      cursor: pointer;
    }
  }

  input:checked + label {
    border-color: #f75151;
    background: rgba(247, 81, 81, 0.1);
    font-weight: 700;
  }

  label {
    border: 1px solid #d9d9d9;
    width: 8em;
    padding: 1em 0;
    font-size: 0.8em;
  }
`
