import { memo, useMemo, useState, useEffect, useCallback } from "react"
import { useQuery } from "@tanstack/react-query"
import styled from "styled-components"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"

import { RRP_KEYS } from "dataset/rrp"
import { USER_KEYS } from "dataset/user"
import { useStore } from "state/store"
import { getUsersRequest } from "api/users"
import { postUsersRequest } from "api/rrp"
import PermissionDialogHeader from "./PermissionDialogHeader"
import PermissionDataTable from "./PermissionDataTable"

function PermissionDialog() {
  const { data: users, isLoading: isUserLoading } = useQuery(
    ["getUsersRequest"],
    () => getUsersRequest(),
  )

  const rrpPermissionDialogVisible = useStore(
    (state) => state.rrpPermissionDialogVisible,
  )
  const setRrpPermissionDialogVisible = useStore(
    (state) => state.setRrpPermissionDialogVisible,
  )
  const rrpDialogData = useStore((state) => state.rrpDialogData)
  const setNeedGetRrp = useStore((state) => state.setNeedGetRrp)

  const [search, setSearch] = useState("")
  const [selectedUsers, setSelectedUsers] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [permissionTableValue, setPermissionTableValue] = useState([])

  const disabled = useMemo(() => {
    if (rrpDialogData?.[RRP_KEYS.users]?.length !== selectedUsers.length) {
      return false
    }

    let _disabled = true
    for (let i = 0; i < rrpDialogData?.[RRP_KEYS.users]?.length; i++) {
      const userId = rrpDialogData?.[RRP_KEYS.users][i]
      if (selectedUsers.findIndex((id) => id === userId) === -1) {
        _disabled = false
        break
      }
    }
    return _disabled
  }, [selectedUsers, rrpDialogData])

  const resetDialog = () => {
    setSearch("")
  }

  const handleHide = () => {
    setRrpPermissionDialogVisible(false)
    resetDialog()
  }

  const handleSearch = (e) => {
    setSearch(e.target.value ? e.target.value : "")
  }

  const handleSave = useCallback(async () => {
    setIsSaving(true)
    await postUsersRequest(
      rrpDialogData?.[RRP_KEYS.id],
      rrpDialogData?.[RRP_KEYS.users],
      selectedUsers,
    )
    setIsSaving(false)
    setNeedGetRrp(true)
    handleHide()
  }, [rrpDialogData, selectedUsers])

  useEffect(() => {
    if (rrpDialogData) {
      setSelectedUsers(rrpDialogData[RRP_KEYS.users])
    }
  }, [rrpDialogData])

  useEffect(() => {
    let newValue = []
    if (users && rrpDialogData) {
      //Sort users, so that the users who have been granted permission are displayed first
      newValue = users.sort((a, b) => {
        if (
          rrpDialogData[RRP_KEYS.users].includes(a[USER_KEYS.id]) &&
          !rrpDialogData[RRP_KEYS.users].includes(b[USER_KEYS.id])
        ) {
          return -1
        } else if (
          !rrpDialogData[RRP_KEYS.users].includes(a[USER_KEYS.id]) &&
          rrpDialogData[RRP_KEYS.users].includes(b[USER_KEYS.id])
        ) {
          return 1
        } else {
          return 0
        }
      })
    }
    setPermissionTableValue(newValue)
  }, [users, rrpDialogData])

  return (
    <Holder
      visible={rrpPermissionDialogVisible}
      onHide={handleHide}
      header={
        <PermissionDialogHeader name={rrpDialogData?.[RRP_KEYS.file_name]} />
      }
    >
      <Content>
        <span className="p-input-icon-left w-full mb-3">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search Users"
            className="w-full"
            value={search}
            onChange={handleSearch}
          />
        </span>
        <PermissionDataTable
          value={permissionTableValue}
          isLoading={isUserLoading}
          search={search}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          showAction={rrpDialogData?.[RRP_KEYS.product_type] === 1} // If type is Bespoke, show action
        />
        <div className="w-full flex justify-content-end mt-4">
          <Button
            label="Save"
            className="p-button-primary"
            icon="pi pi-save"
            loading={isSaving}
            disabled={disabled}
            onClick={handleSave}
          />
        </div>
      </Content>
    </Holder>
  )
}

export default memo(PermissionDialog)

const Holder = styled(Dialog)`
  min-width: 300px;
  width: 60vw;
  max-width: 700px;
  .p-dialog-content {
    padding: 1em;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

const Content = styled.div`
  padding: 0em 0.5em;
`
