import { createRoot } from "react-dom/client"
import posthog from 'posthog-js'
import { PostHogProvider} from 'posthog-js/react'
import App from "./App"

posthog.init(
  process.env.REACT_APP_POSTHOG_KEY,
  {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
    autocapture: false,
    capture_pageview: false,
    capture_pageleave: false,
    property_blacklist: [
      "$pathname",
      "$referrer",
      "$referring_domain",
      "$current_url",
    ],
  }
);

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
  <PostHogProvider client={posthog}>
    <App />
  </PostHogProvider>
);
