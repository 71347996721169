import { memo, useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import { useMeasure } from "react-use"
import { usePostHog } from 'posthog-js/react'
import { Button } from "primereact/button"
import { AutoComplete } from "primereact/autocomplete"
import { toast } from "react-toastify"

import { useStore } from "state/store"
import { IMAGE_KEYS } from "dataset/image"
import { VESSEL_KEYS } from "dataset/vessel"
import FilterButton from "./FilterButton"
import DatePickerDialog from "./DatePickerDialog"
import DateRangePicker from "./DateRangePicker"
import FilterDialog from "./FilterDialog"
import SearchItem from "./SearchItem"
import FilterOption from "../FilterOption"
import { filterImages, getTimelineData } from "./utils"
import FilterIcon from "assets/images/icon-dt-filter.svg"

function Filterbar() {
  const posthog = usePostHog()

  const SELECTION_LIMIT = 10
  const location = useLocation()

  const totalVessels = useStore((state) => state.totalVessels)
  const totalImages = useStore((state) => state.totalImages)
  const setFilteredImages = useStore((state) => state.setFilteredImages)
  const savedImages = useStore((state) => state.savedImages)
  const setFilteredImagesSaved = useStore(
    (state) => state.setFilteredImagesSaved,
  )
  const filterStartDate = useStore((state) => state.filterStartDate)
  const setFilterStartDate = useStore((state) => state.setFilterStartDate)
  const filterEndDate = useStore((state) => state.filterEndDate)
  const setFilterEndDate = useStore((state) => state.setFilterEndDate)
  const setTimelineStartDate = useStore((state) => state.setTimelineStartDate)
  const setTimelineEndDate = useStore((state) => state.setTimelineEndDate)
  const setTimelineData = useStore((state) => state.setTimelineData)
  const startDate = useStore((state) => state.startDate)
  const setStartDate = useStore((state) => state.setStartDate)
  const endDate = useStore((state) => state.endDate)
  const setEndDate = useStore((state) => state.setEndDate)
  const selectedLocations = useStore((state) => state.selectedLocations)
  const setSelectedLocations = useStore((state) => state.setSelectedLocations)
  const selectedSensors = useStore((state) => state.selectedSensors)
  const setSelectedSensors = useStore((state) => state.setSelectedSensors)
  const selectedSources = useStore((state) => state.selectedSources)
  const setSelectedSources = useStore((state) => state.setSelectedSources)
  const setFilterBarHeight = useStore((state) => state.setFilterBarHeight)

  const isOpenAutoComplete = useRef(false)
  const searchbarContainerRef = useRef(null)

  const [searchValue, setSearchValue] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [locations, setLocations] = useState([])
  const [sensors, setSensors] = useState([])
  const [sources, setSources] = useState([])
  const [datePickerDialogVisible, setDatePickerDialogVisible] = useState(false)
  const [filterDialogVisible, setFilterDialogVisible] = useState(false)
  const [filterItemAmount, setFilterItemAmount] = useState(0)
  const [inputValue, setInputValue] = useState("")
  const [isFilteredBySPL, setIsFilteredBySPL] = useState(false)
  const [isFilteredByDate, setIsFilteredByDate] = useState(false)
  const [isFocusAutoComplete, setIsFocusAutoComplete] = useState(false)

  const [searchbarRef, searchbarMeasure] = useMeasure()
  const [filterbarRef, filterbarMeasure] = useMeasure()

  const itemTemplate = (d) => {
    return <SearchItem data={d} width={searchbarMeasure.width} />
  }

  const selectedItemTemplate = (d) => {
    return <ChipText>{d[VESSEL_KEYS.target_name]}</ChipText>
  }

  const handleSearch = () => {
    const filteredAllImages = filterImages(
      totalImages,
      searchValue,
      inputValue,
      startDate,
      endDate,
      filterStartDate,
      filterEndDate,
      selectedLocations,
      selectedSensors,
      selectedSources,
    )
    setFilteredImages(filteredAllImages)

    const filteredImagesSaved = filterImages(
      savedImages,
      searchValue,
      inputValue,
      startDate,
      endDate,
      filterStartDate,
      filterEndDate,
      selectedLocations,
      selectedSensors,
      selectedSources,
    )
    setFilteredImagesSaved(filteredImagesSaved)

    // Set timeline values
    setTimelineStartDate(filterStartDate)
    setTimelineEndDate(filterEndDate)

    const _timelineData = getTimelineData(filteredAllImages)
    setTimelineData(_timelineData)

    if (isFilteredBySPL) {
      posthog.capture("Image filter applied")
      setIsFilteredBySPL(false)
    }

    if (isFilteredByDate) {
      posthog.capture("Image date range applied")
      setIsFilteredByDate(false)
    }
  }

  const handleDatePickerDialogVisible = useCallback(() => {
    setDatePickerDialogVisible(!datePickerDialogVisible)
  }, [datePickerDialogVisible])

  const handleOpenFilterDialog = () => {
    setFilterDialogVisible(true)
  }

  const handleCloseFilterDialog = () => {
    setFilterDialogVisible(false)
  }

  const completeMethod = (event) => {
    const query = event.query.trim()
    isOpenAutoComplete.current = true

    setInputValue(query)
    setTimeout(() => {
      let _suggestions = []
      if (!query.length) {
        _suggestions = [...totalVessels]
      } else {
        _suggestions = totalVessels
          .filter((v) => {
            if (
              v[VESSEL_KEYS.target_name]
                ?.toString()
                .toLowerCase()
                .includes(query.toLowerCase())
            ) {
              return true
            }

            if (
              v[VESSEL_KEYS.internal_id]
                ?.toString()
                .toLowerCase()
                .includes(query.toLowerCase())
            ) {
              return true
            }

            if (
              v[VESSEL_KEYS.target_imo]
                ?.toString()
                .toLowerCase()
                .includes(query.toLowerCase())
            ) {
              return true
            }

            if (
              v[VESSEL_KEYS.target_mmsi]
                ?.toString()
                .toLowerCase()
                .includes(query.toLowerCase())
            ) {
              return true
            }

            return false
          })
          .filter((item) => item[VESSEL_KEYS.images].length > 0)
      }

      //Filter selected items
      _suggestions = _suggestions.filter((item) => {
        return (
          searchValue.findIndex(
            (v) => v[VESSEL_KEYS.id] === item[VESSEL_KEYS.id],
          ) === -1
        )
      })

      _suggestions.unshift(query)
      _suggestions.sort((a, b) => {
        if (a[VESSEL_KEYS.target_name] < b[VESSEL_KEYS.target_name]) {
          return -1
        }
        if (a[VESSEL_KEYS.target_name] > b[VESSEL_KEYS.target_name]) {
          return 1
        }
        return 0
      })

      setSuggestions(_suggestions)
    }, 250)
  }

  const handleChangeAutoComplete = (e) => {
    //Check if last value of e.value is string and add to double quotes
    let lastValue = e.value[e.value.length - 1]
    if (typeof lastValue === "string") {
      lastValue = lastValue.replace(/"/g, "")
      e.value[e.value.length - 1] = `"${lastValue}"`
    }

    setSearchValue(e.value)
    setInputValue("")

    setTimeout(() => {
      isOpenAutoComplete.current = false
    }, 800)

    if (e.value.length >= SELECTION_LIMIT) {
      toast.info(`You can only select up to ${SELECTION_LIMIT} vessels`)
    }
    posthog.capture("Image searched")
  }

  const handleKeyPressAutoComplete = (e) => {
    if (e.key === "Enter") {
      handleSearch()
      posthog.capture("Image searched")
    }
  }

  const handleFocusAutoComplete = (e) => {
    setIsFocusAutoComplete(true)
  }

  const getFilterValues = (images) => {
    // Get locations
    const newLocations = []
    for (let i = 0; i < images.length; i++) {
      const image = images[i]
      const region = image[IMAGE_KEYS.region_aoi]
      if (region !== null && region !== undefined && region !== "") {
        if (newLocations.findIndex((d) => d === region) === -1) {
          newLocations.push(region)
        }
      }
    }
    // Sort alphabetically
    newLocations.sort((a, b) => a.localeCompare(b))

    setLocations(newLocations)

    // Get sensors
    const newSensors = []
    for (let i = 0; i < images.length; i++) {
      const image = images[i]
      const sensor = image[IMAGE_KEYS.sensor]
      if (sensor !== null && sensor !== undefined && sensor !== "") {
        if (newSensors.findIndex((d) => d === sensor) === -1) {
          newSensors.push(sensor)
        }
      }
    }

    // Sort alphabetically
    newSensors.sort((a, b) => a.localeCompare(b))

    setSensors(newSensors)

    // Get sources
    const newSources = []
    for (let i = 0; i < images.length; i++) {
      const image = images[i]
      const source = image[IMAGE_KEYS.source]
      if (source !== null && source !== undefined && source !== "") {
        if (newSources.findIndex((d) => d === source) === -1) {
          newSources.push(source)
        }
      }
    }

    // Sort alphabetically
    newSources.sort((a, b) => a.localeCompare(b))

    setSources(newSources)
  }

  const resetFilter = () => {
    setFilterStartDate(startDate)
    setFilterEndDate(endDate)

    setSelectedLocations([])
    setSelectedSensors([])
    setSelectedSources([])

    setInputValue("")
    setSearchValue([])
    setSuggestions(totalImages)
  }

  // Initialize filter values
  useEffect(() => {
    if (totalImages.length === 0) {
      return
    }

    setSuggestions(totalImages)
    getFilterValues(totalImages)

    if (startDate.getTime() !== new Date(1990, 0, 1).getTime()) {
      return
    }

    const result = getTimelineData(totalImages)
    const start = new Date(result[0].year, result[0].month - 1, 1)
    const end = new Date(
      result[result.length - 1].year,
      result[result.length - 1].month - 1,
      31,
    )

    setStartDate(start)
    setEndDate(end)

    setTimeout(() => {
      setFilterStartDate(start)
      setFilterEndDate(end)

      // Init timeline values
      setTimelineStartDate(start)
      setTimelineEndDate(end)
      setTimelineData(result)
    }, 800)
  }, [totalImages])

  // Search images
  useEffect(() => {
    handleSearch()
  }, [
    searchValue,
    filterStartDate,
    filterEndDate,
    selectedLocations,
    selectedSensors,
    selectedSources,
  ])

  // Set filter item amount
  useEffect(() => {
    let amount = 0
    amount += selectedLocations?.length
    amount += selectedSensors?.length
    amount += selectedSources?.length
    setFilterItemAmount(amount)
  }, [selectedLocations, selectedSensors, selectedSources])

  // Reset filter when navigating to imagery library
  useEffect(() => {
    if (location.pathname === "/imagery-library") {
      resetFilter()
    }
  }, [location.pathname])

  useEffect(() => {
    setFilterBarHeight(filterbarMeasure.height)
  }, [filterbarMeasure.height])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchbarContainerRef.current &&
        !searchbarContainerRef.current.contains(event.target)
      ) {
        if (!isOpenAutoComplete.current) {
          setIsFocusAutoComplete(false)
        }
      }
    }

    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [searchbarContainerRef])

  return (
    <Holder ref={filterbarRef}>
      <FilterOption />
      <FilterContainer>
        <FilterFlexHolder>
          <SearchbarContainer ref={searchbarContainerRef}>
            <Searchbar
              className="p-float-label p-input-icon-left"
              ref={searchbarRef}
            >
              <i className="pi pi-search" />
              <StyledAutoComplete
                value={searchValue}
                suggestions={suggestions}
                itemTemplate={itemTemplate}
                selectedItemTemplate={selectedItemTemplate}
                scrollHeight={
                  suggestions?.length <= 6
                    ? `${suggestions?.length * 70}px`
                    : "22em"
                }
                multiple
                maxLength={100}
                selectionLimit={SELECTION_LIMIT}
                forceSelection={false}
                placeholder="Search by identity or keyword"
                emptyMessage="No results found"
                showEmptyMessage={true}
                completeMethod={completeMethod}
                virtualScrollerOptions={
                  suggestions?.length > 1 ? { itemSize: 70 } : undefined
                }
                onChange={handleChangeAutoComplete}
                onKeyPress={handleKeyPressAutoComplete}
                onFocus={handleFocusAutoComplete}
                focused={isFocusAutoComplete.toString()}
                maxwidth={searchbarMeasure.width}
              />
            </Searchbar>
          </SearchbarContainer>
          <div className="flex align-items-center gap-3">
            <DateRangePicker onClick={handleDatePickerDialogVisible} />
            <Divider />
            <FilterButton
              icon={FilterIcon}
              label="Filter"
              onClick={handleOpenFilterDialog}
              amount={filterItemAmount}
            />
          </div>
        </FilterFlexHolder>
        <SearchButton
          icon="pi pi-search"
          onClick={() => {
            handleSearch()
            posthog.capture("Image searched")
          }}
        />
      </FilterContainer>
      <FilterDialog
        visible={filterDialogVisible}
        onHide={handleCloseFilterDialog}
        locations={locations}
        sensors={sensors}
        sources={sources}
        selectedLocations={selectedLocations}
        setSelectedLocations={setSelectedLocations}
        selectedSensors={selectedSensors}
        setSelectedSensors={setSelectedSensors}
        selectedSources={selectedSources}
        setSelectedSources={setSelectedSources}
        setIsFiltered={setIsFilteredBySPL}
      />
      <DatePickerDialog
        start={startDate}
        end={endDate}
        visible={datePickerDialogVisible}
        onHide={handleDatePickerDialogVisible}
        setIsFiltered={setIsFilteredByDate}
      />
    </Holder>
  )
}

export default memo(Filterbar)

const Holder = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1em;
  gap: 1em;
  font-size: 0.9em;
  background-color: white;
`

const FilterContainer = styled.div`
  border: 1px solid rgba(4, 61, 93, 0.24);
  border-radius: 0.5em;
  display: flex;
  position: relative;
  flex: 1;
`

const FilterFlexHolder = styled.div`
  padding: 0.5em;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  gap: 1em;
`

const ChipText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`

const StyledAutoComplete = styled(AutoComplete)`
  background: #1c496819;
  border-radius: 0.5em;
  padding: 0.5em 0;

  .p-autocomplete-multiple-container {
    width: 100%;
    max-width: ${({ maxwidth }) => maxwidth}px;
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
    margin: 0 1em 0 3em !important;
    column-gap: 0.5em;
    row-gap: 0.5em;
    padding: 0.2em 0;
    flex-wrap: ${({ focused }) => (focused === "true" ? "wrap" : "unset")};
    overflow-x: ${({ focused }) => (focused === "true" ? "hidden" : "unset")};
    cursor: pointer;

    &::-webkit-scrollbar {
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #6b8fa3;
      border-radius: 10px;
      border: 0px solid #f1f1f1;
      border-radius: 2px;
    }

    > li > input {
      min-width: 15em;
    }
  }

  .p-autocomplete-token {
    position: relative;
    max-width: ${({ focused }) => (focused === "true" ? "100%" : "unset")};
  }

  .p-autocomplete-token-label {
    white-space: normal;
    overflow-wrap: ${({ focused }) =>
      focused === "true" ? "break-word" : "unset"};
    max-width: ${({ focused }) =>
      focused === "true" ? "calc(100% - 1.3em)" : "unset"};
  }

  .p-autocomplete-token-icon {
    background: #feeaea;
    border-radius: 1em;
    min-width: 1em;
    width: 1em;
    /* display: ${({ focused }) => (focused === "true" ? "flex" : "none")}; */
  }
`

const SearchButton = styled(Button)`
  min-width: 3em !important;
  width: 4.5em !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`

const SearchbarContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 10em;
`

const Searchbar = styled.span`
  display: flex;
  width: 100%;
`

const Divider = styled.div`
  width: 1px;
  height: 1.5em;
  background: #cccccc;
  margin: 0 0.5em;
`
