import {
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
  useImperativeHandle,
  memo,
} from "react"
import styled from "styled-components"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { Checkbox } from "primereact/checkbox"
import { MultiSelect } from "primereact/multiselect"
import { InputSwitch } from "primereact/inputswitch"
import { Slider } from "primereact/slider"
import { InputText } from "primereact/inputtext"
import { usePostHog } from 'posthog-js/react'

import { useStore } from "state/store"
import { getCookie, eraseCookie, setCookie } from "utils"
import useHideSelectOptionsOnScroll from "hooks/useHideSelectOptionsOnScroll"
import {
  VESSEL_STATUS,
  VESSEL_REDFLAG,
  VESSEL_KEYS,
  getFlags,
  VESSEL_TYPE,
  VESSEL_IDENTITY_TYPE,
} from "dataset/vessel"
import { H29, H32, H33, H7, H8, H9 } from "components/Labels"
import InfoBadge from "components/Badges/InfoBadge"
import "./index.css"

const FilterDialog = forwardRef((props, ref) => {
  const posthog = usePostHog()
  const filterDialogVisible = useStore((state) => state.filterDialogVisible)
  const currentUrl = useStore((state) => state.currentUrl)

  const setFilterDialogVisible = useStore(
    (state) => state.setFilterDialogVisible,
  )
  const setFilteredVessels = useStore((state) => state.setFilteredVessels)
  const totalVessels = useStore((state) => state.totalVessels)
  const savedVessels = useStore((state) => state.savedVessels)
  const setFilteredVesselsSaved = useStore(
    (state) => state.setFilteredVesselsSaved,
  )
  const setFilterItemAmount = useStore((state) => state.setFilterItemAmount)

  useHideSelectOptionsOnScroll()

  //Filter by identity type
  const filteredDataByIdentityType = (data, identityTypes) => {
    let result = data
    if (identityTypes.length > 0) {
      result = result.filter((item) =>
        identityTypes.includes(item[VESSEL_KEYS.identity]),
      )
    }
    return result
  }

  //Filter by RedFlags
  const filteredDataByRedFlags = (data, redFlags) => {
    let result = data
    if (redFlags.length > 0) {
      result = result.filter((item) => {
        let isContained = false
        for (let i = 0; i < item[VESSEL_KEYS.red_flags].length; i++) {
          if (redFlags.includes(item[VESSEL_KEYS.red_flags][i])) {
            isContained = true
          }
        }
        return isContained
      })
    }
    return result
  }

  //Filter by Status
  const filteredDataByStatus = (data, status) => {
    let result = data
    if (status.length > 0) {
      result = result.filter((item) =>
        status.includes(item[VESSEL_KEYS.status]),
      )
    }
    return result
  }

  //Filter by Flags
  const filteredDataByFlags = (data, _flags) => {
    let flags = _flags
    let result = data
    if (flags.length > 0) {
      result = result.filter((item) =>
        flags.includes(item[VESSEL_KEYS.target_flag]),
      )
    }
    return result
  }

  //Filter by Types
  const filteredDataByTypes = (data, types) => {
    let result = data
    if (types.length > 0) {
      result = result.filter((item) => types.includes(item[VESSEL_KEYS.type]))
    }
    return result
  }

  //Filter by Lengths
  const filteredDataByLengths = (data, lengthActive) => {
    let result = data
    if (lengthActive) {
      result = result.filter(
        (item) =>
          item[VESSEL_KEYS.length] >= lengthValue[0] &&
          item[VESSEL_KEYS.length] <= lengthValue[1],
      )
    }
    return result
  }

  //Filter by breadths
  const filteredDataByBreadths = (data, breadthActive) => {
    let result = data
    if (breadthActive) {
      result = result.filter(
        (item) =>
          item[VESSEL_KEYS.breadth] >= breadthValue[0] &&
          item[VESSEL_KEYS.breadth] <= breadthValue[1],
      )
    }
    return result
  }

  const [testStatus, setTestStatus] = useState([])
  const [status, setStatus] = useState([])

  const [identityTypes, setIdentityTypes] = useState([])
  const [duplicateIdentityTypes, setDuplicateIdentityTypes] = useState([])
  const [redFlagsDuplicate, setRedFlagsDuplicate] = useState([])
  const [redFlags, setRedFlags] = useState([])

  const handleFilter = (e) => {
    setTestStatus(status)
    setRedFlagsDuplicate(redFlags)
    setDuplicateIdentityTypes(identityTypes)
    setLengthActiveDuplicate(lengthActive)
    setDuplicateBreadthActive(breadthActive)
    let data = totalVessels
    data = filteredDataByIdentityType(data, identityTypes)
    data = filteredDataByRedFlags(data, redFlags)
    data = filteredDataByStatus(data, status)
    data = filteredDataByFlags(data, flags)
    data = filteredDataByTypes(data, types)
    data = filteredDataByLengths(data, lengthActive)
    data = filteredDataByBreadths(data, breadthActive)
    setFilteredVessels(data)

    let savedData = savedVessels
    savedData = filteredDataByIdentityType(savedData, identityTypes)
    savedData = filteredDataByRedFlags(savedData, redFlags)
    savedData = filteredDataByStatus(savedData, status)
    savedData = filteredDataByFlags(savedData, flags)
    savedData = filteredDataByTypes(savedData, types)
    savedData = filteredDataByLengths(savedData, lengthActive)
    savedData = filteredDataByBreadths(savedData, breadthActive)
    setFilteredVesselsSaved(savedData)

    let amount = 0
    if (identityTypes.length > 0) {
      amount += identityTypes.length
    }
    if (redFlags.length > 0) {
      amount += redFlags.length
    }
    if (status.length > 0) {
      amount += status.length
    }
    if (flags.length > 0) {
      amount += flags.length
    }
    if (types.length > 0) {
      amount += types.length
    }
    if (lengthActive) {
      amount += 1
    }
    if (breadthActive) {
      amount += 1
    }

    setFilterItemAmount(amount)

    onHide("filtered")
    const _flags = flags.filter((item) => item !== "Unknown")
    const filterDataObj = {
      identityTypes,
      redFlags,
      status,
      flags: _flags,
      types,
      lengthActive,
      lengthValue,
      breadthActive,
      breadthValue,
    }
    //Save Filter Data
    const filterData = JSON.stringify(filterDataObj)
    const isFilterEmpty = []
    isFilterEmpty.push(!filterDataObj.breadthActive)
    isFilterEmpty.push(
      JSON.stringify(filterDataObj.breadthValue) === JSON.stringify([10, 50]),
    )
    isFilterEmpty.push(!filterDataObj.flags.length)
    isFilterEmpty.push(!filterDataObj.identityTypes.length)
    isFilterEmpty.push(!filterDataObj.lengthActive)
    isFilterEmpty.push(
      JSON.stringify(filterDataObj.lengthValue) === JSON.stringify([20, 150]),
    )
    isFilterEmpty.push(!filterDataObj.redFlags.length)
    isFilterEmpty.push(!filterDataObj.status.length)
    isFilterEmpty.push(!filterDataObj.types.length)
    if (isFilterEmpty.includes(false)) {
      posthog.capture("Vessel filter applied")
    }
    setCookie("interest_db_filter", filterData, 30)
  }

  const handleReset = () => {
    setIdentityTypes([])
    setDuplicateIdentityTypes([])
    setRedFlags([])
    setTestStatus([])
    setRedFlagsDuplicate([])
    setStatus([])
    setFlags([])
    setTypes([])
    setLengthActive(false)
    setLengthActiveDuplicate(false)
    setLengthValue([20, 150])
    setBreadthActive(false)
    setBreadthValue([10, 50])
    eraseCookie("interest_db_filter")
    setFilterItemAmount(0)
  }

  useImperativeHandle(ref, () => ({
    handleReset,
  }))

  const onHide = (e) => {
    if (e === undefined) {
      setStatus(testStatus)
      setRedFlags(redFlagsDuplicate)
      setIdentityTypes(duplicateIdentityTypes)
      setLengthActive(lengthActiveDuplicate)
      setBreadthActive(duplicateBreadthActive)
    }

    setFilterDialogVisible(false)
  }

  const Header = () => {
    return <H7>Filter</H7>
  }

  const Footer = () => {
    return (
      <div>
        <Button
          label="Reset"
          className="p-button-raised"
          onClick={handleReset}
        />
        <Button
          label="Apply"
          className="p-button-raised"
          onClick={(e) => handleFilter(e)}
        />
      </div>
    )
  }

  const panelHeaderTemplate = (options) => {
    return <></>
  }

  //Identity types

  const onIdentityTypeChange = (e) => {
    let selectedValue = [...identityTypes]
    if (e.checked) {
      selectedValue.push(e.value)
    } else {
      selectedValue.splice(selectedValue.indexOf(e.value), 1)
    }

    setIdentityTypes(selectedValue)
  }

  const onRedFlagChange = (e) => {
    let selectedValue = [...redFlags]
    if (e.checked) {
      selectedValue.push(e.value)
    } else {
      selectedValue.splice(selectedValue.indexOf(e.value), 1)
    }

    setRedFlags(selectedValue)
  }

  const onStatusChange = (e) => {
    let selectedValue = [...status]
    if (e.checked) {
      selectedValue.push(e.value)
    } else {
      selectedValue.splice(selectedValue.indexOf(e.value), 1)
    }
    setStatus(selectedValue)
  }

  //Flags
  const [flags, setFlags] = useState([])
  //Types
  const [types, setTypes] = useState([])

  //Length
  const lengthRef = useRef(null)
  const [lengthActive, setLengthActive] = useState(false)
  const [lengthActiveDuplicate, setLengthActiveDuplicate] = useState(false)
  const [lengthMin, setLengthMin] = useState(0)
  const [lengthMax, setLengthMax] = useState(200)
  const [lengthValue, setLengthValue] = useState([20, 150])
  const [lengthLeft, setLengthLeft] = useState("0%")
  const [lengthRight, setLengthRight] = useState("100%")

  const lengthHandler = () => {
    if (!lengthRef.current) {
      setTimeout(lengthHandler, 500)
      return
    }
    const el = lengthRef.current.getElement()
    const leftEl = el.querySelector(".p-slider-handle-start")
    setLengthLeft(leftEl.style.left)
    const rightEl = el.querySelector(".p-slider-handle-end")
    setLengthRight(rightEl.style.left)
  }

  useEffect(() => {
    lengthHandler()
  }, [lengthValue])

  //breadthRef
  const breadthRef = useRef(null)
  const [breadthActive, setBreadthActive] = useState(false)
  const [duplicateBreadthActive, setDuplicateBreadthActive] = useState(false)
  const [breadthMin, setBreadthMin] = useState(0)
  const [breadthMax, setBreadthMax] = useState(100)
  const [breadthValue, setBreadthValue] = useState([10, 50])
  const [breadthLeft, setBreadthLeft] = useState("0%")
  const [breadthRight, setBreadthRight] = useState("100%")

  const breadthHandler = () => {
    if (!breadthRef.current) {
      setTimeout(breadthHandler, 500)
      return
    }
    const el = breadthRef.current.getElement()
    const leftEl = el.querySelector(".p-slider-handle-start")
    setBreadthLeft(leftEl.style.left)
    const rightEl = el.querySelector(".p-slider-handle-end")
    setBreadthRight(rightEl.style.left)
  }

  useEffect(() => {
    let data = totalVessels
    let cookieFilterData = getCookie("interest_db_filter")
    if (cookieFilterData) {
      cookieFilterData = JSON.parse(getCookie("interest_db_filter"))
      const {
        identityTypes,
        redFlags,
        status,
        flags: _flags,
        types,
        lengthActive,
        lengthValue,
        breadthActive,
        breadthValue,
      } = cookieFilterData
      if (currentUrl == undefined || currentUrl == null) {
        setIdentityTypes(identityTypes)
        setDuplicateIdentityTypes(identityTypes)
        setRedFlags(redFlags)
        setRedFlagsDuplicate(redFlags)
        setStatus(status)
        setTestStatus(status)
        setFlags([..._flags])
        setTypes(types)
        setLengthActive(lengthActive)
        setLengthActiveDuplicate(lengthActive)
        setLengthValue(lengthValue)
        setBreadthActive(breadthActive)
        setDuplicateBreadthActive(breadthActive)
        setBreadthValue(breadthValue)

        data = filteredDataByIdentityType(data, identityTypes)
        data = filteredDataByRedFlags(data, redFlags)
        data = filteredDataByStatus(data, status)
        data = filteredDataByFlags(data, _flags)
        data = filteredDataByTypes(data, types)
        data = filteredDataByLengths(data, lengthActive)
        data = filteredDataByBreadths(data, breadthActive)
      }
    }
    setFilteredVessels(data)
  }, [totalVessels])

  useEffect(() => {
    breadthHandler()
  }, [breadthValue])

  const allFlags = useMemo(() => {
    if (totalVessels?.length) {
      return getFlags(totalVessels)
    }
    return []
  }, [totalVessels])

  const flagLabels = useMemo(() => {
    if (allFlags?.length && flags?.length) {
      return flags.map((flag) => {
        const selected = allFlags.filter((_flag) => _flag.value === flag)?.[0]
        if (selected) {
          return selected
        }
        return { label: flag, value: flag }
      })
    }
    return []
  }, [allFlags, flags])
  const updatedFlags = useMemo(() => {
    if (allFlags?.length) {
      return allFlags
        .filter((_flag) => _flag.value !== "")
        .map((_flag) => {
          if (_flag.value === "0") {
            return { ..._flag, value: "0" }
          } else {
            return _flag
          }
        })
    } else {
      return []
    }
  }, [allFlags])

  const { sortedRedFlags, sortedStatus } = useMemo(
    () => ({
      sortedRedFlags: VESSEL_REDFLAG.sort((a, b) => {
        const labelA = a.label.toLowerCase()
        const labelB = b.label.toLowerCase()
        if (labelA < labelB) {
          return -1
        }
        if (labelA > labelB) {
          return 1
        }
        return 0
      }),
      sortedStatus: VESSEL_STATUS.sort((a, b) => {
        const labelA = a.label.toLowerCase()
        const labelB = b.label.toLowerCase()
        if (labelA < labelB) {
          return -1
        }
        if (labelA > labelB) {
          return 1
        }
        return 0
      }),
    }),
    [],
  )

  return (
    <StyledDialog
      header={Header}
      footer={Footer}
      visible={filterDialogVisible}
      onHide={onHide}
    >
      <Content>
        <div>
          <H9>Identity Type</H9>
          <H8 className="mt-2">
            Know whether an identity belongs to a real vessel or has been used
            fraudulently
          </H8>
          <div className="grid mt-3">
            {VESSEL_IDENTITY_TYPE.map((d, index) => (
              <div
                key={index}
                className="col-12 md:col-6 lg:col-4 flex align-items-center gap-1"
              >
                <Checkbox
                  inputId={`cb-idtype-${index}`}
                  value={d.value}
                  onChange={onIdentityTypeChange}
                  checked={identityTypes.includes(d.value)}
                ></Checkbox>
                <label
                  htmlFor={`cb-idtype-${index}`}
                  className="p-checkbox-label pl-2"
                >
                  {d.label}
                </label>
                {d.info && <InfoBadge label={d.info} />}
              </div>
            ))}
          </div>
        </div>
        <div className="mt-5">
          <H9>Red Flags</H9>
          <H8 className="mt-2">
            Risk indicators based on sanctioned status or activities
          </H8>
          <div className="grid mt-3">
            {sortedRedFlags.map((d, index) => (
              <div
                key={index}
                className="col-12 md:col-6 lg:col-6 custom-cols-4 flex align-items-center gap-1"
              >
                <Checkbox
                  inputId={`cb-flag-${index}`}
                  value={d.value}
                  onChange={onRedFlagChange}
                  checked={redFlags.includes(d.value)}
                ></Checkbox>
                <CheckboxLabel
                  htmlFor={`cb-flag-${index}`}
                  className="p-checkbox-label pl-2"
                >
                  {d.label}
                </CheckboxLabel>
                {d.info && <InfoBadge label={d.info} />}
              </div>
            ))}
          </div>
        </div>
        <div className="mt-5">
          <H9>Status</H9>
          <H8 className="mt-2">Current operational status of the vessel</H8>
          <div className="grid mt-3">
            {sortedStatus.map((d, index) => (
              <div
                key={index}
                className="col-12 md:col-6 lg:col-4 flex align-items-center"
              >
                <Checkbox
                  inputId={`cb-status-${index}`}
                  value={d.value}
                  onChange={onStatusChange}
                  checked={status.includes(d.value)}
                ></Checkbox>
                <label
                  htmlFor={`cb-status-${index}`}
                  className="p-checkbox-label pl-2"
                >
                  {d.label}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-5 grid">
          <div className="col-12 lg:col-6">
            <H9>Flags</H9>
            <MultiSelect
              value={flags}
              options={updatedFlags}
              onChange={(e) => setFlags(e.value)}
              optionLabel="label"
              optionValue="value"
              placeholder="Select flags"
              className="mt-2 w-full"
              panelHeaderTemplate={panelHeaderTemplate}
              maxSelectedLabels={3}
            />
            <div className="flex flex-wrap gap-2 mt-2">
              {flagLabels.map((flag, index) => {
                if (flag.label === "Unknown") return <></>
                return (
                  <Chip key={index}>
                    <H29>{flag.label}</H29>
                    <Button
                      icon="pi pi-times"
                      className="p-button-sm p-button-rounded p-button-text"
                      onClick={() => {
                        setFlags(flags.filter((f) => f !== flag.value))
                      }}
                    />
                  </Chip>
                )
              })}
            </div>
          </div>
          <div className="col-12 lg:col-6">
            <H9>Types</H9>
            <MultiSelect
              value={types}
              options={VESSEL_TYPE}
              onChange={(e) => setTypes(e.value)}
              optionLabel="label"
              optionValue="value"
              placeholder="Select types"
              className="mt-2 w-full"
              panelHeaderTemplate={panelHeaderTemplate}
              maxSelectedLabels={3}
            />
            <div className="flex flex-wrap gap-2 mt-2">
              {types.map((type, index) => (
                <Chip key={index}>
                  <H29>{VESSEL_TYPE.find((d) => d.value === type)?.label}</H29>
                  <Button
                    icon="pi pi-times"
                    className="p-button-sm p-button-rounded p-button-text"
                    onClick={() => {
                      setTypes(types.filter((f) => f !== type))
                    }}
                  />
                </Chip>
              ))}
            </div>
          </div>
        </div>
        <div
          className={`mt-5 ${lengthActive ? "custom-filter" : ""}`}
          style={{
            backgroundColor: "#f9f9f9",
            borderRadius: "0.5em",
            padding: "1em",
          }}
        >
          <div className="flex gap-3">
            <H9>Length</H9>
            <InputSwitch
              checked={lengthActive}
              onChange={(e) => setLengthActive(e.value)}
            />
          </div>
          <InputsContainer>
            <InputTextStyledContainer className="p-float-label">
              <InputTextStyled
                name="min"
                id="min"
                className="w-full"
                value={lengthValue[0]}
                autoFocus
                disabled
              />
              <label htmlFor="min">min</label>
            </InputTextStyledContainer>
            <InputTextStyledContainer className="p-float-label">
              <InputTextStyled
                name="max"
                id="max"
                className="w-full"
                value={lengthValue[1]}
                autoFocus
                disabled
              />
              <label htmlFor="max">max</label>
            </InputTextStyledContainer>
          </InputsContainer>
          <div className="mt-6 pl-4 pr-4">
            <Slider
              ref={lengthRef}
              min={lengthMin}
              max={lengthMax}
              value={lengthValue}
              onChange={(e) => {
                if (e.value[0] < e.value[1]) {
                  setLengthValue(e.value)
                }
              }}
              range
              disabled={!lengthActive}
            />
            <div className="mt-3 flex justify-content-between">
              <H32>{`${lengthMin} Mtr`}</H32>
              <H32>{`${lengthMax} Mtr`}</H32>
            </div>
          </div>
        </div>
        <div
          className={`mt-5 ${breadthActive ? "custom-filter" : ""}`}
          style={{
            backgroundColor: "#f9f9f9",
            borderRadius: "0.5em",
            padding: "1em",
          }}
        >
          <div className="flex gap-3">
            <H9>Breadth</H9>
            <InputSwitch
              checked={breadthActive}
              onChange={(e) => setBreadthActive(e.value)}
            />
          </div>
          <InputsContainer>
            <InputTextStyledContainer className="p-float-label">
              <InputTextStyled
                name="min"
                id="min"
                className="w-full"
                value={breadthValue[0]}
                autoFocus
                disabled
              />
              <label htmlFor="min">min</label>
            </InputTextStyledContainer>
            <InputTextStyledContainer className="p-float-label">
              <InputTextStyled
                name="max"
                id="max"
                className="w-full"
                value={breadthValue[1]}
                autoFocus
                disabled
              />
              <label htmlFor="max">max</label>
            </InputTextStyledContainer>
          </InputsContainer>
          <div className="mt-6 pl-4 pr-4">
            <Slider
              ref={breadthRef}
              min={breadthMin}
              max={breadthMax}
              value={breadthValue}
              onChange={(e) => {
                if (e.value[0] < e.value[1]) {
                  setBreadthValue(e.value)
                }
              }}
              range
              disabled={!breadthActive}
            />
            <div className="mt-3 flex justify-content-between">
              <H32>{`${breadthMin} Mtr`}</H32>
              <H32>{`${breadthMax} Mtr`}</H32>
            </div>
          </div>
        </div>
      </Content>
    </StyledDialog>
  )
})

export default memo(FilterDialog)

const StyledDialog = styled(Dialog)`
  min-width: 20em;
  width: 50%;
  max-width: 60em;
`

const Content = styled.div`
  padding: 1.5em 0;
`

const Chip = styled.div`
  display: flex;
  align-items: center;
  background-color: #fcdbdc;
  border-radius: 3em;
  padding: 0.25em 0.2em 0.25em 0.7em;
  > button {
    padding: 0.8em !important;
    margin: 0 !important;
    width: 1em !important;
    height: 1em !important;
    > span {
      font-size: 0.7em !important;
    }
  }
`

const HandleLabel = styled(H33)`
  position: absolute;
  top: -2.5em;
  left: ${(props) => props.x};
  transform: translateX(-50%);
  width: fit-content;
`

const InputTextStyled = styled(InputText)`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 0.5em;
  padding: 1.2em 0.8em;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.1em;
  color: #000000;
`

const InputTextStyledContainer = styled.div`
  width: 48%;
`

const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
`

const CheckboxLabel = styled.label`
  ${"" /* white-space: nowrap; */}
`
