import { memo } from "react"
import styled from "styled-components"

function RRPUploadDialogHeader() {
  return (
    <Holder>
      <h4>Upload Document</h4>
    </Holder>
  )
}

export default memo(RRPUploadDialogHeader)

const Holder = styled.div`
  > h4 {
    font-size: 1.2em;
    font-weight: 600;
    color: #122945;
  }
  > p {
    font-size: 0.9em;
    font-weight: 100;
    color: #6c757d;
    margin-top: 0.5em;
  }
`
