import { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import { device } from "theme/device"
import { useStore } from "state/store"
import { ChangelogRoute, PrivacyPolicyRoute } from "routes"
import { MiddleContainer } from "components/Containers"
import LogoIcon from "assets/images/c4ads-logo.svg"
import GacLogo from "assets/images/ca-logo.png"

export default function Footer() {
  const footerVisible = useStore((state) => state.footerVisible)
  const setFooterVisible = useStore((state) => state.setFooterVisible)

  const location = useLocation()

  useEffect(() => {
    if (location.pathname === "/imagery-library") {
      setFooterVisible(false)
    } else {
      setFooterVisible(true)
    }
  }, [location, setFooterVisible])
  var currentYear = new Date().getFullYear()

  return (
    <AnimatePresence>
      {footerVisible && (
        <Holder
          transition={{ duration: 0.2 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <MiddleContainer>
            <FlexContainer>
              <LogoPart>
                <Logo />
                <LogoBorder />
                <GACLOGO />
              </LogoPart>
              <div>
                <LinkPart>
                  <StyledLink
                    to={PrivacyPolicyRoute.path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </StyledLink>
                  <StyledLink
                    to="/eula"
                    target="_parent"
                    rel="noopener noreferrer"
                  >
                    EULA
                  </StyledLink>
                  <StyledLink
                    to="/about-us"
                    target="_parent"
                    rel="noopener noreferrer"
                  >
                    About
                  </StyledLink>
                  <StyledLink
                    to={ChangelogRoute.path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Changelog
                  </StyledLink>
                </LinkPart>
                <Caption>
                  © {currentYear} Center for Advanced Defense Studies, Inc.
                </Caption>
              </div>
            </FlexContainer>
          </MiddleContainer>
        </Holder>
      )}
    </AnimatePresence>
  )
}

const Holder = styled(motion.footer)`
  padding: 2em 0;
  //margin-top: 2em;
  border-top: 1px solid #f4f4f4;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const LogoPart = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`

const Logo = styled.div`
  width: 8em;
  height: 2.8em;
  background-image: url(${LogoIcon});
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
`

const GACLOGO = styled.div`
  width: 8em;
  height: 2.8em;
  background-image: url(${GacLogo});
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  margin-left: 1em;
`
const LogoBorder = styled.div`
  border-left: 1px solid #000000;
  height: 2.8em;
  width: 1px;
  margin-left: 1em;
`
const LogoLabel = styled.div`
  padding-top: 0.5em;
  font-size: 0.9em;
  max-width: 40em;
`

const LinkPart = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ContactPart = styled.div`
  padding-top: 2.5em;
  font-size: 0.9em;
  min-width: 14em;
  max-width: 20em;
  @media ${device.mobileL} {
    padding-top: 0.5em;
  }
`
const LinkItem = styled.a`
  font-size: 1em;
  line-height: 250%;
  font-weight: 700;
  color: #7b8694;
  transition: all 300ms ease;
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.primary};
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000000;
  font-size: 0.75em;
  font-weight: 600;
  font-family: "century-gothic";
  margin-left: 2em;
  :first-child {
    margin-left: 0;
  }
`

const Caption = styled.p`
  font-family: "century-gothic";
  font-weight: 400;
  font-size: 10px;
  line-height: 12.25px;
  color: #000000;
  margin-top: 1.5em;
`
