import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import LinkIcon from "assets/images/icon-link-arrow.svg"

export default function ToolCard({ className, title, description, link }) {
  const navigate = useNavigate()

  return (
    <Holder
      className={className}
      onClick={() => {
        if (link) {
          if (link.startsWith("https")) {
            window.open(link, "_blank");
          } else {
            navigate(link);
          }
        }
      }}
    >
      <Container>
        <Title>{title}</Title>
        <Description className="mt-3">{description}</Description>
        {link && <Link src={LinkIcon} />}
      </Container>
    </Holder>
  )
}

const Holder = styled.div`
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.1);
  padding: 1em;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.25);
  }
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 6em;
`

const Title = styled.div`
  color: #122945;
  font-size: 1.25em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const Description = styled.div`
  color: #122945;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 28em;
`

const Link = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 1.1em;
  transition: all 0.2s ease-out;
`
