import styled from "styled-components"
export default function MapLegend(props) {
  return (
    <LegendContainer>
      <Legend noSpace>
        <LegendDot noSpace style={{ backgroundColor: "#A0B9D0" }} />
        <span style={{ color: "#A0B9D0" }}>Port</span>
      </Legend>
      <Legend>
        <LegendDot style={{ backgroundColor: "#3BBC97" }} />
        <span style={{ color: "#3BBC97" }}>Shipyard</span>
      </Legend>
      <Legend>
        <LegendDot style={{ backgroundColor: "#F75151" }} />
        <span style={{ color: "#F75151" }}>Hotspot</span>
      </Legend>
    </LegendContainer>
  )
}

const Legend = styled.div`
  display: flex;
  row-gap: 10px;
  align-items: center;
  z-index: 1500;
  background-color: #122a47;
  padding-left: ${({ noSpace }) => (noSpace ? "0" : "5px")};
`

const LegendContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  bottom: 2.3rem;
  background-color: #122a47;
  border-radius: 8px;
  row-gap: 10px;
  right: 8%;
`

const LegendDot = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  margin: 10px;
  margin-left: ${({ noSpace }) => (noSpace ? "0px" : "10px")};
`
