import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useStore } from "state/store"
import { eraseCookieForLogout } from "utils"

export default function EulaChecker() {
  const currentUser = useStore((state) => state.currentUser)
  const setCurrentUser = useStore((state) => state.setCurrentUser)
  const resetStore = useStore((state) => state.resetStore)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    // console.log("location.pathname", location.pathname)
    // console.log("currentUser", currentUser.accepted_eula)
    if (location.pathname !== "/eula") {
      if (currentUser) {
        if (currentUser.accepted_eula === false) {
          eraseCookieForLogout()
          setCurrentUser(null)
          navigate("/")
          resetStore()
        }
      }
    }
  }, [location, currentUser, setCurrentUser, navigate, resetStore])

  return <></>
}
