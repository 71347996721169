import { memo } from "react"
import styled from "styled-components"
import { Divider } from "primereact/divider"

import { H12, H14, H15 } from "components/Labels"
import { VESSEL_IDENTITY_TYPE } from "dataset/vessel"

const VesselSlideItem = ({ data, start = true, end = true, onClick }) => {
  if (!data) {
    return null
  }

  return (
    <VesselItemHolder
      className="flex flex-column"
      start={start}
      end={end}
      onClick={onClick}
    >
      <IdentityLabel
        className="mt-1 mb-2"
        color={
          VESSEL_IDENTITY_TYPE.find((i) => i.label === data["identity_verbose"])
            ?.color || "#000000"
        }
      >
        {data["identity_verbose"] || "N/A"}
      </IdentityLabel>
      <NameLabel>{data["name"] || "N/A"}</NameLabel>
      <div className="flex mt-2">
        <div>
          <div>
            <H14>IMO</H14>
            <br />
            <IMOLabel>{data["imo"] || "N/A"}</IMOLabel>
          </div>
          <div className="mt-1">
            <H14>MMSI</H14>
            <br />
            <MMSILabel>{data["mmsi"] || "N/A"}</MMSILabel>
          </div>
        </div>
        <Divider layout="vertical" />
        <div>
          <div>
            <H14>TYPE</H14>
            <br />
            <TypeLabel>{data["type_verbose"] || "N/A"}</TypeLabel>
          </div>
          <div className="mt-1">
            <H14>FLAG</H14>
            <br />
            <FlagLabel>{data["flag"] || "N/A"}</FlagLabel>
          </div>
        </div>
      </div>
    </VesselItemHolder>
  )
}

export default memo(VesselSlideItem)

const VesselItemHolder = styled.div`
  border-top-left-radius: ${(props) => (props.start ? "1em" : 0)};
  border-bottom-left-radius: ${(props) => (props.start ? "1em" : 0)};
  border-top-right-radius: ${(props) => (props.end ? "1em" : 0)};
  border-bottom-right-radius: ${(props) => (props.end ? "1em" : 0)};
  border-top: 1px solid #beced4;
  border-bottom: 1px solid #beced4;
  border-left: 1px solid #beced4;
  border-right: ${(props) => (props.end ? "1px solid #beced4" : "unset")};
  min-width: fit-content;
  width: 100%;
  background-color: ${(props) => (props.start ? "#ffffff" : "#f2f2f2")};
  padding: 1em;
  margin-right: ${(props) => (props.end ? "1em" : 0)};
  cursor: pointer;
`

const IdentityLabel = styled.div`
  font-size: 0.75em;
  font-weight: 700;
  color: ${(props) => props.color};
  line-height: 1.25;
  letter-spacing: 0.01em;
  max-width: 21em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const NameLabel = styled(H15)`
  max-width: 21em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const IMOLabel = styled(H12)`
  max-width: 7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const MMSILabel = styled(H12)`
  max-width: 7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TypeLabel = styled(H12)`
  max-width: 12em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const FlagLabel = styled(H12)`
  max-width: 12em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
