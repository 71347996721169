import { memo, useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { InputText } from "primereact/inputtext"
import { FilterMatchMode } from "primereact/api"
import { createBulkUser } from "api/auth"
import PaginatorTemplate from "components/PaginatorTemplate"

function BulkAccountConfirmDialog({
  visible,
  onHide,
  onSubmitted,
  bulkAccounts,
  bulkCsvFile,
}) {
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(10)
  const [search, setSearch] = useState("")
  const [uploading, setUploading] = useState(false)
  const [filters, setFilters] = useState({
    global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  })

  const paginatorTemplate = PaginatorTemplate()

  const onPage = (event) => {
    setFirst(event.first)
    setRows(event.rows)
  }

  const handleSubmit = () => {
    setUploading(true)
    createBulkUser(bulkCsvFile).finally(() => {
      setUploading(false)
      onHide()
      onSubmitted()
    })
  }

  const handleSearch = useCallback((e) => {
    setSearch(e.target.value ? e.target.value : "")
  }, [])

  //Get columns from bulkAccounts
  const columns = useMemo(
    () =>
      bulkAccounts.length > 0
        ? Object.keys(bulkAccounts[0]).map((key) => ({
            field: key,
            header: key,
          }))
        : [],
    [bulkAccounts],
  )

  useEffect(() => {
    let _filters = { ...filters }
    _filters["global"].value = search
    setFilters(_filters)
  }, [search])

  return (
    <Holder visible={visible} onHide={onHide} header="Confirm User Details">
      <Content>
        <span className="p-input-icon-left w-full mb-3">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search Users"
            className="w-full"
            value={search}
            onChange={handleSearch}
          />
        </span>
        <DataTable
          value={bulkAccounts}
          paginator
          filters={filters}
          paginatorTemplate={paginatorTemplate}
          first={first}
          rows={rows}
          onPage={onPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        >
          {columns.map((column, index) => (
            <Column key={index} {...column} />
          ))}
        </DataTable>
        <div className="flex justify-content-end mt-4">
          <Button
            className="p-button p-button-primary"
            onClick={handleSubmit}
            label="Submit"
            disabled={uploading}
            loading={uploading}
          />
        </div>
      </Content>
    </Holder>
  )
}

export default memo(BulkAccountConfirmDialog)

const Holder = styled(Dialog)`
  min-width: 300px;
  width: 50vw;
  max-width: 1200px;
  .p-dialog-content {
    padding: 1em;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`
const Content = styled.div`
  padding: 0em 1em;
`
