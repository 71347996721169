import { MiddleContainer } from "components/Containers"
import { PageTitle, Typography, TypographyHolder } from "components/Labels"
import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import MapContainer from "./MaritimeInterestMap"
export default function MaritimeMap() {
  const history = useNavigate()

  return (
    <Holder>
      <MapContainer />
      <MiddleContainer>
        <FlexRow>
          <ContentRightCols>
            <PageTitle className="w-80">
              DPRK Maritime Interactive Map
            </PageTitle>
            <TypographyHolder>
              <Typography>
                Explore the global impact of DPRK maritime sanctions evasion
                through an interactive map featuring in-depth profiles on
                countries, hotspots, ports, and shipyards.
              </Typography>
            </TypographyHolder>
            <Button
              icon="icon-common icon-arrow-button"
              iconPos="right"
              label="Explore"
              onClick={() => history("/interactive-map")}
            ></Button>
          </ContentRightCols>
        </FlexRow>
      </MiddleContainer>
    </Holder>
  )
}

const Holder = styled.section`
  margin-top: -1px;
  padding-top: 50px;
  width: 100%;
  position: relative;
  min-height: 500px;
  &::before {
    height: 432px;
    background: linear-gradient(180deg, #dde4ec 0%, #ffffff 100%);
    content: "";
    position: absolute;
    width: 100%;
    top: 0px;
  }
  #deckgl-wrapper {
    width: 65% !important;
  }
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    background: rgb(221 227 236 / 50%);
    padding: 1em;
    border: 1px solid #88a2b6;
  }
`
const MapHolder = styled.div`
  width: 50%;
  margin-right: auto;
`
const ContentRightCols = styled.div`
  width: 50%;
  margin-left: auto;
  .w-80 {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
