import styled from "styled-components"
import { useEffect, useState } from "react"
export default function SidebarDropdown(props) {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState("")

  let data = []

  if (props.data !== undefined) {
    data = props.data
  }

  const selectItem = function (val) {
    setSelected(val)
    props.selectItem(val)
  }

  const linked = props.timeline && props.type === "linked"

  useEffect(() => {
    setOpen(false)
  }, [props.infoId])
  return (
    <SourceDropDownHolder onClick={() => setOpen(!open)}>
      <DropDownTop>
        {props.children}
        <div>
          <DropdownTitle>
            {props.title}({data.length})
          </DropdownTitle>
        </div>
        <div onClick={() => setOpen(!open)}>
          {open && (
            <svg
              width="8"
              height="4"
              viewBox="0 0 8 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 -3.49691e-07L8 4L0 4L4 -3.49691e-07Z"
                fill="#043D5D"
              />
            </svg>
          )}
          {!open && (
            <svg
              width="8"
              height="4"
              viewBox="0 0 8 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 4L0 0H8L4 4Z" fill="#043D5D" />
            </svg>
          )}
        </div>
      </DropDownTop>
      {open && !!data?.length && (
        <DropdownDescription>
          {props.timeline && !linked && (
            <DropdownTimeline>
              {data.map((item, index) => {
                return (
                  <TimelineCaption
                    onClick={() => selectItem(item)}
                    key={index}
                    style={{
                      fontWeight: selected === item ? "bold" : "",
                    }}
                  >
                    {item}
                  </TimelineCaption>
                )
              })}
            </DropdownTimeline>
          )}
          {linked && (
            <div style={{ maxHeight: "20vh", overflowY: "scroll" }}>
              <DropdownTimeline>
                {data.map((item, index) => {
                  return (
                    <TimelineCaption
                      onClick={() => {
                        selectItem(item.id)
                        props.openSidebar(item)
                      }}
                      key={index}
                      style={{
                        fontWeight: selected === item.id ? "bold" : "",
                      }}
                    >
                      {item.name}
                    </TimelineCaption>
                  )
                })}
              </DropdownTimeline>
            </div>
          )}
          {!props.timeline && (
            <div>
              <List>
                {data.map((item, index) => {
                  return (
                    <li
                      style={{
                        fontWeight: selected === item.value ? "bold" : "",
                      }}
                      onClick={() => selectItem(item)}
                      key={"l_" + index}
                    >
                      {props.type && props.type === "source" && (
                        <a
                          target="_blank"
                          href={props.links[index]}
                          rel="noreferrer"
                        >
                          {item}
                        </a>
                      )}
                      {props.type === undefined && <span>{item}</span>}
                    </li>
                  )
                })}
              </List>
            </div>
          )}
        </DropdownDescription>
      )}
    </SourceDropDownHolder>
  )
}
const SourceDropDownHolder = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 40px;
  background-color: rgba(4, 61, 93, 0.05);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
`
const DropDownTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
`

const DropdownTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #043d5d;
`

const DropdownDescription = styled.div`
  width: 100%;
  border-top: solid 1px rgba(4, 60, 93, 0.479);
  padding-left: 10px;
  padding-bottom: 16px;
`

const DropdownTimeline = styled.div`
  padding-left: 5px;
  margin-bottom: 10px;
  position: relative;
  border-left: solid 1px rgba(4, 60, 93, 0.479);
`

const TimelineCaption = styled.a`
  position: relative;
  padding-left: 30px;
  padding-top: 10px;
  margin-bottom: 10px;
  display: block;
  ::after {
    content: "";
    position: absolute;
    display: block;
    width: 28px;
    border: solid 1px rgba(4, 60, 93, 0.479);
    top: 65%;
    left: -1.5%;
  }
  :hover {
    text-decoration: underline;
  }
`

const List = styled.ul`
  padding-left: 30px;
  > li {
    margin-top: 10px;
    margin-bottom: 5px;
  }
`
