import styled from "styled-components"

import { H37, H38 } from "components/Labels"
import NoVesselsImage from "assets/images/no-vessels.svg"
import BookmarkIcon from "assets/images/icon-bookmark.svg"

export default function Nodata({ showEmptySavedVessel = false }) {
  return (
    <Holder className="m-6">
      <ImgHolder>
        <img src={NoVesselsImage} alt="No images found" />
      </ImgHolder>
      <H38 className="font-semibold" style={{ fontSize: "1.4em" }}>
        {showEmptySavedVessel ? "No Saved Images" : "No Results"}
      </H38>
      <SubTitle>
        <H37 style={{ fontSize: "1em" }}>
          {showEmptySavedVessel ? (
            <span>
              You can save a vessel by clicking on the bookmark icon bookmark
              &nbsp;
              <Bookmark alt="bookmark" src={BookmarkIcon} />
              &nbsp; on a Card in the All images tab
            </span>
          ) : (
            "Try adjusting your search or filter to find what you’re looking for"
          )}
        </H37>
      </SubTitle>
    </Holder>
  )
}

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`

const SubTitle = styled.div`
  max-width: 50em;
  text-align: center;
  line-height: 1.8;
`

const Bookmark = styled.img`
  width: 0.8em;
  margin-bottom: -0.2em;
`

const ImgHolder = styled.div`
  > img {
    width: 10em;
  }
`
