import bgImageLibrary from "assets/images/BackgroundPattern.svg"
import img1 from "assets/images/imgelibrary-1.jpeg"
import img2 from "assets/images/imgelibrary-2.jpeg"
import img3 from "assets/images/imgelibrary-3.jpeg"
import img4 from "assets/images/imgelibrary-4.jpeg"
import {
  CounterBox,
  CounterBoxHolder,
  PageTitle,
  Typography,
  TypographyHolder,
} from "components/Labels"
import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

function Counter({ label, value }) {
  return (
    <CounterBox>
      <Typography className="counterValue">{label}</Typography>
      <Typography className="counterTitle">{value}</Typography>
    </CounterBox>
  )
}

export default function ImageryLibrary() {
  const history = useNavigate()
  return (
    <OuterContainer>
      <Container>
        <FlexRow>
          <ImageryLibraryContent>
            <PageTitle>Imagery Library</PageTitle>
            <TypographyHolder>
              <Typography>
                Access the world’s most comprehensive database of public and
                commercial imagery of DPRK-linked vessels of interest
              </Typography>
            </TypographyHolder>
            <Button
              icon="icon-common icon-arrow-button"
              label="Explore"
              iconPos="right"
              onClick={() => history("/imagery-library")}
            ></Button>
            <CounterBoxHolder>
              <Counter label={530} value={"Images"} />
              <Counter label={272} value={"Vessels"} />
              <Counter label={6} value={"Providers"} />
            </CounterBoxHolder>
          </ImageryLibraryContent>
          <ImagesLibrary>
            <Row className="md:w-10 mt-4 md:mt-0 gap-6 ml-auto">
              <Column className="w-6 justify-content-end">
                <ImageItem className="">
                  <img src={img1} alt={""} className="zoom-img" />
                </ImageItem>
              </Column>
              <Column className="w-6 gap-6">
                <ImageItem className="">
                  <img src={img2} alt={""} className="zoom-img" />
                </ImageItem>
                <ImageItem className="">
                  <img src={img3} alt={""} className="zoom-img" />
                </ImageItem>
              </Column>
            </Row>
            <Row className="w-full mt-6">
              <ImageItem>
                <img src={img4} alt={""} className="zoom-img" />
              </ImageItem>
            </Row>
          </ImagesLibrary>
        </FlexRow>
      </Container>
    </OuterContainer>
  )
}

const OuterContainer = styled.section`
  width: 100%;
  background: linear-gradient(0deg, #dde4ec 0%, #ffffff 100%);
`
const Container = styled.div`
  width: 1220px;
  margin: 0 auto;
  @media (max-width: 1350px) {
    width: 90%;
  }
  }
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0px;
  padding-bottom: 350px;
  width: 100%;
  position: relative;
  z-index: 2;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 100px;
  }
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 30%;
    top: 15%;
    background-image: url(${bgImageLibrary});
    background-repeat: no-repeat, no-repeat;
    background-size: contain;
    background-position: center;
    content: "";
    z-index: -1;
    @media (max-width: 768px) {
      left: 0;
      top: 0;
      background-size: cover;
      opacity: 0.4;
    }
  }
  &::after {
    height: 100%;
    content: "";
    background: linear-gradient(180deg, #dde4ec 0%, #ffffff 100%);
    width: 100%;
    position: absolute;
    top: 0px;
    opacity: 0;
    z-index: -1;
  }
  img {
    &.zoom-img {
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
`
const ImageryLibraryContent = styled.div`
  width: 50%;
  position: relative;
  z-index: 2;
  @media (max-width: 768px) {
    width: 90%;
  }
`

const ImagesLibrary = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 90%;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`

const ImageItem = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 1em;
  }
`
