import { useState } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

import PaginatorTemplate from "components/PaginatorTemplate"
import { Columns, Data } from "./DemoData"

export default function PaginateDataTable({
  value = Data,
  columns = Columns,
  lazy = false,
  loading = false,
}) {
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(10)

  const paginatorTemplate = PaginatorTemplate()

  const onCustomPage = (event) => {
    setFirst(event.first)
    setRows(event.rows)
  }

  return (
    <DataTable
      value={value}
      paginator
      paginatorTemplate={paginatorTemplate}
      first={first}
      rows={rows}
      onPage={onCustomPage}
      lazy={lazy}
      loading={loading}
    >
      {columns.map((column, index) => (
        <Column
          key={index}
          field={column.field}
          header={column.header}
          style={{ width: column.width }}
          body={column.body || null}
          sortable={column.sortable}
        />
      ))}
    </DataTable>
  )
}
