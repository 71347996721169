export const VOLUME = "Volume (Barrels)"
export const NUMBER_OF_SHIPMENTS = "Number of shipments"
export const NUMBER_OF_SHIPS = "Number of ships"
export const YEAR = "Year"
export const MONTH = "Month"
export const DATE = "Date"

const ALL = {
  VOLUME,
  NUMBER_OF_SHIPMENTS,
  NUMBER_OF_SHIPS,
  YEAR,
  MONTH,
  DATE,
}

export default ALL
