import { useState, useEffect } from "react"
import { DatePicker } from "components/Form"
export default function DateRangePicker({
  value = null,
  onChange,
  ...restProps
}) {
  // date range
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    const [start = null, end = null] = value || []
    setStartDate(start)
    setEndDate(end)
  }, [])

  const handleChange = (val, field = "start") => {
    if (field === "start") {
      setStartDate(val)
      onChange([val, endDate])
    } else if (field === "end") {
      setEndDate(val)
      onChange([startDate, val])
    }
  }

  return (
    <div {...restProps}>
      <DatePicker
        selected={startDate}
        placeholderText="From date"
        onChange={(date) => handleChange(date, "start")}
        selectsStart
        startDate={startDate}
        endDate={endDate}
      />
      <DatePicker
        selected={endDate}
        placeholderText="To date"
        onChange={(date) => handleChange(date, "end")}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </div>
  )
}
