import { useState, forwardRef, useEffect } from "react"
import ReactDatePicker from "react-datepicker"
import styled from "styled-components"
import { getMonth, getYear } from "date-fns"
import range from "lodash/range"
import { InputText } from "primereact/inputtext"
import "react-datepicker/dist/react-datepicker.css"

import DownArrow from "assets/images/icon-arrow-down-black.svg"

const DPInput = forwardRef((props, ref) => {
  return (
    <span className="w-full p-input-icon-right">
      <i className="pi pi-calendar" />
      <InputText {...props} />
    </span>
  )
})

export default function DatePicker(
  { placeholderText, selected: value = null, onChange, ...restProps },
  className,
) {
  const [startDate, setStartDate] = useState(value)
  const years = range(1990, getYear(new Date()) + 1, 1)
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const handleChange = (date) => {
    setStartDate(date)
    onChange(date)
  }

  useEffect(() => {
    setStartDate(value)
  }, [value])

  return (
    <DPContainer className={className}>
      <ReactDatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
            }}
          >
            <LeftButton
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <span>
                <img src={DownArrow} alt="" />
              </span>
            </LeftButton>
            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <RightButton
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <img src={DownArrow} alt="" />
            </RightButton>
          </div>
        )}
        selected={startDate}
        onChange={handleChange}
        calendarClassName="gac-datepicker"
        customInput={<DPInput />}
        placeholderText={placeholderText}
        {...restProps}
      />
    </DPContainer>
  )
}

const LeftButton = styled.div`
  transform: rotate(90deg);
`
const RightButton = styled.div`
  transform: rotate(-90deg);
`

const DPContainer = styled.div`
  position: relative;
  width: 100%;
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input {
        width: 100%;
        background: #f2f2f2;
        border-radius: 0.3em;
        color: #7d7d7d;
        border: 1px solid transparent;
        padding: 0.8em;

        &:hover {
          background: none !important;
          background-image: none !important;
          background-color: #ececec;
          border: 1px solid var(--primary-color);
        }
      }
    }
  }
  .gac-datepicker {
    font-family: "century-gothic";
    box-shadow: 5px 10px 20px rgba(27, 27, 27, 0.38);
    border: none;
    .react-datepicker__header {
      padding: 0;
    }
    .react-datepicker__header select {
      border: none;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;

      padding-right: 2em;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url(${DownArrow});
      background-repeat: no-repeat;
      background-position: calc(100% - 3px) center;
      background-size: 0.8em;
    }

    .react-datepicker__header.react-datepicker__header--custom,
    .react-datepicker__day-names {
      background-color: white;
      border: none;
    }

    .react-datepicker__header > div:first-child {
      border-bottom: 0.9px solid #f4f4f4;
      padding: 1em 0;
      justify-content: space-around;
    }

    .react-datepicker__day-names {
      font-weight: 700;
      font-size: 1em;
      line-height: 12px;
      text-align: center;
      text-transform: uppercase;

      .react-datepicker__day-name {
        color: #7e818c;
        width: 2.5em;
        height: 2.5em;
        line-height: 2.5em;
        font-size: 1em;
        margin: 0 0.5em;
      }
    }

    .react-datepicker__day {
      font-style: normal;
      font-weight: 400;
      font-size: 1em;
      line-height: 1.2em;
      text-transform: uppercase;
      color: #0f2552;

      width: 2.5em;
      height: 2.5em;
      line-height: 2.5em;
      transition: all 0.5s ease-out;
      margin: 0.1em 0.5em;
    }
    .react-datepicker__day:hover:not(.react-datepicker__day--selected),
    .react-datepicker__month-text:hover:not(
        .react-datepicker__month-text--selected
      ),
    .react-datepicker__quarter-text:hover:not(
        .react-datepicker__quarter-text--selected
      ),
    .react-datepicker__year-text:hover:not(
        .react-datepicker__year-text--selected
      ) {
      border-radius: 50%;
      background: rgba(18, 42, 71, 0.14);
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background: #122a47;
      color: white;
      border-radius: 50%;
    }
  }
  .gac-datepicker-inline {
    font-family: "century-gothic";
    border: none;
    .react-datepicker__header {
      padding: 0;
    }
    .react-datepicker__header select {
      border: none;
      font-weight: 700;
      font-size: 1em;
      line-height: 1.5;
      padding: 0.5em 3em 0.5em 1em;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url(${DownArrow});
      background-repeat: no-repeat;
      background-position: calc(100% - 3px) center;
      background-size: 0.8em;
      > option {
        font-size: 1.2em;
      }
    }
    .react-datepicker__header.react-datepicker__header--custom,
    .react-datepicker__day-names {
      background-color: white;
      border: none;
    }

    .react-datepicker__header > div:first-child {
      border-bottom: 0.9px solid #f4f4f4;
      padding: 1em 0;
      justify-content: space-around;
    }

    .react-datepicker__month-container {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
    }

    .react-datepicker__day-names {
      font-weight: 700;
      font-size: 1em;
      line-height: 12px;
      text-align: center;
      text-transform: uppercase;

      .react-datepicker__day-name {
        color: #7e818c;
        width: 2.5em;
        font-size: 1em;
        margin: 0 0.5em;
      }
    }

    .react-datepicker__day {
      font-style: normal;
      font-weight: 400;
      font-size: 1em;
      line-height: 1.2em;
      text-transform: uppercase;
      color: #0f2552;
      width: 2.5em;
      height: 2.5em;
      line-height: 2.5em;
      transition: all 0.2s ease-out;
      margin: 0.1em 0.5em;
    }
    .react-datepicker__day:hover:not(.react-datepicker__day--selected),
    .react-datepicker__month-text:hover:not(
        .react-datepicker__month-text--selected
      ),
    .react-datepicker__quarter-text:hover:not(
        .react-datepicker__quarter-text--selected
      ),
    .react-datepicker__year-text:hover:not(
        .react-datepicker__year-text--selected
      ) {
      border-radius: 50%;
      background: rgba(18, 42, 71, 0.14);
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background: #122a47ee;
      color: white;
      border-radius: 50%;
    }
  }
`
