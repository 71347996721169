import { api } from "api"

/**
 * Intercom Report
 * @param {string} email
 * @param {string} message
 * @returns {Object}
 */
export const reportRequest = async (email, message) => {
  try {
    const response = await api.post(`/general_feedback/`, {
      email: email,
      message_body: message,
    })
    return response
  } catch (error) {
    throw new Error(error?.response?.data?.detail)
  }
}
