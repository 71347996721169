import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts"
import styled from "styled-components"

const data = [
  {
    year: 2017,
    dlvry: 10,
  },
  {
    year: 2018,
    dlvry: 35,
  },
  {
    year: 2019,
    dlvry: 60,
  },
  {
    year: 2020,
    dlvry: 80,
  },
  {
    year: 2021,
    dlvry: 70,
  },
  {
    year: 2022,
    dlvry: 100,
  },
]
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">
          <b>{` ${payload[0].value}`}</b>
        </p>
        <p className="desc">Unique deliveries</p>
      </div>
    )
  }

  return null
}
export default function GACAreaChart() {
  return (
    <Holder>
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#235C7C" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#235C7C" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="year" axisLine={false} tickLine={false} label={""} />
          <Tooltip
            content={<CustomTooltip />}
            wrapperStyle={{ backgroundColor: "white", padding: "10px" }}
          />
          <Area
            strokeWidth={3}
            type="monotone"
            dataKey="dlvry"
            stroke="#235C7C"
            fillOpacity={1}
            fill="url(#colorUv)"
            activeDot={{
              r: 8,
              fill: "red",
              strokeWidth: 5,
              stroke: "white",
              color: "red",
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Holder>
  )
}

const Holder = styled.section`
  position: relative;
  width: 100%;
  height: 32em;
  padding-top: 2em;
`
