import { useEffect, useRef, useState } from "react"
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
} from "styled-components"
import Div100vh from "react-div-100vh"
import { isMobile } from "react-device-detect"
import { useStore } from "state/store"
import { normalize } from "polished"
import { PrimeReactContext } from "primereact/api"

import theme from "./theme"
import { device } from "./device"

//Toastify
import "react-toastify/dist/ReactToastify.css"

//Load swiper css
import "swiper/css"

//Load lazy image
import "react-lazy-load-image-component/src/effects/blur.css"

//Load primereact styles
import "theme/md-light-deeppurple/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"

import IconArrowButtom from "assets/images/icon-arrow-button.svg"
import { isElementInViewport } from "utils"

PrimeReactContext.ripple = true
PrimeReactContext.inputStyle = "filled"
PrimeReactContext.zIndex = {
  modal: 10000, // dialog, sidebar
  overlay: 10000, // dropdown, overlaypanel
  menu: 1000, // overlay menus
  tooltip: 1100, // tooltip
  toast: 1200, // toast
}

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  body {
    color: #212121;
    font-Size: 16px;
    font-family: "century-gothic";
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    touch-action: pan-x pan-y;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    //user-select: none;
  }

  *::-webkit-scrollbar {
    width: 15px;
  }

  *::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #6b8fa3;
    border-radius: 10px;
    border: 5px solid #f1f1f1;
  }

  a {
    text-decoration: none;
  }

  .icon-common {
    width: 24px;
    height: 24px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    &.icon-arrow-button{
      background-image: url(${IconArrowButtom})
    }
  }

  .Toastify__toast-container{
    z-index: 20000;
  }

  .Toastify__toast--success {
    background: #3BBC97 !important;
  }

  .Toastify__toast--error {
    background: #F75151 !important;
  }

  .Toastify__toast--warning {
    //background: #FADFC5 !important;
  }

  .Toastify__toast-container--top-right{
    top: 6em;
    right: 2em;
  }

`

const MobileContainer = styled(Div100vh)`
  width: 100vw;
  font-size: 0.9em;
  position: relative;
`

function DesktopContainer({ children }) {
  const dataTablePagination = useStore((state) => state.dataTablePagination)
  const setDataTablePagination = useStore(
    (state) => state.setDataTablePagination,
  )
  const setCurrentScrollPosition = useStore(
    (state) => state.setCurrentScrollPosition,
  )
  const setCurrentSectionInView = useStore(
    (state) => state.setCurrentSectionInView,
  )
  const ref = useRef(null)
  const [scrollBottom, setScrollBottom] = useState(0)

  const handleScroll = (e) => {
    if (isElementInViewport("anchor_profile_what_to_know")) {
      setCurrentSectionInView("what_to_know")
    } else if (isElementInViewport("anchor_profile_analyst_notes")) {
      setCurrentSectionInView("analyst_notes")
    } else if (isElementInViewport("anchor_profile_vessel_identifiers")) {
      setCurrentSectionInView("vessel_identifiers")
    } else if (isElementInViewport("anchor_profile_vessel_network")) {
      setCurrentSectionInView("vessel_network")
    } else if (isElementInViewport("anchor_profile_linked_shipments")) {
      setCurrentSectionInView("linked_shipments")
    } else if (isElementInViewport("anchor_profile_cover_identities")) {
      setCurrentSectionInView("cover_identities")
    }
    setScrollBottom(e.target.scrollHeight - e.target.scrollTop)
    setCurrentScrollPosition(e.target.scrollTop)
  }

  useEffect(() => {
    if (dataTablePagination) {
      ref.current.scrollTop = ref.current.scrollHeight - scrollBottom
      setDataTablePagination(false)
    }
  }, [dataTablePagination, scrollBottom, setDataTablePagination])

  useEffect(() => {
    setScrollBottom(ref.current.scrollHeight - ref.current.scrollTop)
    setDataTablePagination(false)
  }, [])

  return (
    <DesktopHolder ref={ref} onScroll={handleScroll}>
      {children}
    </DesktopHolder>
  )
}

const DesktopHolder = styled.div`
  width: 100vw;
  height: 100vh;
  font-size: 1em;
  position: relative;
  overflow-x: hidden;
  @media ${device.laptop} {
    font-size: 1.1vw;
  }
  @media ${device.pad} {
    font-size: 0.8em;
  }
  @media ${device.mobileL} {
    font-size: 0.9em;
  }
  @media (max-width: 768px) {
    height: auto;
  }
`

export default function ThemeProvider({ children }) {
  const setCurrentScrollPosition = useStore(
    (state) => state.setCurrentScrollPosition,
  )

  const handleScroll = (e) => {
    setCurrentScrollPosition(e.target.scrollTop)
  }
  return (
    <StyledComponentsThemeProvider theme={theme}>
      <GlobalStyle />
      {isMobile ? (
        <MobileContainer> {children} </MobileContainer>
      ) : (
        <DesktopContainer onScroll={handleScroll}>{children}</DesktopContainer>
      )}
    </StyledComponentsThemeProvider>
  )
}
