import React, { memo, useMemo } from "react"
import { Checkbox } from "primereact/checkbox"
import styled from "styled-components"

import { H41 } from "components/Labels"

const CountryItem = (props) => {
  const toggleCheckbox = (checked) => {
    let temp = [...props.selected]
    if (checked) {
      temp = [...props.selected, props.item]
      props.setSelected([...temp])
    } else {
      temp = props.selected.filter((item) => item.id !== props.item.id)
      props.setSelected([...temp])
    }
    if (temp.length === props.countries.length) {
      props.setSelectAll(true)
    } else {
      props.setSelectAll(false)
    }
    const currentCountries = props.currentCountries.map((country) => country.id)
    const existingInFilter = temp.filter((country) =>
      currentCountries.includes(country.id),
    )
    props.setGroupedCountries({
      ...props.groupedCountries,
      [props.itemKey]: {
        ...props.groupedCountries[props.itemKey],
        isActive: existingInFilter.length === props.currentCountries.length,
      },
    })
  }
  const isChecked = useMemo(
    () => !!props.selected.filter((item) => item.id === props.item.id).length,
    [props],
  )
  return (
    <CheckboxContainer>
      <LeftSpacedElement>
        <Checkbox
          inputId="select_all"
          checked={isChecked}
          onChange={(e) => toggleCheckbox(e.checked)}
        />
      </LeftSpacedElement>
      <TextContainer
        onClick={() => {
          props.selectAOI(props.item)
          props.changePositionItem(props.item)
          if (!isChecked) {
            toggleCheckbox(true)
          }
        }}
      >
        <H41 title={props.item.name}>{props.item.name}</H41>
      </TextContainer>
    </CheckboxContainer>
  )
}

export default memo(CountryItem)

const TextContainer = styled.div`
  margin-left: 16px;
  cursor: pointer;
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`

const LeftSpacedElement = styled.div`
  margin-left: 18px;
`
