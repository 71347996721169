const LINKED_IDENTITY_KEYS = {
  id: "id",
  name: "name",
  date_first_observed: "date_first_observed",
  date_last_observed: "date_last_observed",
  target_vessel_id: "target_vessel_id",
  linked_vessel_id: "linked_vessel_id",
  imo: "imo",
  mmsi: "mmsi",
  source: "source",
  source_link: "source_link",
}

export { LINKED_IDENTITY_KEYS }
